// import {
//   Autocomplete,
//   Box,
//   Button,
//   Card,
//   CardActions,
//   CardContent,
//   CardMedia,
//   Checkbox,
//   Container,
//   FormControlLabel,
//   FormGroup,
//   IconButton,
//   InputAdornment,
//   Menu,
//   MenuItem,
//   OutlinedInput,
//   TextField,
//   Typography,
//   styled,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";

// import TableRow from "@mui/material/TableRow";
// import emp from "../images/emp.png";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// import { useDispatch, useSelector } from "react-redux";
// import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
// import {
//   bulkDepartmentUpdate,
//   deleteDepart,
//   departmentAssetDetails,
//   getDepartmentData,
// } from "../redux/data/action";
// import TableContainer from "@mui/material/TableContainer";

// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

// import Pagination from "../components/Pagination";
// import SuccessModal from "../components/SuccessModal";
// import AssetMovementModal from "../components/AssetMovementModal";
// import SimpleModal from "../components/SimpleModal";

// const Wrapper = styled(Box)(({ theme }) => ({
//   width: "100%",

//   display: "flex",
//   minHeight: "400px",

//   padding: "2%",
//   flexDirection: "column",
//   alignItems: "center",
//   background: "#faf5ee",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const BoxWrapper = styled(Box)(({ theme }) => ({
//   marginTop: "2%",

//   width: "100%",
//   borderRadius: "15px",

//   background: "white",
//   [theme.breakpoints.down("xl")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "800px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "500px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "370px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "280px",
//   },
// }));

// const TableHeading = styled(TableCell)(({ theme }) => ({
//   color: "#1746A2",
//   fontSize: "18px",
//   fontWeight: "500",
//   textAlign: "start",
//   bgcolor: "rgba(40, 98, 212, 0.1)",
//   // ,
//   [theme.breakpoints.down("xl")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const TableRows = styled(TableCell)(({ theme }) => ({
//   "&.MuiTableCell-root": {
//     fontSize: "18px",
//     fontWeight: "400",
//     color: "#212427",
//     textAlign: "start",
//     maxWidth: 130,
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     transition: "all 0.3s ease",
//     "&:hover": {
//       maxWidth: "none",
//       whiteSpace: "normal",
//       overflow: "visible",
//     },
//     [theme.breakpoints.down("xl")]: {
//       fontSize: "16px",
//     },
//     [theme.breakpoints.down("lg")]: {},
//     [theme.breakpoints.down("md")]: {},
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "12px",
//     },
//     [theme.breakpoints.down("xs")]: {},
//   },
// }));

// const TopContainer = styled(Box)(({ theme }) => ({
//   //
//   width: "100%",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   marginLeft: "10px",
//   marginRight: "10px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {
//     width: "800px",
//   },
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "column",
//     width: "500px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "370px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "280px",
//   },
// }));
// const TitleHead = styled(Typography)(({ theme }) => ({
//   fontSize: "24px",
//   fonteight: "400",
//   color: "#FF731D",
//   [theme.breakpoints.down("xl")]: {
//     fonteight: "400",
//     fontSize: "22px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     fonteight: "400",
//     fontSize: "22px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("md")]: {
//     fonteight: "500",
//     fontSize: "18px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fonteight: "500",
//     fontSize: "16px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));
// const SortFilterContainer = styled(Box)(({ theme }) => ({
//   display: "flex",

//   gap: "10px",
//   padding: "10px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {
//     flexDirection: "column",
//     gap: "10px",
//   },
//   [theme.breakpoints.down("md")]: {
//     // flexDirection: "row",
//     width: "98%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "98%",
//     justifyContent: "end",
//     alignItems: "end",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const SFContainer = styled(Box)(({ theme }) => ({
//   display: "flex",

//   alignItems: "end",
//   gap: "10px",
//   padding: "10px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "row",
//     width: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     flexDirection: "row",
//     width: "100%",
//     justifyContent: "end",
//     alignItems: "end",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const ColumnSet = styled(Box)(({ theme }) => ({
//   display: "flex",

//   alignItems: "start",
//   gap: "10px",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {
//     justifyContent: "left",
//     alignItems: "start",
//   },
//   [theme.breakpoints.down("md")]: {
//     // flexDirection: "row",

//     width: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     // flexDirection: "row",
//     width: "100%",
//     justifyContent: "end",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const Search = styled(OutlinedInput)(({ theme }) => ({
//   width: 230,
//   height: "40px",
//   borderRadius: "36px",
//   border: "1px solid rgba(23, 70, 162, 0.5)",
//   bgcolor: "white",
//   "& fieldset": { border: "none" },

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "140px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "150px",
//     height: "35px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterInnerContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "10px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterMain = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "10px",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterText = styled(Typography)(({ theme }) => ({
//   fontSize: "18px",
//   color: "#1746A2",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FormWrapper = styled(FormGroup)(({ theme }) => ({
//   display: "grid",
//   gridTemplateColumns: "repeat(2,1fr)",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));
// const Cards = styled(Card)(({ theme }) => ({
//   width: "688px",
//   height: "351px",
//   fontFamily: "Poppins",
//   fontStyle: "normal",
//   fontWeight: "500",
//   fontSize: "18px",
//   boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {
//     width: "80%",
//     height: "400px",
//     fontSize: "16px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "80%",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "80%",
//     // border: "1px solid orange",
//     marginTop: "2%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     marginTop: "2%",

//     fontSize: "18px",

//     width: "80%",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));
// const TitleBox = styled(Typography)(({ theme }) => ({
//   width: "100%",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "start",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "500px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "330px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "280px",
//   },
// }));

// const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
//   width: "230px",
//   height: "40px",
//   borderRadius: "36px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const DepartmentAssetDetails = () => {
//   const departAssetData = useSelector(
//     (store) => store.data.departmentAssetDetails
//   );
//   let userAccess =
//     JSON.parse(sessionStorage.getItem("user_access")) ||
//     JSON.parse(localStorage.getItem("user_access"));
//   const deleted = useSelector((store) => store.data.deleteAsset);
//   const token = localStorage.getItem("userToken");
//   let userIds = JSON.parse(localStorage.getItem("userData"));
//   const bulkDepartmentStatus = useSelector(
//     (store) => store.data.bulkDepartmentUpdate
//   );
//   const departmentData = useSelector((store) => store.data.department) || [];
//   const departmentAsset = localStorage.getItem("selectedDepartname");
//   const bulkChange = localStorage.getItem("bulkChange");
//   let userBranch =
//     JSON.parse(sessionStorage.getItem("userBranch")) ||
//     JSON.parse(localStorage.getItem("userBranch"));
//   const [selectAll, setSelectAll] = useState(false);
//   const [show, setShow] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const { id } = useParams();
//   let simpleText =
//     "Choose Department and select assets, then move it for a seamless asset relocation.";

//   const [filterdDepartment, setFilterdDepartment] = useState([]);
//   const dispatch = useDispatch();
//   const [data, setData] = useState([]);
//   const [selectedRow, setSelectedRow] = useState(0);
//   const navigate = useNavigate();

//   const [selectedData, setSelectedData] = useState({});
//   const [assetData, setAssetData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedCategories, setSelectedCategories] = useState([]);
//   const [selectedStatus, setSelectedStatus] = useState([]);
//   const [anchorElSort, setAnchorElSort] = React.useState(null);
//   const [anchorElFilter, setAnchorElFilter] = React.useState(null);
//   const [filterChanged, setFilterChanged] = useState(false);
//   const [question, setQuestion] = useState(false);
//   const [toggleDelete, setToggleDelete] = useState(false);
//   const openSort = Boolean(anchorElSort);
//   const openFilter = Boolean(anchorElFilter);
//   const [selectedAssetIds, setSelectedAssetIds] = useState([]);
//   const [selectedAssetName, setSelectedAssetName] = useState([]);
//   const [selectedAssetCode, setSelectedAssetCode] = useState([]);
//   const [successToggle, setSuccessToggle] = useState(false);
//   const [filteredDepartment, setFilteredDepartment] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(7);

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const amccmc = [...new Set(departAssetData?.map((item) => item.cmcamc))];

//   const status = [...new Set(departAssetData?.map((item) => item.status))];

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//     setPage(0);
//     setFilterChanged(true);
//   };
//   const handleChangePage = (event, newPage) => {
//     if (filterChanged) {
//       setPage(0);
//       setFilterChanged(false);
//     } else {
//       const filteredIncidents = filterProducts();
//       const newPageIndexStart = newPage * rowsPerPage;
//       const newPageIndexEnd = newPageIndexStart + rowsPerPage;
//       const currentPageIncidents = filteredIncidents.slice(
//         newPageIndexStart,
//         newPageIndexEnd
//       );

//       const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
//       if (newPage >= newPageCount) {
//         newPage = newPageCount - 1;
//       }

//       setPage(newPage);
//     }
//   };
//   const handleCategoryChange = (e) => {
//     const category = e.target.value;
//     setSelectedCategories((prevCategories) =>
//       prevCategories?.includes(category)
//         ? prevCategories?.filter((c) => c !== category)
//         : [...prevCategories, category]
//     );
//     setPage(0);
//     setFilterChanged(true);
//   };
//   const handleStatusChange = (e) => {
//     const status = e.target.value;
//     setSelectedStatus((prevCategories) =>
//       prevCategories?.includes(status)
//         ? prevCategories?.filter((c) => c !== status)
//         : [...prevCategories, status]
//     );
//     setPage(0);
//     setFilterChanged(true);
//   };

//   const filterProducts = () => {
//     let filteredProducts = departAssetData;

//     filteredProducts = filteredProducts?.filter(
//       (product) =>
//         product?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
//     );

//     if (selectedCategories?.length > 0) {
//       filteredProducts = filteredProducts?.filter((product) =>
//         selectedCategories?.includes(product.cmcamc)
//       );
//     }

//     if (selectedStatus?.length > 0) {
//       filteredProducts = filteredProducts?.filter((product) =>
//         selectedStatus?.includes(product.status)
//       );
//     }
//     return filteredProducts;
//   };

//   const filteredProducts = filterProducts();

//   const handleFilter = (event) => {
//     setAnchorElFilter(event.currentTarget);
//   };

//   const handleCloseSort = () => {
//     setAnchorElSort(null);
//   };

//   const handleSort = (event) => {
//     setAnchorElSort(event.currentTarget);
//   };
//   const handleCloseFilter = () => {
//     setAnchorElFilter(null);
//   };

//   const sortHighToLow = () => {
//     let sorted = assetData.sort((a, b) => {
//       if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
//         const dateA = new Date(a.cmcamcdate?.split("-").reverse().join("-"));
//         const dateB = new Date(b.cmcamcdate?.split("-").reverse().join("-"));
//         return dateA - dateB;
//       } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
//         return 1;
//       } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
//         return -1;
//       } else {
//         return 0;
//       }
//     });

//     setAssetData(sorted);
//     handleCloseSort();
//   };
//   const sortLowToHigh = () => {
//     let sorted = assetData.sort((a, b) => {
//       if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
//         const dateA = new Date(a.cmcamcdate?.split("-").reverse().join("-"));
//         const dateB = new Date(b.cmcamcdate?.split("-").reverse().join("-"));
//         return dateB - dateA;
//       } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
//         return 1;
//       } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
//         return -1;
//       } else {
//         return 0;
//       }
//     });

//     setAssetData(sorted);
//     handleCloseSort();
//   };

//   const handleSelectRow = (rowIndex) => {
//     setSelectedRow(rowIndex);
//   };

//   useEffect(() => {
//     let data = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       deptid: id,
//       branchid: userBranch.id,
//     };

//     dispatch(departmentAssetDetails(data, token));
//   }, [dispatch, deleted, successToggle]);

//   useEffect(() => {
//     if (departAssetData && departAssetData?.length > 0) {
//       setData(departAssetData[0].department);
//       setAssetData(departAssetData);
//     }
//   }, [departAssetData, successToggle]);

//   useEffect(() => {
//     return () => {
//       localStorage.removeItem("selectedDepartname");
//     };
//   }, []);

//   useEffect(() => {
//     return () => {
//       localStorage.removeItem("bulkChange");
//     };
//   }, []);

//   useEffect(() => {
//     let data = {
//       hospid: userIds?.hospid,
//       userid: userIds?.userid,
//       branchid: userBranch.id,
//       depart_ids: userAccess.departmentids,
//     };
//     dispatch(getDepartmentData(data, token));
//   }, [dispatch, successToggle]);

//   useEffect(() => {
//     let filtered = departmentData?.filter((department) => {
//       return department?.departid !== id;
//     });

//     setFilteredDepartment([...filtered]);
//   }, []);

//   const sortDataByKey = (key, ascending) => {
//     let sorted = assetData.sort((a, b) => {
//       const valueA = a[key].trim().toLowerCase();
//       const valueB = b[key].trim().toLowerCase();
//       return ascending
//         ? valueA.localeCompare(valueB)
//         : valueB.localeCompare(valueA);
//     });
//     setAssetData(sorted);
//     handleCloseSort();
//   };

//   const handleDepartment = (event, value) => {
//     if (value !== null && value !== undefined) {
//       setSelectedData(value);
//     }
//     if (value === null) {
//       setSelectedData({});
//     }
//   };

//   const handleSelectRowClick = (assetId, assetName, assetCode) => {

//     const selectedIndex = selectedAssetIds.indexOf(assetId);
//     const selectedIndexName = selectedAssetName.indexOf(assetName);
//     const selectedIndexCode = selectedAssetCode.indexOf(assetCode);

//     let newSelected = [];
//     let newNameSelected = [];
//     let newCodeSelected = [];
//     if (
//       selectedIndex === -1 ||
//       newNameSelected === -1 ||
//       newCodeSelected === -1
//     ) {
//       // Asset is not selected, add it to the array
//       newSelected = [...selectedAssetIds, assetId];
//       newNameSelected = [...selectedAssetName, assetName];
//       newCodeSelected = [...selectedAssetCode, assetCode];
//     } else {
//       // Asset is selected, remove it from the array
//       newSelected = [
//         ...selectedAssetIds.slice(0, selectedIndex),
//         ...selectedAssetIds.slice(selectedIndex + 1),
//       ];
//       newNameSelected = [
//         ...selectedAssetName.slice(0, selectedIndexName),
//         ...selectedAssetName.slice(selectedIndexName + 1),
//       ];
//       newCodeSelected = [
//         ...selectedAssetCode.slice(0, selectedIndexCode),
//         ...selectedAssetCode.slice(selectedIndexCode + 1),
//       ];

//       if (newSelected?.length === 0) {
//         setSelectedData({});
//       }
//     }

//     setSelectedAssetIds(newSelected);
//     setSelectedAssetName(newNameSelected);
//     setSelectedAssetCode(newCodeSelected);
//   };

//   const handleSelectAllClick = () => {
//     const allAssetIds = assetData?.map((item) => item.assetid);
//     const allAssetName = assetData?.map((item) => item.name);
//     const allAssetCode = assetData?.map((item) => item.assetcode);

//     if (selectAll) {
//       setSelectedAssetIds([]);
//       setSelectedAssetName([]);
//       setSelectedAssetCode([]);
//       setSelectedData({});
//     } else {
//       setSelectedAssetIds(allAssetIds);
//       setSelectedAssetName(allAssetName);
//       setSelectedAssetCode(allAssetCode);
//     }

//     setSelectAll(!selectAll);
//   };

//   const handleMove = () => {
//     setQuestion(true);

//     // setToggleDelete(true)
//   };

//   const handleCloseSuccessModal = () => {
//     setSuccessToggle(false);
//   };

//   const handleMoveBulk = () => {
//     let data = {
//       hospid: userIds.hospid,
//       hospname: userIds.hospname,
//       userid: userIds.userid,
//       username: userIds.username,
//       assetids: selectedAssetIds,
//       assetnames: selectedAssetName,
//       assetcodes: selectedAssetCode,
//       p_depart: departmentAsset,
//       p_departid: id,
//       to_depart: selectedData.name,
//       to_departid: selectedData.id,
//       branchid: userBranch.id,
//     };

//     dispatch(bulkDepartmentUpdate(data));

//     setQuestion(false);
//     setSuccessToggle(true);
//     setSelectedData({});
//     setShow(false);
//     setSelectedAssetIds([]);
//     setSelectedAssetName([]);
//     setSelectedAssetCode([]);
//   };

//   const handleCloseQuestion = () => {
//     setQuestion(false);
//   };

//   const handleButton = () => {
//     setShow(true);
//     setShowModal(true);
//   };

//   const handleCloseSimple = () => {
//     // setShow(false)
//     setShowModal(false);
//   };

//   return (
//     <Wrapper>
//       <TopContainer>
//         <TitleBox>
//           {departmentAsset ? (
//             <TitleHead>{departmentAsset} Assets</TitleHead>
//           ) : (
//             <TitleHead>{data} Assets</TitleHead>
//           )}
//           {departAssetData?.length > 0 && (
//             <Typography
//               sx={{
//                 color: "#1746A2",
//               }}>{`Total : ${departAssetData?.length}`}</Typography>
//           )}
//         </TitleBox>

//         {departAssetData?.length > 0 && (
//           <>
//             <SortFilterContainer>
//               <SFContainer>
//                 {bulkChange && (
//                   <>
//                     <ColumnSet>
//                       {!show && (
//                         <Button
//                           variant="contained"
//                           disabled={selectedAssetIds?.length == 0}
//                           onClick={handleButton}
//                           sx={{
//                             bgcolor: "#1746A2",
//                             textTransform: "none",
//                             width: "180px",
//                           }}>
//                           Select Department
//                         </Button>
//                       )}

//                       {show && selectedAssetIds?.length > 0 && (
//                         <CustomAutoComplete
//                           sx={{
//                             bgcolor: "transparent",
//                             "& .MuiOutlinedInput-root": {
//                               padding: "0px",
//                             },
//                           }}
//                           options={departmentData
//                             ?.filter((department) => {
//                               return department?.departid !== id;
//                             })
//                             ?.map((option) => ({
//                               name: option.departname,
//                               id: option?.departid,
//                             }))}
//                           // value={{ name: department, id:departmentId }}
//                           clearOnBlur={true}
//                           clearOnEscape={true}
//                           getOptionLabel={(option) => option.name}
//                           onChange={handleDepartment}
//                           renderInput={(params) => (
//                             <TextField
//                               sx={{
//                                 bgcolor: "transparent", // Add other styles as needed
//                               }}
//                               placeholder="e.g. Emergency Department"
//                               {...params}
//                             />
//                           )}
//                         />
//                       )}
//                     </ColumnSet>
//                   </>
//                 )}

//                 {show && selectedAssetIds?.length > 0 && (
//                   <Button
//                     disabled={Object.keys(selectedData)?.length === 0}
//                     sx={{
//                       borderRadius: "15px",
//                       bgcolor: "#1746A2",
//                       height: "40px",
//                     }}
//                     onClick={handleMove}
//                     variant="contained">
//                     Submit
//                   </Button>
//                 )}
//               </SFContainer>

//               <SFContainer>
//                 <Search
//                   type="search"
//                   placeholder="Search"
//                   value={searchQuery}
//                   onChange={handleSearchChange}
//                   startAdornment={
//                     <InputAdornment position="start">
//                       <IconButton
//                         placeholder="Search"
//                         aria-label="toggle password visibility"
//                         edge="start">
//                         <SearchOutlinedIcon />
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                 />

//                 <div>
//                   <IconButton
//                     id="basic-button"
//                     aria-controls={openSort ? "basic-menu" : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={openSort ? "true" : undefined}
//                     onClick={handleSort}>
//                     <SortByAlphaIcon sx={{ color: "#FF731D" }} />
//                   </IconButton>
//                   <Menu
//                     id="basic-menu"
//                     anchorEl={anchorElSort}
//                     open={openSort}
//                     onClose={handleCloseSort}
//                     MenuListProps={{
//                       "aria-labelledby": "basic-button",
//                     }}>
//                     <MenuItem sx={{ color: "#FF731D" }}>Sort by Name</MenuItem>
//                     <MenuItem onClick={() => sortDataByKey("name", true)}>
//                       A-Z
//                     </MenuItem>
//                     <MenuItem onClick={() => sortDataByKey("name", false)}>
//                       Z-A
//                     </MenuItem>
//                     <MenuItem onClick={sortLowToHigh}>
//                       Newest to oldest
//                     </MenuItem>
//                     <MenuItem onClick={sortHighToLow}>
//                       Oldest to newest
//                     </MenuItem>
//                   </Menu>
//                 </div>

//                 <div>
//                   <IconButton
//                     id="basic-button"
//                     aria-controls={openFilter ? "basic-menu" : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={openFilter ? "true" : undefined}
//                     onClick={handleFilter}>
//                     <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
//                   </IconButton>
//                   <Menu
//                     id="basic-menu"
//                     anchorEl={anchorElFilter}
//                     open={openFilter}
//                     onClose={handleCloseFilter}
//                     MenuListProps={{
//                       "aria-labelledby": "basic-button",
//                     }}>
//                     <MenuItem>
//                       <FilterMain>
//                         <FilterText display="block">Department</FilterText>

//                         <FilterInnerContainer>
//                           <FormWrapper>
//                             {amccmc?.map((item) => (
//                               <FormControlLabel
//                                 control={
//                                   <Checkbox
//                                     color="warning"
//                                     type="checkbox"
//                                     value={item}
//                                     checked={selectedCategories.includes(item)}
//                                     onChange={handleCategoryChange}
//                                     name="gilad"
//                                   />
//                                 }
//                                 label={item}
//                               />
//                             ))}
//                           </FormWrapper>
//                         </FilterInnerContainer>
//                       </FilterMain>
//                     </MenuItem>
//                     <MenuItem>
//                       <FilterMain>
//                         <FilterText display="block">Working Status</FilterText>
//                         <FilterInnerContainer>
//                           <FormWrapper>
//                             {status?.map((item) => (
//                               <FormControlLabel
//                                 control={
//                                   <Checkbox
//                                     color="warning"
//                                     type="checkbox"
//                                     value={item}
//                                     checked={selectedStatus.includes(item)}
//                                     onChange={handleStatusChange}
//                                     name="gilad"
//                                   />
//                                 }
//                                 label={item}
//                               />
//                             ))}
//                           </FormWrapper>
//                         </FilterInnerContainer>
//                       </FilterMain>
//                     </MenuItem>
//                   </Menu>
//                 </div>
//               </SFContainer>
//             </SortFilterContainer>
//           </>
//         )}
//       </TopContainer>

//       {assetData?.length === 0 || filteredProducts?.length === 0 ? (
//         <BoxWrapper
//           style={{ overflowX: "auto" }}
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             borderRadius: " 15px ",
//             justifyContent: "center",
//             alignItems: "center",
//             minHeight: "500px",
//           }}>
//           <Cards>
//             <CardMedia
//               component="img"
//               sx={{ width: 151 }}
//               image={emp}
//               alt="green iguana"
//             />
//             <CardContent>
//               <Typography
//                 sx={{ color: " #212427" }}
//                 color="text.secondary"
//                 gutterBottom>
//                 There is no assets in this department. Please add asset.
//               </Typography>
//             </CardContent>
//             <CardActions>
//               <Button
//                 onClick={() => navigate("/add-asset")}
//                 sx={{
//                   borderRadius: "35px",
//                   textTransform: "none",
//                   bgcolor: "#1746A2",
//                 }}
//                 variant="contained">
//                 Add Asset
//               </Button>
//             </CardActions>
//           </Cards>
//         </BoxWrapper>
//       ) : (
//         <>
//           <BoxWrapper style={{ overflowX: "auto" }}>
//             <Box sx={{ overflow: "auto" }}>
//               <TableContainer
//                 sx={{
//                   width: "100%",

//                   // display: "table",
//                   // tableLayout: "fixed",
//                   maxHeight: 500,
//                 }}>
//                 <Table
//                   stickyHeader
//                   sx={{
//                     borderRadius: " 15px",
//                     bgcolor: "white",
//                     // border: "6px solid green",
//                     whiteSpace: "nowrap",
//                   }}>
//                   <TableHead
//                     // style={{ borderRadius: "30px" }}
//                     sx={{
//                       borderRadius: " 15px 15px 0px 0px",
//                     }}>
//                     <TableRow
//                       selected={selectedRow === 0}
//                       onClick={() => handleSelectRow(0)}
//                       sx={{
//                         borderRadius: " 15px 15px 0px 0px",
//                         bgcolor: "rgba(146, 25, 118, 0.1)",
//                       }}>
//                       {bulkChange && (
//                         <TableCell>
//                           <Checkbox
//                             color="primary"
//                             indeterminate={
//                               selectedAssetIds?.length > 0 &&
//                               selectedAssetIds?.length < assetData?.length
//                             }
//                             checked={
//                               selectedAssetIds?.length === assetData?.length
//                             }
//                             onChange={handleSelectAllClick}
//                             inputProps={{
//                               "aria-label": "select all assets",
//                             }}
//                           />
//                         </TableCell>
//                       )}

//                       <TableHeading
//                         sx={{
//                           borderRadius: "15px 0px 0px 0px",
//                         }}>
//                         Asset Name
//                       </TableHeading>
//                       <TableHeading sx={{}}>Brand</TableHeading>
//                       <TableHeading sx={{}}>Asset Code</TableHeading>
//                       {/* <TableHeading sx={{}}>Department</TableHeading> */}
//                       <TableHeading sx={{ textAlign: "center" }}>
//                         Status
//                       </TableHeading>
//                       <TableHeading sx={{ textAlign: "start" }}>
//                         AMC/CMC
//                       </TableHeading>
//                       <TableHeading sx={{}}>Date</TableHeading>

//                       <TableHeading
//                         sx={{
//                           borderRadius: "0px 15px 0px 0px",
//                         }}></TableHeading>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody sx={{ textDecoration: "none" }}>
//                     {filteredProducts
//                       ?.slice(
//                         page * rowsPerPage,
//                         page * rowsPerPage + rowsPerPage
//                       )
//                       .map((item, index) => (
//                         <TableRow
//                           key={item.assetid}
//                           sx={{
//                             borderRadius: " 15px 15px 0px 0px",
//                             "&:hover": {
//                               backgroundColor: "#E8ECF6",
//                             },
//                             textDecoration: "none",
//                           }}
//                           onClick={() => handleSelectRow(0)}>
//                           {bulkChange && (
//                             <TableCell>
//                               <Checkbox
//                                 checked={selectedAssetIds.includes(
//                                   item.assetid
//                                 )}
//                                 onChange={() =>
//                                   handleSelectRowClick(
//                                     item.assetid,
//                                     item.name,
//                                     item.assetcode
//                                   )
//                                 }
//                               />
//                             </TableCell>
//                           )}

//                           <TableRows
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {" "}
//                             <Link
//                               style={{ textDecoration: "none", color: "black" }}
//                               sx={{
//                                 fontSize: {
//                                   xl: "18px",
//                                   lg: "16px",
//                                   md: "14px",
//                                   sm: "12px",
//                                   xs: "10px",
//                                 },
//                               }}
//                               component="button"
//                               variant="body2"
//                               // onClick={() => {
//                               //   console.info("I'm a button.");
//                               // }}

//                               to={`/asset-detail/${item?.assetid}`}>
//                               {item.name}
//                             </Link>
//                           </TableRows>
//                           <TableRows
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {item.brand}
//                           </TableRows>
//                           <TableRows
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {item.assetcode}
//                           </TableRows>

//                           {/* <TableRows sx={{}}>{item.department}</TableRows> */}
//                           <TableRows
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textAlign: "center", textDecoration: "none" }}
//                             style={{ whiteSpace: "nowrap" }}>
//                             <Box
//                               sx={{
//                                 borderRadius: "30px",

//                                 textAlign: "center",
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                                 fontSize: {
//                                   xl: "18px",
//                                   lg: "16px",
//                                   md: "14px",
//                                   sm: "12px",
//                                   xs: "10px",
//                                 },
//                                 padding: "2px",
//                                 height: "30px",
//                                 width: {
//                                   xl: "150px",
//                                   md: "145px",
//                                   sm: "100px",
//                                 },
//                                 margin: "auto",

//                                 border:
//                                   item.status === "Working"
//                                     ? "1px solid #00A884"
//                                     : "1px solid #FF4B4B",
//                                 color:
//                                   item.status === "Working"
//                                     ? " #00A884"
//                                     : " #FF4B4B",
//                               }}>
//                               {item.status}
//                             </Box>
//                           </TableRows>
//                           <TableRows
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textAlign: "right", textDecoration: "none" }}>
//                             {item.cmcamc}
//                           </TableRows>
//                           <TableRows
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {" "}
//                             {item.cmcamcdate}
//                           </TableRows>
//                           <TableRows
//                             component={Link}
//                             to={`/asset-detail/${item?.assetid}`}
//                             sx={{ textDecoration: "none" }}>
//                             {/* <cccccccccccc? */}
//                           </TableRows>
//                         </TableRow>
//                       ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//               <Pagination
//                 filteredProducts={filteredProducts}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 handleChangePage={handleChangePage}
//                 handleChangeRowsPerPage={handleChangeRowsPerPage}
//               />
//             </Box>
//           </BoxWrapper>
//         </>
//       )}

//       <SuccessModal
//         openModal={bulkDepartmentStatus}
//         handleCloseModal={handleCloseSuccessModal}
//         text={"Asset moved"}
//         toggle={successToggle}
//       />

//       <AssetMovementModal
//         open={question}
//         onClose={handleCloseQuestion}
//         onDelete={handleMoveBulk}
//         count={selectedAssetIds.length}
//         prevDep={departmentAsset}
//         current={selectedData.name}
//       />

//       {/* <SimpleModal openModal={showModal}  text={simpleText} closeModal={handleCloseSimple}   /> */}
//     </Wrapper>
//   );
// };

// export default DepartmentAssetDetails;

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TableSortLabel,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import emp from "../images/noAsset.png";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkAssetDelete,
  deleteAsset,
  departmentAssetDetails,
  retrieveAllAssets,
} from "../redux/nbmData/action";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import DialogBox from "../components/DialogBox";
import DeleteModal from "../components/DeleteModal";
import Pagination from "../components/Pagination";
import NoData from "../components/NoData";
import TableSort from "../components/TableSort";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "../components/FilterMenu";
import BulkDeleteDeleteModal from "../components/BulkDeleteModal";
import SuccessModal from "../components/SuccessModal";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  minHeight: "400px",
  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageCard = styled(Box)(({ theme }) => ({
  width: "320px",
  height: "320px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "151px",
    height: "151px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyButtonSet = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "500",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  // border: "1px solid green",
  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "20px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const SplitContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MultiSplitContainer = styled(Box)(({ theme }) => ({
  //

  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  bgcolor: "#1746A2",
  textTransform: "none",
  fontSize: "19px",
  padding: "2%",
  margin: "auto",
  borderRadius: "40px",
  color: "white",
  width: "130px",
  height: "46px",

  [theme.breakpoints.down("xl")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("md")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "130px",
    height: "46px",
  },
}));

const EmptyButton = styled(Button)(({ theme }) => ({
  bgcolor: "#1746A2",
  textTransform: "none",
  fontSize: "19px",
  padding: "2%",
  margin: "auto",
  borderRadius: "40px",
  color: "white",
  width: "140px",
  height: "46px",

  [theme.breakpoints.down("xl")]: {
    width: "140px",
    height: "46px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "140px",
    height: "46px",
  },
  [theme.breakpoints.down("md")]: {
    width: "140px",
    height: "46px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "46px",
  },
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",
  "& fieldset": { border: "none" },
  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "180px",
    height: "35px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const CountText = styled(Typography)(({ theme }) => ({
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  background: "#FF731D",
  textTransform: "none",
  color: "white",
  borderRadius: "15px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DepartmentAssetDetails = () => {
  const allAssets = useSelector(
    (store) => store.nbmData.departmentAssetDetails
  );
  const { id } = useParams();
  const deletedStatus = useSelector((store) => store.nbmData.bulkAssetDelete);
  const [successToggle, setSuccessToggle] = useState(false);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [getId, setGetId] = useState("");
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const deleted = useSelector((store) => store.nbmData.deleteAsset);
  const department = [...new Set(allAssets?.map((item) => item.department))];
  const status = [...new Set(allAssets?.map((item) => item.status))];

  const amccmc = [...new Set(allAssets?.map((item) => item.cmcamc))];

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);

  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);

  const [toggle, setToggle] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAmcCmc, setSelectedAmcCmc] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const [anchorElSort2, setAnchorElSort2] = React.useState(null);
  const [filterChanged, setFilterChanged] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);
  const [anchorElFilter2, setAnchorElFilter2] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const departmentAsset = localStorage.getItem("selectedDepartname");
  const handleSelectAllClick = () => {
    const allAssetIds = data?.map((item) => item.assetid);

    if (selectAll) {
      // Deselect all
      setSelectedAssetIds([]);
    } else {
      // Select all
      setSelectedAssetIds(allAssetIds);
    }

    setSelectAll(!selectAll);
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Asset is not selected, add it to the array
      newSelected = [...selectedAssetIds, assetId];
    } else {
      // Asset is selected, remove it from the array
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
    }

    setSelectedAssetIds(newSelected);
  };

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  }, [filterValue]);

  const handleClickFilter2 = (event) => {
    setAnchorElFilter2(event.currentTarget);
  };

  const handleCloseFilter2 = () => {
    setAnchorElFilter2(null);
  };

  const handleFilter2 = (status) => {
    setFilterValue(status);
    setAnchorElFilter2(null);
    setPage(0);
    setFilterChanged(true);
  };

  const text = "asset";

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  const handleButtonClick = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      navigate("/bulk-upload-asset");
    }
  };
  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
      handleCloseSort2();
    } else {
      downloadCsvFile();
      handleCloseSort2();
    }
  };
  const handleButtonClick3 = () => {
    if (ids.subs_plan === "Basic" && allAssets?.length >= 50) {
      setOpenModal(true);
    } else {
      navigate("/add-asset");
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openSort2 = Boolean(anchorElSort2);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories?.includes(category)
        ? prevCategories?.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories?.includes(status)
        ? prevCategories?.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleAmcCmcChange = (e) => {
    const amccmc = e.target.value;
    setSelectedAmcCmc((prevCategories) =>
      prevCategories?.includes(amccmc)
        ? prevCategories?.filter((c) => c !== amccmc)
        : [...prevCategories, amccmc]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = [...data];

    filteredProducts = filteredProducts?.filter(
      (product) =>
        product?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.serialno.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedCategories?.includes(product.department)
      );
    }

    if (selectedStatus?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedStatus?.includes(product.status)
      );
    }
    if (selectedAmcCmc?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedAmcCmc?.includes(product.cmcamc)
      );
    }
    return filteredProducts;
  };

  const filteredProducts = filterProducts();

  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);

    setGetId(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleSort2 = (event) => {
    setAnchorElSort2(event.currentTarget);
  };
  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate.split("-").reverse().join("-"));
        return dateA - dateB;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate.split("-").reverse().join("-"));
        return dateB - dateA;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(retrieveAllAssets(data, token));
    setToggle(false);
  }, [dispatch, deleted, toggle, deletedStatus]);

  const handleDelete = (e, item, id) => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetid: getId,
      branchid: userBranch.id,
    };
    setToggle(true);
    setSuccessToggle(true);
    dispatch(deleteAsset(data, token));
    setData(allAssets?.filter((el) => el?.assetid !== getId));
    handleCloseDeleteModal();
  };

  const handleEdit = () => {
    navigate(`/edit-asset/${getId}`);
  };

  useEffect(() => {
    if (allAssets && allAssets?.length > 0) {
      setData(allAssets);
    }
  }, [allAssets, deleted, deletedStatus]);

  const handleDeleteById = () => {
    handleClose();
    handleOpenDeleteModal();
    setToggle(true);
  };
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...allAssets].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    data.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(allAssets);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const theme = useTheme();
  const isMatch2 = useMediaQuery(theme.breakpoints.down("md"));

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.name;
      case "date":
        return item.cmcamcdate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  const handleBulkDelete = () => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetids: selectedAssetIds,
      branchid: userBranch.id,
    };
    dispatch(bulkAssetDelete(data));
    setToggle(true);
    setOpenBulkDeleteModal(false);
    setSuccessToggle(true);
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem("selectedDepartname");
    };
  }, []);

  // useEffect(() => {
  //   let data = {
  //     hospid: ids.hospid,
  //     userid: ids.userid,
  //     deptid: id,
  //     branchid: userBranch.id,
  //   };

  //   dispatch(departmentAssetDetails(data, token));
  // }, [dispatch, deleted, successToggle]);
  useEffect(() => {
    const isAllPresent =
      userAccess.departmentids.includes("all") ||
      userAccess.departments.includes("All");
    console.log("isAllPresent", isAllPresent);

    const isIdMatched = id === "all" || userAccess.departmentids.includes(id);
    console.log("isIdMatched", isIdMatched);

    if (isAllPresent || isIdMatched) {
      let data = {
        hospid: ids.hospid,
        userid: ids.userid,
        deptid: id,
        branchid: userBranch.id,
      };

      dispatch(departmentAssetDetails(data, token));
    } else {
      let data = {
        hospid: ids.hospid,
        userid: ids.userid,
        deptid: "",
        branchid: userBranch.id,
      };

      dispatch(departmentAssetDetails(data, token));
    }
  }, [dispatch, deleted, successToggle]);

  const handleSuccessCloseModal = () => {
    setSuccessToggle(false);
  };

  return (
    <Wrapper>
      {!isMatch2 ? (
        <TopContainer>
          <>
            <TitleBox>
              {departmentAsset ? (
                <TitleHead sx={{ textAlign: "start" }}>
                  {departmentAsset} Assets
                </TitleHead>
              ) : (
                <TitleHead sx={{ textAlign: "start" }}>
                  {allAssets[0]?.department} Assets
                </TitleHead>
              )}
              {allAssets?.length > 0 && (
                <Typography
                  sx={{
                    color: "#1746A2",
                  }}>{`Total : ${allAssets?.length}`}</Typography>
              )}
            </TitleBox>

            {allAssets?.length > 0 && (
              <SortFilterContainer>
                <SplitContainer>
                  <MultiSplitContainer>
                    {selectedAssetIds?.length > 0 && (
                      <DeleteButton
                        disabled={userAccess.role === "Guest"}
                        variant="contained"
                        onClick={handleOpenBulkDeleteModal}
                        aria-label="delete">
                        Delete
                      </DeleteButton>
                    )}
                    <Container>
                      <Button
                        onClick={handleButtonClick}
                        disabled={
                          userAccess?.role !== "Admin" &&
                          userAccess?.role !== "Super_Admin" &&
                          userAccess?.role !== "Owner" &&
                          userAccess?.role !== "Branch_Admin"
                        }
                        sx={{
                          bgcolor:
                            ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                          borderRadius: "30px",
                          "&:hover": {
                            background:
                              ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                          },
                          width: "150px",
                          textTransform: "none",
                        }}
                        variant="contained">
                        Bulk Upload
                      </Button>
                    </Container>
                  </MultiSplitContainer>

                  <MultiSplitContainer>
                    <Container>
                      <Search
                        type="search"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              placeholder="Search"
                              aria-label="toggle password visibility"
                              edge="start">
                              <SearchOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Container>

                    <div>
                      <Tooltip title="Sort">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort ? "true" : undefined}
                          onClick={handleSort}>
                          <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort}
                        open={openSort}
                        onClose={handleCloseSort}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem sx={{ color: "#FF731D" }}>Name Sort</MenuItem>
                        <MenuItem onClick={() => sortDataByKey("name", true)}>
                          A-Z
                        </MenuItem>
                        <MenuItem onClick={() => sortDataByKey("name", false)}>
                          Z-A
                        </MenuItem>
                        <MenuItem sx={{ color: "#FF731D" }}>Date Sort</MenuItem>
                        <MenuItem onClick={sortLowToHigh}>
                          Newest to oldest
                        </MenuItem>
                        <MenuItem onClick={sortHighToLow}>
                          Oldest to newest
                        </MenuItem>
                      </Menu>
                    </div>

                    <div>
                      <Tooltip title="Filter">
                        <IconButton
                          id="basic-button"
                          aria-controls={openFilter ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openFilter ? "true" : undefined}
                          onClick={handleFilter}>
                          <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElFilter}
                        open={openFilter}
                        onClose={handleCloseFilter}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem>
                          <FilterMain>
                            <FilterText display="block">Department</FilterText>

                            <FilterInnerContainer>
                              <FormWrapper>
                                {department?.map((item) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="warning"
                                        type="checkbox"
                                        value={item}
                                        checked={selectedCategories.includes(
                                          item
                                        )}
                                        onChange={handleCategoryChange}
                                        name="gilad"
                                      />
                                    }
                                    label={item}
                                  />
                                ))}
                              </FormWrapper>
                            </FilterInnerContainer>
                          </FilterMain>
                        </MenuItem>
                        <MenuItem>
                          <FilterMain>
                            <FilterText display="block">
                              Working Status
                            </FilterText>
                            <FilterInnerContainer>
                              <FormWrapper>
                                {status?.map((item) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="warning"
                                        type="checkbox"
                                        value={item}
                                        checked={selectedStatus.includes(item)}
                                        onChange={handleStatusChange}
                                        name="gilad"
                                      />
                                    }
                                    label={item}
                                  />
                                ))}
                              </FormWrapper>
                            </FilterInnerContainer>
                          </FilterMain>
                        </MenuItem>
                        <MenuItem>
                          <FilterMain>
                            <FilterText display="block">AMC/CMC </FilterText>
                            <FilterInnerContainer>
                              <FormWrapper>
                                {amccmc?.map((item) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="warning"
                                        type="checkbox"
                                        value={item}
                                        checked={selectedAmcCmc.includes(item)}
                                        onChange={handleAmcCmcChange}
                                        name="gilad"
                                      />
                                    }
                                    label={item}
                                  />
                                ))}
                              </FormWrapper>
                            </FilterInnerContainer>
                          </FilterMain>
                        </MenuItem>
                      </Menu>
                    </div>

                    <div>
                      <Tooltip title="Export to csv">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort2 ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort2 ? "true" : undefined}
                          onClick={handleSort2}>
                          <BrowserUpdatedIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort2}
                        open={openSort2}
                        onClose={handleCloseSort2}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem
                          onClick={handleButtonClick2}
                          sx={{
                            color:
                              ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                          }}>
                          Export to csv
                        </MenuItem>
                      </Menu>
                    </div>
                  </MultiSplitContainer>
                </SplitContainer>
              </SortFilterContainer>
            )}
          </>
        </TopContainer>
      ) : (
        <TopContainer sx={{}}>
          <>
            <SortFilterContainer sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                  marginTop: "2%",
                }}>
                <TitleBox>
                  {departmentAsset ? (
                    <TitleHead sx={{ textAlign: "start" }}>
                      {departmentAsset} Assets
                    </TitleHead>
                  ) : (
                    <TitleHead sx={{ textAlign: "start" }}>
                      {allAssets[0]?.department} Assets
                    </TitleHead>
                  )}
                  {allAssets?.length > 0 && (
                    <Typography
                      sx={{
                        color: "#1746A2",
                      }}>{`Total : ${allAssets?.length}`}</Typography>
                  )}
                </TitleBox>
                {allAssets?.length > 0 && (
                  <Search
                    sx={{}}
                    type="search"
                    value={searchQuery}
                    placeholder="Search"
                    onChange={handleSearchChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          placeholder="Search"
                          aria-label="toggle password visibility"
                          edge="start">
                          {/* <SearchOutlinedIcon /> */}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              </Box>
              {allAssets?.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex" }}>
                    {selectedAssetIds?.length > 0 && (
                      <IconButton
                        onClick={handleOpenBulkDeleteModal}
                        aria-label="delete">
                        <DeleteIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    )}

                    <Button
                      size="small"
                      onClick={handleButtonClick}
                      sx={{
                        bgcolor:
                          ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                        borderRadius: "30px",
                        "&:hover": {
                          background:
                            ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                        },

                        textTransform: "none",
                        height: "35px",
                      }}
                      variant="contained">
                      Bulk Upload
                    </Button>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <div>
                      <Tooltip title="Sort">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort ? "true" : undefined}
                          onClick={handleSort}>
                          <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort}
                        open={openSort}
                        onClose={handleCloseSort}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem sx={{ color: "#FF731D" }}>Name Sort</MenuItem>
                        <MenuItem onClick={() => sortDataByKey("name", true)}>
                          A-Z
                        </MenuItem>
                        <MenuItem onClick={() => sortDataByKey("name", false)}>
                          Z-A
                        </MenuItem>
                        <MenuItem sx={{ color: "#FF731D" }}>Date Sort</MenuItem>
                        <MenuItem onClick={sortLowToHigh}>
                          Newest to oldest
                        </MenuItem>
                        <MenuItem onClick={sortHighToLow}>
                          Oldest to newest
                        </MenuItem>
                      </Menu>
                    </div>

                    <div>
                      <Tooltip title="Filter">
                        <IconButton
                          id="basic-button"
                          aria-controls={openFilter ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openFilter ? "true" : undefined}
                          onClick={handleFilter}>
                          <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElFilter}
                        open={openFilter}
                        onClose={handleCloseFilter}
                        PaperProps={{
                          style: {
                            maxHeight: "450px",
                            width: "300px",
                            overflow: "auto",
                          },
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem>
                          <FilterMain>
                            <FilterText display="block">Department</FilterText>

                            <FilterInnerContainer style={{ overflowY: "auto" }}>
                              <FormWrapper>
                                {department?.map((item) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="warning"
                                        type="checkbox"
                                        value={item}
                                        checked={selectedCategories.includes(
                                          item
                                        )}
                                        onChange={handleCategoryChange}
                                        name="gilad"
                                      />
                                    }
                                    label={item}
                                  />
                                ))}
                              </FormWrapper>
                            </FilterInnerContainer>
                          </FilterMain>
                        </MenuItem>
                        <MenuItem>
                          <FilterMain>
                            <FilterText display="block">
                              Working Status
                            </FilterText>
                            <FilterInnerContainer style={{ overflowY: "auto" }}>
                              <FormWrapper>
                                {status?.map((item) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="warning"
                                        type="checkbox"
                                        value={item}
                                        checked={selectedStatus.includes(item)}
                                        onChange={handleStatusChange}
                                        name="gilad"
                                      />
                                    }
                                    label={item}
                                  />
                                ))}
                              </FormWrapper>
                            </FilterInnerContainer>
                          </FilterMain>
                        </MenuItem>
                        <MenuItem>
                          <FilterMain>
                            <FilterText display="block">AMC/CMC </FilterText>
                            <FilterInnerContainer style={{ overflowY: "auto" }}>
                              <FormWrapper>
                                {amccmc?.map((item) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="warning"
                                        type="checkbox"
                                        value={item}
                                        checked={selectedAmcCmc.includes(item)}
                                        onChange={handleAmcCmcChange}
                                        name="gilad"
                                      />
                                    }
                                    label={item}
                                  />
                                ))}
                              </FormWrapper>
                            </FilterInnerContainer>
                          </FilterMain>
                        </MenuItem>
                      </Menu>
                    </div>

                    <div>
                      <Tooltip title="Export to csv">
                        <IconButton
                          id="basic-button"
                          aria-controls={openSort2 ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSort2 ? "true" : undefined}
                          onClick={handleSort2}>
                          <BrowserUpdatedIcon sx={{ color: "#FF731D" }} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSort2}
                        open={openSort2}
                        onClose={handleCloseSort2}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem
                          onClick={handleButtonClick2}
                          sx={{
                            color:
                              ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                          }}>
                          Export to csv
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                </Box>
              )}
            </SortFilterContainer>
          </>
        </TopContainer>
      )}

      {allAssets?.length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            padding: "20px",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}>
          <EmptyText
            sx={{ color: "#1746A2", fontWeight: "500" }}
            // color="text.secondary"
            gutterBottom>
            There are no assets on this page. Please add an asset.
          </EmptyText>

          <ImageCard as="img" src={emp} alt="empty" />
          <CardContent></CardContent>
          <EmptyButtonSet>
            <EmptyButton
              onClick={() => navigate("/")}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              variant="contained">
              Go to Home
            </EmptyButton>

            <EmptyButton
              onClick={() => navigate("/add-asset")}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              disabled={userAccess?.addprod !== "Yes"}
              variant="contained">
              Add Asset
            </EmptyButton>
          </EmptyButtonSet>
        </BoxWrapper>
      ) : filteredProducts?.length === 0 ? (
        <>
          <NoData text={"asset"} />
        </>
      ) : (
        <>
          <BoxWrapper style={{ overflowX: "auto" }}>
            <Box sx={{ overflow: "auto" }}>
              <TableContainer
                sx={{
                  width: "100%",
                  maxHeight: 540,
                }}>
                <Table
                  stickyHeader
                  sx={{
                    borderRadius: " 15px",
                    bgcolor: "white",
                    whiteSpace: "nowrap",
                  }}>
                  <TableHead
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                    }}>
                    <TableRow
                      sx={{
                        borderRadius: " 15px 15px 0px 0px",
                        bgcolor: "rgba(40, 98, 212, 0.1)",
                      }}>
                      {/* Add the select/deselect all checkbox in the header */}
                      <TableCell>
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedAssetIds.length > 0 &&
                            selectedAssetIds.length < data?.length
                          }
                          checked={selectedAssetIds.length === data?.length}
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all assets",
                          }}
                        />
                      </TableCell>

                      <TableHeading
                        sx={{
                          borderRadius: "15px 0px 0px 0px",
                        }}>
                        <TableSortLabel
                          style={{ color: "#1746A2" }}
                          active={true}
                          direction={sortDirection.name}
                          onClick={() => handleSortTable("name")}>
                          Asset Name
                        </TableSortLabel>
                      </TableHeading>

                      <TableHeading>Asset Code</TableHeading>
                      <TableHeading>Brand</TableHeading>
                      <TableHeading>Department</TableHeading>
                      <TableHeading sx={{ textAlign: "center" }}>
                        Status
                      </TableHeading>
                      <TableHeading>
                        <TableSortLabel
                          style={{ color: "#1746A2" }}
                          active={true}
                          direction={sortDirection.date}
                          onClick={() => handleSortTable("date")}>
                          AMC/CMC
                        </TableSortLabel>
                      </TableHeading>
                      {selectedAssetIds?.length === 0 && (
                        <TableHeading
                          sx={{
                            borderRadius: "0px 15px 0px 0px",
                          }}></TableHeading>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow
                          key={item.assetid}
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            "&:hover": {
                              backgroundColor: "#E8ECF6",
                            },
                            textDecoration: "none",
                          }}>
                          {/* Add the checkbox for each row */}
                          <TableCell>
                            <Checkbox
                              checked={selectedAssetIds.includes(item.assetid)}
                              onChange={() =>
                                handleSelectRowClick(item.assetid)
                              }
                            />
                          </TableCell>

                          <TableRows
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{ maxWidth: 200, textDecoration: "none" }}>
                            {item.name}
                          </TableRows>
                          <TableRows
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{ maxWidth: 210, textDecoration: "none" }}>
                            {item.assetcode}
                          </TableRows>
                          <TableRows
                            sx={{ textDecoration: "none" }}
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}>
                            {item.brand}
                          </TableRows>

                          <TableRows
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{ maxWidth: 200, textDecoration: "none" }}>
                            {item.department}
                          </TableRows>
                          <TableRows
                            style={{ whiteSpace: "nowrap" }}
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{
                              textAlign: "center",
                              textDecoration: "none",
                            }}>
                            <Box
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: {
                                  xl: "18px",
                                  lg: "16px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                                height: "30px",
                                padding: "4px",
                                width: {
                                  xl: "150px",
                                  lg: "130px",
                                  md: "110px",
                                  sm: "100px",
                                },

                                border:
                                  item.status === "Working"
                                    ? "1px solid #00A884"
                                    : "1px solid #FF4B4B",
                                color:
                                  item.status === "Working"
                                    ? " #00A884"
                                    : " #FF4B4B",
                              }}>
                              {item.status}
                            </Box>
                          </TableRows>

                          <TableRows
                            sx={{ textDecoration: "none" }}
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}>
                            {" "}
                            {item.cmcamcdate}
                          </TableRows>
                          {selectedAssetIds?.length === 0 && (
                            <TableRows>
                              <Box>
                                <IconButton
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, item.assetid)}>
                                  <MoreVertIcon color="black" />
                                </IconButton>
                              </Box>
                            </TableRows>
                          )}

                          {/* Rest of your table row code... */}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                filteredProducts={filteredProducts}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </BoxWrapper>
          <Box sx={{ marginTop: "3%" }}>
            <SubmitButton
              size="large"
              sx={{
                borderRadius: "35px",
                textTransform: "none",

                bgcolor:
                  ids.subs_plan === "Basic" && allAssets?.length > 50
                    ? "#FF731D"
                    : "#1746A2",

                "&:hover": {
                  background:
                    ids.subs_plan === "Basic" && allAssets?.length > 50
                      ? "#FF731D"
                      : "#1746A2",
                },
              }}
              disabled={userAccess?.addprod !== "Yes"}
              onClick={handleButtonClick3}
              variant="contained">
              Add Asset
            </SubmitButton>
          </Box>
        </>
      )}

      <Menu
        elevation={0}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem
          disabled={userAccess?.editprod !== "Yes"}
          onClick={handleEdit}
          value="edit">
          <ModeOutlinedIcon sx={{ marginRight: "7%" }} />
          Edit
        </MenuItem>
        <MenuItem
          disabled={userAccess?.delprod !== "Yes"}
          value="delete"
          // onClick={handleDelete}

          onClick={handleDeleteById}>
          <DeleteOutlineOutlinedIcon
            sx={{
              marginRight: "7%",
            }}
          />
          Delete
        </MenuItem>

        <Link
          style={{ textDecoration: "none", color: "black" }}
          to={`/asset-detail/${getId}`}>
          <MenuItem value="view">
            <RemoveRedEyeOutlinedIcon
              sx={{
                marginRight: "7%",
              }}
            />
            View
          </MenuItem>
        </Link>
      </Menu>

      <DeleteModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={handleDelete}
        text={text}
        id={getId}
      />

      <BulkDeleteDeleteModal
        open={openBulkDeleteModal}
        onClose={handleCloseBulkDeleteModal}
        onDelete={handleBulkDelete}
        text={text}
        count={selectedAssetIds?.length}
      />

      <SuccessModal
        openModal={deletedStatus === "Deleted" || deleted === "Deleted"}
        handleCloseModal={handleSuccessCloseModal}
        response={"Deleted"}
        text={"Deleted"}
        toggle={successToggle}
      />

      {/* 
<SuccessModal
        openModal={bulkDepartmentStatus}
        handleCloseModal={handleCloseSuccessModal}
        text={"Asset moved"}
        toggle={successToggle}
      /> */}

      <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
    </Wrapper>
  );
};

export default DepartmentAssetDetails;
