import { Box, Button, ListItem, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import Demo from "../components/Demo";
import mobile from "../images/mobile.png";
import mobile2 from "../images/mobile2.png";
import vector1 from "../images/vector1.png";
import vector2 from "../images/vector2.png";
import vector3 from "../images/vector3.png";
import vector4 from "../images/vector4.png";
import vector5 from "../images/vector5.png";
import vector6 from "../images/vector6.png";
import ellips from "../images/ellips.png";
// import homescreen from "../images/homescreen.png"
import hmscreen0 from "../images/hmscreen0.png";
import hmscreen1 from "../images/hmscreen1.png";
import hmscreen2 from "../images/hmscreen2.png";
import hmscreen3 from "../images/hmscreen3.png";
import hmscreen4 from "../images/hmscreen4.png";
import hmscreen5 from "../images/hmscreen5.png";
import icon from "../images/icon.png";
import hospitals from "../images/hospitals.png";
import products from "../images/products.png";
import services from "../images/services.png";
import group1 from "../images/group1.png";
import group2 from "../images/group2.png";
import group3 from "../images/group3.png";
import mobilegif from "../gif/mobilegif.gif";
import vajraCycle from "../gif/vajracycle.gif";
import double from "../images/double.png";
import video1 from "../videos/New-Vajra-video.mp4";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Navbar3 from "../components/Navbar3";
import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const HowVajraWorks = styled(Box)(({ theme }) => ({
  bgcolor: "#FFF1E6",

  width: "100%",
  // height: "719px",
  display: "flex",
  gap: "20px",
  justifyContent: "space-evenly",
  alignItems: "center",

  // flexDirection:'row',
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
    // height: "619px",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    // height: "819px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "850px",

  [theme.breakpoints.down("xl")]: {
    width: "650px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "408px",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerRight = styled(Box)(({ theme }) => ({
  width: "498px",
  height: "710px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    height: "510px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "auto",
    gap: "20px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerTextBox = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const HowTextTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: "#1746A2",
  textAlign: "left",
  fontSize: "36px",

  [theme.breakpoints.down("xl")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "28px",

  textAlign: "left",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FeaturesBox = styled(Box)(({ theme }) => ({
  width: "100%",

  bgcolor: "#F0F8FF",
  //  height:{xl:"729px",lg:"729px",md:"1710px",sm:"1710px",xs:"1710px"},

  paddingTop: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FeaturesSet = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  bgcolor: "white",
  width: "370px",
  height: "270px",
  boxShadow: "0px 2px 16px -2px rgba(0, 79, 149, 0.25)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FeaturesInnerBox = styled(Box)(({ theme }) => ({
  alignContent: "center",
  justifyItems: "center",
  gap: "20px",
  display: "grid",
  justifyContent: "center",
  gridTemplateColumns: "repeat(3,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2,1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ReviewBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",

  justifyContent: "space-around",
  alignItems: "center",
  marginTop: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "20px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ProductBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ReviewInnerBox = styled(Box)(({ theme }) => ({
  height: "256px",
  width: "30%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "200px",
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    height: "300px",
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    height: "300px",
  },
}));

const TextTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "44px",
  color: "#1746A2",
  padding: "auto",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxTitle = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  textAlign: "center",
  marginTop: "10px",
  color: "#1746A2",
  fontWeight: "600",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleDescription = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  textAlign: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

function Home() {
  const location = useLocation();
  const path = location.pathname;

  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  const images = [
    { url: hmscreen0 },
    { url: hmscreen1 },
    { url: hmscreen2 },
    { url: hmscreen3 },
    { url: hmscreen4 },
    { url: hmscreen5 },
  ];

  const handleNext = () => {
    if (count > 4) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
  };

  const handlePrevious = () => {
    if (count < 1) {
      setCount(5);
    } else {
      setCount(count - 1);
    }
  };

  const handleScrollTo = () => {
    const how = document.getElementById("how");

    if (how) {
      how.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollToTop = () => {
    const home = document.getElementById("home");

    if (home) {
      home.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box>
      <Navbar3 handleScrollTo={handleScrollTo} scrollTop={handleScrollToTop} />
      {/* check height */}
      <Box id="home">
        {/* ................0th..................... */}

        <Box
          display={"flex"}
          bgcolor={"#f0f8ff"}
          sx={{
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
              xs: "column",
            },
            paddingTop: {
              xl: "150px",
              lg: "90px",
              md: "90px",
              sm: "90px",
              xs: "90px",
            },

            // height:{ sm: "1200px", xs: "1200px"},

            gap: { sm: "10px", xs: "10px" },
          }}
          alignItems={"center"}
          justifyContent={"space-evenly"}>
          <Box
            as="video"
            autoPlay
            loop
            muted
            src={video1}
            sx={{
              width: {
                xl: "846px",
                lg: "680px",
                md: "450px",
                sm: "90%",
                xs: "90%",
              },
              height: {
                xl: "500px",
                lg: "500px",
                md: "330px",
                sm: "290px",
                xs: "290px",
              },
              borderRadius: {
                xl: "50px",
                lg: "50px",
                md: "50px",
                sm: "50px",
                xs: "50px",
              },
            }}
            height="500px"></Box>
          <Demo />
        </Box>

        {/*............1st.............*/}

        {/* .................2nd.............. */}

        {/* <Box  paddingLeft="5px" sx={{ width:{xl: "498px", lg: "498px", md: "428px", sm: "380px", xs: "380px"}, 
          height: "324px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}> */}

        <HowVajraWorks id="how">
          <ImageBox as="img" src={vajraCycle} alt="cycle" />

          <InnerRight>
            <Box
              sx={{
                width: {
                  xl: "498px",
                  lg: "498px",
                  md: "428px",
                  sm: "90%",
                  xs: "90%",
                },
              }}>
              <HowTextTitle>
                The Best Hospital Asset Management Application Ever
              </HowTextTitle>
            </Box>

            <Box
              sx={{
                width: {
                  xl: "498px",
                  lg: "498px",
                  md: "428px",
                  sm: "90%",
                  xs: "90%",
                },
              }}>
              <SubTitle>Multi Chain Hospitals</SubTitle>
              <Typography
                fontFamily="Poppins"
                sx={{
                  fontWeight: 400,
                  fontSize: "20px",
                  textAlign: "left",
                  lineHeight: "200%",
                }}>
                Digitize your Biomedical operations and reduce the spends on
                Medical device services and accessories.
              </Typography>
            </Box>
            <Box
              sx={{
                width: {
                  xl: "498px",
                  lg: "498px",
                  md: "428px",
                  sm: "90%",
                  xs: "90%",
                },
              }}>
              <SubTitle>Multi Speciality Hospitals</SubTitle>
              <Typography
                fontFamily="Poppins"
                sx={{
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "200%",
                  textAlign: "left",
                }}>
                Digitize your Biomedical Operations and reduce the spends on
                Medical device services and accessories.
              </Typography>
            </Box>
          </InnerRight>
        </HowVajraWorks>

        {/* ............Features ..............*/}

        <FeaturesBox sx={{}}>
          <TextTitle>Features</TextTitle>
          <FeaturesInnerBox
            sx={{
              width: {
                xl: "80%",
                lg: "80%",
                md: "420px",
                sm: "90%",
                xs: "90%",
              },
              margin: { md: "30px", sm: "30px", xs: "30px" },

              gridTemplateColumns: {
                xl: "repeat(3,1fr)",
                lg: "repeat(3,1fr)",
                md: "repeat(1,1fr)",
                sm: "repeat(1,1fr)",
                xs: "repeat(1,1fr)",
              },
            }}>
            <FeaturesSet sx={{ bgcolor: "white" }}>
              <Box
                sx={{
                  height: "72px",
                  boxShadow: "0px 0px 4px rgba(74, 74, 74, 0.25)",
                  width: "72px",
                  margin: "auto",
                  marginTop: "20px",
                }}>
                <Box as="img" src={vector5} alt="featureIcons" />
              </Box>
              <BoxTitle>Digitize</BoxTitle>
              <Box sx={{ margin: "auto", marginTop: "10px" }}>
                <TitleDescription>
                  A bird eye view of the entire Biomedical Operations.
                </TitleDescription>
              </Box>
            </FeaturesSet>
            <FeaturesSet sx={{ bgcolor: "white" }}>
              <Box
                sx={{
                  height: "72px",
                  boxShadow: "0px 0px 4px rgba(74, 74, 74, 0.25)",
                  width: "72px",
                  margin: "auto",
                  marginTop: "20px",
                }}>
                <Box as="img" src={vector6} alt="featureIcons" />
              </Box>
              <BoxTitle>Track</BoxTitle>
              <Box sx={{ margin: "auto", marginTop: "10px" }}>
                <TitleDescription>
                  Track the product life cycle and service history.
                </TitleDescription>
              </Box>
            </FeaturesSet>
            <FeaturesSet sx={{ bgcolor: "white" }}>
              <Box
                sx={{
                  height: "72px",
                  boxShadow: "0px 0px 4px rgba(74, 74, 74, 0.25)",
                  width: "72px",
                  margin: "auto",
                  marginTop: "20px",
                }}>
                <Box as="img" src={vector4} alt="featureIcons" />
              </Box>
              <BoxTitle>Calibration</BoxTitle>
              <Box sx={{ margin: "auto", marginTop: "10px" }}>
                <TitleDescription>
                  Keep upto date with calibration remainders and PM calibration
                  module.
                </TitleDescription>
              </Box>
            </FeaturesSet>
            <FeaturesSet sx={{ bgcolor: "white" }}>
              <Box
                sx={{
                  height: "72px",
                  boxShadow: "0px 0px 4px rgba(74, 74, 74, 0.25)",
                  width: "72px",
                  margin: "auto",
                  marginTop: "20px",
                }}>
                <Box as="img" src={vector1} alt="featureIcons" />
              </Box>
              <BoxTitle>AMC Extensions</BoxTitle>
              <Box sx={{ margin: "auto", marginTop: "10px" }}>
                <TitleDescription>
                  Extend AMC's for the equipment at discounted prices.
                </TitleDescription>
              </Box>
            </FeaturesSet>
            <FeaturesSet sx={{ bgcolor: "white" }}>
              <Box
                sx={{
                  height: "72px",
                  boxShadow: "0px 0px 4px rgba(74, 74, 74, 0.25)",
                  width: "72px",
                  margin: "auto",
                  marginTop: "20px",
                }}>
                <Box as="img" src={vector2} alt="featureIcons" />
              </Box>
              <BoxTitle>Service Requests</BoxTitle>
              <Box sx={{ margin: "auto", marginTop: "10px" }}>
                <TitleDescription>
                  Get any device ,any model services within 12 hours through
                  Vajra
                </TitleDescription>
              </Box>
            </FeaturesSet>
            <FeaturesSet sx={{ bgcolor: "white" }}>
              <Box
                sx={{
                  height: "72px",
                  boxShadow: "0px 0px 4px rgba(74, 74, 74, 0.25)",
                  width: "72px",
                  margin: "auto",
                  marginTop: "20px",
                }}>
                <Box as="img" src={vector3} alt="featureIcons" />
              </Box>
              <BoxTitle>Biomedical Budget</BoxTitle>
              <Box sx={{ margin: "auto", marginTop: "10px" }}>
                <TitleDescription>
                  Regulate and save budget through analysed data and informed
                </TitleDescription>
              </Box>
            </FeaturesSet>
          </FeaturesInnerBox>
        </FeaturesBox>

        {/* ............Product updates........... */}

        <ProductBox
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
              xs: "column",
            },
            height: {
              xl: "745px",
              lg: "745px",
              md: "1355px",
              sm: "1055px",
              xs: "1055px",
            },
            justifyContent: "space-evenly",
            alignItems: "center",
            bgcolor: "white",
          }}>
          <Box sx={{}}>
            <Box
              sx={{
                width: {
                  xl: "600.96px",
                  lg: "600.96px",
                  md: "600.96px",
                  sm: "310px",
                  xs: "310px",
                },
                height: {
                  xl: "420px",
                  lg: "420px",
                  md: "420px",
                  sm: "260px",
                  xs: "260px",
                },
                backgroundRepeat: "no-repeat",
                marginTop: "160px",

                backgroundSize: "100%",
                backgroundImage: `url(${ellips})`,
              }}>
              <Button
                sx={{
                  marginTop: {
                    xl: "155px",
                    lg: "155px",
                    md: "155px",
                    sm: "60px",
                    xs: "60px",
                  },
                  marginLeft: {
                    xl: "-400px",
                    lg: "-400px",
                    md: "-400px",
                    sm: "-200px",
                    xs: "-200px",
                  },
                  height: "60px",
                  width: "40px",
                  bgcolor: "#F78117",
                  borderRadius: "50%",
                }}
                onClick={handlePrevious}>
                {" "}
                <ArrowBackIosNewIcon
                  sx={{ color: "white", size: "32px" }}
                />{" "}
              </Button>
            </Box>

            <Box
              sx={{
                width: {
                  xl: "263.88px",
                  lg: "263.88px",
                  md: "263.88px",
                  sm: "140px",
                  xs: "140px",
                },
                height: {
                  xl: "570.91px",
                  lg: "570.91px",
                  md: "570.91px",
                  sm: "280px",
                  xs: "280px",
                },

                marginLeft: {
                  xl: "150px",
                  lg: "150px",
                  md: "150px",
                  sm: "90px",
                  xs: "90px",
                },
                marginTop: {
                  xl: "-700px",
                  lg: "-700px",
                  md: "-700px",
                  sm: "-420px",
                  xs: "-400px",
                },
              }}>
              <Box
                as="img"
                sx={{
                  width: {
                    xl: "263.88px",
                    lg: "263.88px",
                    md: "263.88px",
                    sm: "140px",
                    xs: "140px",
                  },
                  height: {
                    xl: "570.91px",
                    lg: "570.91px",
                    md: "570.91px",
                    sm: "280px",
                    xs: "280px",
                  },
                }}
                src={`${images[count].url}`}
                alt="home"
              />

              <Button
                sx={{
                  marginTop: {
                    xl: "-270px",
                    lg: "-270px",
                    md: "-270px",
                    sm: "-140px",
                    xs: "-140px",
                  },
                  marginLeft: {
                    xl: "280px",
                    lg: "280px",
                    md: "280px",
                    sm: "140px",
                    xs: "140px",
                  },
                  height: "60px",
                  width: "40px",
                  bgcolor: "#F78117",
                  borderRadius: "50%",
                }}
                onClick={handleNext}>
                <ArrowForwardIosIcon sx={{ color: "white", size: "32px" }} />{" "}
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              marginTop: { sm: "70px", xs: "70px" },
              display: "flex",
              gap: { xl: "20px", lg: "20px", md: "20px", sm: "5px", xs: "5px" },
            }}>
            <Box
              sx={{
                width: {
                  xl: "253px",
                  lg: "150px",
                  md: "150px",
                  sm: "100px",
                  xs: "100px",
                },
                height: {
                  xl: "400px",
                  lg: "400px",
                  md: "290px",
                  sm: "200px",
                  xs: "200px",
                },

                "&:hover": { transform: "scale3d(1.05, 1.05, 2)" },
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  height: {
                    xl: "125px",
                    lg: "125px",
                    md: "150px",
                    sm: "80px",
                    xs: "80px",
                  },
                  width: {
                    xl: "125px",
                    lg: "125px",
                    md: "150px",
                    sm: "80px",
                    xs: "80px",
                  },
                  borderRadius: "50%",
                  border: "1px solid #1746A2",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}>
                <Box
                  as="img"
                  sx={{ height: { sm: "50px", xs: "50px" } }}
                  src={hospitals}
                  alt={"hospital"}
                />
              </Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xl: "50px",
                    lg: "50px",
                    md: "50px",
                    sm: "30px",
                    xs: "30px",
                  },
                  lineHeight: "10px",
                  color: "#1746A2",
                }}>
                {" "}
                15+
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xl: "30px",
                    lg: "30px",
                    md: "30px",
                    sm: "20px",
                    xs: "20px",
                  },
                  lineHeight: "30px",
                  color: "#1746A2",
                }}>
                Hospitals
              </Typography>
            </Box>
            <Box
              sx={{
                width: {
                  xl: "253px",
                  lg: "150px",
                  md: "150px",
                  sm: "100px",
                  xs: "100px",
                },
                height: {
                  xl: "400px",
                  lg: "400px",
                  md: "290px",
                  sm: "200px",
                  xs: "200px",
                },
                borderRadius: "15px",
                display: "flex",
                "&:hover": { transform: "scale3d(1.05, 1.05, 2)" },
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  height: {
                    xl: "125px",
                    lg: "125px",
                    md: "150px",
                    sm: "80px",
                    xs: "80px",
                  },
                  width: {
                    xl: "125px",
                    lg: "125px",
                    md: "150px",
                    sm: "80px",
                    xs: "80px",
                  },
                  borderRadius: "50%",
                  border: "1px solid #1746A2",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}>
                <Box
                  as="img"
                  sx={{ height: { sm: "50px", xs: "50px" } }}
                  src={products}
                  alt={"products"}
                />
              </Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xl: "50px",
                    lg: "50px",
                    md: "50px",
                    sm: "30px",
                    xs: "30px",
                  },
                  lineHeight: "10px",
                  color: "#1746A2",
                }}>
                {" "}
                5000+
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xl: "30px",
                    lg: "30px",
                    md: "30px",
                    sm: "20px",
                    xs: "20px",
                  },
                  lineHeight: "30px",
                  color: "#1746A2",
                }}>
                Products
              </Typography>
            </Box>
            <Box
              sx={{
                width: {
                  xl: "253px",
                  lg: "150px",
                  md: "150px",
                  sm: "100px",
                  xs: "100px",
                },
                height: {
                  xl: "400px",
                  lg: "400px",
                  md: "290px",
                  sm: "200px",
                  xs: "200px",
                },

                borderRadius: "15px",
                "&:hover": { transform: "scale3d(1.05, 1.05, 2)" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  height: {
                    xl: "125px",
                    lg: "125px",
                    md: "150px",
                    sm: "80px",
                    xs: "80px",
                  },
                  width: {
                    xl: "125px",
                    lg: "125px",
                    md: "150px",
                    sm: "80px",
                    xs: "80px",
                  },

                  borderRadius: "50%",
                  border: "1px solid #1746A2",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}>
                <Box
                  as="img"
                  sx={{
                    height: { sm: "50px", xs: "50px" },
                    width: { sm: "50px", xs: "50px" },
                  }}
                  src={services}
                  alt={"services"}
                />
              </Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xl: "50px",
                    lg: "50px",
                    md: "50px",
                    sm: "30px",
                    xs: "30px",
                  },
                  lineHeight: "10px",
                  color: "#1746A2",
                }}>
                {" "}
                66+
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xl: "30px",
                    lg: "30px",
                    md: "30px",
                    sm: "20px",
                    xs: "20px",
                  },
                  lineHeight: "30px",
                  color: "#1746A2",
                }}>
                Service Requested
              </Typography>
            </Box>
          </Box>
        </ProductBox>

        {/*........... Hospitals with/without app ................ */}

        <Box
          sx={{
            height: {
              xl: "792px",
              lg: "792px",
              md: "930px",
              sm: "920px",
              xs: "920px",
            },
            backgroundImage: `url(${double})`,

            width: "100%",
            display: "flex",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
              xs: "column",
            },
            justifyContent: "space-evenly",
            alignItems: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "100% 100%",
          }}>
          <Box
            paddingLeft="10px"
            sx={{
              height: {
                xl: "500px",
                lg: "500px",
                md: "300px",
                sm: "220px",
                xs: "220px",
              },
              width: {
                xl: "700px",
                lg: "700px",
                md: "300px",
                sm: "300px",
                xs: "300px",
              },
            }}>
            <Typography
              fontFamily="Poppins"
              sx={{
                fontWeight: "600",
                fontSize: {
                  xl: "48px",
                  lg: "48px",
                  md: "20px",
                  sm: "20px",
                  xs: "20px",
                },
                color: "#FFF7E9",
              }}>
              Hospital with Vajra app
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",

                justifyContent: "center",
                alignItems: "center",
              }}>
              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Asset mapping of Hospital
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Turn around time
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Device History
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Biomedical Operations Efficiency
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Product and service manuals
              </ListItem>
              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Depreciation & Budgeting (Future)
              </ListItem>
            </Box>
          </Box>
          <Box
            // width: 212px;
            // height: 200px;
            // marginLeft="-310px"
            // backgroundImage={url:('')}
            src={mobile2}
            as="img"
            alt="mobile2"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            sx={{
              // backgroundImage: `url(${mobile2})`,
              width: {
                xl: "654px",
                lg: "600px",
                md: "300px",
                sm: "212px",
                xs: "212px",
              },
              height: {
                xl: "714px",
                lg: "600px",
                md: "300px",
                sm: "200px",
                xs: "200px",
              },
            }}></Box>
          <Box
            paddingRight="20px"
            sx={{
              paddingLeft: "10px",
              height: {
                xl: "500px",
                lg: "500px",
                md: "240px",
                sm: "220px",
                xs: "220px",
              },
              width: {
                xl: "760px",
                lg: "760px",
                md: "600px",
                sm: "300px",
                xs: "300px",
              },

              color: "#FFF7E9",
            }}>
            <Typography
              fontFamily="Poppins"
              sx={{
                fontWeight: "600",
                fontSize: {
                  xl: "48px",
                  lg: "48px",
                  md: "20px",
                  sm: "20px",
                  xs: "20px",
                },
              }}>
              Hospital without Vajra app
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                marginTop: {
                  xl: "10px",
                  lg: "50px",
                  md: "50px",
                  sm: "15px",
                  xs: "15px",
                },
                justifyContent: "center",
                alignItems: "center",
              }}>
              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Delayed services and inefficiency
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Equipment discarded can be avoided.
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Manual and repeated paper work can be avoided
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                Human error & over spending on Biomedical Operations
              </ListItem>

              <ListItem
                sx={{
                  padding: 0,
                  color: "#FFFFFF",
                  fontWeight: 400,
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "16px",
                    sm: "16px",
                    xs: "16px",
                  },
                  textAlign: "left",
                  listStyleType: "disc",
                  display: "list-item",
                }}>
                The ease of digitization
              </ListItem>
            </Box>
          </Box>
        </Box>

        {/* ............Audits.............. */}

        <Box
          sx={{
            width: "100%",
            bgcolor: "#0E6CBF",
            paddingTop: "20px",

            display: "flex",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            justifyContent: "space-evenly",
            alignItems: "center",
          }}>
          <Box
            sx={{
              paddingLeft: { sm: "10px", xs: "10px" },
              width: {
                xl: "780px",
                lg: "780px",
                md: "780px",
                sm: "90%",
                xs: "90%",
              },
            }}>
            <Typography
              fontFamily="Poppins"
              sx={{
                fontWeight: "600",
                paddingLeft: "4px",
                textAlign: "left",
                color: "#FFF7E9",
                fontSize: {
                  xl: "40px",
                  lg: "40px",
                  md: "40px",
                  sm: "24px",
                  xs: "24px",
                },
                lineHeight: "125%",
              }}>
              Biomedical engineers, don't stress yourself during the audit
              months!
            </Typography>
            <Typography
              fontFamily="Poppins"
              sx={{
                fontWeight: "500",
                paddingLeft: "4px",
                textAlign: "left",
                color: "#FFF7E9",
                fontSize: {
                  xl: "32px",
                  lg: "32px",
                  md: "32px",
                  sm: "20px",
                  xs: "20px",
                },
                lineHeight: "125%",
                marginTop: "20px",
              }}>
              Create digital PM Checklists for all the assets
            </Typography>
            <Box sx={{ display: "grid", marginTop: "35px", gap: "30px" }}>
              <Box sx={{ display: "flex" }}>
                <img src={group1} alt="one" height={"50px"} width={"50px"} />
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "400",
                    color: "#FFF7E9",
                    fontSize: "20px",
                    marginLeft: "15px",
                    lineHeight: "125%",
                    marginTop: "15px",
                    textAlign: "left",
                  }}>
                  Stop repetitive work with an efficient checklist and user
                  flow.
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <img src={group2} alt="two" height={"50px"} width={"50px"} />
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "400",
                    color: "#FFF7E9",
                    marginLeft: "15px",
                    fontSize: "20px",
                    lineHeight: "125%",
                    marginTop: "15px",
                    textAlign: "left",
                  }}>
                  Incorporating past device history helps you autofill most of
                  the variables.
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <img src={group3} alt="three" height={"50px"} width={"50px"} />
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "400",
                    color: "#FFF7E9",
                    fontSize: "20px",
                    lineHeight: "125%",
                    display: "flex",
                    marginTop: "15px",
                    marginLeft: "15px",
                    textAlign: "left",
                  }}>
                  Digitized reports will help you avoid messy and confusing
                  paper work
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            as="img"
            src={mobilegif}
            sx={{
              width: {
                xl: "400px",
                lg: "400px",
                md: "400px",
                sm: "193.73px",
                xs: "193.73px",
              },
              height: {
                xl: "642px",
                lg: "642px",
                md: "642px",
                sm: "410.84px",
                xs: "410.84px",
              },
            }}></Box>
        </Box>

        {/*................Review....................*/}

        <ReviewBox sx={{}}>
          <ReviewInnerBox
            bgcolor={"white"}
            display={"flex"}
            padding="10px"
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{
              boxShadow: "0px 2px 16px -2px rgba(0, 79, 149, 0.25)",
              borderRadius: "30px",

              // width: {
              //   xl: "384px",
              //   lg: "370px",
              //   md: "370px",
              //   sm: "90%",
              //   xs: "90%",
              // },
            }}>
            <Typography fontFamily="Poppins" sx={{ textAlign: "left" }}>
              “Adding all assets into the application, Vajra helped us in
              digitizing our Biomedical Operations to a great extent. The app
              enabled us to have an overview of the Biomedical department
              without having to go through tons of paperwork.”
            </Typography>
            <Box sx={{ display: "flex" }}>
              <img src={icon} height={"44px"} width={"44px"} alt="icon" />
              <Box sx={{ marginLeft: "14px" }}>
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "left",
                  }}>
                  Tulasi Multi Speciality Hospital
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontSeight: "400",
                    fontSize: "12px",
                    textAlign: "left",
                  }}>
                  Dr. Soma Sekhara Reddy Kankara
                </Typography>
              </Box>
            </Box>
          </ReviewInnerBox>
          <ReviewInnerBox
            bgcolor={"white"}
            padding="10px"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{
              boxShadow: "0px 2px 16px -2px rgba(0, 79, 149, 0.25)",
              borderRadius: "30px",
              // width: {
              //   xl: "384px",
              //   lg: "370px",
              //   md: "370px",
              //   sm: "90%",
              //   xs: "90%",
              // },
            }}>
            <Typography fontFamily="Poppins" sx={{ textAlign: "left" }}>
              “Vajra helped me keep tabs on the functioning of our Biomedical
              team, increasing our team's efficiency.”
            </Typography>
            <Box sx={{ display: "flex" }}>
              <img src={icon} height={"44px"} width={"44px"} alt="icon" />
              <Box sx={{ marginLeft: "14px" }}>
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "left",
                  }}>
                  Hrudaya Multispeciality Hospital
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontSeight: "400",
                    fontSize: "12px",
                    textAlign: "left",
                  }}>
                  - Dr. Kutumba Rao,
                </Typography>
              </Box>
            </Box>
          </ReviewInnerBox>
          <ReviewInnerBox
            bgcolor={"white"}
            padding="10px"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{
              boxShadow: "0px 2px 16px -2px rgba(0, 79, 149, 0.25)",
              borderRadius: "30px",
              // width: {
              //   xl: "384px",
              //   lg: "370px",
              //   md: "370px",
              //   sm: "90%",
              //   xs: "90%",
              // },
            }}>
            <Typography fontFamily="Poppins" sx={{ textAlign: "left" }}>
              “The app helps me manage my budget for services and monitor the
              current equipment in my hospital.”
            </Typography>
            <Box sx={{ display: "flex" }}>
              <img src={icon} height={"44px"} width={"44px"} alt="icon" />
              <Box sx={{ marginLeft: "14px" }}>
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "left",
                  }}>
                  Gopala Gowda Shanthaveri Memorial Hospital
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  sx={{
                    fontSeight: "400",
                    fontSize: "12px",
                    textAlign: "left",
                  }}>
                  Dr. Shushrutha Hedna.
                </Typography>
              </Box>
            </Box>
          </ReviewInnerBox>
        </ReviewBox>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Footer />
      </Box>
    </Box>
  );
}

export default Home;
