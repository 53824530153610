import { Box, Button, styled } from "@mui/material";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Department from "./Department";

import SavedDepart from "./SavedDepart";
import EmptySavedDepart from "./EmptySavedDepart";

const ButtonWrapper1 = styled(Button)(({ theme }) => ({
  width: "269px",
  height: "60px",
  boxSizing: "border-box",

  fontWeight: "500",
  fontSize: "20px",
  boxShadow: "0px 2px 8px -2px rgba(0, 79, 149, 0.25)",
  borderRadius: "10px 0px 0px 10px",

  [theme.breakpoints.down("xl")]: {
    width: "50%",
    height: "50px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "40px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ButtonWrapper2 = styled(Button)(({ theme }) => ({
  width: "269px",
  height: "60px",
  boxSizing: "border-box",
  fontSize: "20px",
  boxShadow: "0px 2px 8px -2px rgba(0, 79, 149, 0.25)",
  borderRadius: "0px 10px 10px 0px",

  [theme.breakpoints.down("xl")]: {
    width: "50%",
    height: "50px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "40px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  width: "892px",

  marginTop: "10px",
  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "400px",

    fontSize: "12px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "6%",
    width: "300px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const AddDep = () => {
  const [flag, setFlag] = useState(true);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [b1color, setB1Color] = useState("#FF731D");
  const [b2color, setB2Color] = useState("rgba(33, 36, 39, 0.5)");
  const [show, setShow] = useState(false);
  const data = useSelector((store) => store.nbmData.alreadySaved);

  const handleClick1 = () => {
    setFlag(true);
    setB1Color("#FF731D");
    setB2Color("rgba(33, 36, 39, 0.5)");
  };
  const handleClick2 = () => {
    setFlag(false);
    setB1Color("rgba(33, 36, 39, 0.5)");
    setB2Color("#FF731D");
    setShow(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        minHeight: "500px",
      }}>
      <ButtonBox>
        <ButtonWrapper1
          onClick={handleClick1}
          sx={{
            color: `${b1color}`,

            textTransform: "none",
            border: `1px solid ${b1color}`,
          }}>
          Already Saved
        </ButtonWrapper1>

        <ButtonWrapper2
          disabled={userAccess?.deldepart !== "Yes"}
          onClick={handleClick2}
          sx={{
            textTransform: "none",
            color: `${b2color}`,
            border: `1px solid ${b2color}`,
          }}>
          Add Department
        </ButtonWrapper2>
      </ButtonBox>
      {data?.length > 0
        ? !show && <SavedDepart />
        : !show && (
            <EmptySavedDepart
              disabled={userAccess?.deldepart !== "Yes"}
              handParentClick={handleClick2}
            />
          )}

      {show && flag ? <SavedDepart /> : show && <Department />}
    </Box>
  );
};

export default AddDep;
