import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery,styled, Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import deleteimg from "../images/delete.png";

function AssetMovementModal({ open, onClose, onDelete, count, prevDep, current }) {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dialogStyle = {
    width: isSmallScreen ? "80vw" : "40vw",
    height: "220px",
    borderRadius: "8px",
  };

  const imageStyle = {
    width: "70px",
    height: "70px",
  };


  const TopHead = styled(Box)(({ theme }) => ({
    width: "100%",
    padding:'10px',
    display:'flex',
    justifyContent:'end',
    [theme.breakpoints.down("xl")]: {
     
    },
    [theme.breakpoints.down("lg")]: {
    
    },
    [theme.breakpoints.down("md")]: {
     
    },
    [theme.breakpoints.down("sm")]: {
     
    },
    [theme.breakpoints.down("xs")]: {
     
    },
  }));




  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        PaperProps={{ style: isSmallScreen ? dialogStyle : dialogStyle }}>


<TopHead><IconButton onClick={onClose}><CloseIcon sx={{color:'#FF731D'}}/></IconButton></TopHead>


        <DialogTitle id="alert-dialog-title" sx={{ display: "flex" }}>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              width: "100%",
            }}>
            {/* <img src={deleteimg} alt="Delete Icon" style={imageStyle} /> */}

            <DialogContent
              sx={{
                width: "100%",
                padding: "0px",
                paddingLeft: "2%",
                paddingTop: "2%",
              }}>
              {/* {`Delete ${text}`} */}
              <DialogContentText
                id="alert-dialog-description"
                style={{ width: "100%", color:'black' }}>
                Are you sure you want to move {`${count}`} asset from {`${prevDep}`} department to {`${current}`} department  ?
              </DialogContentText>
            </DialogContent>
          </div>
        </DialogTitle>

        <DialogActions sx={{ width: "95%" }}>
          <Button
            onClick={onClose}
            size="large"
            style={{
              border: "1px solid var(--gray-300, #D0D5DD)",
              color: "black",
              borderRadius: "8px",
              marginRight: "2%",
              textTransform: "none",
            }}>
            Cancel
          </Button>
          <Button
            onClick={onDelete}
            style={{
              backgroundColor: "#1746A2",
              color: "white",
              borderRadius: "8px",
              textTransform: "none",
            }}
            autoFocus
            size="large">
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
export default AssetMovementModal;
