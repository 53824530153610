import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import taskSubmit from "../images/taskSubmit.png";
import taskFailed from "../images/taskFailed.png";
import { FormHelperText, FormLabel, OutlinedInput, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useEffect } from "react";
import { retrieveAllAssets } from "../redux/nbmData/action";
import { postVendor } from "./../redux/nbmData/action";
import VendorSuccess from "./VendorSuccess";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  border: "2px solid red",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  width: "500px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "270px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const HalfInput = styled(OutlinedInput)(({ theme }) => ({
  width: "230px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "270px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const Bottom = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  gap: "10px",
  justifyContent: "end",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CustomBottom = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  textTransform: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SpecialDropDowne = styled(Stack)(({ theme }) => ({
  border: "none",
  width: "500px",
  minHeight: "50px",
  marginBottom: "5px",
  [theme.breakpoints.down("xl")]: {
    width: "500px",
    minHeight: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    minHeight: "50px",
  },
  [theme.breakpoints.down("md")]: {
    width: "270px",
    minHeight: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "270px",
    minHeight: "50px",
  },
}));

const TitleBox = styled(Box)(({ theme }) => ({
  width: "90%",

  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  paddingBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  textAlign: "left",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Main = styled(Box)(({ theme }) => ({
  color: "red",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  flexDirection: "column",
  marginTop: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

export default function AddVendorModal({ openModal, handleClose }) {
  // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  // const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <Checkbox fontSize="small" />;
  const checkedIcon = <Checkbox fontSize="small" checked />;
  const allAssets = useSelector((store) => store.nbmData.allAssets);
  const status = useSelector((store) => store.nbmData.postVendor);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [assetName, setAssetName] = React.useState([]);
  const [flag, setFlag] = React.useState(false);
  const [isAssetNameError, setIsAssetNameError] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [selectedId, setSelectedId] = React.useState([]);
  const [code, setCode] = React.useState([]);

  const [selectedAssets, setSelectedAssets] = React.useState([]);

  const handleAssetSelection = (event, newValue) => {
    setSelectedAssets(newValue);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(retrieveAllAssets(data));
  }, [dispatch]);

  // const handleAssetName = (e, newValue) => {
  //   if (newValue !== null && newValue !== undefined) {
  //     let value = newValue.map((item) => {
  //       return item.value;
  //     });
  //     setSelectedId(value);
  //     let name = newValue.map((item) => {
  //       return item.name;
  //     });
  //     setAssetName(name);
  //     let code = newValue.map((item) => {
  //       return item.code;
  //     });
  //     setCode(code);

  //     setIsAssetNameError("");
  //   } else {
  //     setIsAssetNameError("Please fill the field");
  //   }
  // };
  // const handleAssetName = (e, newValue) => {
  //   if (newValue !== null && newValue !== undefined) {
  //     let value = newValue.map((item) => item.value);
  //     setSelectedId(value);
  //     let name = newValue.map((item) => item.name);
  //     setAssetName(name);
  //     let code = newValue.map((item) => item.code);
  //     setCode(code);
  //     setIsAssetNameError("");
  //     setSelectedAssets(newValue); // Update the selectedAssets state
  //   } else {
  //     setIsAssetNameError("Please fill the field");
  //   }
  // };

  // const handleAssetName = (e, newValue) => {
  //   if (newValue !== null && newValue !== undefined) {
  //     let selectedItems = newValue.filter((item) => item.selected); // Filter out only selected items
  //     let value = selectedItems.map((item) => item.value);
  //     setSelectedId(value);
  //     let name = selectedItems.map((item) => item.name);
  //     setAssetName(name);
  //     let code = selectedItems.map((item) => item.code);
  //     setCode(code);
  //     setIsAssetNameError("");
  //     setSelectedAssets(selectedItems); // Update the selectedAssets state
  //   } else {
  //     setSelectedId([]); // Unselect all items when nothing is selected
  //     setAssetName([]);
  //     setCode([]);
  //     setIsAssetNameError("Please fill the field");
  //   }
  // };

  const [textFieldValue, setTextFieldValue] = React.useState(""); // Add a state variable for TextField value

  // ... (your other code remains unchanged)

  const handleAssetName = (e, newValue) => {
    if (newValue !== null && newValue !== undefined) {
      let selectedItems = newValue.filter((item) => item.selected); // Filter out only selected items
      let value = selectedItems.map((item) => item.value);
      setSelectedId(value);
      let name = selectedItems.map((item) => item.name);
      setAssetName(name);
      let code = selectedItems.map((item) => item.code);
      setCode(code);
      setIsAssetNameError("");
      setSelectedAssets(selectedItems); // Update the selectedAssets state

      // Set the TextField value based on selected items
      setTextFieldValue(
        selectedItems.map((item) => `${item.name} (${item.code})`).join(", ")
      );
    } else {
      setSelectedId([]); // Unselect all items when nothing is selected
      setAssetName([]);
      setCode([]);
      setIsAssetNameError("Please fill the field");
      setTextFieldValue(""); // Clear the TextField value when no items are selected
    }
  };

  // ... (rest of your component code)

  const handleEmail = (e) => {
    const value = e.target.value;
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setEmailError("Wrong email");
    }
    if (value === "") {
      setEmailError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const regex = /^\d{10}$/;
    setPhone(value);
    if (value === "" || regex.test(value)) {
      setPhone(value);
      setPhoneError("");
    }
    if (value !== "" && !regex.test(value)) {
      setPhoneError("Invalid Phone Number");
    }
    if (value === "") {
      setPhoneError("Please fill the field");
    }
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;
    setName(value);
    if (value === "" || regex.test(value)) {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Invalid name");
    }
    if (value === "") {
      setNameError("Please fill the field");
    }
  };

  const handleSubmit = () => {
    if (
      phoneError === "" &&
      emailError === "" &&
      nameError === "" &&
      isAssetNameError === "" &&
      name !== "" &&
      email !== "" &&
      phone !== ""
    ) {
      let data = {
        hospid: userIds?.hospid,
        hospname: userIds?.hospname,
        userid: userIds?.userid,
        username: userIds?.username,
        compname: name.trim(),
        mobile: phone.trim(),
        email: email.trim(),
        branchid: userBranch?.id,
        branchname: userBranch.branch,
        assetname:
          selectedAssets?.length === 0
            ? "NA"
            : selectedAssets?.map((item) => {
                return item.name;
              }),
        assetcode:
          selectedAssets?.length === 0
            ? "NA"
            : selectedAssets?.map((item) => {
                return item.assetcode;
              }),
        assetid:
          selectedAssets?.length === 0
            ? "NA"
            : selectedAssets?.map((item) => {
                return item.assetid;
              }),
      };

      dispatch(postVendor(data));
      setEmail("");
      setEmailError("");
      setPhone("");
      setPhoneError("");
      setName("");
      setNameError("");
      setIsAssetNameError("");
      setSelectedAssets([]);
      setFlag(true);
    }
  };

  const handleCloseSuccess = () => {
    setFlag(false);
  };

  return (
    <div>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Main>
            <InnerBox>
              <TitleBox>
                <TitleHead>Add vendor</TitleHead>
              </TitleBox>

              <FormElement>
                <FormTextLabel>Company Name</FormTextLabel>
                <CustomInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  value={name}
                  onChange={handleName}
                  placeholder="e.g. Silver Company"
                />
                <HelperText>{nameError}</HelperText>
              </FormElement>
              <FormWrapper>
                <FormElement>
                  <FormTextLabel>Phone</FormTextLabel>
                  <HalfInput
                    sx={{ bgcolor: "#EFF5FE" }}
                    value={phone}
                    onChange={handlePhone}
                    placeholder="e.g. 8971580265"
                  />
                  <HelperText>{phoneError}</HelperText>
                </FormElement>

                <FormElement>
                  <FormTextLabel>Email</FormTextLabel>
                  <HalfInput
                    sx={{ bgcolor: "#EFF5FE" }}
                    value={email}
                    onChange={handleEmail}
                    placeholder="e.g. example@gmail.com"
                  />
                  <HelperText>{emailError}</HelperText>
                </FormElement>
              </FormWrapper>

              <FormElement>
                <FormTextLabel>Asset Name/code</FormTextLabel>
                <SpecialDropDowne>
                  {/* <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={allAssets?.map((option) => ({
                      label: `${option?.name} (${option?.assetcode})`,
                      value: option?.assetid,
                      name: option?.name,
                      code: option?.assetcode,
                    }))}
                    onChange={handleAssetName}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ bgcolor: "white" }}
                        placeholder="Asset/code"
                        value={textFieldValue} // Set the TextField value
                      />
                    )}
                  /> */}

                  <Autocomplete
                    sx={{ bgcolor: "#EFF5FE" }}
                    multiple
                    id="checkboxes-tags-demo"
                    options={allAssets}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={selectedAssets}
                    onChange={handleAssetSelection}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name} ({option.assetcode})
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Checkboxes"
                        placeholder="e.g. Ventilator/H81-Q1006"
                      />
                    )}
                  />
                </SpecialDropDowne>
                <HelperText>{isAssetNameError}</HelperText>
              </FormElement>

              <Bottom>
                <CustomBottom
                  onClick={handleClose}
                  variant="outlined"
                  sx={{ border: "1px solid #1746A2", color: "#1746A2" }}
                  size="medium">
                  Cancel
                </CustomBottom>
                <CustomBottom
                  disabled={
                    nameError ||
                    emailError ||
                    phoneError ||
                    !name ||
                    !phone ||
                    !email
                  }
                  onClick={() => {
                    handleSubmit();
                    handleClose();
                  }}
                  sx={{ bgcolor: "#1746A2" }}
                  variant="contained"
                  size="medium">
                  Submit
                </CustomBottom>
              </Bottom>
            </InnerBox>
          </Main>
        </ModalBox>
      </Modal>

      <VendorSuccess
        open={status?.success}
        close={handleCloseSuccess}
        flag={flag}
      />
    </div>
  );
}
