import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Box, Button } from "@mui/material";
import empty from "../images/notificationImage.png";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
export default function BottomAppBar() {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = React.useState(1);
  const navigate = useNavigate();

  let existingNotifications =
    JSON.parse(localStorage.getItem("notification")) || [];
  const totalPages = Math.ceil(existingNotifications.length / itemsPerPage);
  const paginatedNotifications = existingNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const EmptySet = styled(Box)(({ theme }) => ({
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  }));

  const ImageBox = styled(Box)(({ theme }) => ({
    width: "330px",
    height: "330px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "280px",
      height: "280px",
    },
    [theme.breakpoints.down("sm")]: {},
  }));

  const EmptyText = styled(Typography)(({ theme }) => ({
    color: "#1746A2",
    fontSize: "20px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  }));

  const EmptyButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#1746A2",
    textTransform: "none",
    fontSize: "18px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  }));

  const MainDiv = styled(Box)(({ theme }) => ({
    width: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // border: "1px solid red",
    marginTop: "2%",

    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const handleNavigate = () => {
    navigate("/");
    console.log("working");
  };

  return (
    <MainDiv>
      <CssBaseline />

      {existingNotifications?.length === 0 && (
        <EmptySet>
          <ImageBox as="img" alt="empty" src={empty} />
          <EmptyText>No notification here</EmptyText>
          <EmptyButton onClick={handleNavigate} variant="contained">
            {" "}
            Go to Home
          </EmptyButton>
        </EmptySet>
      )}

      {existingNotifications?.length > 0 && (
        <React.Fragment>
          <List sx={{ mb: 2 }}>
            {paginatedNotifications.map(({ id, title, body, icon }) => (
              <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }} key={id}>
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar alt={title} src={icon}></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6" color="primary">
                        {title}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="h7" color="textSecondary">
                        {body}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </Box>
            ))}
          </List>
          <Box>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                disabled={currentPage === index + 1}>
                {index + 1}
              </Button>
            ))}
          </Box>
        </React.Fragment>
      )}
    </MainDiv>
  );
}
