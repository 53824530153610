import { useEffect, useState } from "react";
import "./App.css";
import ChatBot from "./components/ChatBot";
import Checking from "./components/Checking";
import { v4 as uuidv4 } from "uuid";
import MainRoutes from "./routes/MainRoutes";
import NoInternet from "./components/NoInternet";
import { useNavigate } from "react-router-dom";
import Notification2 from "./components/Notification2";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
 const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);




  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'logoutEvent') {
        // window.close()
        window.location.reload()
        // localStorage.setItem('logoutEvent');
        localStorage.clear();
        sessionStorage.clear();
        

      }if (event.key === 'loginEvent') {
      

        const delayedFunction = () => {
          window.location.reload()
          
        };

        const timeoutId = setTimeout(delayedFunction, 3000);
    
        return () => clearTimeout(timeoutId);

      }
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);



  return (
    <div className="App">
      {isOnline ? (
        <>
          <MainRoutes />
          <ChatBot />
        
        </>
      ) : (
        <NoInternet />
      )}
    </div>
  );
}

export default App;
