import {
  Box,
  TablePagination,
  TableSortLabel,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useDispatch, useSelector } from "react-redux";

import { Cmc } from "../redux/nbmData/action";

import UpperContainer from "../components/UpperContainer";
import NoData from "../components/NoData";
import EmptyAmcCmc from "../components/EmptyAmcCmc";
import TableSort from "../components/TableSort";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  minHeight: "400px",

  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const AmcPage = () => {
  const cmcData = useSelector((store) => store.nbmData.cmcData);
  let userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [showOnlyExpired, setShowOnlyExpired] = useState(false);
  const [showNotExpired, setShowNotExpired] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [filterChanged, setFilterChanged] = useState(false);
  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterIncidents();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };

  const currentDate = new Date();
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const select = [...new Set(cmcData?.map((item) => item.department))];

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const uniqueMonths = [
    ...new Set(cmcData.map((item) => item.duedate.split("-")[1])),
  ].sort();
  const uniqueYears = [
    ...new Set(cmcData.map((item) => item.duedate.split("-")[2])),
  ].sort();

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.startdate.split("-").reverse().join("-"));
      const dateB = new Date(b.startdate.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.startdate.split("-").reverse().join("-"));
      const dateB = new Date(b.startdate.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const sortHighToLow1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.duedate.split("-").reverse().join("-"));
      const dateB = new Date(b.duedate.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.duedate.split("-").reverse().join("-"));
      const dateB = new Date(b.duedate.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories?.includes(category)
        ? prevCategories?.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories?.includes(status)
        ? prevCategories?.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterIncidents = () => {
    let filteredIncidents = [...data];

    if (searchQuery) {
      filteredIncidents = filteredIncidents.filter(
        (incident) =>
          incident.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          incident.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategories.length > 0) {
      filteredIncidents = filteredIncidents.filter((incident) =>
        selectedCategories.includes(incident.department)
      );
    }

    if (selectedYear) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentYear = incident.duedate.split("-")[2];
        return incidentYear === selectedYear;
      });
    }

    if (selectedMonth) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentMonth = incident.duedate.split("-")[1];
        return incidentMonth === String(selectedMonth).padStart(2, "0");
      });
    }
    const currentDate = new Date();
    filteredIncidents = filteredIncidents.filter((incident) => {
      if (!incident.duedate) {
        return false;
      }
      if (showOnlyExpired && showNotExpired) {
        return true;
      }
      if (showOnlyExpired) {
        return formatDate(incident.duedate) < currentDate;
      }
      if (showNotExpired) {
        return formatDate(incident.duedate) >= currentDate;
      }
      return true;
    });

    return filteredIncidents;
  };
  const filteredProductss = filterIncidents();
  useEffect(() => {
    if (cmcData && cmcData?.length > 0) {
      setData(cmcData);
    }
  }, [cmcData]);

  useEffect(() => {
    let data = {
      hospid: userData.hospid,
      userid: userData.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(Cmc(data, token));
  }, [dispatch]);

  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const [anchorElSort2, setAnchorElSort2] = React.useState(null);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [openModal, setOpenModal] = useState(false);

  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };
  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      downloadCsvFile();
    }
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    filteredProductss.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(cmcData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const cleanData = (data) => {
    return filteredProductss.map((item) => {
      const cleanedItem = {};
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          cleanedItem[key] =
            typeof item[key] === "string" ? item[key].trim() : item[key];
        }
      }
      return cleanedItem;
    });
  };

  const trimmedData = cleanData(filteredProductss);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.name;
      case "date":
        return item.startdate;

      case "duedate":
        return item.duedate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );
  return (
    <Wrapper>
      <UpperContainer
        data={cmcData}
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        selectedMonth={selectedMonth}
        handleMonthChange={handleMonthChange}
        uniqueMonths={uniqueMonths}
        selectedYear={selectedYear}
        handleYearChange={handleYearChange}
        uniqueYears={uniqueYears}
        sortDataByKey={sortDataByKey}
        sortLowToHigh={sortLowToHigh}
        sortHighToLow={sortHighToLow}
        sortLowToHigh1={sortLowToHigh1}
        sortHighToLow1={sortHighToLow1}
        department={select}
        // status={amccmc}
        selectedCategories={selectedCategories}
        showOnlyExpired={showOnlyExpired}
        setShowOnlyExpired={setShowOnlyExpired}
        showNotExpired={showNotExpired}
        setShowNotExpired={setShowNotExpired}
        selectedStatus={selectedStatus}
        handleStatusChange={handleStatusChange}
        handleButtonClick2={handleButtonClick2}
        ids={ids}
        handleCategoryChange={handleCategoryChange}
        text="CMC"
      />

      {cmcData?.length === 0 ? (
        <EmptyAmcCmc text="Cmc" />
      ) : filteredProductss?.length === 0 ? (
        <>
          <NoData text={"cmc"} />
        </>
      ) : (
        <BoxWrapper style={{ overflowX: "auto" }}>
          <Box sx={{ overflow: "auto" }}>
            <TableContainer
              sx={{
                width: "100%",
                maxHeight: 540,
              }}>
              <Table
                stickyHeader
                sx={{
                  borderRadius: " 15px",
                  bgcolor: "white",

                  whiteSpace: "nowrap",
                }}>
                <TableHead
                  sx={{
                    borderRadius: " 15px 15px 0px 0px",
                  }}>
                  <TableRow
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                      bgcolor: "rgba(40, 98, 212, 0.1)",
                    }}>
                    <TableHeading
                      sx={{
                        borderRadius: "15px 0px 0px 0px",
                      }}>
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.name}
                        onClick={() => handleSortTable("name")}>
                        Asset Name
                      </TableSortLabel>
                    </TableHeading>

                    <TableHeading sx={{}}>Asset code</TableHeading>
                    <TableHeading sx={{}}>Serial no</TableHeading>
                    <TableHeading sx={{}}>Department</TableHeading>
                    <TableHeading sx={{ textAlign: "center" }}>
                      Status
                    </TableHeading>
                    <TableHeading sx={{}}>
                      {" "}
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.date}
                        onClick={() => handleSortTable("date")}>
                        Date/Time
                      </TableSortLabel>
                    </TableHeading>
                    <TableHeading sx={{}}>
                      {" "}
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.duedate}
                        onClick={() => handleSortTable("duedate")}>
                        Due Date
                      </TableSortLabel>
                    </TableHeading>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trimmedData
                    .filter((asset) => {
                      if (showOnlyExpired && showNotExpired) {
                        return true;
                      }
                      if (showOnlyExpired) {
                        return formatDate(asset.duedate) < currentDate;
                      }
                      if (showNotExpired) {
                        return formatDate(asset.duedate) >= currentDate;
                      }
                      return true;
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                    ?.map((item, index) => {
                      const expirationDate = formatDate(item.duedate);
                      const isExpired = expirationDate < currentDate;
                      const textColor = isExpired ? "red" : "green";
                      if (showOnlyExpired && !isExpired) {
                        return null;
                      }
                      return (
                        <TableRow
                          key={item?.assetid}
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            "&:hover": {
                              backgroundColor: "#E8ECF6",
                            },
                            textDecoration: "none",
                          }}>
                          <TableRows>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.name}
                            </Link>
                          </TableRows>

                          <TableRows sx={{}}>{item?.assetcode}</TableRows>

                          <TableRows sx={{}}>{item?.serialno}</TableRows>
                          <TableRows sx={{}}>{item?.department}</TableRows>
                          <TableRows sx={{ textAlign: "center" }}>
                            <Box
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: {
                                  xl: "18px",
                                  lg: "16px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                                height: "30px",
                                padding: "4px",

                                width: { xl: "150px", md: "100px" },
                                border: "1px solid #00A884",

                                color: " #00A884",
                              }}>
                              {item?.cmcamc}
                            </Box>
                          </TableRows>
                          <TableRows> {item?.startdate}</TableRows>
                          <TableRows
                            style={{ whiteSpace: "nowrap", color: textColor }}>
                            {" "}
                            {item?.duedate}
                          </TableRows>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {filteredProductss?.length > rowsPerPage && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                sx={{
                  display: "flex",
                  justifyContent: isMobileScreen ? "center" : "end",

                  alignItems: isMobileScreen ? "center" : "end",
                  padding: "16px",

                  width: isMobileScreen ? "80%" : "90%",
                  "& .MuiTablePagination-selectLabel": {
                    fontSize: !isMobileScreen ? "14px" : "95%",
                  },

                  "& .MuiTablePagination-actions": {
                    fontSize: !isMobileScreen ? "14px" : "12px",
                  },

                  "& .MuiTablePagination-select": {
                    position: "relative",
                    cursor: "text",

                    alignItems: "center",
                    color: "inherit",

                    flexShrink: 0,
                    marginRight: "3px",
                    marginLeft: "-36px",
                  },

                  "& .MuiTablePagination-displayedRows": {
                    marginRight: "1px",
                    marginLeft: "-30px",
                    fontSize: !isMobileScreen ? "14px" : "12px",
                  },
                }}
                component="div"
                count={
                  showOnlyExpired
                    ? filteredProductss.filter(
                        (asset) => formatDate(asset.duedate) < currentDate
                      ).length
                    : filteredProductss?.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Box>
        </BoxWrapper>
      )}
    </Wrapper>
  );
};

export default AmcPage;
