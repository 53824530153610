import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import contact from "../images/contact.png";
import { useDispatch, useSelector } from "react-redux";
import { contactUs } from "../redux/data/action";

import SuccessModal from "../components/SuccessModal";
import { useTheme } from "@emotion/react";

import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const MainDiv = styled(Box)(({ theme }) => ({
  bgcolor: "#FAF5EE",
  paddingTop: "20px",
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleFont = styled(Box)(({ theme }) => ({
  color: "#FF731D",
  fontSize: "28px",
  textAlign: "left",
  fontWeight: "600",
  [theme.breakpoints.down("xl")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "18px",
  },
}));

const ContactText = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "left",
  fontWeight: "600",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubTitle = styled(Box)(({ theme }) => ({
  fontSize: "18px",
  textAlign: "left",
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "400px",
  height: "400px",

  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {
    width: "300px",
    height: "300px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "250px",
    height: "250px",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const RightBottomBox = styled(Box)(({ theme }) => ({
  width: "400px",
  gap: "10px",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "295px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Flex = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  color: "black",
  fontSize: "16px",
  width: "340px",

  [theme.breakpoints.down("xl")]: {
    width: "340px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "340px",
  },
  [theme.breakpoints.down("md")]: {
    width: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.25)",
  padding: "20px",
  borderRadius: "20px",

  bgcolor: "white",
  width: "60%",
  gap: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "60px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "4%",
    paddingBottom: "7%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "80%",
  },
}));

const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  color: "white",

  width: "340px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "300px",
    marginBottom: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  color: "black",

  fontSize: "16px",
  width: "340px",
  height: "167px",
  [theme.breakpoints.down("xl")]: {
    width: "340px",
    height: "147px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "340px",
    height: "160px",
  },
  [theme.breakpoints.down("md")]: {
    width: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const BottomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "50%",
  gap: "10px",
  // marginBottom: "2%",
  borderRadius: "15px",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",
  marginTop: "4%",
  // height: "120px",

  //
  [theme.breakpoints.down("xl")]: {
    width: "50%",

    // height: "120px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "50%",
    height: "100px",
    alignItems: "center",
    justifyContent: "end",
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
    height: "150px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
    padding: "2%",
    alignItems: "center",
    height: "100px",
    marginTop: "45px",
    gap: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: "18px",
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",

  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

function Contact() {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const response = useSelector((store) => store.data.contact);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [email, setEmail] = useState(userIds.email);
  const [name, setName] = useState(userIds.username);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [notes, setNotes] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isNotesError, setIsNotesError] = useState("");
  const dispatch = useDispatch();

  const text = "Submitted";

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    setNotes("");
  };

  const handleNotes = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    setNotes(value);
    if (regex.test(value) || value === "") {
      setNotes(value);
      setIsNotesError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsNotesError("Please avoid that special character");
    }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    setEmail(value);

    if (regex.test(value)) {
      setEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setEmailError("Invalid email");
    }
    if (value === "") {
      setEmailError("Please fill all field");
    }
  };

  const handleName = (e) => {
    let value = e.target.value;
    const maxLength = 50;
    const regex = /^[A-Za-z\s]+$/;
    setName(value);
    if (value === "") {
      setNameError("Please fill the field");
      // } else if (value.length < 3) {
      //   setNameError("Invalid hospital name (minimum 3 characters)");
    } else if (value.length >= maxLength) {
      setNameError("Name should not exceed 50 characters");
    } else if (!regex.test(value)) {
      setNameError("Invalid characters in hospital name");
    } else {
      setNameError("");
    }
  };

  const handleSubmit = () => {
    if (notes === "") {
      setIsNotesError("Please fill the field");
    }
    if (name === "") {
      setIsNotesError("Please fill the field");
    }
    if (email === "") {
      setIsNotesError("Please fill the field");
    }

    if (
      notes !== "" &&
      isNotesError === "" &&
      email !== "" &&
      emailError === "" &&
      name !== "" &&
      nameError === ""
    ) {
      let data = {
        hospid: userIds.hospid,
        hospname: userIds.hospname,
        hospcity: userIds.city,
        userid: userIds.userid,
        username: name.trim(),
        useremail: email.trim(),
        usermobile: userIds.mobile,
        message: notes.trim(),
        branchid: userBranch.id,
        branchname:userBranch.branch
      };
      dispatch(contactUs(data));
      setToggle(true);
    }
  };

  const handleSendEmail = () => {
    const email = "contact@vajra.software";
    const subject = "Please help me to resolve the issue.";
    const body = "Hi there,";

    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(gmailComposeUrl, "_blank");
  };

  return (
    <MainDiv>
      <InnerContainer sx={{ bgcolor: "white" }}>
        <LeftBox>
          <Box>
            <TitleFont>Get in touch</TitleFont>
            <SubTitle> We are here for you! How can we help? </SubTitle>
          </Box>
          <FormTextLabel>Name</FormTextLabel>
          <CustomInput
            value={name}
            onChange={handleName}
            sx={{ bgcolor: "#EFF5FE" }}
          />
          <HelperText>{nameError}</HelperText>
          <FormTextLabel>Email</FormTextLabel>
          <CustomInput
            value={email}
            onChange={handleEmail}
            sx={{ bgcolor: "#EFF5FE" }}
          />
          <HelperText>{emailError}</HelperText>
          <FormTextLabel>Message</FormTextLabel>
          <NotesInput
            value={notes}
            onChange={handleNotes}
            sx={{ bgcolor: "#EFF5FE" }}
            placeholder="Enter your message..."
          />
          <HelperText>{isNotesError}</HelperText>
          <SubmitButton
            variant="contained"
            sx={{
              bgcolor: "#1746A2",
              textTransform: "none",
              color: "white",
              marginTop: "20px",
            }}
            onClick={handleSubmit}
            size="large">
            Submit
          </SubmitButton>

          {isMatch && (
            <RightBottomBox sx={{}}>
              <Flex>
                <LocationOnOutlinedIcon sx={{ color: "#FF731D" }} />
                <ContactText>
                  2086,9th Cross RPC Layout , Vijayanagar, Bangalore - 560040
                </ContactText>
              </Flex>
              <Flex>
                <LocalPhoneOutlinedIcon sx={{ color: "#FF731D" }} />
                <ContactText>+91 8971580265</ContactText>
              </Flex>

              <Flex sx={{ cursor: "pointer" }} onClick={handleSendEmail}>
                <EmailOutlinedIcon sx={{ color: "#FF731D" }} />
                <ContactText>contact@vajra.software</ContactText>
              </Flex>

              <Flex>
                <WhatsAppIcon sx={{ color: "green" }} />
                <ContactText>Chat with us</ContactText>
              </Flex>
            </RightBottomBox>
          )}
        </LeftBox>
        <RightBox>
          <ImageBox as="img" src={contact} />
          {!isMatch && (
            <RightBottomBox sx={{ marginLeft: "10%" }}>
              <Flex>
                <LocationOnOutlinedIcon sx={{ color: "#FF731D" }} />
                <ContactText>
                  2086,9th Cross RPC Layout , Vijayanagar, Bangalore - 560040
                </ContactText>
              </Flex>
              <Flex>
                <LocalPhoneOutlinedIcon sx={{ color: "#FF731D" }} />
                <ContactText>+91 8971580265</ContactText>
              </Flex>

              <Flex sx={{ cursor: "pointer" }} onClick={handleSendEmail}>
                <EmailOutlinedIcon sx={{ color: "#FF731D" }} />
                <ContactText>contact@vajra.software</ContactText>
              </Flex>

              <Flex>
                <a
                  href="https://api.whatsapp.com/send?phone=918971580265&text=Hi,%20I%20have%20a%20query%20in%20Vajra%20App.%20Can%20you%20help"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer">
                  <WhatsAppIcon sx={{ color: "green" }} />
                </a>

                <a
                  href="https://api.whatsapp.com/send?phone=918971580265&text=Hi,%20I%20have%20a%20query%20in%20Vajra%20App.%20Can%20you%20help"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer">
                  <ContactText sx={{ cursor: "pointer", color: "black" }}>
                    Chat with us
                  </ContactText>
                </a>
              </Flex>
            </RightBottomBox>
          )}
        </RightBox>
      </InnerContainer>
      <Typography sx={{ marginTop: "10px" }}>Version 1.0.3</Typography>

      <BottomBox>
        <SuccessModal
          openModal={response}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          response={response}
          text={text}
          toggle={toggle}
        />
      </BottomBox>
    </MainDiv>
  );
}

export default Contact;
