import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import nbm from "../images/nbm.png";
import bm from "../images/bm.png";
import nbmMobile from "../images/nbmMobile.png";
import bmMobile from "../images/bmMobile.png";
import { Typography, styled, useMediaQuery } from "@mui/material";
import logo3 from "../images/logo3.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/data/action";
import { useTheme } from "@emotion/react";

const boxStyle = {
  display: "flex",
  width: "90%",
  height: "90vh",
};

const coloredBoxStyle = {
  flex: "1",
  height: "90%",

  background: "linear-gradient(110deg, white 50%, #1746A2 50%)",
};

const boxStyle2 = {
  display: "flex",
  width: "100%",
  height: "100vh",
  // border: "1px solid red",
  flexDirection: "column",
};

const coloredBoxStyle2 = {
  flex: "1",
  background: "linear-gradient(160deg, white 50%, #1746A2 50%)",
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",

  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const token = localStorage.getItem("userToken");

  const handleBiomedical = () => {
    sessionStorage.setItem("SbmOrNbm", "BM");
    localStorage.setItem("bmOrNbm", "BM");
    let branch = {
      branch: userIds?.bm_branches[0],
      id:userIds.bm_branchids[0]
     }  
     localStorage.setItem("userBranch", JSON.stringify(branch));
     sessionStorage.setItem("userBranch", JSON.stringify(branch));
    navigate("/home");
    window.location.reload();
  };

  const handleNoneBiomedical = () => {
    sessionStorage.setItem("SbmOrNbm", "NBM");
    localStorage.setItem("bmOrNbm", "NBM");
    let branch = {
      branch: userIds?.nbm_branches[0],
      id:userIds?.nbm_branchids[0]
     }  
     localStorage.setItem("userBranch", JSON.stringify(branch));
     sessionStorage.setItem("userBranch", JSON.stringify(branch));
    navigate("/home");
    window.location.reload();
  };

  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.getItem("userToken");
    navigate("/signin");
    localStorage.setItem('logoutEvent', Date.now().toString());
    window.location.reload()
    
  };
  const isMatch2 = useMediaQuery(theme.breakpoints.down("lg"));
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 300);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isMobileBig, setMobileBig] = useState(window.innerWidth <= 750);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth > 200 && window.innerWidth < 300);
      setIsMobile(window.innerWidth <= 500);
      setMobileBig(window.innerWidth > 490 && window.innerWidth < 750);
      setIsTablet(window.innerWidth > 500 && window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {!isMatch2 ? (
        <>
          {" "}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F0F8FF"
            height="100vh">
            <Box
              sx={{
                // border: "3px solid red",
                width: "90%",
                display: "flex",
                justifyContent: "end",
              }}>
              <Button
                // variant="contained"
                // color="primary"
                style={{
                  marginBottom: "16px",
                  background: "#F0F8FF",
                  color: "#1746A2",
                  textTransform: "none",
                  fontWeight: "500",
                }}
                endIcon={<LogoutIcon />}
                onClick={handleLogout}>
                <Typography variant="h6"> Logout</Typography>
              </Button>
            </Box>

            <div style={boxStyle}>
              <div style={coloredBoxStyle}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                    // border: "1px solid black",
                  }}>
                  <img
                    src={bm}
                    alt="Images 1"
                    style={{
                      height: "90%",
                      width: "45%",
                    }}
                  />

                  <img
                    src={nbm}
                    alt="Images 2"
                    style={{ height: "95%", width: "50%" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "70%",
                      //top: isTablet ? 320 : 420,
                      left: 0,
                      //height: "50%",
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <Box
                      sx={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        // border: "1px solid red",
                        TextAlign: "center",
                      }}>
                      <Typography
                        variant="h6"
                        style={{ color: "#1746A2", fontWeight: 700 }}>
                        For Biomedical
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ color: "#1746A2", fontWeight: 700 }}>
                        Equipment
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      onClick={handleBiomedical}
                      disabled={userIds?.bm_branches?.length === 0}
                      style={{
                        marginTop: "4%",
                        width: "30%",
                        background:
                          userIds?.bm_branches?.length === 0
                            ? "grey"
                            : "#1746A2",
                        color: "white",
                        // padding: "24px 56px",
                        //width: "140px",
                        fontWeight: 700,
                        borderRadius: "40px",
                        textTransform: "none",
                      }}>
                      Biomedical
                    </Button>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "10%",
                      right: 0,
                      // height: "50%",
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // border: "1px solid red",
                    }}>
                    <Button
                      variant="contained"
                      onClick={handleNoneBiomedical}
                      disabled={userIds?.nbm_branches?.length === 0}
                      style={{
                        marginBottom: "6%",

                        background:
                          userIds?.nbm_branches?.length === 0
                            ? "grey"
                            : "white",
                        // padding: "24px 56px",
                        //width: "140px",
                        color:
                          userIds?.nbm_branches?.length === 0
                            ? "white"
                            : "#1746A2",
                        borderRadius: "40px",
                        textTransform: "none",
                        fontWeight: 700,
                        width: "30%",
                        whiteSpace:"nowrap"
                      }}>
                      Non-Biomedical
                    </Button>
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontWeight: 700 }}>
                      For Non-Biomedical
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontWeight: 700 }}>
                      Equipment
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </>
      ) : (
        <>
          <>
            {" "}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor="#1746A2">
              <Box
                sx={{
                  width: "90%",

                  height: "50px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}>
                <Button
                  // variant="contained"
                  // color="primary"
                  style={{
                    color: "#F0F8FF",
                    background: "#1746A2",

                    textTransform: "none",
                    fontWeight: "500",
                  }}
                  endIcon={<LogoutIcon />}
                  onClick={handleLogout}>
                  <Typography variant="h6"> Logout</Typography>
                </Button>
              </Box>

              <div style={boxStyle2}>
                <div style={coloredBoxStyle2}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                      flexDirection: "column",
                    }}>
                    <img
                      src={bmMobile}
                      alt="Images 1"
                      style={{ height: "100%", width: "100%" }}
                    />

                    <img
                      src={nbmMobile}
                      alt="Images 2"
                      style={{ height: "100%", width: "100%" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "30%",
                        // top: isSmallScreen
                        //   ? 290
                        //   : isMobile
                        //   ? 390
                        //   : isMobileBig
                        //   ? 200
                        //   : isTablet
                        //   ? 920
                        //   : "90px",
                        left: 0,
                        //height: "50%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <Box
                        sx={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          // border: "1px solid red",
                          TextAlign: "center",
                        }}>
                        <Typography
                          variant="h5"
                          style={{ color: "#1746A2", fontWeight: 700 }}>
                          For Biomedical
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ color: "#1746A2", fontWeight: 700 }}>
                          Equipment
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        onClick={handleBiomedical}
                        disabled={userIds?.bm_branches?.length === 0}
                        style={{
                          marginTop: "8%",
                          background:
                          userIds?.bm_branches?.length === 0
                            ? "grey"
                            : "#1746A2",
                          color: "white",
                          // padding: "24px 56px",
                          //width: "140px",
                          fontWeight: 700,
                          borderRadius: "40px",
                          textTransform: "none",
                          width: 200,
                          // width: isSmallScreen
                          // ? 100
                          // : isMobile
                          // ? 200
                          // : isMobileBig
                          // ? 200
                          // : isTablet
                          // ? 230
                          // : "90px",
                        }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                          {" "}
                          Biomedical
                        </Typography>
                      </Button>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        // top: isSmallScreen
                        //   ? "60%"
                        //   : isMobile
                        //   ? "60%"
                        //   : isMobileBig
                        //   ? 1160
                        //   : isTablet
                        //   ? 1700
                        //   : "90px",
                        top: "63%",
                        right: 0,
                        // height: "50%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <Button
                        variant="contained"
                        onClick={handleNoneBiomedical}
                        disabled={userIds?.nbm_branches?.length === 0}
                        style={{
                          marginBottom: "8%",
                          // background: "white",
                          whiteSpace:"nowrap",
                          color: "#1746A2",
                          background:
                          userIds?.nbm_branches?.length === 0
                            ? "grey"
                            : "white",
                          // padding: "24px 56px",
                          //width: "140px",
                          borderRadius: "40px",
                          textTransform: "none",
                          fontWeight: 700,
                          width: 200,
                          // width: isSmallScreen
                          // ? 100
                          // : isMobile
                          // ? 200
                          // : isMobileBig
                          // ? 200
                          // : isTablet
                          // ? 230
                          // : "90px",
                        }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 , whiteSpace:"nowrap"}}>
                          {" "}
                          Non-Biomedical
                        </Typography>
                      </Button>
                      <Typography
                        variant="h5"
                        sx={{ color: "white", fontWeight: 700 }}>
                        For Non-Biomedical
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{ color: "white", fontWeight: 700 }}>
                        Equipment
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </>
        </>
      )}
    </>
  );
};

export default BoxComponent;
