import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  FormLabel,
  TextField,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch } from "react-redux";
import {
  createSubuser,
  getDepartmentData,
  getUserData,
} from "../redux/nbmData/action";
import AddUserModal from "./AddUserModal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSuperAdmin, userDetailCheck } from "../redux/data/action";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "#1746A2",
  fontSize: "20px",
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  gap: "80px",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
    border: "1px solid green",

    gap: "50px",
  },
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
    marginTop: "10px",
    gap: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",

    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "10px",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));
const FormWrapper1 = styled(Box)(({ theme }) => ({
  display: "flex",

  gap: "80px",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
    border: "1px solid green",

    gap: "50px",
  },
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
    marginTop: "10px",
    gap: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",

    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "10px",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  bgcolor: "white",
  minHeight: "510px",

  padding: "20px",

  borderRadius: "15px",
  display: "flex",
  width: "950px",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    width: "850px",
    minHeight: "410px",

    bgcolor: "white",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const LowerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "1030px",
  justifyContent: "center",
  gap: "30px",
  marginTop: "20px",

  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column-reverse",
    gap: "10px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const UpperContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "950px",
  justifyContent: "left",
  gap: "20px",
  paddingLeft: "20px",

  marginBottom: "10px",
  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormInput = styled(TextField)(({ theme }) => ({
  border: "none",
  width: "370px",

  [theme.breakpoints.down("xl")]: {
    width: "370px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));
const Boxes = styled(Box)(({ theme }) => ({
  border: "none",
  width: "370px",

  [theme.breakpoints.down("xl")]: {
    width: "370px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));
const allOption = {
  departid: "all",
  departname: "All",
};

function AddUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  const userList = useSelector((store) => store.data.userData);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const departmentData = useSelector((store) => store.nbmData.department) || [];
  const addedStatus = useSelector((store) => store.nbmData.createSubuser) || [];

  const createSuperAdmins = useSelector(
    (store) => store.nbmData.createSuperAdmin
  );
  let userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const [name, setName] = useState("");
  const [toggle, setToggle] = useState(false);
  const [email, setEmail] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");

  const [nameError, setNameError] = useState("");
  const [allData, setAllData] = useState({});

  const [designationError, setDesignationError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [userRollError, setUserRollError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [data, setData] = useState([]);

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDepartments, setSelectedDepartments] = React.useState([]);

  const response = useSelector((store) => store.data.userDetailCheck);

  const [selectAll, setSelectAll] = React.useState(false);

  const handleChange = (_, value) => {
    if (value.some((dept) => dept.departid === "all")) {
      setSelectAll(true);
      setSelectedDepartments([allOption]);
    } else {
      setSelectAll(false);
      setSelectedDepartments(value.filter((dept) => dept.departid !== "all"));
    }
  };

  useEffect(() => {
    if (userList && userList?.length > 0) {
      setData(userList);
    }
  }, [userList]);

  const handleButtonClick = (role) => {
    setSelectedRole(role);
    setSelectedDepartments([]);
  };
  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };
  // const handleEmailChange = async (e) => {
  //   const newEmail = e.target.value;
  //   setEmail(newEmail);

  //   if (newEmail.length === 0) {
  //     // Email is empty, no need to make the API call
  //     setEmailError(""); // Clear the error message
  //     setName(""); // Clear the name input
  //     return;
  //   }

  //   try {
  //     if (!validateEmail(newEmail)) {
  //       setEmailError("Invalid email format");
  //       setName(""); // Clear the name input
  //       return;
  //     }

  //     let data = {
  //       adminid: userData.userid,
  //       emailid: newEmail,
  //       hospid: userData.hospid,
  //     };

  //     // Call your API to check if email already exists
  //     await dispatch(userDetailCheck(data));

  //     // Enable the name input only if there is no error after API call and the user already exists
  //     setName(
  //       validateEmail(newEmail) &&
  //         emailError === "" &&
  //         Object.keys(response).length !== 0
  //         ? newEmail
  //         : ""
  //     );
  //   } catch (error) {
  //     console.error("Error checking email:", error);
  //   }
  // };
  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (newEmail.length === 0) {
      // Email is empty, no need to make the API call
      setEmailError(""); // Clear the error message
      setName(""); // Clear the name input
      setMobileNumber("");
      setDesignation("");
      return;
    }

    try {
      if (!validateEmail(newEmail)) {
        setEmailError("Invalid email format");
        setName(""); // Clear the name input
        setMobileNumber("");
        setDesignation("");
        return;
      }

      let data = {
        adminid: userData.userid,
        emailid: newEmail,
        hospid: userData.hospid,
      };

      // Set loading to true before making the API call
      //setLoading(true);

      // Call your API to check if email already exists
      await dispatch(userDetailCheck(data));
    } catch (error) {
      console.error("Error checking email:", error);
      //setLoading(false); // Set loading to false in case of an error
    }
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleOpenUser = () => setOpenAddUser(true);
  const handleCloseUser = () => {
    setOpenAddUser(false);
    setToggle(false);

    if (addedStatus?.success === false) {
    } else {
      navigate("/user");
    }
  };

  const isEmailExists = (email) => {
    return userList.some((user) => user.email === email);
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (value === "") {
      setEmailError("Please fill the field");
    } else if (!regex.test(value)) {
      setEmailError("Wrong email");
    } else if (isEmailExists(value)) {
      setEmailError("Email already exists");
    } else {
      setEmailError("");
    }

    setEmail(value);
  };

  const handleDesignation = (e) => {
    const value = e.target.value.trim();
    const regex = /^[A-Za-z0-9\- ]+$/;
    setDesignation(value);

    if (value === "" || regex.test(value)) {
      setDesignationError("");
    } else {
      setDesignationError("Wrong designation");
    }

    if (value === "") {
      setDesignationError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d{10}$/;
    setMobileNumber(value);

    if (value === "" || regex.test(value)) {
      setPhoneError("");
    } else {
      setPhoneError("Invalid Phone Number");
    }

    if (value === "") {
      setPhoneError("Please fill the field");
    }
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z ]+$/;
    setName(value);

    if (value === "") {
      setNameError("Please fill the field");
    } else if (!regex.test(value)) {
      setNameError("Invalid name");
    } else {
      setNameError(""); // Clear the error message if the input is valid
    }
  };

  const handleClick = () => {
    let isValid = true;

    if (name === "") {
      setNameError("Please fill the field");
      isValid = false;
    } else {
      setNameError(""); // Clear the name error if the field is not empty
    }

    if (email === "") {
      setEmailError("Please fill the field");
      isValid = false;
    } else {
      setEmailError(""); // Clear the email error if the field is not empty
    }
    if (designation === "") {
      setDesignationError("Please fill the  field");
      isValid = false;
    } else {
      setDesignationError("");
    }

    if (mobileNumber === "") {
      setPhoneError("Please fill the  field");
      isValid = false;
    } else {
      setPhoneError("");
    }
    if (
      (selectedRole === "Admin" || selectedRole === "Guest") &&
      selectedDepartments.length === 0
    ) {
      setDepartmentError("Please select at least one department");
      isValid = false;
    } else {
      setDepartmentError(""); // Clear the department error if the condition is met
    }

    if (isValid) {
      const formattedDepartments =
        selectedDepartments.length === 0 &&
        (selectedRole === "Branch_Admin" || selectedRole === "Super_Admin")
          ? [
              {
                depart_id: "all",
                depart_name: "All",
              },
            ]
          : selectedDepartments.map((dept) => ({
              depart_id: dept.departid,
              depart_name: dept.departname,
            }));

      if (
        selectedRole === "Admin" ||
        selectedRole === "Branch_Admin" ||
        selectedRole === "Guest"
      ) {
        let data = {
          admin_id: userData.userid,
          hosp_id: userData.hospid,
          hosp_name: userData?.hospname,
          user_name: name.trim(),
          user_email: email.trim(),
          user_mobile: mobileNumber.trim(),
          user_role: "Sub_User",
          user_desig: designation.trim(),
          user_departs: "All",
          user_departids: "all",
          subscrb_plan: userData.subs_plan,
          subscrb_date: moment(userData.subs_date).format("DD-MM-YYYY"),
          subscrb_expdate: moment(userData.subs_expdate).format("DD-MM-YYYY"),
          hospcity: userData.city,
          dash_acc: "Yes",
          addprod: "Yes",
          deleteprod: "Yes",
          editprod: "Yes",
          adddepart: "Yes",
          deldepart: "Yes",
          editdepart: "Yes",
          adduser: "Yes",
          deleteuser: "No",
          edituser: "Yes",
          branchname: userBranch.branch,
          branchid: userBranch.id,
          branchdepart: {
            branchId: userBranch.id,
            branch: userBranch.branch,
            role: selectedRole,
            addprod:
              selectedRole === "Branch_Admin" ||
              selectedRole === "Super_Admin" ||
              selectedRole === "Admin"
                ? "Yes"
                : "No",
            delprod:
              selectedRole === "Branch_Admin" ||
              selectedRole === "Super_Admin" ||
              selectedRole === "Admin"
                ? "Yes"
                : "No",

            editprod:
              selectedRole === "Branch_Admin" ||
              selectedRole === "Super_Admin" ||
              selectedRole === "Admin"
                ? "Yes"
                : "No",
            adddepart:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            deldepart:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            editdepart:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            adduser:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            deluser:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            edituser:
              selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
                ? "Yes"
                : selectedRole === "Admin" &&
                  selectedDepartments &&
                  selectedDepartments.some((dept) => dept.departid !== "all")
                ? "No"
                : selectedRole === "Guest"
                ? "No"
                : "Yes",
            department: formattedDepartments,
          },
        };
        dispatch(createSubuser(data));

        setToggle(true);
      } else if (selectedRole === "Super_Admin") {
        let data = {
          admin_id: userData.userid,
          hosp_id: userData.hospid,
          hosp_name: userData?.hospname,
          user_name: name.trim(),
          user_email: email.trim(),
          user_mobile: mobileNumber.trim(),
          user_desig: designation.trim(),
        };
        dispatch(createSuperAdmin(data));
        setToggle(true);
      }
    }
  };

  useEffect(() => {
    let data = {
      hospid: userData.hospid,
      userid: userData.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  //-------------------------------p-----------------------------------//

  useEffect(() => {
    if (addedStatus !== undefined && addedStatus?.length > 0) {
      if (addedStatus?.success && name.length > 0) {
        setOpenAddUser(true);
      }
      if (addedStatus?.success === false && name.length > 0) {
        setOpenAddUser(true);
      }
    }
  }, [addedStatus]);
  useEffect(() => {
    if (createSuperAdmins !== undefined && createSuperAdmins?.length > 0) {
      if (createSuperAdmins?.success && name.length > 0) {
        setOpenAddUser(true);
      }
      if (createSuperAdmins?.success === false && name.length > 0) {
        setOpenAddUser(true);
      }
    }
  }, [createSuperAdmins]);

  useEffect(() => {
    let data = {
      adminid: userData.userid,
      hosp_id: userData.hospid,
      branchid: userBranch.id,
    };
    dispatch(getUserData(data, token));
  }, [dispatch]);
  // useEffect(() => {
  //   const checkUserExistence = () => {
  //     // Check if the response is an empty object
  //     if (Object.keys(response).length === 0) {
  //       // Email doesn't exist, show no error message
  //       setEmailError("");
  //     } else {
  //       const { role, bm_branchids } = response;

  //       // Check if the role is "Owner" or "Super_admin"
  //       if (role === "Owner" || role === "Super_admin") {
  //         setEmailError("User already exists");
  //       } else if (role === "Admin") {
  //         // Check if the branchid and role match with the local storage
  //         const userData = JSON.parse(localStorage.getItem("user_access"));
  //         if (
  //           userData.role === "Admin" &&
  //           bm_branchids.includes(userData.branchid)
  //         ) {
  //           setEmailError("User already exists");
  //         } else {
  //           setEmailError("");
  //         }
  //       } else {
  //         // Handle other roles if needed
  //         setEmailError("");
  //       }
  //     }
  //   };

  //   checkUserExistence();
  // }, [response]);

  useEffect(() => {
    if (Object.keys(response).length === 0) {
      // Email doesn't exist, show no error message
      setEmailError("");
      // Clear the name input when there is no response

      setName(""); // Clear the name input
      setMobileNumber("");
      setDesignation("");
    } else {
      if (response.role === "Owner" || response.role === "Super_Admin") {
        // Email already exists, show error message
        setEmailError("User already exists");
      } else if (response.role !== "Owner" || response.role !== "Super_Admin") {
        // Check if the user with the same email exists in the user list
        const existingUser = userList.find(
          (user) =>
            user.email &&
            user.email.toLowerCase() === response.email.toLowerCase()
        );

        if (existingUser) {
          // Check if branch ids match
          const branchIdFromLocalStorage = userBranch.id;
          const branchIdsFromResponse = response.nbm_branchids;

          // Check if any branch id from the response matches the branch id from local storage
          const branchIdMatch = branchIdsFromResponse.some(
            (id) => id === branchIdFromLocalStorage
          );

          if (branchIdMatch) {
            // Branch IDs match, show user already exists
            setEmailError("User already exists");
          } else {
            // Same email exists but different branch, allow editing
            setEmailError("");
            // Auto-fill the name if the branch IDs don't match
            setName(existingUser.username);
          }
        } else {
          // User not found in the user list, allow editing
          setEmailError("");
          // If no user in the list, set the name from response (if response has a username)
          setName(response.username || "");
          setMobileNumber(response.mobile || "");
          setDesignation(response.designate || "");
        }
      } else {
        // For other roles, allow editing
        setEmailError("");
      }
    }
    //  setLoading(false); // Set loading to false when response is received
  }, [response, userList]);

  useEffect(() => {
    setEmailError("");
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#FAF5EE",
        marginTop: "10px",
        justifyContent: "center",
        flexDirection: "column",

        alignItems: "center",
        padding: "3%",
      }}>
      <UpperContainer>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: {
              xl: "28px",
              lg: "22px",
              md: "22px",
              sm: "20px",
              xs: "20px",
            },
            color: "#FF731D",
            textAlign: "left",
          }}>
          Add User
        </Typography>
      </UpperContainer>
      <InnerContainer sx={{ bgcolor: "white" }}>
        <Stack>
          <FormControl>
            <FormWrapper>
              <FormElement>
                <TextLabel>Email</TextLabel>
                <FormInput
                  size="small"
                  sx={{ bgcolor: "#EFF5FE" }}
                  // onChange={handleEmail}
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="e.g. example@gmail.com"
                />
                {emailError && (
                  <HelperText style={{ color: "red" }}>{emailError}</HelperText>
                )}
                {/* <HelperText>{emailError}</HelperText> */}
              </FormElement>
              <FormElement>
                <TextLabel>Name</TextLabel>
                <FormInput
                  size="small"
                  sx={{ bgcolor: "#EFF5FE" }}
                  error={nameError}
                  onChange={handleName}
                  value={name}
                  placeholder="e.g. Philip"
                  disabled={
                    email.length === 0 ||
                    !validateEmail(email) ||
                    emailError !== ""
                  }
                />
                <HelperText>{nameError}</HelperText>
              </FormElement>
            </FormWrapper>
            <FormWrapper>
              <FormElement>
                <TextLabel>Mobile Number</TextLabel>
                <FormInput
                  size="small"
                  value={mobileNumber}
                  sx={{ bgcolor: "#EFF5FE" }}
                  onChange={handlePhone}
                  placeholder="e.g. 8971580265"
                  disabled={
                    email.length === 0 ||
                    !validateEmail(email) ||
                    emailError !== ""
                  }
                />
                <HelperText>{phoneError}</HelperText>
              </FormElement>
              <FormElement>
                <TextLabel>Designation</TextLabel>
                <FormInput
                  size="small"
                  value={designation}
                  sx={{ bgcolor: "#EFF5FE" }}
                  onChange={handleDesignation}
                  placeholder="e.g. CEO"
                  disabled={
                    email.length === 0 ||
                    !validateEmail(email) ||
                    emailError !== ""
                  }
                />
                <HelperText>{designationError}</HelperText>
              </FormElement>
            </FormWrapper>

            <FormWrapper1>
              <Stack
                sx={{
                  width:
                    selectedRole === "Admin" || selectedRole === "Guest"
                      ? "50%"
                      : "100%",
                }}>
                <TextLabel>User access</TextLabel>
                <Box
                  sx={{
                    marginTop: "2%",
                    width: "50%",
                  }}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        justifyItems: "start",
                      }}>
                      {userAccess.role !== "Admin" && (
                        <>
                          {(userAccess.role === "Owner" ||
                            userAccess.role === "Super_Admin") && (
                            <div>
                              <button
                                disabled={
                                  email.length === 0 ||
                                  !validateEmail(email) ||
                                  emailError !== ""
                                }
                                style={{
                                  backgroundColor:
                                    selectedRole === "Super_Admin"
                                      ? "#1746A2"
                                      : "white",
                                  color:
                                    selectedRole === "Super_Admin"
                                      ? "white"
                                      : "#1746A2",
                                  border: "1px solid #1746A2",
                                  borderRadius: "10px",
                                  width: "150px",
                                  padding: "10px",
                                  margin: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleButtonClick("Super_Admin")
                                }>
                                Super Admin
                              </button>
                            </div>
                          )}
                          <div>
                            <button
                              disabled={
                                email.length === 0 ||
                                !validateEmail(email) ||
                                emailError !== ""
                              }
                              style={{
                                backgroundColor:
                                  selectedRole === "Branch_Admin"
                                    ? "#1746A2"
                                    : "white",
                                color:
                                  selectedRole === "Branch_Admin"
                                    ? "white"
                                    : "#1746A2",
                                border: "1px solid #1746A2",
                                borderRadius: "10px",
                                width: "150px",
                                padding: "10px",
                                margin: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleButtonClick("Branch_Admin")}>
                              Branch Admin
                            </button>
                          </div>
                        </>
                      )}

                      <div>
                        <button
                          disabled={
                            email.length === 0 ||
                            !validateEmail(email) ||
                            emailError !== ""
                          }
                          style={{
                            backgroundColor:
                              selectedRole === "Admin" ? "#1746A2" : "white",
                            color:
                              selectedRole === "Admin" ? "white" : "#1746A2",
                            border: "1px solid #1746A2",
                            borderRadius: "10px",
                            width: "150px",
                            padding: "10px",

                            margin: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleButtonClick("Admin")}>
                          Department Admin
                        </button>
                      </div>

                      <div>
                        <button
                          disabled={
                            email.length === 0 ||
                            !validateEmail(email) ||
                            emailError !== ""
                          }
                          style={{
                            backgroundColor:
                              selectedRole === "Guest" ? "#1746A2" : "white",
                            color:
                              selectedRole === "Guest" ? "white" : "#1746A2",
                            border: "1px solid #1746A2",
                            borderRadius: "10px",
                            width: "150px",
                            padding: "10px",
                            margin: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleButtonClick("Guest")}>
                          Guest
                        </button>
                      </div>
                    </div>
                  </div>
                </Box>

                <HelperText>{userRollError}</HelperText>
              </Stack>

              {selectedRole === "Admin" || selectedRole === "Guest" ? (
                <FormElement sx={{ width: "50%" }}>
                  <TextLabel>Select Department</TextLabel>
                  <Boxes>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={[allOption, ...departmentData]}
                      disableCloseOnSelect
                      size="small"
                      style={{
                        textAlign: "left",
                        background: "#EFF5FE",
                      }}
                      getOptionLabel={(option) => option.departname}
                      onChange={handleChange}
                      value={selectedDepartments}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            disabled={selectAll && option.departid !== "all"}
                          />
                          {option.departname}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select department"
                        />
                      )}
                    />
                  </Boxes>
                  {selectedDepartments.length === 0 && (
                    <HelperText>Select at least one department</HelperText>
                  )}
                </FormElement>
              ) : null}
            </FormWrapper1>
          </FormControl>
        </Stack>
      </InnerContainer>

      <LowerContainer>
        <Button
          variant="contained"
          sx={{
            borderRadius: "35px",
            bgcolor: "white",
            textTransform: "none",
            color: "#3c52b2",
            border: "1px solid #1746A2",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#3c52b2",
            },
          }}
          onClick={() => navigate("/")}
          size="large">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleClick}
          disabled={userAccess === "Guest"}
          sx={{
            borderRadius: "35px",
            textTransform: "none",
            bgcolor: "#1746A2",
          }}
          size="large">
          Submit
        </Button>
        <AddUserModal
          openAddUser={openAddUser}
          handleOpenUser={handleOpenUser}
          handleCloseUser={handleCloseUser}
          addedStatus={addedStatus}
          toggle={toggle}
        />
        <AddUserModal
          openAddUser={openAddUser}
          handleOpenUser={handleOpenUser}
          handleCloseUser={handleCloseUser}
          addedStatus={createSuperAdmins}
          toggle={toggle}
        />
      </LowerContainer>
    </Box>
  );
}

export default AddUser;
