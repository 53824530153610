import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React from "react";
import criticalCare from "../images/criticalCare.png";
import ot from "../images/ot.png";
import laboratory from "../images/laboratory.png";
import radiology from "../images/radiology.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingTop: "20px",
  paddingBottom: "20px",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TopContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  minHeight: "60px",
  gap: "10px",
  display: "grid",
  justifyItems: "center",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2,1fr)",
    minHeight: "120px",
    gap: "20px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  // gap: "10px",
  display: "grid",
  justifyItems: "center",
  gridTemplateColumns: "repeat(2,1fr)",
  columnGap: "20px",
  rowGap: "70px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2,1fr)",
    minHeight: "120px",
    gap: "20px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const CategoryButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#FF731D",
  },
  color: "white",
  borderRadius: "0px  0px 40px 40px",
  border: "1px solid rgba(33, 36, 39, 0.70)",
  background: "#FF731D",
  height: "60px",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const SubTitle = styled(Typography)(({ theme }) => ({
  //   textAlign: "left",
  color: "#1746A2",
  fontSize: "22px",
  marginTop: "10px",
  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {},
}));
const DepartmentSet = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const ImageBox = styled(Box)(({ theme }) => ({
  width: "366px",
  height: "207px",

  [theme.breakpoints.down("xl")]: {
    width: "336px",
    height: "187px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "246px",
    height: "107px",
  },
  [theme.breakpoints.down("md")]: {
    width: "170px",
    height: "98px",
  },
  [theme.breakpoints.down("sm")]: {},
}));
const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: "35px",
  textTransform: "none",
  width: "150px",
  fontSize: "20px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {
    width: "140px",
    height: "45px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "130px",
    height: "45px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  textAlign: "left",

  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",

  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TopContainers = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    border: "none",
  },
  [theme.breakpoints.down("sm")]: {
    border: "none",
  },
  [theme.breakpoints.down("xs")]: {},
}));

function PmChoose() {
  const navigate = useNavigate();

  const [color, setColor] = useState("");
  let data2 = [
    {
      department: "Critical Care",
    },
    {
      department: "OT",
    },
    {
      department: "Laboratory",
    },
    {
      department: "Radiology",
    },
  ];

  const handleChoose = (value) => {
    navigate(`/pm-checklist/${value}`);
  };

  let depImage = [criticalCare, ot, laboratory, radiology];

  return (
    <Wrapper>
      <TopContainers>
        <TitleHead>PM Check List</TitleHead>
      </TopContainers>
      {/* Please select your department */}
      <SubTitle>Please select your department</SubTitle>
      <Main>
        <InnerContainer>
          {data2?.map((item, index) => (
            <DepartmentSet onClick={() => handleChoose(item?.department)}>
              <ImageBox as="img" src={depImage[index]}></ImageBox>
              <CategoryButton
                variant="contained"
                sx={{
                  border: "1px solid #FF731D",
                  color: item?.department === color && "#FF731D",
                }}>
                {item?.department}
              </CategoryButton>
            </DepartmentSet>
          ))}
        </InnerContainer>
      </Main>
    </Wrapper>
  );
}

export default PmChoose;
