import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  styled,
} from "@mui/material";

import pdf from "../images/pdf icon.png";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import download from "downloadjs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DownloadPDF, userManual } from "../redux/data/action";

const InnerSet = styled(Box)(({ theme }) => ({
  paddingTop: "10px",
  display: "grid",
  gap: "10px",
  gridTemplateColumns: "repeat(3,1fr)",
  [theme.breakpoints.down("xl")]: {
    gridTemplateColumns: "repeat(3,1fr)",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHeading = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const UserManualDetails = ({ cardData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const userManualData = useSelector((store) => store.data?.userManual);
  const [manualBrand, setManualBrand] = useState("");
  const localStorageId = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const filtered = userManualData?.manuallist?.filter(
      (item) => item?.brand_name === params.id
    );

    if (filtered?.length !== "") {
      let data = {
        manuallist: filtered,
      };
      setManualBrand(data);
    } else {
      setManualBrand(userManualData);
    }
  }, [params]);

  useEffect(() => {
    let data = {
      hospid: localStorageId?.hospid,
      userid: localStorageId?.userid,
    };
    dispatch(userManual(data));
  }, [dispatch]);

  const downloadPDF = (pdfUrl) => {
    // const pdfUrl = 'https://medpick.in/wp-content/uploads/2022/12/siemens-acuson-juniper-ultrasound-datasheet.pdf';

    download(
      pdfUrl,
      "siemens-acuson-juniper-ultrasound-datasheet.pdf",
      "application/pdf"
    );
  };

  const filteredCardData = manualBrand?.manuallist?.filter(
    (item) =>
      item.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.model_no.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: "20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TitleHeading>{params.id}</TitleHeading>

          <OutlinedInput
            sx={{ width: "220px", height: "40px", borderRadius: "30px" }}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <IconButton aria-label="search">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>

        <InnerSet>
          {filteredCardData?.map((item) => (
            <Box
              key={item.id}
              sx={{ cursor: "pointer" }}
              onClick={() => dispatch(DownloadPDF(item.manual_link,item?.product_name))}
              // onClick={() => downloadPDF(item.manual_link)}
            >
              <Card
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "120px",
                  alignItems: "center",
                  borderRadius: "20px",
                  border: "1px solid #FF731D",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "5px",
                    textAlign: "left",
                  }}>
                  <Typography sx={{ color: "#1746A2" }}>
                    {item?.product_name}
                  </Typography>
                  <Typography sx={{ color: "#FF731D" }}>
                    {item?.brand_name}
                  </Typography>
                  <Typography sx={{ color: "black" }}>
                    {item?.model_no}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                  <Box as="img" width={"60px"} height={"60px"} src={pdf} />
                  <DownloadIcon sx={{ color: "#FF731D" }} />
                </Box>
              </Card>
            </Box>
          ))}
        </InnerSet>
        {filteredCardData?.length === 0 && (
          <Box
            height="300px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography>No manual found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserManualDetails;
