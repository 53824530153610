import { Box, Button, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { departmentAlreadySaved } from "../redux/data/action";
import Department from "./Department";
import Register from "./Register";
import SavedDepart from "./SavedDepart";
import EmptySavedDepart from "./EmptySavedDepart";

const columns = [
  { id: "department", label: "Department", minWidth: 170 },
  { id: "total", label: "Total Assets\u00a0Code", minWidth: 100 },
  {
    id: "date",
    label: "Created Date",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "incharge",
    label: "Incharge",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(department, total, date, incharge) {
  return { department, total, date, incharge };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const MainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#faf5ee",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "1000px",
    // border: "1px solid red",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "1000px",
  // border: "1px solid blue",
  display: "flex",

  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {
    width: "1000px",
    // height: "900px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "900px",
    // height: "700px",
  },
  [theme.breakpoints.down("md")]: {
    width: "700px",

    // height: "1180px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "320px",
    // height: "900px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonWrapper1 = styled(Button)(({ theme }) => ({
  width: "269px",
  height: "60px",
  boxSizing: "border-box",

  fontWeight: "500",
  fontSize: "20px",
  boxShadow: "0px 2px 8px -2px rgba(0, 79, 149, 0.25)",
  borderRadius: "10px 0px 0px 10px",

  [theme.breakpoints.down("xl")]: {
    width: "50%",
    height: "50px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "40px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ButtonWrapper2 = styled(Button)(({ theme }) => ({
  width: "269px",
  height: "60px",
  boxSizing: "border-box",
  fontSize: "20px",
  boxShadow: "0px 2px 8px -2px rgba(0, 79, 149, 0.25)",
  borderRadius: "0px 10px 10px 0px",

  [theme.breakpoints.down("xl")]: {
    width: "50%",
    height: "50px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "40px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  //   width: "1130px",

  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    // width: "900px",
    // border: "1px solid  black",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    height: "980px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "300px",
    height: "655px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "380px",
  },
}));
const ButtonBox = styled(Box)(({ theme }) => ({
  width: "892px",

  marginTop: "10px",
  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "400px",

    fontSize: "12px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "6%",
    width: "300px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const AddDep = () => {
  const [flag, setFlag] = useState(true);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [b1color, setB1Color] = useState("#FF731D");
  const [b2color, setB2Color] = useState("rgba(33, 36, 39, 0.5)");
  const [show, setShow] = useState(false);
  const data = useSelector((store) => store.data.alreadySaved);
  const dispatch = useDispatch();
  let num = 0;
  const handleChildClick = (flag2) => {
    setFlag(flag2);
    setB1Color("rgba(33, 36, 39, 0.5)");
    setB2Color("#FF731D");
  };

  const handleClick1 = () => {
    setFlag(true);
    setB1Color("#FF731D");
    setB2Color("rgba(33, 36, 39, 0.5)");
  };
  const handleClick2 = () => {
    setFlag(false);
    setB1Color("rgba(33, 36, 39, 0.5)");
    setB2Color("#FF731D");
    setShow(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        // border: "3px solid red",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        minHeight: "500px",
      }}>
      <ButtonBox>
        <ButtonWrapper1
          onClick={handleClick1}
          sx={{
            color: `${b1color}`,

            textTransform: "none",
            border: `1px solid ${b1color}`,
          }}>
          Already Saved
        </ButtonWrapper1>

        <ButtonWrapper2
          disabled={userAccess?.deldepart !== "Yes"}
          onClick={handleClick2}
          sx={{
            textTransform: "none",
            color: `${b2color}`,
            border: `1px solid ${b2color}`,
          }}>
          Add Department
        </ButtonWrapper2>
      </ButtonBox>
      {data?.length > 0
        ? !show && <SavedDepart />
        : !show && (
            <EmptySavedDepart
              disabled={userAccess?.deldepart !== "Yes"}
              handParentClick={handleClick2}
            />
          )}
    

      {show && flag ? <SavedDepart /> : show && <Department />}
    </Box>
  );
};

export default AddDep;
