import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  departmentAlreadySaved,
  postDepartment,
  postDepartmentName,
} from "../redux/data/action";
import SuccessModal from "../components/SuccessModal";

const FormText = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  color: "#212427",
  fontWeight: 500,

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SelectWrapper = styled(Select)(({ theme }) => ({
  width: "100%",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "56px",
  },
}));

const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // border: "1px solid red",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "2%",

  bgcolor: "white",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  width: "80%",
  justifyContent: "center",
  alignItems: "center",

  padding: "30px 30px 30px 30px",
  borderRadius: "33px",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",

    minWidth: "340px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const TextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "#1746A2",
  marginTop: "2%",
  fontSize: "20px",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "90%",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {
    height: "350px",
    width: "100%",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  marginTop: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FormInput = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "50px",
  border: "1px solid rgba(126, 126, 126, 0.3)",
  "& fieldset": { border: "none" },
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "56px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  width: "45%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "45%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  width: "80%",

  // border: "4px solid yellow",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
    // border: "1px solid green",
  },
}));

const BtnWrapper2 = styled(Stack)(({ theme }) => ({
  border: "none",
  marginTop: "10px",
  display: "flex",
  width: "100%",
  flexDirection: "row",
  gap: "20px",
  paddingTop: "1%",

  borderRadius: "15px",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    // border: "1px solid green",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",

    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid blue",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "150px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid black",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
    padding: "2%",
    alignItems: "center",
    height: "100px",
    marginTop: "45px",
    // border: "1px solid yellow",
  },
  [theme.breakpoints.down("xs")]: {
    width: "50%",
  },
}));

const FeedbackBox = styled(Box)(({ theme }) => ({
  // border: "1px solid red",
  width: "100%",
  bgcolor: "#EFF5FE",
  display: "flex",

  height: "250px",
  borderRadius: "10px",

  marginBottom: "20px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "230px",
    // border:"1px solid blue"
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "230px",
    // border:"1px solid black"
  },
}));

const AddressInput = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "100%",

  borderRadius: "6px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));
const BoxText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",

  width: "100%",
  overflowWrap: "break-word",
  fontWeight: "400",
  textAlign: "left",

  height: "100%",

  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    width: "100%",

    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "80%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Department = () => {
  const data = useSelector((store) => store.data.incharge);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const response = useSelector((store) => store.data.departmentName);
  const allDepartment = useSelector((store) => store.data.alreadySaved);
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const dispatch = useDispatch();

  const [inCharge, setInCharge] = useState("");
  const [departName, setDepartName] = useState("");
  const [description, setDescription] = useState("");
  const [inchargeuserId, setInchargeUserId] = useState("");

  const [departNameError, setDepartNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const text = "Department added";

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    setDepartName("");
    setDescription("");
    navigate("/department");
  };

  useEffect(() => {
    let data = {
      hosp_id: ids.hospid,
      userid: ids.userid,
      branchid: userBranch?.id,
    };

    dispatch(postDepartment(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(departmentAlreadySaved(data));
  }, [dispatch]);

  const handleDepartment = (e) => {
    let value = e.target.value;
    setDepartName(value);

    const isDepartmentExist = allDepartment?.some(
      (department) =>
        department.departname.toLowerCase() === value.toLowerCase().trim()
    );

    if (!value.trim()) {
      setDepartNameError("Department is required.");
    } else if (isDepartmentExist) {
      setDepartNameError("Department already exist.");
    } else if (value.length < 3) {
      setDepartNameError("Please enter valid department.");
    } else {
      setDepartNameError("");
    }
  };

  const handleIncharge = (e, newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setInCharge(newValue.name);
      setInchargeUserId(newValue.id);
   
    } else {
      setInCharge("");
      setInchargeUserId("");
    }
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
    if (!e.target.value) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError("");
    }
  };

  const handleSubmit = () => {
    if (departName === "") {
      setDepartNameError("Please fill the field");
    }

    if (departNameError === "" && departName !== "") {
      let data = {
        hospid: ids?.hospid,
        username: ids?.username,
        userid: ids?.userid,
        hospname: ids.hospname,
        department: departName.trim(),
        departdesc: description === "" ? "NA" : description.trim(),
        departincharge: inCharge === "" ? "NA" : inCharge,
        inchargeuserid: inchargeuserId === "" ? "NA" : inchargeuserId,
        branchid: userBranch.id,
        branchname: userBranch.branch,
        // hospname: userIds?.hospname,
      };

      setOpenModal(true);

      setToggle(true);
      dispatch(postDepartmentName(data, token));
    }
  };

  useEffect(() => {
    if (response === "Inserted" && departName !== "") {
      setOpenModal(true);
    }
  }, [response]);

  return (
    <>
      <MainDiv>
        <TopContainer>
          <TitleHead>Department</TitleHead>
        </TopContainer>
        <InnerContainer sx={{ bgcolor: "white" }}>
          <TextContainer>
            <FormWrapper>
              <FormElement>
                <TextLabel> Department Name </TextLabel>
                <FormInput
                  value={departName}
                  onChange={handleDepartment}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountBalanceIcon sx={{ color: "#1746A2" }} />
                    </InputAdornment>
                  }
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. Cardiology"
                />
                {<HelperText>{departNameError}</HelperText>}
              </FormElement>
              <FormElement>
                <TextLabel>In charge</TextLabel>
                {/* <SelectWrapper
                  value={inCharge}
                  onChange={handleIncharge}
                  displayEmpty
                  sx={{
                    bgcolor: "#EFF5FE",
                    "& fieldset": { border: "none" },
                    border: "1px solid rgba(23, 70, 162, 0.2)",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonIcon sx={{ color: "#1746A2" }} />
                    </InputAdornment>
                  }>
                  <MenuItem value="">
                    <FormText sx={{ color: "#a9a9a9" }}>In charge</FormText>
                  </MenuItem>
                  {data?.length !== undefined &&
                    data?.map((item) => (
                      <MenuItem
                        onClick={() => setInchargeUserId(item.userid)}
                        key={item.userid}
                        value={item.username}>
                        <FormText> {item.username}</FormText>
                      </MenuItem>
                    ))}
                </SelectWrapper> */}
                <Autocomplete
                  sx={{ bgcolor: "#EFF5FE" }}
                  options={data?.map((option) => ({
                    name: option.username,
                    id: option?.userid,
                  }))}
                  getOptionLabel={(option) => option.name} // Specify how to get the label for each option
                  onChange={handleIncharge}
                  renderInput={(params) => (
                    <TextField
                      placeholder="e.g.Arunraj MK"
                      {...params}
                    />
                  )}
                />
              </FormElement>
            </FormWrapper>
          </TextContainer>
          <BottomContainer>
            <FormControl>
              <TextLabel>Description </TextLabel>

              <FeedbackBox
                sx={{
                  bgcolor: "#EFF5FE",
                  border: "0.5px solid rgba(126, 126, 126, 0.3)",
                }}>
                <BoxText>
                  <AddressInput
                    sx={{
                      height: "100%",
                      "& fieldset": { border: "none" },
                      "& input::placeholder": {
                        verticalAlign: "top",
                      },
                    }}
                    id="standard-basic"
                    multiline
                    maxRows={10}
                    placeholder="e.g. Experts in heart health, diagnosing and treating cardiovascular conditions with care and precision."
                    value={description}
                    onChange={handleDescription}
                  />
                </BoxText>
              </FeedbackBox>

              {/* {<HelperText>{descriptionError}</HelperText>} */}
            </FormControl>
          </BottomContainer>
        </InnerContainer>
        <UploadContainer>
          <BtnWrapper2>
            <Button
              size="large"
              onClick={() => navigate("/department")}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                color: "#1746A2",
                bgcolor: "white",
                border: "1px solid #1746A2",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#3c52b2",
                },
              }}
              variant="contained">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              size="large"
              disabled={userAccess?.adddepart !== "Yes"}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              variant="contained">
              Submit
            </Button>
          </BtnWrapper2>
        </UploadContainer>
        <SuccessModal
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          response={response}
          text={text}
          toggle={toggle}
        />
      </MainDiv>
    </>
  );
};

export default Department;
