import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import Navbar3 from "./Navbar3";
import Footer from "./Footer";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { bioMedicalService } from "../redux/data/action";

import { useNavigate } from "react-router-dom";
import SubmissionPopUp from "./SubmissionPopUp";
import MuiAlert from "@mui/material/Alert";
const MainDiv = styled(Box)(({ theme }) => ({
  bgcolor: "white",

  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",

  alignItems: "center",
  marginTop: "7%",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "9%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TextFields = styled(TextField)(({ theme }) => ({
  // width:"100%",
  width: "100%",
  bgcolor: "white",
  "& fieldset": { border: "none" },
  borderRadius: "5px",
  border: "1px solid white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const InnerContainer = styled(Box)(({ theme }) => ({
  bgcolor: "#5C98E9",
  display: "flex",
  flexDirection: "column",

  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "28%",
  justifyContent: "center",
  alignItems: "center",
  padding: "40px",
  borderRadius: "30px",
  gap: "10px",
  paddingBottom: "20px",
  textAlign: "start",

  [theme.breakpoints.down("xl")]: {
    width: "28%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "50%",
  },
  [theme.breakpoints.down("md")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "65%",
  },
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginTop: "3%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    marginTop: "8%",
    marginBottom: "3%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "10%",
    marginBottom: "3%",
  },
  [theme.breakpoints.down("xs")]: {
    marginTop: "10%",
  },
}));
function ServiceSupport() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [equipName, setEquipName] = useState("");
  const [brand, setBrand] = useState("");
  const [issue, setIssue] = useState("");
  const [hospName, setHospName] = useState("");

  const [notes, setNotes] = useState("");
  const [isNotesError, setIsNotesError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isEmailError, setIsEmailError] = useState("");
  const [isMobileError, setIsMobileError] = useState("");
  const [isequipNameError, setIsequipNameError] = useState("");
  const [issueError, setIssueError] = useState("");
  const [isHospError, setIsHospError] = useState("");
  const [isBrandError, setIsBrandError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const text = " Sended ";

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);

    navigate("/");
  };
  const handleNotes = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setNotes(value);
      setIsNotesError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsNotesError("Please avoid that special character");
    }
  };
  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Please avoid that special character");
    }
  };

  const handleEquip = (e) => {
    const value = e.target.value;
    const regex = /^[^"']*$/;
    if (regex.test(value) || value === "") {
      setEquipName(value);
      setIsequipNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsequipNameError("Please avoid that special character");
    }
  };

  const handleIssue = (e) => {
    const value = e.target.value;
    const regex = /^[^"']*$/;
    if (regex.test(value) || value === "") {
      setIssue(value);
      setIssueError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIssueError("Please avoid that special character");
    }
  };
  const handleMobile = (e) => {
    const value = e.target.value;
    const regex = /^\+?[0-9]{10}$/;
    if (regex.test(value) || value === "") {
      setMobile(value);
      setIsMobileError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsMobileError("Please enter valid mobile number");
    }
  };

  const handleHospital = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setHospName(value);
      setIsHospError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsHospError("Please avoid that special character");
    }
  };

  const handleBrand = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setBrand(value);
      setIsBrandError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsBrandError("Please avoid that special character");
    }
  };
  const handleEmail = (e) => {
    const value = e.target.value;
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{1,4}$/;
    if (regex.test(value) || value === "") {
      setEmail(value);
      setIsEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsEmailError("Please enter valid email");
    }
  };

  const handleSubmit = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
    if (notes === "") {
      setIsNotesError("Please fill the field");
    }
    if (name === "") {
      setNameError("Please fill the field");
    }
    if (mobile === "") {
      setIsMobileError("Please fill the field");
    }
    if (email === "") {
      setIsEmailError("Please fill the field");
    }
    if (hospName === "") {
      setIsHospError("Please fill the field");
    }
    if (equipName === "") {
      setIsequipNameError("Please fill the field");
    }
    if (issue === "") {
      setIssueError("Please fill the field");
    }

    if (
      notes !== "" &&
      isNotesError === "" &&
      name !== "" &&
      nameError === "" &&
      mobile !== "" &&
      isMobileError === "" &&
      email !== "" &&
      isEmailError === "" &&
      hospName !== "" &&
      isHospError === "" &&
      equipName !== "" &&
      isequipNameError === "" &&
      issue !== "" &&
      issueError === ""
    ) {
      let data = {
        hospname: hospName,
        address: notes,
        userid: "NA",
        name: name,
        mobile: mobile,
        email: email,
        equipment: equipName,
        brand: brand,
        message: issue,
      };

      dispatch(bioMedicalService(data))
        .then(() => {
          setOpenModal(true);
        })
        .catch((error) => {});
    } else {
      setSnackbarMessage("Please fill in all the required fields.");
      setSnackbarOpen(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  return (
    <>
      <MainDiv sx={{ background: "#faf5ee" }}>
        <Navbar3 />
        <Heading variant="h5">Biomedical Services & Support</Heading>
        <InnerContainer
          sx={{ bgcolor: "#5C98E9", marginBottom: "60px", marginTop: "2%" }}>
          <Typography variant="h6" color={"white"}>
            Biomedical Services & Support
          </Typography>

          <Box sx={{ width: "100%" }}>
            <InputLabel
              htmlFor="component-simple"
              sx={{
                marginTop: "20px",
                color: "white",
                "& fieldset": { border: "none" },
              }}>
              Hospital Name
            </InputLabel>
            <TextFields
              sx={{ bgcolor: "#EFF5FE" }}
              onChange={handleHospital}
              size="small"
              placeholder="Enter hospital name"
              onKeyPress={handleKeyPress}
            />
            {<FormHelperText>{isHospError}</FormHelperText>}
            <InputLabel
              htmlFor="component-simple"
              sx={{ marginTop: "20px", color: "white" }}>
              Address
            </InputLabel>
            <TextFields
              multiline
              maxRows={4}
              sx={{ bgcolor: "#EFF5FE" }}
              size="small"
              onChange={handleNotes}
              onKeyPress={handleKeyPress}
              placeholder="Enter address"
            />
            {<FormHelperText>{isNotesError}</FormHelperText>}
            <InputLabel
              htmlFor="component-simple"
              sx={{ marginTop: "20px", color: "white" }}>
              Name
            </InputLabel>
            <TextFields
              sx={{ bgcolor: "#EFF5FE" }}
              size="small"
              onChange={handleName}
              onKeyPress={handleKeyPress}
              placeholder="Enter Name"
            />
            {<FormHelperText>{nameError}</FormHelperText>}

            <InputLabel
              htmlFor="component-simple"
              sx={{
                marginTop: "20px",
                color: "white",
                "& fieldset": { border: "none" },
              }}>
              Mobile
            </InputLabel>
            <TextFields
              sx={{ bgcolor: "#EFF5FE" }}
              onChange={handleMobile}
              onKeyPress={handleKeyPress}
              size="small"
              placeholder="e.g. 7356230040"
            />
            {<FormHelperText>{isMobileError}</FormHelperText>}
            <InputLabel
              htmlFor="component-simple"
              sx={{ marginTop: "20px", color: "white" }}>
              Email
            </InputLabel>
            <TextFields
              sx={{ bgcolor: "#EFF5FE" }}
              onChange={handleEmail}
              onKeyPress={handleKeyPress}
              size="small"
              placeholder="Enter email"
            />
            {<FormHelperText>{isEmailError}</FormHelperText>}
            <InputLabel
              htmlFor="component-simple"
              sx={{ marginTop: "20px", color: "white" }}>
              Equipment Name
            </InputLabel>
            <TextFields
              sx={{ bgcolor: "#EFF5FE" }}
              onChange={handleEquip}
              onKeyPress={handleKeyPress}
              size="small"
              placeholder="Enter equipment name"
            />
            {<FormHelperText>{isequipNameError}</FormHelperText>}
            <InputLabel
              htmlFor="component-simple"
              sx={{ marginTop: "20px", color: "white" }}>
              Brand
            </InputLabel>
            <TextFields
              sx={{ bgcolor: "#EFF5FE" }}
              onChange={handleBrand}
              onKeyPress={handleKeyPress}
              size="small"
              placeholder="Enter brand"
            />
            {<FormHelperText>{isBrandError}</FormHelperText>}
            <InputLabel
              htmlFor="component-simple"
              sx={{ marginTop: "20px", color: "white" }}>
              Describe the Issue
            </InputLabel>
            <TextFields
              multiline
              maxRows={4}
              sx={{ bgcolor: "#EFF5FE" }}
              onChange={handleIssue}
              onKeyPress={handleKeyPress}
              size="small"
              placeholder="Enter issue"
            />
            {<FormHelperText>{issueError}</FormHelperText>}
          </Box>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#1746A2",

              marginTop: "5%",
              borderRadius: "30px",
              textTransform: "none",
            }}
            onClick={handleSubmit}
            size="large">
            Submit
          </Button>
        </InnerContainer>
        <SubmissionPopUp
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          text={text}
        />
        <Box sx={{ width: "100%" }}>
          <Footer />
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </MainDiv>
    </>
  );
}

export default ServiceSupport;
