import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import "react-multi-carousel/lib/styles.css";

import { useNavigate } from "react-router-dom";
import { userManual } from "../redux/data/action";

const MainDiv = styled(Box)(({ theme }) => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  paddingBottom: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHeading = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  height: "10px",
  fontSize: "18px",
  minWidth: "200px",

  color: "black",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5,1fr)",
  justifyContent: "space-between",
  justifyItems: "center",
  // gap: "20px",
  columnGap: "20px",
  rowGap: "50px",
  // height:'140px',
  width: "90%",
  padding: "20px",
  paddingTop: "60px",
  paddingBottom: "60px",

  [theme.breakpoints.down("xl")]: {
    gridTemplateColumns: "repeat(5,1fr)",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(4,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    // border: "4px solid blue",
    gridTemplateColumns: "repeat(2,1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
    // border: "2px solid blue",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const InnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  gap: "20px",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  // height:'140px',
  width: "170px",
  height: "170px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageCard = styled(Box)(({ theme }) => ({
  // width: "300px",

  display: "flex",
  gap: "15px",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: "230px",
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",

  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "200px",
  },
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImagePettal = styled(Box)(({ theme }) => ({
  // width: "300px",
  width: "72px",
  height: "72px",
  borderRadius: "50%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "160px",
    // height: "160px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopRight = styled(Box)(({ theme }) => ({
  // width: "300px",
  display: "flex",
  gap: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const UserManual = () => {
  const userManualData = useSelector((store) => store.data?.userManual);
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [productName, setProductName] = useState("");

  const localStorageId =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [brandData, setBrandData] = useState([]);

  const handleNavigate = (brand) => {
    navigate(`/manual-details/${brand}`);
  };

  const search = (e) => {
    let searchTerm = e.target.value;
    setProductName(searchTerm);
  };

  const filteredCardData = brandData
    ?.filter((item) =>
      item.brand_name.trim().toLowerCase().includes(productName.toLowerCase())
    )
    .sort((a, b) => {
      const aStartsWithSearchTerm = a.brand_name
        .toLowerCase()
        .startsWith(productName.toLowerCase());
      const bStartsWithSearchTerm = b.brand_name
        .toLowerCase()
        .startsWith(productName.toLowerCase());

      if (aStartsWithSearchTerm && !bStartsWithSearchTerm) {
        return -1; // a comes first
      } else if (!aStartsWithSearchTerm && bStartsWithSearchTerm) {
        return 1; // b comes first
      } else {
        return a.brand_name.localeCompare(b.brand_name);
      }
    });

  let data = filteredCardData;

  const manual = userManualData?.brandlist?.map((el) => el?.brand_icon_url);

  for (let i = 0; i < manual?.length; i++) {}

  useEffect(() => {
    let data = {
      hospid: localStorageId?.hospid,
      userid: localStorageId?.userid,
    };
    dispatch(userManual(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (userManualData?.brandlist?.length > 0) {
      setBrandData(userManualData?.brandlist);
    }
  }, [userManualData?.brandlist]);

  return (
    <MainDiv>
      <TopContainer sx={{}}>
        <TitleHeading>User Manual</TitleHeading>

        <TopRight>
          <SortFilterContainer>
            <Container>
              <Search
                type="search"
                value={productName}
                placeholder="Search"
                onChange={search}
                sx={{
                  height: "40px",
                  border: "1px solid rgba(23, 70, 162, 0.5)",
                  bgcolor: "white",

                  borderRadius: "36px",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      placeholder="Search"
                      aria-label="toggle password visibility"
                      edge="start">
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Container>
          </SortFilterContainer>

          <Button
            so
            sx={{
              borderRadius: "17px",
              textTransform: "none",
              bgcolor: "#1746A2",
            }}
            size="small"
            variant="contained"
            onClick={() => navigate("/contact")}>
            <Typography sx={{ whiteSpace: "noWrap" }}>
              {" "}
              Request Manual
            </Typography>
          </Button>
        </TopRight>
      </TopContainer>

      <TopBox
        sx={{ bgcolor: "rgba(255, 143, 74, 0.21)", borderRadius: "10px" }}>
        {data?.map((imageUrl, index) => (
          <InnerBox key={index} sx={{ bgcolor: "white" }}>
            <ImageCard onClick={() => handleNavigate(imageUrl.brand_name)}>
              <ImagePettal
                as="img"
                src={imageUrl?.brand_icon_url}
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",

                  width: "72px",
                  height: "72px",
                }}></ImagePettal>

              <ButtonText value={imageUrl?.brand_name}>
                {imageUrl?.brand_name}
              </ButtonText>
            </ImageCard>
          </InnerBox>
        ))}
      </TopBox>
    </MainDiv>
  );
};

export default UserManual;
