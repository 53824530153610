import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { IoMdGitPullRequest } from "react-icons/io";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ArticleIcon from "@mui/icons-material/Article";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SwitchCameraIcon from "@mui/icons-material/SwitchCamera";
import SwitchCameraOutlinedIcon from "@mui/icons-material/SwitchCameraOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddchartIcon from "@mui/icons-material/Addchart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import BroadcastOnHomeIcon from "@mui/icons-material/BroadcastOnHome";
import BedroomChildOutlinedIcon from "@mui/icons-material/BedroomChildOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { ImTarget } from "react-icons/im";
import { CgNotes } from "react-icons/cg";
import { MdMiscellaneousServices } from "react-icons/md";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { TbReportSearch } from "react-icons/tb";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import vajra from "../images/vajra.png";
import { Route, Router, Routes, useLocation } from "react-router";
import { Link } from "react-router-dom";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import { useDispatch, useSelector } from "react-redux";
import NbmPrivateRoutes from "../routes/NbmPrivateRoutes";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Tooltip,
  useMediaQuery,
  Select,
} from "@mui/material";
import { DialogTitle, Menu } from "@material-ui/core";
import { isMoment } from "moment";
import PrivateRoute from "./../routes/PrivateRoute";

import { useState } from "react";
import { useEffect } from "react";
import UserLogOut from "../components/UserLogOut";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import {
  getUserData,
  logOut,
  retrieveAllAssets,
} from "../redux/nbmData/action";
import DialogBox from "../components/DialogBox";
import NoUserFound from "../components/NoUserFound";

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: "#1746A2",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  width: drawerWidth,
});

const closedMixin = (theme) => ({
  background: "#1746A2",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  width: theme.spacing(7),
});

const StyledList = styled(List)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  "&, & .MuiListItemIcon-root": {
    color: "white",
  },
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "white",
    "&, & .MuiListItemIcon-root": {
      color: "#1746A2",
    },
  },
  // hover states
});

const SmallSwitch = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  // width: "60px",
  textTransform: "none",
  padding: "0px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  position: "sticky",
  top: 0,
  zIndex: 1000,
  bgcolor: "#faf5ee",

  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const SideBarPage2 = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }),
}));

const Previous = styled(Button)(({ theme }) => ({
  border: "0px solid transparent",
  textTransform: "none",
  height: "30px",
  fontSize: "16px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PreviousContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const BranchSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  background: "white",
  border: "1px solid white",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const BranchSelect2 = styled(Select)(({ theme }) => ({
  height: "40px",
  background: "white",
  border: "1px solid white",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "85px",
    height: "25px",
    margin: "5%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
export default function MiniDrawer() {
  const theme = useTheme();
  const allAssets = useSelector((store) => store.data.allAssets);
  const userData = useSelector((store) => store.data.userData);
  const [nbmChecker, setNbmChecker] = useState([]);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  // let userAccess2 = JSON.parse(sessionStorage.getItem("user_access"));

  // let checkAccess = userAccess === null ? false : userAccess.role === "NA"; //
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [selectedBranch, setSelectedBranch] = useState(userBranch);
  const [open, setOpen] = React.useState(false);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModal, setOpenModal] = useState(false);
  const truncatedUsername = userIds.username
    ? userIds.username.slice(0, 2).toUpperCase()
    : "";
  const handleButtonClick = () => {
    if (userIds.subs_plan === "Basic") {
      setOpenModal(true);
      handleDrawerClose();
    } else {
      navigate("/user-manual");
      handleDrawerClose();
    }
  };
  const handleButtonClick2 = () => {
    if (userIds.subs_plan === "Basic" && allAssets?.length >= 50) {
      setOpenModal(true);
      handleDrawerClose();
      handleClose();
    } else {
      navigate("/add-asset");
      handleDrawerClose();
      handleClose();
    }
  };
  const handleButtonClick3 = () => {
    if (userIds.subs_plan === "Basic" && userData?.length >= 1) {
      setOpenModal(true);
      handleDrawerClose();
      handleClose();
    } else {
      navigate("/adduser");
      handleDrawerClose();
      handleClose();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openLogout, setOpenLogout] = React.useState(false);
  const handleOpenLogout = () => {
    setAnchorE2(null);
    setOpenLogout(true);
  };
  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const token = localStorage.getItem("userToken");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open2 = Boolean(anchorE2);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = location.pathname;
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [opens, setOpens] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const bmOrNbm = localStorage.getItem("bmOrNbm");
  const [openRequest, setOpenRequest] = React.useState(true);
  const handleChange = () => {
    setSelectedBranch({
      branch: userIds?.bm_branches[0],
      id: userIds?.bm_branchids[0],
    });

    let branchData = {
      branch: userIds?.bm_branches[0],
      id: userIds?.bm_branchids[0],
    };

    if (nbmChecker?.length > 0) {
      let branchData = {
        branch: nbmChecker[0]?.bm_branch,
        id: nbmChecker[0]?.bm_branchid,
      };

      localStorage.setItem("zzzz", JSON.stringify(branchData));
    } else {
      let branchData = {
        branch: userIds?.bm_branches[0],
        id: userIds?.bm_branchids[0],
      };
      localStorage.setItem("zzzz", JSON.stringify(branchData));
    }

    localStorage.setItem("userBranch", JSON.stringify(branchData));
    sessionStorage.setItem("userBranch", JSON.stringify(branchData));

    sessionStorage.setItem("SbmOrNbm", "BM");
    localStorage.setItem("bmOrNbm", "BM");

    window.location.reload();
    window.location.replace("/home");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (isMobileScreen) {
      setOpen(false);
    }
  };
  const handleDrawerClose2 = () => {
    setOpen(false);
  };

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClicks = () => {
    setOpens(!opens);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRequest = () => {
    setOpenRequest(!openRequest);
  };

  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));
    // localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.getItem("userToken");
    navigate("/signin");
    localStorage.setItem("logoutEvent", Date.now().toString());
    window.location.reload();
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch2 = useMediaQuery(theme.breakpoints.down("md"));
  React.useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);
  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(getUserData(data, token));
  }, [dispatch]);

  const handleBranch = (branch, id) => {
    setSelectedBranch({ branch: branch, id: id });
    let branchData = {
      branch: branch,
      id: id,
    };
    localStorage.setItem("userBranch", JSON.stringify(branchData));
    sessionStorage.setItem("userBranch", JSON.stringify(branchData));
    window.location.reload();
    window.location.replace("/home");
  };

  const branchesAndIds = userIds?.nbm_branches?.map((branchName, index) => ({
    branch: branchName,
    id: userIds?.nbm_branchids[index],
  }));

  useEffect(() => {
    let check = userIds?.bm_branchids.filter((item) => {
      return item === selectedBranch.id;
    });

    let filteredBranches = check.map((branchId) => {
      let index = userIds.bm_branchids.indexOf(branchId);
      return {
        bm_branch: userIds.bm_branches[index],
        bm_branchid: userIds.bm_branchids[index],
      };
    });

    setNbmChecker(filteredBranches);
  }, [selectedBranch]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SideBarPage2
        sx={{
          bgcolor: "transparent",
        }}
        position="fixed"
        open={open}>
        {isMatch ? (
          <>
            <Toolbar
              elevation={0}
              sx={{
                boxShadow: "none",
                bgcolor: "#1746A2",
              }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 0,
                  ...(open && { display: "none" }),
                }}>
                <MenuIcon />
              </IconButton>

              {path !== "/home" && (
                <PreviousContainer>
                  {isMatch ? (
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBackIosIcon
                        sx={{ fontSize: "20px", color: "white" }}
                      />
                    </IconButton>
                  ) : (
                    <Previous onClick={() => navigate(-1)}>
                      <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "25px" }} />{" "}
                      Previous
                    </Previous>
                  )}
                </PreviousContainer>
              )}

              <Box
                sx={{
                  width: "300px",
                  display: "flex",
                  justifyContent: "space-between",
                  // gap: "5px",
                }}>
                <Tooltip title="Scan qr-code to get asset detail.">
                  <IconButton
                    sx={{ marginRight: "2%" }}
                    onClick={() => navigate("/scan")}>
                    <QrCodeScannerIcon sx={{ color: "#FF731D" }} />
                  </IconButton>
                </Tooltip>

                <FormControl
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {/* <Tooltip title="Choose your branch." arrow> */}
                  <BranchSelect2
                    sx={{
                      marginRight: "2%",
                      borderRadius: "36px",
                      height: "36px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      "& fieldset": {
                        border: "none",
                        borderWidth: 0,
                      },
                    }}
                    value={selectedBranch?.branch}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}>
                    {branchesAndIds?.map((item) => (
                      <MenuItem
                        value={item?.branch}
                        key={item?.id}
                        onClick={() => handleBranch(item?.branch, item?.id)}>
                        {item?.branch}
                      </MenuItem>
                    ))}
                  </BranchSelect2>
                  {/* </Tooltip> */}
                </FormControl>

                <IconButton onClick={handleChange} style={{ marginLeft: "2%" }}>
                  <SmallSwitch
                    sx={{
                      bgcolor: "white",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}>
                    Bio
                  </SmallSwitch>
                </IconButton>

                <Box
                  variant="h6"
                  color="black"
                  onClick={() => navigate("/contact")}
                  sx={{
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    cursor: "pointer",
                  }}>
                  {/* <DiamondOutlinedIcon sx={{ color: "white" }} /> */}
                </Box>


                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    cursor: "pointer",
                  }}>
                  <AddCircleOutlineOutlinedIcon sx={{ color: "white" }} />

                  {/* {!anchorEl ? <ExpandMore /> : <ExpandLess />} */}
                </Box>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={anchorEl}
                  onClose={handleClose}
                  sx={{ marginTop: "2%" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                    },
                  }}>
                  <MenuItem
                    onClick={handleButtonClick2}
                    // component={Link}
                    // to="/add-asset"
                    button
                    selected={"/add-asset" === path}>
                    {" "}
                    Asset
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/add-department"
                    button
                    selected={"/add-department" === path}>
                    {" "}
                    Department
                  </MenuItem>
                  <MenuItem
                    onClick={handleButtonClick3}
                    button
                    selected={"/adduser" === path}>
                    {" "}
                    User
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/pm-checklist"
                    button
                    selected={"/pm-checklist" === path}>
                    {" "}
                    PM Checklist
                  </MenuItem>
                </Menu>

                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                  sx={{
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    cursor: "pointer",
                  }}>
                  <AccountCircleOutlinedIcon sx={{ color: "white" }} />

                  {/* {!open2 ? <ExpandMore /> : <ExpandLess />} */}
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={handleClose2}
                  sx={{ marginTop: "2%" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                    },
                  }}>
                  <MenuItem
                    onClick={handleClose2}
                    component={Link}
                    to="/profile"
                    button
                    selected={"/profile" === path}>
                    {" "}
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleOpenLogout}
                    component={Link}
                    to="/signin"
                    button
                    selected={"/signin" === path}>
                    {" "}
                    Log out
                  </MenuItem>
                </Menu>
              </Box>
              <NotificationsIcon
                  style={{
                    color: "#FF731D",
                    fontSize: "25px",
                    cursor:'pointer'
                  }}
                  onClick={() => navigate("/notification")}
                />


            </Toolbar>
          </>
        ) : (
          <>
            {" "}
            <Toolbar sx={{}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}>
                <MenuIcon />
              </IconButton>

              {path !== "/home" && (
                <PreviousContainer>
                  {isMatch ? (
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  ) : (
                    <Previous onClick={() => navigate(-1)}>
                      <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "25px" }} />{" "}
                      Previous
                    </Previous>
                  )}
                </PreviousContainer>
              )}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "35px",
                }}>
                <Tooltip title="Scan qr-code to get asset detail.">
                  <IconButton
                    sx={{ marginRight: "2%" }}
                    onClick={() => navigate("/scan")}>
                    <QrCodeScannerIcon sx={{ color: "#FF731D" }} />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Choose your branch." arrow> */}
                <BranchSelect
                  value={selectedBranch?.branch}
                  // onChange={handleBranch}
                  sx={{
                    borderRadius: "36px",
                    height: "36px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    "& fieldset": {
                      border: "none",
                      borderWidth: 0,
                    },
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}>
                  {branchesAndIds?.map((item) => (
                    <MenuItem
                      value={item?.branch}
                      key={item?.id}
                      onClick={() => handleBranch(item?.branch, item?.id)}>
                      {item?.branch}
                    </MenuItem>
                  ))}
                </BranchSelect>
                {/* </Tooltip> */}
                <Tooltip title="Go to biomedical section" disableTouchListener>
                  <Button
                    sx={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "36px",
                      marginRight: "2%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      bgcolor: "white",
                      cursor: "pointer",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                    onClick={handleChange}
                    disabled={
                      nbmChecker.length === 0 && userIds.role === "Sub_User"
                    }>
                    {isMatch2 ? "Bio" : " Switch to Biomedical"}
                  </Button>
                </Tooltip>

                <Tooltip title="To add User, Asset, Department etc..">
                  <Box
                    variant="h6"
                    color="black"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "36px",
                      width: "100px",
                      marginRight: "2%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "white",
                      cursor: "pointer",
                      height: "35px",
                    }}>
                    <AddCircleOutlineOutlinedIcon sx={{ color: "#FF731D" }} />
                    Add
                    {!anchorEl ? <ExpandMore /> : <ExpandLess />}
                  </Box>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={anchorEl}
                  onClose={handleClose}
                  sx={{ marginTop: "2%" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                    },
                  }}>
                  <MenuItem
                    onClick={handleButtonClick2}
                    // component={Link}
                    // to="/add-asset"
                    button
                    selected={"/add-asset" === path}>
                    {" "}
                    Asset
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/add-department"
                    button
                    selected={"/add-department" === path}>
                    {" "}
                    Department
                  </MenuItem>
                  <MenuItem
                    onClick={handleButtonClick3}
                    button
                    selected={"/adduser" === path}>
                    {" "}
                    User
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/pm-checklist"
                    button
                    selected={"/pm-checklist" === path}>
                    {" "}
                    PM Checklist
                  </MenuItem>

                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/vendor-relations/vendor"
                    button
                    selected={"/vendor-relations/vendor" === path}>
                    {" "}
                    Vendor/service
                  </MenuItem>

                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/task"
                    button
                    selected={"/task" === path}>
                    {" "}
                    Task
                  </MenuItem>
                </Menu>

                <NotificationsIcon
                  style={{
                    color: "#FF731D",
                    fontSize: "30px",
                    cursor:'pointer'
                  }}
                  onClick={() => navigate("/notification")}
                />


                <Tooltip title="Profile">
                  <Box
                    variant="h6"
                    color="black"
                    id="basic-button"
                    aria-controls={open2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}
                    sx={{
                      boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "36px",
                      // minWidth: "150px",
                      // padding: "20px",
                      height: "38px",
                      marginRight: "2%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "white",
                      cursor: "pointer",
                    }}>
                    <Avatar
                      sx={{
                        bgcolor: "white",
                        color: "#FF731D",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      }}>
                      {truncatedUsername}
                    </Avatar>
                  </Box>
                </Tooltip>



                
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={handleClose2}
                  sx={{ marginTop: "5%", top: "24px" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                    },
                  }}>
                  <MenuItem
                    onClick={handleClose2}
                    component={Link}
                    to="/profile"
                    button
                    sx={{}}
                    selected={"/profile" === path}>
                    {" "}
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleOpenLogout}
                    component={Link}
                    // to="/signin"
                    button
                    // selected={"/signin" === path}
                  >
                    {" "}
                    Log out
                  </MenuItem>
                </Menu>
              </Box>

            

              <Badge
                // badgeContent={unreadCount}
                color="error"
                sx={{
                  "& .MuiBadge-badge": {
                    width: "15px",
                    height: "15px",
                    minWidth: "15px",
                    transform: "scale(1) translate(13%, -50%)",
                  },
                }}>
             
              </Badge>
            </Toolbar>
          </>
        )}
      </SideBarPage2>
      <Drawer
        variant="permanent"
        sx={{
          position: {
            xl: "static",
            lg: "static",
            md: "static",
            sm: "static",
            xs: "absolute",
          },
        }}
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose2}>
        <DrawerHeader sx={{ bgcolor: "#1746A2" }}>
          {open && (
            <Box
              onClick={() => navigate("/home")}
              as="img"
              margin={"auto"}
              src={vajra}
              alt="logo"
              sx={{ height: "44px", width: "80px", cursor: "pointer" }}
            />
          )}

          <IconButton onClick={handleDrawerClose2}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              open && <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        {/* <Divider /> */}
        <List></List>
        {/* <Divider /> */}

        <StyledList>
          <ListItem
            disablePadding
            component={Link}
            to="/home"
            button
            selected={"/home" === path}
            sx={{}}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>

              <ListItemText primary={"Home"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/all-assets"
            button
            selected={"/all-assets" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon sx={{}}>
                <BroadcastOnHomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Assets"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/department"
            button
            selected={"/department" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <LanOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Departments"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/incident-list"
            button
            selected={"/incident-list" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <AnalyticsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Incidents"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/service-list"
            button
            selected={"/service-list" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <MdMiscellaneousServices size={25} />
              </ListItemIcon>
              <ListItemText primary={"Services"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={Link}
            to="/depreciation"
            button
            selected={"/depreciation" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <CalculateOutlinedIcon size={25} />
              </ListItemIcon>
              <ListItemText primary={"Depreciation"} />
            </ListItemButton>
          </ListItem>

          {/* <ListItem
            disablePadding
            component={Link}
            to="/depreciation"
            button
            selected={"/depreciation" === path} onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <CalculateOutlinedIcon size={25} />
              </ListItemIcon>
              <ListItemText primary={"Depreciation"} />
            </ListItemButton>
          </ListItem> */}

          <ListItem
            disablePadding
            component={Link}
            to="/report"
            button
            selected={"/report" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <TbReportSearch size={25} />
              </ListItemIcon>
              <ListItemText primary={"Reports"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/calendar"
            button
            selected={"/calendar" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <CalendarMonthIcon size={20} />
              </ListItemIcon>
              <ListItemText primary={"Calendar"} />
            </ListItemButton>
          </ListItem>

          {userAccess &&
            (userAccess.role === "Owner" ||
              userAccess.role === "Super_Admin") && (
              <ListItem
                disablePadding
                component={Link}
                to="/asset-history-list"
                button
                selected={"/asset-history-list" === path}
                onClick={handleDrawerClose}>
                <ListItemButton sx={{ height: "40px" }}>
                  <ListItemIcon>
                    <PublishedWithChangesOutlinedIcon size={20} />
                  </ListItemIcon>
                  <ListItemText primary={"Asset History"} />
                </ListItemButton>
              </ListItem>
            )}

          {/* <ListItem
            disablePadding
            component={Link}
            to="/warranty-status/all"
            button
            selected={"/warranty-status/all" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <CgNotes size={20} />
              </ListItemIcon>
              <ListItemText primary={"Warranty Status"} />
            </ListItemButton>
          </ListItem> */}

          <ListItem
            disablePadding
            component={Link}
            to="/user"
            button
            selected={"/user" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <Groups2OutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={handleRequest}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <IoMdGitPullRequest size={22} />
              </ListItemIcon>
              <ListItemText primary={"Request"} />
              {openRequest ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
          </ListItem>

          <Collapse in={!openRequest} timeout="auto" unmountOnExit>
            <ListItemButton
              sx={{ height: "40px", borderBottom: "2px solid white" }}
              button
              to="/calibration"
              selected={"/calibration" === path}
              onClick={handleClose}>
              <ListItemIcon>
                <ImTarget size={20} />
              </ListItemIcon>
              <ListItemText primary={"Calibrations"} />
            </ListItemButton>

            <ListItemButton
              sx={{ height: "40px", borderBottom: "2px solid white" }}
              button
              to="/amc-cmc"
              onClick={handleClose}
              selected={"/amc-cmc" === path}>
              <ListItemIcon>
                <EngineeringOutlinedIcon size={25} />
              </ListItemIcon>
              <ListItemText primary={"AMC/CMC "} />
            </ListItemButton>

            <ListItemButton
              sx={{ height: "40px", borderBottom: "2px solid white" }}
              button
              to="/warranty-status/all"
              onClick={handleClose}
              selected={"/warranty-status/all" === path}>
              <ListItemIcon>
                <ArticleIcon size={25} />
              </ListItemIcon>
              <ListItemText primary={"Warranty"} />
            </ListItemButton>

            {/* ArticleIcon */}
          </Collapse>

          <ListItem disablePadding onClick={handleClicks}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Add"} />
              {opens ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
          </ListItem>
          <Collapse in={!opens} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ height: "40px", borderBottom: "2px solid white" }}
                // component={Link}
                // to="/add-asset"
                button
                onClick={handleButtonClick2}
                selected={"/add-asset" === path}>
                <ListItemIcon>
                  <BedroomChildOutlinedIcon
                  // sx={{
                  //   color:
                  //     userIds.subs_plan === "Basic" && allAssets?.length >= 50
                  //       ? "#FF731D"
                  //       : "white",
                  // }}
                  />
                </ListItemIcon>
                <ListItemText primary=" Asset" />
              </ListItemButton>
              <ListItemButton
                sx={{ height: "40px", borderBottom: "2px solid white" }}
                component={Link}
                to="/add-department"
                button
                selected={"/add-department" === path}
                onClick={handleDrawerClose}>
                <ListItemIcon>
                  <AddchartIcon />
                </ListItemIcon>
                <ListItemText primary="Department" />
              </ListItemButton>
              {userAccess && userAccess?.role !== "Guest" && (
                <ListItemButton
                  sx={{ height: "40px", borderBottom: "2px solid white" }}
                  component={Link}
                  to="/asset-history"
                  button
                  selected={"/asset-history" === path}
                  onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <MoveUpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Asset Movement" />
                </ListItemButton>
              )}
              <ListItemButton
                onClick={handleButtonClick3}
                sx={{ height: "40px", borderBottom: "2px solid white" }}
                button
                selected={"/adduser" === path}>
                <ListItemIcon>
                  <PersonAddIcon
                  // sx={{
                  //   color:
                  //     userIds.subs_plan === "Basic" && userData?.length >= 1
                  //       ? "#FF731D"
                  //       : "white",
                  // }}
                  />
                </ListItemIcon>
                <ListItemText primary=" User" />
              </ListItemButton>
              <ListItemButton
                sx={{ height: "40px", borderBottom: "2px solid white" }}
                component={Link}
                to="/pm-checklist"
                button
                selected={"/pm-checklist" === path}
                onClick={handleDrawerClose}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary=" PM Checklist" />
              </ListItemButton>

              <ListItemButton
                sx={{ height: "40px", borderBottom: "2px solid white" }}
                component={Link}
                to="/vendor-relations/vendor"
                button
                selected={"/vendor-relations/vendor" === path}
                onClick={handleDrawerClose}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Vendor/service" />
              </ListItemButton>

              <ListItemButton
                sx={{ height: "40px", borderBottom: "2px solid white" }}
                component={Link}
                to="/task"
                button
                selected={"/task" === path}
                onClick={handleDrawerClose}>
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary="Task" />
              </ListItemButton>

              <ListItemButton
                sx={{ height: "40px", borderBottom: "2px solid white" }}
                component={Link}
                to="/checkin-checkout/checkin"
                button
                selected={"/checkin-checkout/checkin" === path}
                onClick={handleDrawerClose}>
                <ListItemIcon>
                  <SwapHorizIcon />
                </ListItemIcon>
                <ListItemText primary="Check in & out" />
              </ListItemButton>
            </List>
          </Collapse>

          {/* <ListItem
            disablePadding
            component={Link}
            to="/user-manual"
            button
            onClick={handleButtonClick}
            selected={"/user-manual" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <MenuBookOutlinedIcon
                  sx={{
                    color: userIds.subs_plan === "Basic" ? "#FF731D" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"User Manual"} />
            </ListItemButton>
          </ListItem> */}
          <ListItem
            disablePadding
            component={Link}
            to="/profile"
            button
            selected={"/profile" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <PermIdentityOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Profile"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/contact"
            button
            selected={"/contact" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <HeadsetMicOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Contact us"} />
            </ListItemButton>
          </ListItem>
        </StyledList>
        <StyledList sx={{ marginTop: { xl: "5px", lg: "0" } }}>
          <ListItem
            disablePadding
            to="/signin"
            button
            selected={"/signin" === path}
            onClick={handleOpenLogout}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Log Out"} />
            </ListItemButton>
          </ListItem>
        </StyledList>
      </Drawer>
      <UserLogOut
        handleCloseLogout={handleCloseLogout}
        handleLogout={handleLogout}
        openLogout={openLogout}
      />

      {/* accessChecker */}

      <Box
        component="main"
        onClick={handleDrawerClose}
        sx={{
          flexGrow: 1,
          // p:3,
          paddingBottom: "10px",
        }}>
        <DrawerHeader sx={{ bgcolor: "#faf5ee" }} />

        {<NbmPrivateRoutes />}
      </Box>
      <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
    </Box>
  );
}
