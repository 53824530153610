import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import BroadcastOnHomeIcon from "@mui/icons-material/BroadcastOnHome";
import BedroomChildOutlinedIcon from "@mui/icons-material/BedroomChildOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { ImTarget } from "react-icons/im";
import { CgNotes } from "react-icons/cg";
import { MdMiscellaneousServices } from "react-icons/md";

import { TbReportSearch } from "react-icons/tb";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddchartIcon from "@mui/icons-material/Addchart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import vajra from "../images/vajra.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { logOut, profile } from "../redux/data/action";

import PrivateRoute from "../routes/PrivateRoute";
import { useNavigate } from "react-router-dom";

const StyledList = styled(List)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  "&, & .MuiListItemIcon-root": {
    color: "white",
  },
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "white",
    "&, & .MuiListItemIcon-root": {
      color: "#1746A2",
    },
  },
});

const SideBar = styled(Box)(({ theme }) => ({
  color: "white",
  width: "200px",

  marginTop: "205px",
  borderRadius: " 0px 12px 12px 0px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    marginTop: "55px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const Previous = styled(Button)(({ theme }) => ({
  border: "0px solid transparent",
  textTransform: "none",
  height: "30px",
  fontSize: "16px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PreviousContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const drawerWidth = 200;

function ResponsiveDrawer(props) {
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(true);

  const handleClicks = () => {
    setOpens(!opens);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleChange = () => {
    sessionStorage.setItem("SbmOrNbm", "BM");
    localStorage.setItem("bmOrNbm", "BM");

    window.location.reload();
  };
  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));

    localStorage.clear();
    sessionStorage.clear();

    navigate("/signin");
    localStorage.setItem('logoutEvent', Date.now().toString());
    window.location.reload()
    
  };

  let token = true;
  const drawer = (
    <SideBar
      sx={{
        bgcolor: "#1746A2",
      }}>
      <Toolbar
        sx={{
          marginTop: "10px",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          onClick={() => navigate("/home")}
          as="img"
          src={vajra}
          alt="logo"
          sx={{ height: "44px", width: "80px", cursor: "pointer" }}
        />
      </Toolbar>

      <StyledList>
        <ListItem
          disablePadding
          component={Link}
          to="/home"
          button
          selected={"/home" === path}
          sx={{}}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>

            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/all-assets"
          button
          selected={"/all-assets" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon sx={{}}>
              <BroadcastOnHomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Assets"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/department"
          button
          selected={"/department" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <LanOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Department"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/incident-list"
          button
          selected={"/incident-list" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <AnalyticsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Incidents"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/service-list"
          button
          selected={"/service-list" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <MdMiscellaneousServices size={25} />
            </ListItemIcon>
            <ListItemText primary={"Services"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/report"
          button
          selected={"/report" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <TbReportSearch size={25} />
            </ListItemIcon>
            <ListItemText primary={"Reports"} />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          component={Link}
          to="/warranty-status/all"
          button
          selected={"/warranty-status/all" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <CgNotes size={20} />
            </ListItemIcon>
            <ListItemText primary={"Warranty"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/calibration"
          button
          selected={"/calibration" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <ImTarget size={20} />
            </ListItemIcon>
            <ListItemText primary={"Calibration"} />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          component={Link}
          to="/user"
          button
          selected={"/user" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <Groups2OutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Users"} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={handleClicks}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Add"} />
            {opens ? <ExpandMore /> : <ExpandLess />}
          </ListItemButton>
        </ListItem>
        <Collapse in={!opens} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ height: "40px" }}
              component={Link}
              to="/add-asset"
              button
              selected={"/add-asset" === path}>
              <ListItemIcon>
                <BedroomChildOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary=" Asset" />
            </ListItemButton>
            <ListItemButton
              sx={{ height: "40px" }}
              component={Link}
              to="/add-department"
              button
              selected={"/add-department" === path}>
              <ListItemIcon>
                <AddchartIcon />
              </ListItemIcon>
              <ListItemText primary=" Department" />
            </ListItemButton>
            <ListItemButton
              sx={{ height: "40px" }}
              component={Link}
              to="/adduser"
              button
              selected={"/adduser" === path}>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary=" Users" />
            </ListItemButton>
            <ListItemButton
              sx={{ height: "40px" }}
              component={Link}
              to="/pm-checklist"
              button
              selected={"/pm-checklist" === path}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary=" PM Checklist" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItem
          disablePadding
          component={Link}
          to="/user-manual"
          button
          selected={"/user-manual" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <MenuBookOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"User Manual"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/profile"
          button
          selected={"/profile" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <PermIdentityOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          component={Link}
          to="/contact"
          button
          selected={"/contact" === path}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <HeadsetMicOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Contact"} />
          </ListItemButton>
        </ListItem>
      </StyledList>
      <StyledList sx={{ marginTop: { xl: "5px", lg: "0" } }}>
        <ListItem
          disablePadding
          to="/signin"
          button
          selected={"/signin" === path}
          onClick={handleLogout}>
          <ListItemButton sx={{ height: "40px" }}>
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Log Out"} />
          </ListItemButton>
        </ListItem>
      </StyledList>
    </SideBar>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    let data = {
      userid: ids.userid,
      hospid: ids.hospid,
    };

    dispatch(profile(data));
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline sx={{}} />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          bgcolor: "transparent",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
        {isMatch ? (
          <>
            <Toolbar
              elevation={0}
              sx={{
                boxShadow: "none",
                bgcolor: "#1746A2",
              }}>
              <IconButton
                color="black"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { sm: "none" },

                  color: "white",
                }}>
                <MenuIcon />
              </IconButton>
              <Box
                onClick={() => navigate("/home")}
                as="img"
                sx={{ width: "70px", cursor: "pointer" }}
                src={vajra}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  bgcolor: "#1746A2",
                }}>
                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ marginRight: "2%" }}>
                  <AddCircleOutlineOutlinedIcon sx={{ color: "white" }} />
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{
                    marginTop: "2%",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/add-asset"
                    button
                    selected={"/add-asset" === path}>
                    {" "}
                    Asset
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/department"
                    button
                    selected={"/department" === path}>
                    {" "}
                    Department
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/user"
                    button
                    selected={"/user" === path}>
                    {" "}
                    Users
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/fd"
                    button
                    selected={"/dffd" === path}>
                    {" "}
                    PM Checklist
                  </MenuItem>
                </Menu>
                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                  sx={{
                    marginRight: "2%",
                    cursor: "pointer",
                  }}>
                  <AccountCircleOutlinedIcon sx={{ color: "white" }} />
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={handleClose2}
                  sx={{ marginTop: "2%" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem
                    onClick={handleClose2}
                    component={Link}
                    to="/profile"
                    button
                    selected={"/profile" === path}>
                    {" "}
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    component={Link}
                    to="/signin"
                    button
                    selected={"/signin" === path}>
                    {" "}
                    Log out
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </>
        ) : (
          <>
            <Toolbar
              elevation={0}
              sx={{
                boxShadow: "none",
                background: "transparent",
              }}>
              <IconButton
                color="black"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { sm: "none" },
                }}>
                <MenuIcon />
              </IconButton>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Box
                  variant="h6"
                  color="black"
                  sx={{
                    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "36px",
                    width: "150px",
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    cursor: "pointer",
                  }}
                  onClick={handleChange}>
                  Change to BM
                </Box>

                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "36px",
                    width: "100px",
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    cursor: "pointer",
                  }}>
                  <AddCircleOutlineOutlinedIcon sx={{ color: "#FF731D" }} />
                  Add
                  {!open ? <ExpandMore /> : <ExpandLess />}
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{ marginTop: "2%" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/add-asset"
                    button
                    selected={"/add-asset" === path}>
                    {" "}
                    Asset
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/add-department"
                    button
                    selected={"/add-department" === path}>
                    {" "}
                    Department
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/adduser"
                    button
                    selected={"/adduser" === path}>
                    {" "}
                    User
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/pm-checklist"
                    button
                    selected={"/pm-checklist" === path}>
                    {" "}
                    PM Checklist
                  </MenuItem>
                </Menu>

                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                  sx={{
                    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "36px",
                    minWidth: "150px",
                    padding: "20px",
                    height: "38px",
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    cursor: "pointer",
                  }}>
                  <AccountCircleOutlinedIcon sx={{ color: "#FF731D" }} />
                  {userIds.username}
                  {!open2 ? <ExpandMore /> : <ExpandLess />}
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={handleClose2}
                  sx={{ marginTop: "2%" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem
                    onClick={handleClose2}
                    component={Link}
                    to="/profile"
                    button
                    selected={"/profile" === path}>
                    {" "}
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    component={Link}
                    to="/signin"
                    button
                    selected={"/signin" === path}>
                    {" "}
                    Log out
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </>
        )}
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,

              boxShadow: "none",
              bgcolor: "transparent",
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              minHeight: "900px",

              boxShadow: "none",
              bgcolor: "#faf5ee",
              marginTop: "-200px",

              zIndex: 0,
              borderRight: "none",
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100%",
          bgcolor: "#faf5ee",

          paddingTop: 5,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          background: "#faf5ee",
        }}>
        <Toolbar variant="dense" sx={{ minHeight: "20px" }} disableGutters />

        <Box>
          {path !== "/home" && (
            <PreviousContainer>
              {isMatch ? (
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              ) : (
                <Previous onClick={() => navigate(-1)}>
                  <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "25px" }} />{" "}
                  Previous
                </Previous>
              )}
            </PreviousContainer>
          )}
          {<PrivateRoute />}
        </Box>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
