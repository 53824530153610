import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { assetDetailsData } from "../redux/data/action";
import { useEffect } from "react";
import { BiLogIn } from "react-icons/bi";

const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  gap: "20px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "380px",
  height: "200px",
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: "25px",

  background: " #FFF",
};

const RButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  width: "240px",
  borderRadius: "28px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function ServicesModal({ status, id }) {
  const detailData = useSelector((store) => store.data.assetDetails);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const dispatch = useDispatch();
  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleService = () => {
    localStorage.setItem("serviceId", detailData.servid);
  };
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data));
  }, [dispatch]);
  return (
    <div>
      {status !== "Open" && (
        <RButton
          sx={{ bgcolor: "#1746A2" }}
          variant={"contained"}
          onClick={handleOpen}>
          Request Services
        </RButton>
      )}

      {status === "Open" && (
        <Link to={`/clear-service/${id}`}>
          <RButton
            onClick={handleService}
            sx={{
              color: "#FF731D",
              border: "2px solid #FF731D",
            }}
            variant={"outlined"}>
            Clear Services
          </RButton>
        </Link>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={open}>
          <Box sx={style} style={{}}>
            {" "}
            <Box
              sx={{
                bgcolor: "#2E58AA",
                width: "100%",
                textAlign: "center",
                color: "white",
                // borderRadius: "15px ,15px,0px,0px",
                borderRadius: " 15px 15px 0px 0px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              Service Request
            </Box>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={`/service-medpick/${id}`}>
              <RButton
                startIcon={<BiLogIn sx={{ color: "#F78117" }} />}
                sx={{
                  bgcolor: "#EFF5FE",
                  color: "black",
                  "&:hover": {
                    transform: "scale3d(1.05, 1.05, 2)",
                    cursor: "pointer",
                    color: "#FFF7E9",
                    bgcolor: "#F78117",
                  },
                }}>
                {" "}
                Send to Medpick
              </RButton>
            </Link>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={`/service-others/${id}`}>
              <RButton
                startIcon={<BiLogIn sx={{ color: "#F78117" }} />}
                sx={{
                  bgcolor: "#EFF5FE",
                  color: "black",
                  "&:hover": {
                    transform: "scale3d(1.05, 1.05, 2)",
                    cursor: "pointer",
                    color: "#FFF7E9",
                    bgcolor: "#F78117",
                  },
                }}>
                Send to Others
              </RButton>
            </Link>
            <Box
              sx={{
                width: "90%",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}>
              <Button
                sx={{ color: "#FF731D", textTransform: "none" }}
                onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
