import React, { useEffect } from "react";
import { makeStyles, styled } from "@mui/styles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Typography } from "@mui/material";
import { IoLogoWhatsapp, IoClose } from "react-icons/io";
import { TfiPlus } from "react-icons/tfi";
import { useState } from "react";
import WavingHandOutlinedIcon from "@mui/icons-material/WavingHandOutlined";

// const boxStyle = {
//   width: "400px",
//   height: "auto",
//   borderRadius: "50px",
//   background: "white",
//   position: "relative",
//   filter: "drop-shadow(0px 0px 1px green) ".repeat(5),
// };
const BoxStyle = styled(Box)(({ theme }) => ({
  width: "400px",
  height: "auto",
  borderRadius: "50px",
  background: "white",
  position: "relative",
  filter: "drop-shadow(0px 0px 1px green) ".repeat(5),

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "347px",
  },
}));

const pseudoElementStyle = {
  content: '""',
  position: "absolute",
  top: "81%",
  left: "77.3%",
  height: "58px",
  width: "61px",
  background: "white",
  clipPath: "polygon(0 0, 100% 0, 100% 100%)",
};

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    position: "fixed",

    bottom: theme.spacing(2),
    right: theme.spacing(2),
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  box: {
    position: "absolute",
    bottom: "calc(100% + 20px)",
    right: 0,

    borderRadius: "50px",
    display: "none",
    // width: "400px",
    border: "2px solid #06991D",
  },
  boxOpen: {
    display: "block",
  },
  icon: {
    marginBottom: "14px",
  },
}));

const ChatBot = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isCrossIcon, setIsCrossIcon] = React.useState(false);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [userData, setUserData] = useState(null);

  const [branch, setBranch] = useState(null);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  useEffect(() => {
    const localStorageUserData =
      sessionStorage.getItem("userData") || localStorage.getItem("userData");
    if (localStorageUserData) {
      const parsedUserData = JSON.parse(localStorageUserData);
      setUserData(parsedUserData);
    }
  }, []);
  useEffect(() => {
    const localStorageUserData =
      sessionStorage.getItem("userBranch") ||
      localStorage.getItem("userBranch");
    if (localStorageUserData) {
      const parsedUserData = JSON.parse(localStorageUserData);
      setBranch(parsedUserData);
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setIsCrossIcon(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsCrossIcon(false);
  };

  const handleWhatsAppClick = () => {
    setOpen(!open);
    setIsCrossIcon(!open);
  };

  return (
    <div className={classes.root} sx={{ border: "2px solid #06991D" }}>
      <SpeedDial
        className={classes.icon}
        ariaLabel="SpeedDial"
        sx={{
          "& .MuiFab-primary": {
            backgroundColor: "#2db742",
            color: "white",
            "&:hover": {
              backgroundColor: "#2db742",
              color: "white",
            },
          },
        }}
        icon={
          <SpeedDialIcon
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
            icon={
              isCrossIcon ? (
                <TfiPlus
                  style={{ fontSize: "30px" }}
                  onClose={handleClose}
                  onClick={handleClose}
                />
              ) : (
                <WhatsAppIcon
                  style={{ color: "white", fontSize: "70px" }}
                  onOpen={handleOpen}
                  open={open}
                  direction="up"
                  onClick={handleWhatsAppClick}
                />
              )
            }
          />
        }
      />

      <Box
        className={`${classes.box} ${open ? classes.boxOpen : ""}`}
        sx={{
          gap: "2%",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
          height: "auto",
        }}>
        <BoxStyle>
          <div style={pseudoElementStyle}></div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                bgcolor: "#06991D",
                color: "white",

                padding: "20px",
                borderRadius: "50px  50px 0 0",
              }}>
              <Box>
                <WhatsAppIcon style={{ fontSize: "50px" }} />
              </Box>
              <Box sx={{ textAlign: "start", paddingLeft: "20px" }}>
                <Typography variant="h5">Start a Conversation</Typography>
                <Typography variant="body2">
                  Hi! Click one of our member below to chat on WhatsApp
                </Typography>
              </Box>
            </Box>

            {/* {!userData ? (
              <a
                href={`https://api.whatsapp.com/send?phone=918971580265&text=Hi,%20I%20have%20a%20query%20in%20Vajra%20App%20https://vajra.software${window.location.pathname}.%20Can%20you%20help%20?`}
                target="_blank"
                onClick={handleClose}
                style={{
                  textDecoration: "none",
                  //
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                rel="noreferrer">
                <Box
                  sx={{
                    display: "flex",
                    width: "90%",
                    alignItems: "center",
                    marginTop: "6%",
                    bgcolor: "white",

                    padding: "2%",
                  }}>
                  <WavingHandOutlinedIcon
                    sx={{
                      fontSize: "40px",

                      bgcolor: "white",
                      color: "#06991D",
                    }}
                  />
                  <Box
                    sx={{
                      width: "80%",
                      textAlign: "start",
                      paddingLeft: "20px",
                    }}>
                    <Typography>Vajra</Typography>
                    <Typography variant="body2" sx={{ color: "#a5abb7" }}>
                      Vajra Team
                    </Typography>
                  </Box>
                </Box>
              </a>
            ) : userData && ? (
              <a
                // href={`https://api.whatsapp.com/send?phone=918971580265&text=Hi,%20I%20am%20${userData.username}%20from%20${userData.hospname}%20in%20the%20${userBranch.branch}%20branch.%20I%20have%20a%20query%20in%20Vajra%20App%20https://vajra.software${window.location.pathname}%20.%20Can%20you%20help%20?`}
                target="_blank"
                onClick={handleClose}
                style={{
                  textDecoration: "none",
                  //
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                rel="noreferrer">
                <Box
                  sx={{
                    display: "flex",
                    width: "90%",
                    alignItems: "center",
                    marginTop: "6%",
                    bgcolor: "white",

                    padding: "2%",
                  }}>
                  <WavingHandOutlinedIcon
                    sx={{
                      fontSize: "40px",

                      bgcolor: "white",
                      color: "#06991D",
                    }}
                  />
                  <Box
                    sx={{
                      width: "80%",
                      textAlign: "start",
                      paddingLeft: "20px",
                    }}>
                    <Typography>Vajra</Typography>
                    <Typography variant="body2" sx={{ color: "#a5abb7" }}>
                      Vajra Team
                    </Typography>
                  </Box>
                </Box>
              </a>
            ):userData} */}

            <a
              href={
                userData
                  ? `https://api.whatsapp.com/send?phone=918971580265&text=Hi,%20I%20am%20${userData.username}%20from%20${userData.hospname}.%20I%20have%20a%20query%20in%20Vajra%20App%20https://vajra.software${window.location.pathname}%20.%20Can%20you%20help%20?`
                  : userData && branch
                  ? `https://api.whatsapp.com/send?phone=918971580265&text=Hi,%20I%20am%20${userData.username}%20from%20${userData.hospname}%20in%20the%20${userBranch.branch}%20branch.%20I%20have%20a%20query%20in%20Vajra%20App%20https://vajra.software${window.location.pathname}%20.%20Can%20you%20help%20?`
                  : `https://api.whatsapp.com/send?phone=918971580265&text=Hi,%20I%20have%20a%20query%20in%20Vajra%20App%20https://vajra.software${window.location.pathname}.%20Can%20you%20help%20?`
              }
              target="_blank"
              onClick={handleClose}
              style={{
                textDecoration: "none",
                //
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              rel="noreferrer">
              <Box
                sx={{
                  display: "flex",
                  width: "90%",
                  alignItems: "center",
                  marginTop: "6%",
                  bgcolor: "white",

                  padding: "2%",
                }}>
                <WavingHandOutlinedIcon
                  sx={{
                    fontSize: "40px",

                    bgcolor: "white",
                    color: "#06991D",
                  }}
                />
                <Box
                  sx={{
                    width: "80%",
                    textAlign: "start",
                    paddingLeft: "20px",
                  }}>
                  <Typography>Vajra</Typography>
                  <Typography variant="body2" sx={{ color: "#a5abb7" }}>
                    Vajra Team
                  </Typography>
                </Box>
              </Box>
            </a>
            <Typography
              variant="caption"
              sx={{ color: "#a5abb7", marginTop: "2%", marginBottom: "2%" }}>
              The team typically replies in a few minutes.
            </Typography>
          </Box>
        </BoxStyle>
      </Box>
    </div>
  );
};

export default ChatBot;
