import styled from "@emotion/styled";
import {
  Box,
  Button,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import taskFailed from "../images/taskFailed.png";
import AddVendorModal from "../components/AddVendorModal";
import AddServiceCompanyModal from "../components/AddServiceCompanyModal";
import { useSelector } from "react-redux";
import TableSort from "../components/TableSort";
import Pagination from "../components/Pagination";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ElementRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease", // Add a smooth transition for hover effect
    "&:hover": {
      maxWidth: "none", // Remove the max-width on hover to show full text
      whiteSpace: "normal", // Allow text to wrap and expand
      overflow: "visible", // Show the full text
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap", // Set white-space back to nowrap
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130, // Remove the max-width on hover to show full text
        whiteSpace: "wrap", // Allow text to wrap and expand
        overflow: "hidden", // Show the full text
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "end",
  alignItems: "end",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CustomBottom = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  textTransform: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  // border: "1px solid green",
  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const EmptyContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  // height:"400px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "300px",
  height: "300px",

  [theme.breakpoints.down("xl")]: {
    width: "250px",
    height: "250px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("md")]: {
    width: "180px",
    height: "180px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

function ServiceCompanies({ filteredData,page,
  rowsPerPage,
  handleChangeRowsPerPage,
  setPage,
  setFilterChanged,
  filterChanged, }) {
  const [data, setData] = useState([]);
  

  const [open, setOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = [...data];
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (filteredData && filteredData?.length >= 0) {
      setData(filteredData);
    }
  }, [filteredData]);
  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.company;
      case "date":
        return item.date;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );
  return (
  
    <Wrapper>
      <BottomContainer>
        <CustomBottom
          onClick={handleOpen}
          startIcon={<AddCircleOutlineIcon />}
          sx={{ bgcolor: "#FF731D" }}
          variant="contained"
          size="medium">
          Add Service
        </CustomBottom>
      </BottomContainer>

      {data === undefined || data?.length === 0 ? (
        <BoxWrapper style={{ overflowX: "auto" }} sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: " 15px ",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "400px",
        }}>
          <EmptyContainer sx={{ bgcolor: "white" }}>
            <ImageBox as="img" src={taskFailed} alt="empty" />

            <Typography
              sx={{ color: " #212427" }}
              color="text.secondary"
              gutterBottom>
              No data found!
            </Typography>
          </EmptyContainer>
        </BoxWrapper>
      ) : (
        <>
          <BoxWrapper style={{ overflowX: "auto" }} >
            <TableContainer
              sx={{
                width: "100%",
                borderRadius: "5px",

                maxHeight: 400,
              }}>
              <Table
                stickyHeader
                sx={{
                  borderRadius: " 15px",
                  bgcolor: "white",
                }}>
                <TableHead>
                  <TableRow style={{}}>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.name}
                        onClick={() => handleSortTable("name")}>
                        Company Name
                      </TableSortLabel>{" "}
                    </TableCell>
                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      {" "}
                      Phone{" "}
                    </TableCell>
                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      {" "}
                      Email{" "}
                    </TableCell>
                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      {" "}
                      Asset Name{" "}
                    </TableCell>
                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      {" "}
                      Asset Code{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell> {item.company}</TableCell>
                        <TableCell> {item.mobile}</TableCell>
                        <TableCell> {item.email}</TableCell>
                        <TableCell>
                          {Array.isArray(item.assetname) &&
                            item.assetname.join(", ")}
                        </TableCell>
                        <TableCell>
                          {Array.isArray(item.assetcode) &&
                            item.assetcode.join(", ")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              filteredProducts={data}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </BoxWrapper>
        </>
      )}

      

      <AddServiceCompanyModal openModal={open} handleClose={handleClose} />
    </Wrapper>
  );
}

export default ServiceCompanies;
