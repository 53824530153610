
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
   
  }
  });

export default theme;





// const FormWrapper = styled(Box)(({ theme }) => ({
      
//   [theme.breakpoints.down("xl")]: {
      
//    },
//    [theme.breakpoints.down("lg")]: {
      
//    },
//   [theme.breakpoints.down("md")]: {
   
//   },
//   [theme.breakpoints.down("sm")]: {
    
//   },
//   [theme.breakpoints.down("xs")]: {
     
//     },

// }));