import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "350px", lg: "350px", md: "300px", sm: "300px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
export default function RegisterModal({ handleClose, status }) {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/signin");
    window.location.reload();
    handleClose();
  };

  return (
    <div>
      <Modal
        keepMounted
        open={status === "Updated"}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Typography
            sx={{
              mt: 2,
              mb: 4,
              color: "green",
              fontSize: "20px",
              textAlign: "center",
            }}>
            Your hospital details submitted successfully. Your account will be
            activated after verification. If you have any query, Call or
            WhatsApp +91-89715-80265 Thank you.
          </Typography>

          {
            <Box
              sx={{
                color: "green",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}>
              <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                  borderRadius: "35px",
                  margin: "auto",
                  textTransform: "none",
                }}
                size="large">
                Done
              </Button>
            </Box>
          }
        </ModalBox>
      </Modal>
    </div>
  );
}
