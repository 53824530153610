import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import contact from "../images/contact.png";
import { useDispatch, useSelector } from "react-redux";
import { contactHome } from "../redux/data/action";

import { useNavigate } from "react-router-dom";

import SuccessModal from "./SuccessModal";
import Navbar3 from "./Navbar3";
import Footer from "./Footer";

const MainDiv = styled(Box)(({ theme }) => ({
  bgcolor: "white",

  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",

  alignItems: "center",
  marginTop: "7%",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    marginTop: "15%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "19%",
  },
  [theme.breakpoints.down("xs")]: {
    marginTop: "25%",
  },
}));
const TitleFont = styled(Box)(({ theme }) => ({
  color: "#FF731D",
  fontSize: "28px",
  fontWeight: "500",
  [theme.breakpoints.down("xl")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "18px",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "250px",
  height: "250px",

  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  color: "black",

  fontSize: "16px",
  width: "700px",
  height: "167px",
  [theme.breakpoints.down("xl")]: {
    width: "700px",
    height: "147px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "160px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const BottomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "40%",
  gap: "2%",

  borderRadius: "15px",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",
  marginTop: "4%",

  [theme.breakpoints.down("xl")]: {
    width: "40%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
    height: "100px",
    alignItems: "center",
    justifyContent: "end",
  },
  [theme.breakpoints.down("md")]: {
    width: "70%",
    height: "150px",

    alignItems: "center",
    justifyContent: "end",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
    padding: "2%",
    alignItems: "center",
    height: "100px",
    marginTop: "45px",
    gap: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: "12px",
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FontBox = styled(Box)(({ theme }) => ({
  marginTop: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",

  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  width: "45%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TextFieldWrapper = styled(TextField)(({ theme }) => ({
  width: "45%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "85%",
    paddingBottom: "30px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "85%",
    paddingBottom: "30px",
  },
}));

function HomeContact() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [hospName, setHospName] = useState("");
  const response = useSelector((store) => store?.data?.contactHome);

  const [notes, setNotes] = useState("");
  const [isNotesError, setIsNotesError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isEmailError, setIsEmailError] = useState("");
  const [isMobileError, setIsMobileError] = useState("");
  const [isCityError, setIsCityError] = useState("");
  const [isHospError, setIsHospError] = useState("");
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(false);
  const text = " Sended ";

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    navigate("/");
  };
  const handleNotes = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setNotes(value);
      setIsNotesError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsNotesError("Please avoid that special character");
    }
  };
  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Please avoid that special character");
    }
  };
  const handleMobile = (e) => {
    const value = e.target.value;
    const regex = /^\+?[0-9]{10}$/;
    if (regex.test(value) || value === "") {
      setMobile(value);
      setIsMobileError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsMobileError("Please enter valid mobile number");
    }
  };
  const handleCity = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setCity(value);
      setIsCityError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsCityError("Please avoid that special character");
    }
  };
  const handleHospital = (e) => {
    const value = e.target.value;
    const regex = /^[^,"']*$/;
    if (regex.test(value) || value === "") {
      setHospName(value);
      setIsHospError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsHospError("Please avoid that special character");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value;
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{1,4}$/;
    if (regex.test(value) || value === "") {
      setEmail(value);
      setIsEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsEmailError("Please enter valid email");
    }
  };

  const handleSubmit = () => {
    if (notes === "") {
      setIsNotesError("Please fill the field");
    }
    if (name === "") {
      setNameError("Please fill the field");
    }
    if (mobile === "") {
      setIsMobileError("Please fill the field");
    }
    if (email === "") {
      setIsEmailError("Please fill the field");
    }
    if (hospName === "") {
      setIsHospError("Please fill the field");
    }
    if (city === "") {
      setIsCityError("Please fill the field");
    }

    if (
      notes !== "" &&
      isNotesError === "" &&
      name !== "" &&
      nameError === "" &&
      mobile !== "" &&
      isMobileError === "" &&
      email !== "" &&
      isEmailError === "" &&
      hospName !== "" &&
      isHospError === "" &&
      city !== "" &&
      isCityError === ""
    ) {
      let data = {
        hospid: "NA",
        hospname: hospName,
        hospcity: city,
        userid: "NA",
        username: name,
        useremail: email,
        usermobile: mobile,
        message: notes,
        branchid: "NA",
        branchname: "NA",
      };
      setToggle(true);

      dispatch(contactHome(data));
    }
  };

  useEffect(() => {
    if (response === "Inserted" && name !== "") {
      setOpenModal(true);
    }
  }, [response]);

  return (
    <>
      <MainDiv sx={{ background: "#faf5ee", marginTop: "70px" }}>
        <Navbar3 />
        <TitleFont>How can we Help?</TitleFont>
        <ImageBox as="img" src={contact} />

        <FontBox>
          <Box></Box>
        </FontBox>
        <InnerContainer
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: "20px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            textAlign: "start",
            borderRadius: "30px",
            background: "white",
          }}>
          <BoxWrapper>
            <InputWrapper>
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginTop: "20px", color: "#1746A2" }}>
                Name
              </InputLabel>

              <TextField
                size="small"
                sx={{
                  width: "100%",
                  bgcolor: "#EFF5FE",
                  "& fieldset": { border: "none" },
                  borderRadius: "5px",
                }}
                onChange={handleName}
                placeholder="Enter Name"
              />
              {<HelperText>{nameError}</HelperText>}
            </InputWrapper>

            <InputWrapper>
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginTop: "20px", color: "#1746A2" }}>
                Mobile
              </InputLabel>
              <TextField
                size="small"
                sx={{
                  width: "100%",
                  bgcolor: "#EFF5FE",
                  "& fieldset": { border: "none" },
                  borderRadius: "5px",
                }}
                onChange={handleMobile}
                placeholder="e.g. 8971580265"
              />
              {<HelperText>{isMobileError}</HelperText>}
            </InputWrapper>
          </BoxWrapper>
          <BoxWrapper>
            <InputWrapper>
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginTop: "20px", color: "#1746A2" }}>
                City
              </InputLabel>

              <TextField
                size="small"
                sx={{
                  width: "100%",
                  bgcolor: "#EFF5FE",
                  "& fieldset": { border: "none" },
                  borderRadius: "5px",
                }}
                onChange={handleCity}
                placeholder="Enter city"
              />
              {<HelperText>{isCityError}</HelperText>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginTop: "20px", color: "#1746A2" }}>
                Hospital Name
              </InputLabel>
              <TextField
                size="small"
                sx={{
                  width: "100%",
                  bgcolor: "#EFF5FE",
                  "& fieldset": { border: "none" },
                  borderRadius: "5px",
                }}
                onChange={handleHospital}
                placeholder="Enter hospital name"
              />
              {<HelperText>{isHospError}</HelperText>}
            </InputWrapper>
          </BoxWrapper>

          <InputLabel
            htmlFor="component-simple"
            sx={{
              marginTop: "20px",
              color: "#1746A2",
              "& fieldset": { border: "none" },
              borderRadius: "5px",
            }}>
            Email
          </InputLabel>
          <TextFieldWrapper
            size="small"
            sx={{
              bgcolor: "#EFF5FE",
              "& fieldset": { border: "none" },
              borderRadius: "5px",
            }}
            onChange={handleEmail}
            placeholder="Enter email"
          />
          {<HelperText>{isEmailError}</HelperText>}

          <NotesInput
            onChange={handleNotes}
            sx={{
              bgcolor: "#EFF5FE",
              borderRadius: "20px",
              marginTop: "20px",
              "& fieldset": { border: "none" },
            }}
            placeholder="Enter your message..."
          />
          {<HelperText>{isNotesError}</HelperText>}
        </InnerContainer>

        <BottomBox sx={{ marginBottom: "50px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "35px",
              bgcolor: "white",
              textTransform: "none",
              color: "#3c52b2",
              border: "1px solid #1746A2",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#3c52b2",
              },
            }}
            onClick={() => navigate("/")}
            size="large">
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "35px",
              bgcolor: "#1746A2",
              textTransform: "none",
              color: "white",
              border: "1px solid #1746A2",
            }}
            onClick={handleSubmit}
            size="large">
            Submit
          </Button>
        </BottomBox>
        <SuccessModal
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          response={response}
          text={text}
          toggle={toggle}
        />
        <Box sx={{ width: "100%" }}>
          <Footer />
        </Box>
      </MainDiv>
    </>
  );
}

export default HomeContact;
