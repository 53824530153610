import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";

import "../App.css";

import { useNavigate } from "react-router-dom";

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);

const HalfRoundedChart = ({ total, name, percentage, id }) => {
  const count = !isNaN(percentage) ? parseFloat(percentage) : 0;
  const totalCount = total === undefined ? 0 : total;
  const navigate = useNavigate();

  const handleChartClick = () => {
    if (name === "Amc") {
      navigate("/amc");
    }
    if (name === "Cmc") {
      navigate("/cmc");
    }
  };

  useEffect(() => {
    const options = {
      chart: {
        type: "pie",
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: "100%",

        events: {
          contextmenu: function (e) {
            e.preventDefault();
          },
          click: handleChartClick,
        },
      },
      title: {
        text: null,
      },
      tooltip: false,
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "50%"],
          size: "140%",
          dataLabels: {
            enabled: false,
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "pie",
          name: "Percentage",
          innerSize: "80%",
          data: [
            {
              name: name,
              y: count,
              color: "#8D60F7",
            },
            {
              name: name === "Amc" ? "Cmc" : "Amc",
              y: 100 - count,
              color: "#404040",
            },
          ],
        },
      ],
    };

    const chart = Highcharts.chart(id, options);

    chart.renderer
      .label(totalCount, chart.plotWidth / 2.3, chart.plotHeight - 100)
      .css({
        color: "#1746A2",
        fontSize: "14px",
      })
      .attr({
        padding: 5,
        r: 5,
      })
      .add();

    chart.renderer
      .label(name, chart.plotWidth / 2.3, chart.plotHeight - 80)
      .css({
        color: "#212427",
        fontSize: "14px",
      })
      .attr({
        padding: 5,
        r: 5,
      })
      .add();
  }, [count]);

  return (
    <div>
      <div
        onClick={handleChartClick}
        style={{ height: "80px", width: "160px", cursor: "pointer" }}
        id={id}></div>
    </div>
  );
};

export default HalfRoundedChart;
