import React, { useEffect, useRef, useState } from "react";
import jsQR from "jsqr";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

function Scan() {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [scannedData, setScannedData] = useState(null);

  useEffect(() => {
    let stream;

    const startScanner = async () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "environment" },
          });
          if (videoRef.current instanceof HTMLVideoElement) {
            videoRef.current.srcObject = stream;

            videoRef.current.onloadedmetadata = () => {
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.width = videoRef.current.videoWidth;
              canvas.height = videoRef.current.videoHeight;

              const scanQRCode = async () => {
                if (videoRef.current instanceof HTMLVideoElement) {
                  context.drawImage(
                    videoRef.current,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                  );
                  const imageData = context.getImageData(
                    0,
                    0,
                    canvas.width,
                    canvas.height
                  );

                  const code = scanQR(imageData, canvas.width, canvas.height);

                  if (code) {
                    setScannedData(code.data); // Store the scanned data in state
                    // navigate(`/asset-detail/${code.data}`);

                    // Handle the scanned result as needed
                  }
                }

                requestAnimationFrame(scanQRCode);
              };

              scanQRCode();
            };
          } else {
          }
        } catch (error) {}
      } else {
      }
    };

    // QR code scanning function
    const scanQR = (imageData, width, height) => {
      const code = jsQR(imageData.data, width, height);
      return code;
    };

    startScanner();

    // Cleanup function to stop the camera stream when the component unmounts
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (scannedData) {
      navigate(`/asset-detail/${scannedData}`);
    }
  }, [scannedData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}>
      <div style={{ width: "250px", height: "250px" }}>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          style={{ width: "250px", height: "250px" }}
        />
      </div>
      <Typography>Scan your QR code here.</Typography>
      <Box sx={{ maxWidth: "300px" }}>
        <Typography sx={{ color: "red" }}>
          Please check your system settings to ensure that the camera is not
          locked. If the issue persists, consider adjusting your browser or
          device camera settings.
        </Typography>
      </Box>
    </Box>
  );
}

export default Scan;
