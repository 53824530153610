import styled from "@emotion/styled";
import {
  Badge,
  Autocomplete,
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Stack,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import moment from "moment";

import { v4 as uuid } from "uuid";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import pdfImage from "../images/pdfImage.png";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import emp from "../images/noDepartment.png";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import {
  CreateQRCode,
  addAsset,
  deleteImage,
  getDepartmentData,
  uploadImage,
  uploadQrCodeImage,
  uploadedCertificate,
} from "../redux/nbmData/action";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddAssetModal from "../components/AddAssetModal";
import AddReport from "../nbmPages/AddReport";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
const FormInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",
  width: "370px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    height: "56px",
  },
}));

const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",
  width: "100%",
  height: "174px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "174px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "174px",
  },
}));

const SpecialDropDowne = styled(Stack)(({ theme }) => ({
  border: "none",
  width: "370px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    height: "56px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  justifyItems: "center",
  justifyContent: "space-evenly",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("xs")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
}));
const TopContainer = styled(Box)(({ theme }) => ({
  // border:"1px solid green",
  width: "900px",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "750px",
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Main = styled(Box)(({ theme }) => ({
  // border: "2px solid blue",
  display: "flex",
  flexDirection: "column",
  width: "900px",
  paddingBottom: "40px",
  borderRadius: "30px",
  background: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",
  padding: "20px",
  [theme.breakpoints.down("xl")]: {
    padding: "20px",
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "20px",
    width: "750px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CheckBoxTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  fontSize: "22px",
  fontWeight: "400",
  marginTop: "30px",
  color: "#FF731D",

  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
    marginTop: "10px",
    fontWeight: "400",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const NotesTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  fontSize: "22px",
  marginTop: "30px",
  color: "#FF731D",

  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
    marginTop: "20px",
    fontWeight: "400px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: "400px",
  },
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const BottomElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  padding: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const HalfInput = styled(DatePicker)(({ theme }) => ({
  bgcolor: "white",
  width: "165px",
  // height: "40px",
  // border: "none",

  [theme.breakpoints.down("xl")]: {
    width: "165px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "160px",
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
}));

const CheckBoxContainer = styled(Box)(({ theme }) => ({
  // border: "4px solid blue",
  width: "370px",
  minHeight: "50px",

  [theme.breakpoints.down("xl")]: {
    width: "360px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));

const OuterBox = styled(Box)(({ theme }) => ({
  // border: "1px solid green",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormRadio = styled(FormControl)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  bgcolor: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const DateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const DueDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const YesDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CustomText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  textAlign: "left",
  fontWeight: "400px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "15px",
  textTransform: "none",
  bgcolor: "#ADC2EC",
  color: "#1746A2",
  "&:hover": {
    backgroundColor: "#ADC2EC",
    color: "#1746A2",
  },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  // border: "2px solid blue",
  marginTop: "10px",
  display: "flex",
  flexDirection: "column",
  width: "900px",
  paddingBottom: "40px",

  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "750px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
}));
const FormInput2 = styled(DatePicker)(({ theme }) => ({
  border: "none",
  width: "370px",
  // height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    // height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
    // height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    // height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    // height: "56px",
  },
}));

const UploadImageBox = styled(Box)(({ theme }) => ({
  // border: "4px solid blue",
  border: "1px solid rgba(23, 70, 162, 0.2)",
  boxShadow: "0px 0px 2px #EAF1FF",
  width: "232px",
  padding: "10px",
  display: "flex",
  alignItems: "center",

  borderRadius: "8px",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "164px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "164px",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: "8px",
  width: "40px",
  height: "40px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    marginLeft: "4px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const DotedBox = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  cursor: "pointer",

  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ImageOuterBox = styled(Box)(({ theme }) => ({
  width: "282px",
  minHeight: "1px",

  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "186px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "186px",
  },
}));

const FlexBox = styled(Box)(({ theme }) => ({
  // border: "3px solid black",

  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const ReportBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Photo = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const PhotoText = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CustomLabel = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  bgcolor: "#1746A2",
  textTransform: "none",
  fontSize: "16px",

  borderRadius: "40px",

  width: "130px",
  height: "46px",
  // "&:hover": {
  //   transform: "scale3d(1.05, 1.05, 2)",
  //   cursor: "pointer",
  //   color: "#FFF7E9",
  //   bgcolor: "#F78117",
  // },

  [theme.breakpoints.down("xl")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("md")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "130px",
    height: "46px",
  },
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",

  // flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  gap: "40px",
  marginTop: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
    gap: "20px",
    flexDirection: "column-reverse",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  // border:"1px solid green",
  paddingTop: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainers = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "10px",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    border: "none",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    border: "none",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  width: "90%",
  borderRadius: "15px",

  background: "white",

  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {},
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
    borderRadius: "30px",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "30px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const ImageCard = styled(Box)(({ theme }) => ({
  width: "330px",
  height: "330px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "151px",
    height: "151px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyButtonSet = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",
  padding: "10px",
  fontWeight: "500",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

function AddAsset() {
  let assetId = localStorage.getItem("assetId") || "";
  let idSplit = assetId.substring(0, 4);
  const [usefullLife, setUsefullLife] = useState("1");
  const [isUsefullLifeError, setIsUsefullLifeError] = useState(null);
  const [toggle, setToggle] = useState(false);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const qrCode = useSelector((store) => store.nbmData.createQrCode) || [];

  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const [locale, setLocale] = React.useState("en-gb");
  const departmentData = useSelector((store) => store.nbmData.department) || [];
  const status = useSelector((store) => store.nbmData.addAsset);
  const [department, setDepartment] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  const [certificateFlag, setCertificateFlag] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState([]);
  const [name, setName] = useState([]);
  const [warranty, setWarranty] = useState("");
  const [assetName, setAssetName] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [brand, setBrand] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [soldBy, setSoldBy] = useState("");
  const [price, setPrice] = useState("");
  const [assetType, setAssetType] = useState("");
  const [assetStatus, setAssetStatus] = useState("");
  const [calibrationStatus, setCalibrationStatus] = useState("");
  const [cmcAmc, setCmcAmc] = useState("");
  const [cmcAmcStartDate, setCmcAmcStartDate] = useState("");
  const [cmcAmcEndDate, setCmcAmcEndDate] = useState("");
  const [calibrationDate, setCalibrationDate] = useState("");
  const [required, setRequired] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [notes, setNotes] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [isAssetError, setIsAssetError] = useState(null);
  const [isSerialNumberError, setIsSerialNumberError] = useState(null);
  const [isModelNumberError, setIsModelNumberError] = useState(null);
  const [isBrandError, setIsBrandError] = useState(null);
  const [isSoldByError, setIsSoldByError] = useState(null);
  const [isWarrantyError, setIsWarrantyError] = useState(null);
  const [isPriceError, setIsPriceError] = useState(null);

  const [isAssetCodeError, setIsAssetCodeError] = useState(null);
  const [warrantyStartDate, setWarrantyStartDate] = useState("");
  const [warrantyEndDate, setWarrantyEndDate] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isPhoneError, setIsPhoneError] = useState(null);
  const [isEmailError, setIsEmailError] = useState(null);

  const [isAmcEndDateError, setIsAmcEndDateError] = useState(null);
  const [isCmcEndDateError, setIsCmcEndDateError] = useState(null);
  const [assetReport, setAssetReport] = useState([]);

  const handleAssetReport = (data) => {
    setAssetReport(data);
  };
  const [isAssetTypeError, setIsAssetTypeError] = useState(null);
  const [isAssetStatusError, setIsAssetStatusError] = useState(null);
  const [isCalibrationStatusError, setIsCalibrationStatusError] =
    useState(null);
  const [isCmcAmcError, setIsCmcAmcError] = useState(null);
  const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);
  const [isRequiredError, setIsRequiredError] = useState(null);
  const [isDepartmentError, setIsDepartmentError] = useState(null);
  const [isDateOfPurchaseError, setIsDateOfPurchaseError] = useState(null);
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [isCertificateError, setIsCertificateError] = useState(null);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const uploadedCertificateName = certificateUrl?.map((el) => el?.imageid);

  const [calibrationStartDate, setCalibrationStartDate] = useState("");
  const [calibrationEndDate, setCalibrationEndDate] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${idSplit}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(uploadImage(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(uploadImage(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };
  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    dispatch(deleteImage(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };

  const handleCertificateUpload = (e) => {
    const singlefile = e.target.files[0];
    const splitId = assetId.substring(0, 4);
    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsCertificateError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImageCertificate(file);
      } else {
        setIsCertificateError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let checkData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${splitId}_` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setCertificateUrl([...certificateUrl, checkData]);
          dispatch(uploadedCertificate(checkData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImageCertificate = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let checkData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setCertificateUrl([...certificateUrl, checkData]);
              dispatch(uploadedCertificate(checkData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const removeCertificate = (id) => {
    setCertificateUrl(certificateUrl?.filter((item) => item?.imageid !== id));
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  const handleAssetName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    if (value === "" || regex.test(value)) {
      setAssetName(value);
      setIsAssetError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetError("wrong asset Name");
    }
    if (value === "") {
      setIsAssetError("Please fill the field");
    }
  };

  const handleAssetCode = (e) => {
    const value = e.target.value;
    if (value !== "") {
      setAssetCode(value);
      setIsAssetCodeError(null);
    }
    if (value === "") {
      setIsAssetCodeError("Please fill the field");
    }
  };

  const handleSerialNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setSerialNumber(value);
    if (regex.test(value) || value === "") {
      setSerialNumber(value);
      setIsSerialNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsSerialNumberError("wrong serial number");
    }
  };

  const handleModelNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setModelNumber(value);
    if (regex.test(value) || value === "") {
      setModelNumber(value);
      setIsModelNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsModelNumberError("wrong model number");
    }
  };

  const handleBrand = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9/-\s]+$/;
    if (value === "" || regex.test(value)) {
      setBrand(value);
      setIsBrandError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsBrandError("wrong brand Name");
    }
  };

  const handleSoldBy = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;

    if (value === "" || regex.test(value)) {
      setSoldBy(value);
      setIsSoldByError(null);

      if (phone === "") {
        setIsPhoneError("Please fill the field");
      }
      if (email === "") {
        setIsEmailError("Please fill the field");
      }
    }
    if (value !== "" && !regex.test(value)) {
      setIsSoldByError("Enter valid details");
    }
    if (value === "") {
      setIsPhoneError(null);
      setIsEmailError(null);
      setEmail("");
      setPhone("");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (value === "" || regex.test(value)) {
      setEmail(value);
      setIsEmailError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsEmailError("Enter valid email");
    }
    if (value === "") {
      setIsEmailError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d{10}$/;

    if (value === "" || regex.test(value)) {
      setPhone(value);
      setIsPhoneError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPhoneError("Enter valid Phone Number");
    }
    if (value === "") {
      setIsPhoneError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
  };

  const handleNotes = (e) => {
    const value = e.target.value.trim();
    setNotes(value);
  };

  const handleUsefulLife = (e) => {
    const value = e.target.value.trim();
    const regex = /^[1-9]\d*$/;
    setUsefullLife(value);
    if (value === "" || regex.test(value)) {
      setUsefullLife(value);
      setIsUsefullLifeError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsUsefullLifeError("Enter valid number");
    }
    if (value === "") {
      setIsUsefullLifeError(null);
      setIsPriceError(null);
      setIsDateOfPurchaseError(null);
    }
    if (value !== "" && price === "") {
      setIsPriceError("Please fill the field");
    }
    if (value !== "" && dateOfPurchase === "") {
      setIsDateOfPurchaseError("Please fill the field");
    }
  };

  const warrantyValidDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsWarrantyError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsWarrantyError(null);
    }
  };

  const handleWarrantyStartDate = (date) => {
    warrantyValidDateRange(date, warrantyEndDate);
    setWarrantyStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    if (date && warrantyEndDate === "") {
      setIsWarrantyError("Please fill end date");
    }
  };

  const handleWarrantyEndDate = (date) => {
    setWarrantyEndDate(date && dayjs(date).format("YYYY-MM-DD"));

    warrantyValidDateRange(warrantyStartDate, date);

    if (date && warrantyStartDate === "") {
      setIsWarrantyError("Please fill start date");
    }
  };

  const handleDepartment = (event, newValue) => {
    setDepartment(newValue);
    dispatch(CreateQRCode(assetId));
    const selectedDepartment = departmentData?.find(
      (item) => item?.departname === newValue
    );

    if (selectedDepartment) {
      setDepartmentId(selectedDepartment?.departid);
      setIsDepartmentError(null);
    } else {
      setDepartmentId("");
    }
  };

  const handleAmcCmcStartDateChange = (date) => {
    setCmcAmcStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(date, cmcAmcEndDate);
  };

  const handleAmcCmcEndDateChange = (date) => {
    setCmcAmcEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(cmcAmcStartDate, date);
  };

  const amcValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsAmcEndDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsAmcEndDateError(null);
      setIsCmcEndDateError(null);
    }
  };

  const handleAmcCmc = (e) => {
    const value = e.target.value;
    setCmcAmc(value);
    if (value === "None") {
      setIsCmcEndDateError(null);
      setIsAmcEndDateError(null);
      setCmcAmcEndDate("");
      setCmcAmcStartDate("");
    }
    if (value === "AMC") {
      setIsCmcEndDateError("Please enter the date");
      setIsAmcEndDateError(null);
    }
    if (value === "CMC") {
      setIsAmcEndDateError("Please enter the date");
      setIsCmcEndDateError(null);
    }
    if (value !== "") {
      setIsCmcAmcError(null);
    }
  };

  const handleCalibrationStatus = (e) => {
    let value = e.target.value;
    setCalibrationStatus(value);
    if (value === "No") {
      setCalibrationDate("");

      setIsRequiredError("");
      setIsCalibrationDateError(null);
    }
    if (value === "Yes") {
      setRequired("NA");
      setIsRequiredError(null);
      setCalibrationStartDate("");
      setCalibrationEndDate("");
      setIsCalibrationDateError("Please fill the field");
    }
    if (value !== "") {
      setIsCalibrationStatusError(null);
    }
  };

  useEffect(() => {
    let Id = uuid();
    localStorage.setItem("assetId", Id);
  }, []);

  const handleAssetStatus = (e) => {
    const value = e.target.value;
    setAssetStatus(value);
    if (value !== "") {
      setIsAssetStatusError(null);
    }
  };

  const handleAssetType = (e) => {
    const value = e.target.value;
    setAssetType(value);
    if (value !== "") {
      setIsAssetTypeError(null);
    }
  };

  const handlePurchaseDate = (date) => {
    setDateOfPurchase(date && dayjs(date).format("YYYY-MM-DD"));
    if (date !== "") {
      setIsDateOfPurchaseError(null);
    }
    setDateOfPurchase(date && dayjs(date).format("YYYY-MM-DD"));
  };

  const handleCalibrationDate = (e) => {
    let value = e.target.value;
    setCalibrationDate(value);
    if (value !== "") {
      setIsCalibrationDateError(null);
    }
  };

  const calibrationValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsCalibrationDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsCalibrationDateError(null);
      setIsCalibrationDateError(null);
      // setIsCmcEndDateError(null);
    }
  };

  const handleCalibrationStartDate = (date) => {
    setCalibrationStartDate(date);
    calibrationValidateDateRange(date, calibrationEndDate);
  };

  const handleCalibrationEndDate = (date) => {
    setCalibrationEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    calibrationValidateDateRange(calibrationStartDate, date);
  };

  const handleRequired = (e) => {
    let value = e.target.value;
    setRequired(value);
    setIsRequiredError(null);
    setCalibrationStartDate("");
    setCalibrationEndDate("");
  };

  const handleSubmit = () => {
    assetName === "" && setIsAssetError("Please fill the field");

    (department === ""|| department === null) && setIsDepartmentError("Please fill the field");
    assetType === "" && setIsAssetTypeError("Please fill the field");
    assetStatus === "" && setIsAssetStatusError("Please fill the field");
    calibrationStatus === "" &&
      setIsCalibrationStatusError("Please fill the field");
    cmcAmc === "" && setIsCmcAmcError("Please fill the field");
    assetCode === "" && setIsAssetCodeError("Please fill the field");

    fileUrl.length <= 0 && setIsPhotoError("Please add an image");
    if (calibrationStatus === "Yes") {
      ((calibrationStartDate === "NA" || calibrationStartDate === "") &&
        setIsCalibrationDateError("Please fill the field")) ||
        ((calibrationEndDate === "NA" || calibrationEndDate === "") &&
          setIsCalibrationDateError("Please fill the field"));
    }
    if (calibrationStatus === "No") {
      (required === "NA" || required === "") &&
        setIsRequiredError("Please fill the field");
    }

    if (cmcAmc === "AMC" || cmcAmc === "CMC") {
      (cmcAmcEndDate === "" ||
        cmcAmcEndDate === "NA" ||
        cmcAmcStartDate === "" ||
        cmcAmcStartDate === "NA") &&
        setIsCmcEndDateError("Please fill this field");
    }

    if (
      assetName === "" ||
      department === "" ||
      department === null ||
      assetType === "" ||
      assetStatus === "" ||
      calibrationStatus === "" ||
      cmcAmc === "" ||
      fileUrl.length === 0 ||
      assetCode === ""
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }
    if (
      assetName !== "" &&
      department !== "" &&
      department !== null &&
      // usefullLife !== "" &&
      // soldBy !== "" &&
      // brand !== "" &&
      assetType !== "" &&
      assetStatus !== "" &&
      calibrationStatus !== "" &&
      cmcAmc !== "" &&
      fileUrl.length !== 0 &&
      assetCode !== ""
    ) {
      if (
        isAssetError === null &&
        isPhoneError === null &&
        isEmailError === null &&
        isSerialNumberError === null &&
        isModelNumberError === null &&
        isUsefullLifeError === null &&
        isWarrantyError === null &&
        isDateOfPurchaseError === null &&
        isPriceError === null &&
        isAmcEndDateError === null &&
        isCmcEndDateError === null &&
        isAssetTypeError === null &&
        isAssetStatusError === null &&
        isCalibrationStatusError === null &&
        isCmcAmcError === null &&
        isCalibrationDateError === null &&
        isRequiredError === null &&
        isPhotoError === null &&
        isCertificateError === null &&
        isAssetCodeError === null
      ) {
        const splitId = assetId.substring(0, 4);
        let dataQr = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          imageid: `${splitId}_` + uuid() + `.${"png"}`,
          image: qrCode,
          branchid: userBranch?.id,
        };

        dispatch(uploadQrCodeImage(dataQr));

        let data = {
          assetid: assetId,
          hospid: userIds?.hospid,
          hospname: userIds?.hospname,
          userid: userIds?.userid,
          username: userIds?.username,
          assetname: assetName.trim(),
          serialno: serialNumber === "" ? "NA" : serialNumber.trim(),
          assetcode: assetCode === "" ? "NA" : assetCode.trim(),
          modelno: modelNumber === "" ? "NA" : modelNumber.trim(),
          department: department,
          department_id: departmentId,
          branchid: userBranch?.id,
          branchname: userBranch.branch,
          assettype: assetType,
          lifeyrs: usefullLife === "" ? "1" : usefullLife.trim(),
          assetstatus: assetStatus,
          brand: brand === "" ? "NA" : brand.trim(),
          sellermobile: phone===""?'NA':phone,
          selleremail:email===""?'NA':email,
          price: price === "" ? "NA" : price.trim(),
          purchdate:
            dateOfPurchase === ""
              ? "NA"
              : moment(dateOfPurchase).format("DD-MM-YYYY"),
          warntdate:
            warrantyStartDate === ""
              ? "NA"
              : moment(warrantyStartDate).format("DD-MM-YYYY"),
          wrntymonth: "1",
          expdate:
            warrantyEndDate === "" || warrantyEndDate === "NA"
              ? "NA"
              : moment(warrantyEndDate).format("DD-MM-YYYY"),
          sellername: soldBy === "" ? "NA" : soldBy.trim(),
          prodimgs:
            fileUrl?.length > 0
              ? fileUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          wrntyimage:
            certificateUrl?.length > 0
              ? certificateUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          report_names:
            assetReport?.length === 0
              ? []
              : assetReport?.map((item) => {
                  return item.report_name;
                }),
          report_ids:
            assetReport?.length === 0
              ? []
              : assetReport?.map((item) => {
                  return item.imageid;
                }),
          qrcodeimg: dataQr?.imageid,
          calibrate_status: calibrationStatus,
          calibrate_date:
            calibrationStartDate === ""
              ? "NA"
              : moment(calibrationStartDate).format("DD-MM-YYYY"),
          calibrate_req: required === "" ? "Na" : required,
          calibrate_duemonth: "1",
          calibrate_duedate:
            calibrationEndDate === ""
              ? "NA"
              : moment(calibrationEndDate)
                  .add(dueDate, "months")
                  .subtract(1, "days")
                  .format("DD-MM-YYYY"),
          cmcamc: cmcAmc,
          cmcamc_start:
            cmcAmcStartDate === ""
              ? "NA"
              : moment(cmcAmcStartDate).format("DD-MM-YYYY"),
          cmcamc_due:
            cmcAmcEndDate === ""
              ? "NA"
              : moment(cmcAmcEndDate).format("DD-MM-YYYY"),
          calibformat:
            calibrationStartDate === ""
              ? "NA"
              : moment(calibrationStartDate).format("YYYY-MM-DD"),
          purdateformat:
            dateOfPurchase === ""
              ? "NA"
              : moment(dateOfPurchase).format("YYYY-MM-DD"),
          notes: notes === "" ? "NA" : notes.trim(),
        };

        dispatch(addAsset(data));

        setToggle(true);
      }
    }
  };

  const handleCertificateFlag = () => {
    setCertificateFlag(!certificateFlag);

    setIsCertificateError(null);
  };

  const handleImageFlag = () => {
    setPhotoFlag(!photoFlag);

    setIsPhotoError(null);
  };

  const handleCloseModal = () => {
    setToggle(false);
    navigate("/all-assets");
  };

  return (
    <OuterBox>
      {departmentData?.length === 0 ? (
        <>
          <TopContainers>
            <TitleHead>Add Asset</TitleHead>
          </TopContainers>
          <BoxWrapper
            style={{ overflowX: "auto" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "15px ",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}>
            <EmptyText>
              Before adding to the asset, please add your departments.
            </EmptyText>

            <ImageCard as="img" src={emp} alt="green iguana" />
            <CardContent></CardContent>
            <CardActions>
              <Button
                onClick={() => navigate("/add-department")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                disabled={userAccess?.adddepart !== "Yes"}
                variant="contained">
                Add Department
              </Button>
            </CardActions>
          </BoxWrapper>
        </>
      ) : (
        <>
          <TopContainer sx={{}}>
            <TitleHead>Add Asset</TitleHead>
          </TopContainer>
          <Main sx={{ bgcolor: "white" }}>
            <FormWrapper>
              <FormElement>
                <FormTextLabel>Assets Name</FormTextLabel>
                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. Defibrillator"
                  onChange={handleAssetName}
                />
                {<HelperText>{isAssetError}</HelperText>}
              </FormElement>
              <FormElement>
                <FormTextLabel>Serial Number</FormTextLabel>
                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. S10001921"
                  onChange={handleSerialNumber}
                />
                {<HelperText>{isSerialNumberError}</HelperText>}
              </FormElement>
              <FormElement>
                <FormTextLabel>Department</FormTextLabel>

                <FormElement>
                  <SpecialDropDowne>
                    <Autocomplete
                      sx={{ bgcolor: "#EFF5FE" }}
                      disabled={departmentData?.length <= 0}
                      value={department}
                      options={departmentData?.map(
                        (option) => option.departname
                      )}
                      onChange={handleDepartment}
                      renderInput={(params) => (
                        <TextField
                          placeholder="e.g. Emergency Department"
                          {...params}
                        />
                      )}
                    />
                  </SpecialDropDowne>
                  {/* {<HelperText>{"isAssetError"}</HelperText>} */}
                </FormElement>

                {<HelperText>{isDepartmentError}</HelperText>}
              </FormElement>
              <FormElement>
                <FormTextLabel>Model Number</FormTextLabel>
                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. M811-1000BH"
                  onChange={handleModelNumber}
                />
                {<HelperText>{isModelNumberError}</HelperText>}
              </FormElement>

              <FormElement>
                <FormTextLabel>Assets code</FormTextLabel>
                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. AC-199281-10"
                  onChange={handleAssetCode}
                />
                {<HelperText>{isAssetCodeError}</HelperText>}
              </FormElement>

              <FormElement>
                <FormTextLabel>Brand</FormTextLabel>
                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. Philips Healthcare"
                  onChange={handleBrand}
                />
                {<HelperText>{isBrandError}</HelperText>}
              </FormElement>
              <FormElement>
                <FormTextLabel>Seller Name</FormTextLabel>

                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. Richard"
                  onChange={handleSoldBy}
                />
                {<HelperText>{isSoldByError}</HelperText>}
              </FormElement>

              <FormElement>
                <FormTextLabel>Date of Purchase</FormTextLabel>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locale}>
                  <FormInput2
                    size="small"
                    sx={{ bgcolor: "#EFF5FE", padding: "0px" }}
                    format="DD-MM-YYYY"
                    onChange={handlePurchaseDate}
                  />
                </LocalizationProvider>
                {<HelperText>{isDateOfPurchaseError}</HelperText>}
              </FormElement>

              {soldBy && (
                <FormElement>
                  <FormTextLabel>Phone Number</FormTextLabel>

                  <FormInput
                    sx={{ bgcolor: "#EFF5FE" }}
                    placeholder="e.g. 8971580265"
                    onChange={handlePhone}
                  />
                  {<HelperText>{isPhoneError}</HelperText>}
                </FormElement>
              )}

              {soldBy && (
                <FormElement>
                  <FormTextLabel>Email</FormTextLabel>

                  <FormInput
                    sx={{ bgcolor: "#EFF5FE" }}
                    placeholder="richard@vajra.in"
                    onChange={handleEmail}
                  />
                  {<HelperText>{isEmailError}</HelperText>}
                </FormElement>
              )}

              <FormElement>
                <FormTextLabel>Useful Life</FormTextLabel>
                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. 5"
                  onChange={handleUsefulLife}
                />
                {<HelperText>{isUsefullLifeError}</HelperText>}
              </FormElement>

              <FormElement>
                <FormTextLabel>Price</FormTextLabel>
                <FormInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  placeholder="e.g. 199999"
                  onChange={handlePrice}
                />
                {<HelperText>{isPriceError}</HelperText>}
              </FormElement>

              <FormElement>
                <CheckBoxTextLabel>Warranty</CheckBoxTextLabel>
                <YesDateBox>
                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600">
                      Warranty start date
                    </CustomText>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <HalfInput
                        format="DD/MM/YYYY"
                        sx={{ bgcolor: "white" }}
                        onChange={handleWarrantyStartDate}
                      />
                    </LocalizationProvider>
                  </DateBox>

                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600">Warranty end date</CustomText>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <HalfInput
                        format="DD/MM/YYYY"
                        sx={{ bgcolor: "white" }}
                        onChange={handleWarrantyEndDate}
                      />
                    </LocalizationProvider>
                  </DateBox>
                </YesDateBox>
                {<HelperText>{isWarrantyError}</HelperText>}
              </FormElement>

              <FormElement>
                <CheckBoxTextLabel>Asset Type</CheckBoxTextLabel>
                <CheckBoxContainer>
                  <FormRadio>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleAssetType}>
                      <FormControlLabel
                        value="New"
                        control={<Radio size="small" />}
                        label={<CustomLabel>New</CustomLabel>}
                      />
                      <FormControlLabel
                        value="Old"
                        control={<Radio size="small" />}
                        label={<CustomLabel>Old</CustomLabel>}
                      />
                      <FormControlLabel
                        value="Refurbished"
                        control={<Radio size="small" />}
                        label={<CustomLabel>Refurbished</CustomLabel>}
                      />
                    </RadioGroup>
                    {<HelperText>{isAssetTypeError}</HelperText>}
                  </FormRadio>
                </CheckBoxContainer>
              </FormElement>

              <FormElement>
                <CheckBoxTextLabel>Assets Status</CheckBoxTextLabel>
                <CheckBoxContainer>
                  <FormRadio>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleAssetStatus}>
                      <FormControlLabel
                        value="Working"
                        control={<Radio size="small" />}
                        label={<CustomLabel>Working</CustomLabel>}
                      />
                      <FormControlLabel
                        value="Not working"
                        control={<Radio size="small" />}
                        label={<CustomLabel>Not working</CustomLabel>}
                      />
                      <FormControlLabel
                        value="Need Service"
                        control={<Radio size="small" />}
                        label={<CustomLabel>Need Service</CustomLabel>}
                      />
                      <FormControlLabel
                        value="Discard"
                        control={<Radio size="small" />}
                        label={<CustomLabel>Discard</CustomLabel>}
                      />
                    </RadioGroup>
                    {<HelperText>{isAssetStatusError}</HelperText>}
                  </FormRadio>
                </CheckBoxContainer>
              </FormElement>

              <FormElement>
                <CheckBoxTextLabel>Calibration Status</CheckBoxTextLabel>
                <CheckBoxContainer>
                  <FormRadio onChange={handleCalibrationStatus}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group">
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label={<CustomLabel>Yes</CustomLabel>}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label={<CustomLabel>No</CustomLabel>}
                      />
                    </RadioGroup>
                    {<HelperText>{isCalibrationStatusError}</HelperText>}
                  </FormRadio>
                  {calibrationStatus === "Yes" && (
                    <YesDateBox>
                      <DateBox padding="5px" bgcolor="#EFF5FE">
                        <CustomText fontWeight="600">
                          Calibration Start Date
                        </CustomText>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}>
                          <HalfInput
                            format="DD/MM/YYYY"
                            sx={{ bgcolor: "white" }}
                            onChange={handleCalibrationStartDate}
                          />
                        </LocalizationProvider>
                        {<HelperText>{isCalibrationDateError}</HelperText>}
                      </DateBox>
                      <DueDateBox>
                        <DateBox padding="5px" bgcolor="#EFF5FE">
                          <CustomText fontWeight="600">
                            Calibration End Date
                          </CustomText>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}>
                            <HalfInput
                              sx={{ bgcolor: "white" }}
                              // value={calibrationEndDate}
                              onChange={handleCalibrationEndDate}
                            />
                          </LocalizationProvider>
                          <Box>
                            {
                              <Typography
                                variant="caption"
                                sx={{ color: "#EFF5FE" }}>
                                {isCalibrationDateError}
                              </Typography>
                            }
                          </Box>
                          {/* {<HelperText>{isCalibrationDateError}</HelperText>} */}
                        </DateBox>
                      </DueDateBox>
                    </YesDateBox>
                  )}
                  {calibrationStatus === "No" && (
                    <DateBox
                      bgcolor="#EFF5FE"
                      padding="5px"
                      maxHeight="88px"
                      maxWidth="258px">
                      <CustomText fontWeight="600" textAlign={"left"}>
                        Required ?
                      </CustomText>
                      <FormRadio>
                        <Box bgcolor="white" padding="3px" borderRadius="6px">
                          <RadioGroup
                            onChange={handleRequired}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group">
                            <FormControlLabel
                              value="Required"
                              control={<Radio size="small" />}
                              label={<CustomText>Required</CustomText>}
                            />
                            <FormControlLabel
                              value="Not Required"
                              control={<Radio size="small" />}
                              label={<CustomText>Not Required</CustomText>}
                            />
                          </RadioGroup>
                          {<HelperText>{isRequiredError}</HelperText>}
                        </Box>
                      </FormRadio>
                    </DateBox>
                  )}
                </CheckBoxContainer>
              </FormElement>

              <FormElement>
                <CheckBoxTextLabel>CMC/AMC</CheckBoxTextLabel>
                <CheckBoxContainer>
                  <FormRadio onChange={handleAmcCmc}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group">
                      <FormControlLabel
                        value="CMC"
                        control={<Radio size="small" />}
                        label={<CustomLabel>CMC</CustomLabel>}
                      />
                      <FormControlLabel
                        value="AMC"
                        control={<Radio size="small" />}
                        label={<CustomLabel>AMC</CustomLabel>}
                      />
                      <FormControlLabel
                        value="None"
                        control={<Radio size="small" />}
                        label={<CustomLabel>None</CustomLabel>}
                      />
                    </RadioGroup>
                    {<HelperText>{isCmcAmcError}</HelperText>}
                  </FormRadio>
                  {cmcAmc === "AMC" && (
                    <YesDateBox>
                      <DateBox padding="5px" bgcolor="#EFF5FE">
                        <CustomText fontWeight="600">Start Date</CustomText>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}>
                          <HalfInput
                            sx={{ bgcolor: "white" }}
                            // value={calibrationEndDate}
                            onChange={handleAmcCmcStartDateChange}
                          />
                        </LocalizationProvider>
                      </DateBox>
                      <DateBox padding="5px" bgcolor="#EFF5FE">
                        <CustomText fontWeight="600">End Date</CustomText>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}>
                          <HalfInput
                            sx={{ bgcolor: "white" }}
                            // value={calibrationEndDate}
                            onChange={handleAmcCmcEndDateChange}
                          />
                        </LocalizationProvider>
                      </DateBox>
                    </YesDateBox>
                  )}

                  {cmcAmc === "CMC" && (
                    <YesDateBox>
                      <DateBox padding="5px" bgcolor="#EFF5FE">
                        <CustomText fontWeight="600">Start Date</CustomText>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}>
                          <HalfInput
                            sx={{ bgcolor: "white" }}
                            // value={calibrationEndDate}
                            onChange={handleAmcCmcStartDateChange}
                          />
                        </LocalizationProvider>
                      </DateBox>
                      <DateBox padding="5px" bgcolor="#EFF5FE">
                        <CustomText fontWeight="600">End Date</CustomText>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}>
                          <HalfInput
                            sx={{ bgcolor: "white" }}
                            // value={calibrationEndDate}
                            onChange={handleAmcCmcEndDateChange}
                          />
                        </LocalizationProvider>
                      </DateBox>
                    </YesDateBox>
                  )}
                  {<HelperText>{isAmcEndDateError}</HelperText>}
                  {<HelperText>{isCmcEndDateError}</HelperText>}
                </CheckBoxContainer>
              </FormElement>
            </FormWrapper>

            <BottomElement sx={{}}>
              <NotesTextLabel>Notes</NotesTextLabel>
              <NotesInput
                sx={{ bgcolor: "#EFF5FE", width: "100%" }}
                placeholder="e.g. Regular inspection log maintained to comply with regulations."
                onChange={handleNotes}
              />
              {/* { <HelperText>Error text</HelperText>} */}
            </BottomElement>
          </Main>
          <UploadContainer>
            <>
              {userAccess?.role !== "Guest" && (
                <>
                  <ButtonWrapper sx={{}}>
                    <>
                      <UploadButton
                        onClick={handleImageFlag}
                        sx={{
                          bgcolor: "#ADC2EC",
                        }}>
                        <AddPhotoAlternateIcon sx={{ color: "#1746A2" }} />
                        Upload Photos
                      </UploadButton>
                    </>

                    <UploadButton
                      onClick={handleCertificateFlag}
                      sx={{
                        bgcolor: "#ADC2EC",
                      }}>
                      <VideoLibraryIcon sx={{ color: "#1746A2" }} />
                      Warranty Certificate
                    </UploadButton>
                  </ButtonWrapper>

                  <FlexBox>
                    <ImageOuterBox
                      sx={{
                        textAlign: "start",
                        color: "#1746A2",
                      }}>
                      {photoFlag && (
                        <>
                          <PhotoText>Upload photos</PhotoText>
                          <UploadImageBox>
                            {uploadedImageName?.length < 3 && (
                              <>
                                <DotedBox>
                                  <label for="img1">
                                    {" "}
                                    <AddIcon
                                      sx={{
                                        marginTop: "2%",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </label>
                                </DotedBox>
                                <input
                                  type="file"
                                  ref={ref}
                                  name="uploadfile"
                                  id="img1"
                                  style={{
                                    display: "none",

                                    width: "42px",
                                    height: "42px",
                                  }}
                                  onChange={handlePhotoUpload}
                                />
                              </>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              {fileUrl?.map((image, index) => (
                                <ImageBox key={index} className="image-item">
                                  <Badge
                                    sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                                    badgeContent={
                                      <RemoveCircleIcon
                                        sx={{
                                          width: "10px",
                                          height: "10px",
                                          color: "red",
                                        }}
                                        onClick={() =>
                                          removePhoto(image?.imageid)
                                        }
                                      />
                                    }>
                                    <Photo
                                      as="img"
                                      src={image?.image}
                                      alt={image?.image}
                                    />
                                  </Badge>
                                </ImageBox>
                              ))}
                            </Box>
                          </UploadImageBox>
                        </>
                      )}

                      <Box style={{ color: "red", fontSize: "12px" }}>
                        <>
                          {photoFlag && (
                            <HelperText color="#1746A2">
                              format should be either in jpg, jpeg or png
                            </HelperText>
                          )}
                        </>
                        <>{<HelperText>{isPhotoError}</HelperText>}</>
                      </Box>
                    </ImageOuterBox>

                    <ImageOuterBox
                      sx={{
                        textAlign: "start",
                        color: "#1746A2",
                      }}>
                      {certificateFlag && (
                        <>
                          Upload photos
                          <UploadImageBox>
                            {uploadedCertificateName?.length < 3 && (
                              <>
                                <DotedBox>
                                  <label for="img">
                                    {" "}
                                    <AddIcon
                                      sx={{
                                        marginTop: "2%",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </label>
                                </DotedBox>
                                <input
                                  type="file"
                                  name="uploadfile"
                                  id="img"
                                  style={{
                                    display: "none",

                                    width: "42px",
                                    height: "42px",
                                  }}
                                  onChange={handleCertificateUpload}
                                />
                              </>
                            )}

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              {certificateUrl?.map((image, index) => (
                                <ImageBox key={index} className="image-item">
                                  <Badge
                                    sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                                    badgeContent={
                                      <RemoveCircleIcon
                                        sx={{
                                          width: "10px",
                                          height: "10px",
                                          color: "red",
                                        }}
                                        onClick={() =>
                                          removeCertificate(image?.imageid)
                                        }
                                      />
                                    }>
                                    <Photo
                                      as="img"
                                      src={
                                        image.imageid
                                          .toLowerCase()
                                          .endsWith(".pdf")
                                          ? pdfImage
                                          : image?.image
                                      }
                                      alt={image?.image}
                                    />
                                  </Badge>
                                </ImageBox>
                              ))}
                            </Box>
                          </UploadImageBox>
                        </>
                      )}
                      <Box style={{ color: "red", fontSize: "12px" }}>
                        <>
                          {certificateFlag && (
                            <HelperText color="#1746A2">
                              format should be either in jpg, jpeg or png
                            </HelperText>
                          )}
                        </>
                        <>{<HelperText>{isCertificateError}</HelperText>}</>
                      </Box>
                    </ImageOuterBox>
                  </FlexBox>

                  <ReportBox>
                    <AddReport
                      handleAssetReport={handleAssetReport}
                      assetId={assetId}
                    />
                  </ReportBox>
                </>
              )}

              <ButtonBox>
                <SubmitButton
                  variant="outlined"
                  size="large"
                  sx={{
                    border: "2px solid #1746A2",
                    color: "#1746A2",
                    bgcolor: "white",
                  }}
                  onClick={() => navigate(-1)}>
                  Cancel
                </SubmitButton>

                <SubmitButton
                  variant="contained"
                  size="large"
                  sx={{
                    bgcolor: "#1746A2",
                  }}
                  disabled={userAccess?.role === "Guest"}
                  onClick={handleSubmit}>
                  Submit
                </SubmitButton>
              </ButtonBox>
            </>
          </UploadContainer>
        </>
      )}

      <AddAssetModal
        status={status}
        handleCloseModal={handleCloseModal}
        toggle={toggle}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </OuterBox>
  );
}

export default AddAsset;
