
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
// import { postNotificationToken } from "./redux/data/action";
const token = localStorage.getItem("userToken");


let userData =
JSON.parse(sessionStorage.getItem("userData")) ||
JSON.parse(localStorage.getItem("userData"));
// const dispatch = useDispatch()
const firebaseConfig = {
  apiKey: "AIzaSyDBwWLXdBmI6fjmjCuR4g_hW2O4xvE9bzY",
  authDomain: "vajra-70f94.firebaseapp.com",
  projectId: "vajra-70f94",
  storageBucket: "vajra-70f94.appspot.com",
  messagingSenderId: "714788592453",
  appId: "1:714788592453:web:14226c86131d0b9feafc2c",
  measurementId: "G-X8H1GD5JSY"
  };


const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)



export const requestForToken = ()=>{
    return getToken(messaging,{vapidKey:'BMWa9SfiJ-Kzts5OTMcJPIphs5MTExRRbGBe16Veo8W0kBLPnGhDwvNqhdVXgCjojb8eqEjcjvg9wQUAml8iwPk'})
    
    .then((currentToken)=>{
        if(currentToken){
            console.log("Client Token",currentToken)
            let data = {
              userid: userData.userid,
              webtoken: currentToken
            }         
              axios({
                method: "POST",
                url: process.env.REACT_APP_POST_NOTIFICATION_TOKEN,
                headers: { Authorization: `Bearer ${token}` },
                data,
              })
                .then((res) => {
                console.log("data",res.data)
                })
                .catch((error) => {

                });    
        }else{
            console.log("No token received")
        }
    })
    .catch((err)=>{
        console.log("error",err)
    })
}

export const onMessageListener = ()=>{
    return new Promise((resolve)=>{
        onMessage(messaging, (payload)=>{

            resolve(payload)
            const existingNotificationsString = localStorage.getItem("notification");
            const existingNotifications = existingNotificationsString ? JSON.parse(existingNotificationsString) : [];
      
              let not = payload.notification.title
    
              const newNotification = {
                body: payload.notification.body,
                icon: payload.notification.image,
                title: payload.notification.title,
              };

              const updatedNotifications = [...existingNotifications, newNotification];
              localStorage.setItem("notification", JSON.stringify(updatedNotifications));
              showNotification(not,newNotification)
        })
    })
}

export const showNotification = (title, options) => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(title, options);
          return notification;
        }
      });
    } else {
      console.log('Notifications not supported in this browser.');
    }
  };