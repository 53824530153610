import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, useMediaQuery,styled, IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import deleteimg from "../images/delete.png";


function BulkDeleteDepartment({ open, onClose, onDelete, id, text }) {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dialogStyle = {
    width: isSmallScreen ? "80vw" : "30vw",
    // height: "220px",
    borderRadius: "20px",
    padding:"15px"
  };

  const imageStyle = {
    width: "70px",
    height: "70px",
  };

  const TopHead = styled(Box)(({ theme }) => ({
    width: "100%",
    padding:'10px',
    display:'flex',
    justifyContent:'end',
    [theme.breakpoints.down("xl")]: {
     
    },
    [theme.breakpoints.down("lg")]: {
    
    },
    [theme.breakpoints.down("md")]: {
     
    },
    [theme.breakpoints.down("sm")]: {
     
    },
    [theme.breakpoints.down("xs")]: {
     
    },
  }));
  

  

  const CustomDialog = styled(Dialog)(({ theme }) => ({

    padding:'10px',
    display:'flex',
    justifyContent:'end',
    [theme.breakpoints.down("xl")]: {
     
    },
    [theme.breakpoints.down("lg")]: {
    
    },
    [theme.breakpoints.down("md")]: {
     
    },
    [theme.breakpoints.down("sm")]: {
     
    },
    [theme.breakpoints.down("xs")]: {
     
    },
  }));



  return (

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        PaperProps={{ style: isSmallScreen ? dialogStyle : dialogStyle }}>
            <TopHead><IconButton onClick={onClose}><CloseIcon sx={{color:'#FF731D'}}/></IconButton></TopHead>
        <DialogTitle id="alert-dialog-title" sx={{ display: "flex", textAlign:'center' }}>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              width: "100%",
            }}>
         
            <DialogContent
              sx={{
                width: "100%",
                padding: "0px",
                paddingLeft: "2%",
               
              }}>
           
              <DialogContentText
                id="alert-dialog-description"
                style={{ width: "100%", color:'#1746A2', fontSize:'20px' }}>
             
                      Move assets to another department before deleting.

              </DialogContentText>
            </DialogContent>
          </div>
        </DialogTitle>

        <Box sx={{ display:'flex', justifyContent:'center', width: "100%",  }}>
        
          <Button
            onClick={onDelete}
            style={{
              backgroundColor: "#1746A2",
              color: "white",
              borderRadius: "8px",
              textTransform: "none",
            }}
            autoFocus
            size="large">
            Go to asset page
          </Button>
        </Box>
      </Dialog>

  );
}
export default BulkDeleteDepartment;
