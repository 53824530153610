import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  TableFooter,
  Typography,
} from "@mui/material";
import html2canvas from "html2canvas";
import pdfImage from "../images/pdfImage.png";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArticleIcon from "@mui/icons-material/Article";
import emptyAsset from "../images/emptyAsset.png";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ellips from "../images/ellips.png";
import vajralogo from "../images/vajralogo.jpg";

import {
  DownloadPDF,
  assetDetailsData,
  deleteAsset,
} from "../redux/data/action";
import ServicesModal from "../components/ServicesModal";
import empty from "../images/empty.png";
import DeleteAssetModal from "../components/DeleteAssetModal";
import DeleteModal from "../components/DeleteModal";
import ViewImageModal from "../components/ViewImageModal";

const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",

    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "90%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "auto",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  width: "95%",
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Previous = styled(Button)(({ theme }) => ({
  border: "0px solid transparent",
  textTransform: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EandDbutton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  gap: "20px",
  marginBottom: "20px",
  display: "flex",
  justifyContent: "center",

  // width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopTitleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "left",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "603px",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  // border:'3px solid green',
  justifyContent: "left",
  gap: "20px",
  marginBottom: "20px",

  [theme.breakpoints.down("xl")]: {
    // alignItems: "center",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DetailContainer = styled(Box)(({ theme }) => ({
  boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.2)",

  width: "603px",
  height: "100%",
  padding: "10px",
  bgcolor: "#FFFFFF",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: "400px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

// const TopLeftContainer = styled(Box)(({ theme }) => ({

//   display: "flex",
//   flexDirection: "column",
//   gap: "20px",
//   justifyContent: "center",
//   alignItems: "center",

//   [theme.breakpoints.down("xl")]: {
//     marginTop: "30px",
//     flexDirection: "row",
//     alignItems: "center",
//     gap: "20px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     flexDirection: "column",
//   },
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "column",
//   },
//   [theme.breakpoints.down("sm")]: {
//     flexDirection: "column",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

const TopLeftContainer = styled(Box)(({ theme }) => ({
  // width: "100%",
  // border:'5px solid red',
  height: "auto",
  display: "flex",
  //   flexDirection: "column",
  width: "100%",
  alignItems: "center",
  bgcolor: "#FFFFFF",
  // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",

  gap: "20px",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {
    // marginTop: "30px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // height: "380px",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    // height: "485px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // justifyContent: "center",
  width: "407px",
  height: "100%",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    // height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    // height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ReportSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  // minHeight: "201px",
  height: "100%",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    // minHeight: "201px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // minHeight: "201px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  // border:'3px solid red',
  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerWarrantyImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //   border:'6px solid red',

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const QrcodeContainer = styled(Box)(({ theme }) => ({
  width: "407px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  padding: "10px",
  // height: "210px",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    padding: "10px",
    // height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    // height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ServiceHistory = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "200px",
  maxHeight: "280px",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  //   border:"3px solid blue",
  // padding: "10px",
  //  justifyContent:'center',
  bgcolor: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {
    // marginTop: "30px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "485px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Warranty = styled(Box)(({ theme }) => ({
  width: "385px",
  height: "261px",
  background: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "231px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
    height: "231px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "380px",
    height: "231px",
  },
}));

const InnerBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "80%",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MultiWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  //   border:'1px solid red',
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopRightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    // marginTop: "30px",
    // flexDirection: "row",
    alignItems: "center",
    gap: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    // width:'100%',
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const WarrantyInnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //    border:'2px solid black',
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const WarrantyImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  // justifyContent: "center",
  width: "100%",
  height: "100%",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    // height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const RightBox = styled(Box)(({ theme }) => ({
  //

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageCard = styled(Box)(({ theme }) => ({
  width: "159.84px",
  height: "155px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const WarrantyImageCard = styled(Box)(({ theme }) => ({
  width: "159px",
  height: "145px",
  //  border:"3px solid black",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomRight = styled(Box)(({ theme }) => ({
  width: "159.84px",
  height: "155px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PrevNextButton = styled(Button)(({ theme }) => ({
  height: "50px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

// width: 159.84px;
// height: 155px;

//
const RButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  width: "240px",
  borderRadius: "28px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageText = styled(Typography)(({ theme }) => ({
  border: "1px solid #FF731D",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  width: "90%",
  display: "flex",
  gap: "30px",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "30px",
  // flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: "500",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "18px",
  marginLeft: "20px",
  color: "#FF731D",
  // padding: "4px",
  lineWeight: "36px",

  textAlign: "left",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontWeight: 500,
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontWeight: 500,
    fontSize: "16px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const QrText = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  lineWeight: "36px",

  textAlign: "left",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const QrBox = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const QrLeftBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "200px",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const WarrantyImage = styled(Box)(({ theme }) => ({
  height: "108px",
  width: "84px",
  borderRadius: "0px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextBox = styled(Box)(({ theme }) => ({
  margin: "5px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "200px",
  // height:'200px',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableSet = styled(TableContainer)(({ theme }) => ({
  // minHeight:"400px",
  // maxHeight: "700px",
  overflow: "auto",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "0.4em",
    minHeight: "100px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
    borderRadius: "30px",
    maxHeight: "200px",
    // paddingTop: "50px",
  },
  // ::-webkit-scrollbar-track {max-height: 50%; height: 50%;}
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "30px",
    maxHeight: "200px",

    // marginTop: "2%",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },

  [theme.breakpoints.down("xl")]: {
    // height: "260px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "260px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyBoxWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "200px",
  height: "auto",
  display: "flex",
  gap: "20px",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const EmptyText = styled(Typography)(({ theme }) => ({
  color: "#212427",
  fontSize: "18px",

  fontWeight: 500,

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AddAsset = styled(Button)(({ theme }) => ({
  width: "200px",
  height: "56px",
  borderRadius: "28px",
  //  margin:'auto',
  textTransform: "none",
  fontSize: "18px",

  fontWeight: 500,

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "180px",
    height: "50px",
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const IncidentTable = styled(Table)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TableCard = styled(TableCell)(({ theme }) => ({
  width: 10,

  borderBottom: "0",
  fontSize: "18px",
  padding: "10px",
  paddingLeft: "16px",
  [theme.breakpoints.down("xl")]: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: "5px",
    fontSize: "12px",
    paddingBottom: "5px",
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: "5px",
    fontSize: "12px",
    paddingBottom: "5px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: "5px",

    paddingBottom: "5px",
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const HeadingContainer = styled(Box)(({ theme }) => ({
  padding: "10px",
  width: "80%",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

// height: 108px;
// width: 84px;
// border-radius: 0px;

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
];

function AssetDetails() {
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [image, setImage] = useState([]);

  const [reportImage, setReportImage] = useState([]);
  const [reportImageName, setReportImageName] = useState([]);
  const [direction, setDirection] = React.useState("up");
  const [zoomImageUrl, setZoomImageUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [hidden, setHidden] = React.useState(false);
  const [certificateImage, setCertificateImage] = useState([]);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const params = useParams();
  const dispatch = useDispatch();
  const detailData = useSelector((store) => store.data.assetDetails);

  const [openImage, setOpenImage] = useState(false);

  const handleImageOpen = (url) => {
    setZoomImageUrl(url);
    setOpenImage(true);
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const text = "Asset";
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handlePrevious = () => {
    setCount((prevCount) => {
      if (prevCount === 0) {
        return image?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNext = () => {
    setCount((prevCount) => {
      if (prevCount === image?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleReportPrevious = () => {
    setCount3((prevCount) => {
      if (prevCount === 0) {
        return reportImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleReportNext = () => {
    setCount3((prevCount) => {
      if (prevCount === reportImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handlePreviousCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === 0) {
        return certificateImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNextCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === certificateImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleDelete = () => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
    };

    dispatch(deleteAsset(data, token));
    navigate(-1);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.target = "_blank";
    link.download = "downloaded_image.jpg";
    link.click();
  };

  useEffect(() => {
    if (detailData?.imgs) {
      setImage(detailData?.imgs);
      setCertificateImage(detailData?.wimg);
    }
    if (detailData?.reportimgs) {
      setReportImage(detailData?.reportimgs);
    }
    if (detailData?.reportnames) {
      setReportImageName(detailData?.reportnames);
    }
    // setReportImageName
  }, [detailData]);

  const handleClick = () => {
    localStorage.setItem("incidentId", detailData?.incidid);
  };

  const handleDownloadPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";

      const imageX = (pdfWidth - 30) / 2;

      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);

      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;

      pdf.save(`${name}.pdf`);
    };

    img.src = pdfData;
  };

  const handlePrintPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";
      const imageX = (pdfWidth - 30) / 2;
      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);
      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;
      pdf.text(name, pdfWidth / 2, nameY, { align: "center" });
      pdf.text(code, pdfWidth / 2, codeY, { align: "center" });

      const pdfBlob = pdf.output("blob");

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const newWindow = window.open(pdfUrl);

      newWindow.onload = function () {
        newWindow.print();

        URL.revokeObjectURL(pdfUrl);
      };
    };

    img.src = pdfData;
  };

  // const handleDownloadPDF2 = (
  //   pdfData,
  //   name,
  //   code,
  //   brand,
  //   calibdate,
  //   calibduedate,
  //   calibmnth,
  //   calibreq,
  //   calibstatus,
  //   cmcamc,
  //   cmcamcdue,
  //   cmcamcstart,
  //   dept,
  //   dop,
  //   imgs,
  //   serialno,
  //   incidstatus,
  //   lifeyrs,
  //   modelno,
  //   price,
  //   servstatus,
  //   soldby,
  //   status
  // ) => {
  //   if (!pdfData) return;

  //   const pdf = new jsPDF();
  //   const img = new Image();

  //   img.onload = function () {
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (img.height * 30) / img.width; // Set the desired height to 30 pixels
  //     const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";

  //     // Calculate the X position to align text to the left
  //     const textXPosition = 15; // Adjust the left margin as needed

  //     let startY = 15; // Start at the top of the page

  //     // Function to add a new page if content exceeds the current page height
  //     const addNewPageIfNeeded = () => {
  //       const pageHeight = pdf.internal.pageSize.getHeight();
  //       if (startY + pdfHeight > pageHeight) {
  //         pdf.addPage();
  //         startY = 10; // Reset the Y position
  //       }
  //     };

  //     // Continue adding text and images

  //     // Add a heading for details
  //     pdf.setFontSize(18);
  //     pdf.setTextColor(255, 165, 0);
  //     pdf.text("Details", textXPosition, startY);
  //     pdf.setFontSize(14);
  //     pdf.setTextColor(0);
  //     startY += 10;

  //     pdf.text(`Name: ${name}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Code: ${code}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Brand: ${brand}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Calibration Date: ${calibdate}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Calibration Due Date: ${calibduedate}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Calibration Month: ${calibmnth}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Calibration Request: ${calibreq}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Calibration Status: ${calibstatus}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`CMC/AMC: ${cmcamc}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`CMC/AMC Due: ${cmcamcdue}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`CMC/AMC Start: ${cmcamcstart}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Department: ${dept}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Date of Purchase: ${dop}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Incident Status: ${incidstatus}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Life Years: ${lifeyrs}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Model No: ${modelno}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Price: ${price}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Service Status: ${servstatus}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Sold By: ${soldby}`, textXPosition, startY);
  //     startY += 10;
  //     pdf.text(`Status: ${status}`, textXPosition, startY);
  //     startY += 20;
  //     pdf.setFontSize(18);
  //     pdf.setTextColor(255, 165, 0);
  //     pdf.text("Qr code", textXPosition, startY);
  //     pdf.setFontSize(14);
  //     startY += 5;
  //     pdf.addImage(pdfData, imageType, textXPosition, startY, 30, pdfHeight);
  //     startY += pdfHeight + 20;
  //     addNewPageIfNeeded();

  //     pdf.setFontSize(18);
  //     pdf.setTextColor(255, 165, 0);
  //     pdf.text("Asset image", textXPosition, startY);
  //     pdf.setFontSize(10);

  //     let imageX = textXPosition;
  //     let imageY = startY + 20;

  //     const imageWidth = 30;
  //     const imageHeight = 30;

  //     for (let i = 0; i < imgs.length; i++) {
  //       const { imgurl } = imgs[i];

  //       addNewPageIfNeeded();

  //       pdf.addImage(imgurl, "JPEG", imageX, imageY, imageWidth, imageHeight);

  //       imageX += 50;
  //       if (i % 2 === 1) {
  //         imageX = textXPosition;
  //         imageY += imageHeight + 10;
  //       }
  //     }

  //     pdf.save(`${name}.pdf`);
  //   };

  //   img.src = pdfData;
  // };

  const handleDownloadPDF2 = (
    logoUrl,
    pdfData,
    name,
    code,
    brand,
    calibdate,
    calibduedate,
    calibmnth,
    calibreq,
    calibstatus,
    cmcamc,
    cmcamcdue,
    cmcamcstart,
    dept,
    dop,
    imgs,
    wimg,
    serialno,
    incidstatus,
    lifeyrs,
    modelno,
    price,
    servstatus,
    soldby,
    status
  ) => {
    if (!pdfData || !logoUrl) return;

    const pdf = new jsPDF();
    const img = new Image();
    img.src = logoUrl;

    img.onload = function () {
      const desiredWidth = 40; // Set the desired width of the logo
      const aspectRatio = img.width / img.height;
      const desiredHeight = 35;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const lineWidth = 0.9 * pageWidth;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const xPosition = (pageWidth - lineWidth) / 2; // Centering on X axis
      const yPosition = 20; // Position from top of the page
      const underlineYPosition = yPosition + desiredHeight + 5; // Position for the underline

      // Move logo to the middle top of the page
      const logoXPosition = (pageWidth - desiredWidth) / 2;
      const logoYPosition = 10;

      pdf.addImage(
        logoUrl,
        "PNG",
        logoXPosition,
        logoYPosition,
        desiredWidth,
        desiredHeight
      );

      // Draw the blue line below the logo
      const logoLineY = logoYPosition + desiredHeight + 5; // Y position for the line
      pdf.setLineWidth(1); // Set line width
      pdf.setDrawColor("1b76bc"); // Set line color to blue
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY); // Draw the line
      const desiredLogoWidth = 40; // Set the desired width of the logo

      const desiredLogoHeight = desiredLogoWidth / aspectRatio;

      // Adding details below the logo
      pdf.setFontSize(12);
      const textXPosition = 15; // X position for the text
      let textYPosition = desiredLogoHeight + 20; // Y position for the first text line below the logo

      // Set the desired height to 30 pixels
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";
      let startY = 15;
      const addNewPageIfNeeded = () => {
        const pageHeight = pdf.internal.pageSize.getHeight();
        if (startY + pdfHeight > pageHeight) {
          pdf.addPage();
          startY = 10; // Reset the Y position
        }
      };

      startY += 10;

      pdf.setTextColor(0, 0, 0); // Black color

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Name: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(name, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Code: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(code, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Brand: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(brand, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Department: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(dept, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Status: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(status, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Calibration Date: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(calibdate, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Calibration Status: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(calibstatus, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Useful Life: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(lifeyrs, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Model No: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(modelno, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`AMC/CMC: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(cmcamc, textXPosition + 45, textYPosition);
      textYPosition += 10;

      // pdf.setTextColor(255, 165, 0); // Set text color to orange
      // pdf.text(`AMC/CMC: `, textXPosition, textYPosition);
      // pdf.setTextColor(0, 0, 0); // Set text color to black
      // pdf.text(cmcamc, textXPosition + 45, textYPosition);
      // textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Purchase Date: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(dop, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Price: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(price, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Sold by: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(soldby, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Incident Status: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(incidstatus, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Service Status: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(servstatus, textXPosition + 45, textYPosition);
      textYPosition += 10;
      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`CMC/AMC Start: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(cmcamcstart, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`CMC/AMC Due: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(cmcamcdue, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Calibration Month: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(calibmnth, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Calibration Due Date: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(calibduedate, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setTextColor(255, 165, 0); // Set text color to orange
      pdf.text(`Calibration Request: `, textXPosition, textYPosition);
      pdf.setTextColor(0, 0, 0); // Set text color to black
      pdf.text(calibreq, textXPosition + 45, textYPosition);
      textYPosition += 10;

      pdf.setFontSize(18);
      pdf.setTextColor(255, 165, 0);

      const contentWidth = pdf.internal.pageSize.getWidth();
      const contentHeight = pdf.internal.pageSize.getHeight();

      // Set positions for QR code and asset images on the right side
      const qrCodeX = contentWidth - 70;
      const qrCodeY = 80;
      const assetImageX = contentWidth - 70;
      const assetImageY = 130;

      pdf.setFontSize(14);
      pdf.setTextColor("1b76bc");

      pdf.addImage(pdfData, "JPEG", qrCodeX, qrCodeY, 30, 30);
      pdf.text("Asset image", assetImageX, assetImageY);

      pdf.setFontSize(10);

      let imageX = assetImageX;
      let imageY = assetImageY + 5;

      const imageWidth = 20;
      const imageHeight = 20;
      // Add border and label for QR code
      pdf.setDrawColor("1b76bc"); // Set border color to black
      pdf.setLineWidth(0.5); // Set border width
      pdf.rect(qrCodeX, qrCodeY, 30, 30); // Draw a rectangle around the QR code
      pdf.setFontSize(14);
      pdf.setTextColor("1b76bc");
      pdf.text("QR Code", qrCodeX, qrCodeY - 5); // Add label above the QR code

      for (let i = 0; i < imgs.length; i++) {
        const { imgurl } = imgs[i];
        pdf.setDrawColor("1b76bc"); // Set border color to black
        pdf.setLineWidth(0.5);
        pdf.rect(imageX, imageY, imageWidth, imageHeight);
        pdf.addImage(imgurl, "JPEG", imageX, imageY, imageWidth, imageHeight);

        imageX += imageWidth + 3;
        if (imageX > contentWidth - imageWidth - 3) {
          imageX = assetImageX;
          imageY += imageHeight + 3;
        }
      }
      if (wimg !== "NA" && wimg.length > 0) {
        const warrantyImageWidth = 18;
        const warrantyImageHeight = 18;
        let warrantyImageX = qrCodeX;
        let warrantyImageY = qrCodeY + 100;

        const warrantyImageHeadingX = qrCodeX;
        const warrantyImageHeadingY = qrCodeY + 105; // Adjust the Y position as needed

        pdf.setFontSize(14);
        pdf.setLineWidth(0.5);
        pdf.setTextColor("1b76bc");

        pdf.text(
          "Warranty Images",
          warrantyImageHeadingX,
          warrantyImageHeadingY - 10
        );

        for (let i = 0; i < wimg.length; i++) {
          const { imgurl } = wimg[i];
          pdf.setDrawColor("1b76bc"); // Set border color to black
          pdf.setLineWidth(0.5); // Set border width
          pdf.rect(
            warrantyImageX,
            warrantyImageY,
            warrantyImageWidth,
            warrantyImageHeight
          ); // Draw a rectangle around the warranty image
          pdf.addImage(
            imgurl,
            "JPEG",
            warrantyImageX,
            warrantyImageY,
            warrantyImageWidth,
            warrantyImageHeight
          );
          warrantyImageX += warrantyImageWidth + 3;
        }
      }
      const pageHeight = pdf.internal.pageSize.getHeight();
      const contactBoxHeight = 15; // Height of the contact box
      const contactBoxY = pageHeight - contactBoxHeight; // Box starts at the bottom of the page

      // Add a box with email address and phone number
      const contactBoxX = 0; // X position for the contact box (starts from the left edge)
      const contactBoxWidth = pdf.internal.pageSize.getWidth(); // Full width of the page

      // Set background color and add the box
      pdf.setFillColor("1b76bc"); // Blue color
      pdf.rect(
        contactBoxX,
        contactBoxY,
        contactBoxWidth,
        contactBoxHeight,
        "F"
      ); // Draw a filled rectangle for the contact box

      pdf.setFontSize(13);
      pdf.setTextColor(255); // White color
      const contactTextX = 35; // X position for the contact text (left margin)
      const contactTextY = contactBoxY + 8; // Y position for the contact text (centered vertically in the box)
      pdf.text(
        "www.vajra.software  |  contact@vajra.software   |   +91 89715-80265",
        contactTextX,
        contactTextY
      );

      pdf.save(`${name}.pdf`);
    };

    img.src = pdfData;
  };

  return (
    <Main>
      <HeadingContainer>
        <TitleHead>Asset Details </TitleHead>
      </HeadingContainer>

      {Object.keys(detailData).length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: " 15px ",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <Cards>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={empty}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                sx={{ color: " #212427" }}
                color="text.secondary"
                gutterBottom
              >
                No asset found
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => navigate("/home")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                variant="contained"
              >
                Go to Home
              </Button>
            </CardActions>
          </Cards>
        </BoxWrapper>
      ) : (
        <>
          <MultiWrapper>
            <TopContainer sx={{}}>
              <DetailContainer sx={{ bgcolor: "white" }}>
                <TopBox>
                  <LeftBox>
                    <SubTitle> Details</SubTitle>
                  </LeftBox>
                  <RightBox>
                    <IconButton
                      disabled={userAccess?.editprod !== "Yes"}
                      onClick={() => navigate(`/edit-asset/${params.id}`)}
                    >
                      <EditOutlinedIcon
                        sx={{
                          color:
                            userAccess?.editprod !== "Yes"
                              ? "rgb(196, 206, 196)"
                              : "#FF731D",
                        }}
                      />
                    </IconButton>
                    <IconButton disabled={userAccess?.delprod !== "Yes"}>
                      <DeleteOutlineIcon
                        onClick={handleOpenDeleteModal}
                        sx={{
                          color:
                            userAccess?.delprod !== "Yes"
                              ? "rgb(196, 206, 196)"
                              : "#FF731D",
                        }}
                      />
                    </IconButton>
                  </RightBox>
                </TopBox>

                <TextContainer sx={{}}>
                  <TableSet sx={{}}>
                    <IncidentTable aria-label="custom pagination table">
                      <TableBody sx={{}}>
                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Asset Name
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.name}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Serial no
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.serialno}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Department
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.dept}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Type
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.type}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Status
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.status}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Sold By
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.soldby}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Calibrate Status
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.calibstatus}
                          </TableCard>
                        </TableRow>

                        {detailData?.calibdate !== "NA" && (
                          <TableRow>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              Calibrated date
                            </TableCard>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              :
                            </TableCard>
                            <TableCard style={{ width: 10 }}>
                              {detailData?.calibdate}
                            </TableCard>
                          </TableRow>
                        )}

                        {detailData?.calibduedate !== "NA" && (
                          <TableRow>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              Calibration due date
                            </TableCard>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              :
                            </TableCard>
                            <TableCard style={{ width: 10 }}>
                              {detailData?.calibduedate}
                            </TableCard>
                          </TableRow>
                        )}
                        {detailData?.calibreq !== "NA" &&
                          detailData?.calibreq !== "" && (
                            <TableRow>
                              <TableCard
                                sx={{ color: "#1746A2" }}
                                component="th"
                                scope="row"
                              >
                                Calibration requirement
                              </TableCard>
                              <TableCard
                                sx={{ color: "#1746A2" }}
                                component="th"
                                scope="row"
                              >
                                :
                              </TableCard>
                              <TableCard style={{ width: 10 }}>
                                {detailData?.calibreq}
                              </TableCard>
                            </TableRow>
                          )}

                        {detailData?.lifeyrs !== "NA" && (
                          <TableRow>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              Useful life
                            </TableCard>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              :
                            </TableCard>
                            <TableCard style={{ width: 10 }}>
                              {detailData?.lifeyrs === ""
                                ? "Na"
                                : detailData?.lifeyrs}
                            </TableCard>
                          </TableRow>
                        )}

                        {detailData?.modelno !== "NA" && (
                          <TableRow>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              Model number
                            </TableCard>
                            <TableCard
                              sx={{ color: "#1746A2" }}
                              component="th"
                              scope="row"
                            >
                              :
                            </TableCard>
                            <TableCard style={{ width: 10 }}>
                              {detailData?.modelno}
                            </TableCard>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            AMC/CMC
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.cmcamc}{" "}
                            {detailData?.cmcamcdue !== "NA" &&
                              detailData?.cmcamcdue}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Asset Code
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.code}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Price
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          {detailData?.price !== "NA" ? (
                            <TableCard style={{ width: 10 }}>
                              ₹ {detailData?.price}
                            </TableCard>
                          ) : (
                            <TableCard style={{ width: 10 }}>
                              {detailData?.price}
                            </TableCard>
                          )}
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Brand
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.brand}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Purchase Date
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.dop}
                          </TableCard>
                        </TableRow>

                        <TableRow>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            Warranty Expiry
                          </TableCard>
                          <TableCard
                            sx={{ color: "#1746A2" }}
                            component="th"
                            scope="row"
                          >
                            :
                          </TableCard>
                          <TableCard style={{ width: 10 }}>
                            {detailData?.wrntexpdate}
                          </TableCard>
                        </TableRow>

                        {/* <TableRow >
                        <TableCard
                          sx={{ color: "#1746A2" }}
                          component="th"
                          scope="row">
                          Warranty Status
                        </TableCard>
                        <TableCard
                          sx={{ color: "#1746A2" }}
                          component="th"
                          scope="row">
                          :
                        </TableCard>
                        <TableCard style={{ width: 10 }}>
                          {detailData?.wrntstatus}
                        </TableCard>
                      </TableRow> */}
                      </TableBody>
                      <TableFooter>
                        <TableRow></TableRow>
                      </TableFooter>
                    </IncidentTable>
                  </TableSet>
                </TextContainer>
              </DetailContainer>
              <TopRightContainer sx={{ marginTop: "0px" }}>
                <ImageContainer sx={{ bgcolor: "white" }}>
                  <LeftBox sx={{ width: "98%" }}>
                    <SubTitle> Image </SubTitle>
                  </LeftBox>
                  <InnerBorderBoxBox>
                    <InnerImageBox>
                      <IconButton onClick={handlePrevious}>
                        <ArrowBackIosNewIcon />
                      </IconButton>
                      <ImageCard
                        as="img"
                        src={`${image[count]?.imgurl}`}
                        alt="check"
                        onClick={() => handleImageOpen(image[count]?.imgurl)}
                      />
                      <IconButton onClick={handleNext}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </InnerImageBox>

                    <IconButton
                      onClick={() => handleDownload(image[count]?.imgurl)}
                    >
                      <FileDownloadIcon sx={{ color: "#FF731D" }} />
                    </IconButton>
                  </InnerBorderBoxBox>
                </ImageContainer>

                <QrcodeContainer sx={{ bgcolor: "white" }}>
                  <QrLeftBox>
                    <QrText sx={{ color: "#FF731D", paddingBottom: "5px" }}>
                      QR code{" "}
                    </QrText>
                    <QrBox
                      as="img"
                      src={detailData?.qrimg}
                      onClick={() => handleImageOpen(detailData?.qrimg)}
                    ></QrBox>

                    <Box
                      sx={{
                        position: "relative",
                        ml: "-90px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <StyledSpeedDial
                        ariaLabel="SpeedDial playground example"
                        hidden={hidden}
                        icon={<FileDownloadIcon sx={{ color: "#FF731D" }} />}
                        direction={"right"}
                        sx={{
                          height: "25px",
                          "& .MuiFab-primary": {
                            "&:hover": { backgroundColor: "transparent" },
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                        }}
                        style={{ background: "transparent" }}
                      >
                        <SpeedDialAction
                          onClick={() =>
                            handleDownloadPDF(
                              detailData?.qrimg,
                              detailData?.code,
                              detailData?.name
                            )
                          }
                          icon={<SaveIcon />}
                          tooltipTitle={"Save"}
                        />
                        <SpeedDialAction
                          onClick={() =>
                            handlePrintPDF(
                              detailData?.qrimg,
                              detailData?.code,
                              detailData?.name
                            )
                          }
                          icon={<PrintIcon />}
                          tooltipTitle={"Print"}
                        />
                      </StyledSpeedDial>
                    </Box>

                    {/* { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' }, */}
                    <TextBox>
                      {/* <QrText>Scan to get information.</QrText> */}
                    </TextBox>
                  </QrLeftBox>
                </QrcodeContainer>

                {detailData?.wimg !== "NA" && (
                  <WarrantyImageContainer sx={{ bgcolor: "white" }}>
                    <LeftBox sx={{ width: "98%" }}>
                      <SubTitle> Warranty Image</SubTitle>
                    </LeftBox>
                    <WarrantyInnerBorderBoxBox>
                      <InnerWarrantyImageBox>
                        <IconButton onClick={handlePreviousCertificate}>
                          <ArrowBackIosNewIcon />
                        </IconButton>

                        <WarrantyImageCard
                          as="img"
                          src={`${
                            certificateImage[count2]?.imgid
                              ?.split(".")
                              ?.pop() === "pdf"
                              ? pdfImage
                              : certificateImage[count2]?.imgurl
                          }`}
                          alt="check"
                          onClick={() =>
                            handleImageOpen(certificateImage[count2]?.imgurl)
                          }
                        />
                        <IconButton onClick={handleNextCertificate}>
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </InnerWarrantyImageBox>

                      <IconButton
                        onClick={() =>
                          handleDownload(certificateImage[count2]?.imgurl)
                        }
                      >
                        <FileDownloadIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </WarrantyInnerBorderBoxBox>
                    {/* <QrText sx={{ color: "#FF731D" }}>Download Image</QrText> */}
                  </WarrantyImageContainer>
                )}
              </TopRightContainer>
            </TopContainer>

            <BottomContainer>
              {(detailData?.service_history !== "No History" ||
                detailData?.incident_history !== "No History") && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  {detailData?.service_history !== "No History" && (
                    <ServiceHistory sx={{ bgcolor: "white" }}>
                      <SubTitle> Service History </SubTitle>

                      <TextContainer>
                        <TableContainer>
                          <Box
                            sx={{
                              minHeight: "200px",
                              maxHeight: "230px",
                              overflow: "auto",
                              scrollbarWidth: "thin",
                              "&::-webkit-scrollbar": {
                                width: "0.4em",
                                // minHeight: "100px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                                borderRadius: "30px",
                                maxHeight: "300px",
                                // paddingTop: "50px",
                              },
                              // ::-webkit-scrollbar-track {max-height: 50%; height: 50%;}
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#888",
                                borderRadius: "30px",
                                maxHeight: "300px",

                                // marginTop: "2%",
                              },
                              "&::-webkit-scrollbar-thumb:hover": {
                                background: "#555",
                              },
                            }}
                          >
                            <Table
                              aria-label="simple table"
                              sx={{
                                fontSize: "1.125rem",

                                boxShadow: "0px 0px 4px 0px #00000033",
                                // backgroundColor: "#FFFFFF",
                              }}
                            >
                              <TableHead sx={{ backgroundColor: "#1746A233" }}>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                      borderTopLeftRadius: "1rem",
                                    }}
                                  >
                                    Date
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                    }}
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                    }}
                                  >
                                    Report
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                      borderTopRightRadius: "1rem",
                                    }}
                                  >
                                    Download
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {detailData?.service_history?.map((item) => (
                                  <TableRow
                                    sx={{ ":hover": { boxShadow: "tableRow" } }}
                                    key={item.id}
                                  >
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {item?.servdate}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {item?.assetname}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {item?.servstatus}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      <FileDownloadIcon
                                        onClick={() =>
                                          dispatch(
                                            DownloadPDF(
                                              item?.reporturl,
                                              item?.assetname
                                            )
                                          )
                                        }
                                        sx={{ cursor: "pointer" }}
                                        color="warning"
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableContainer>
                      </TextContainer>
                    </ServiceHistory>
                  )}

                  {detailData?.incident_history !== "No History" && (
                    <ServiceHistory sx={{ bgcolor: "white" }}>
                      <SubTitle> Incident History </SubTitle>

                      <TextContainer>
                        <TableContainer>
                          <Box
                            sx={{
                              // border:'4px solid black',
                              minHeight: "200px",
                              maxHeight: "230px",
                              overflow: "auto",
                              scrollbarWidth: "thin",
                              "&::-webkit-scrollbar": {
                                width: "0.4em",
                                // minHeight: "100px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                                borderRadius: "30px",
                                maxHeight: "280px",
                                // paddingTop: "50px",
                              },
                              // ::-webkit-scrollbar-track {max-height: 50%; height: 50%;}
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#888",
                                borderRadius: "30px",
                                maxHeight: "280",

                                // marginTop: "2%",
                              },
                              "&::-webkit-scrollbar-thumb:hover": {
                                background: "#555",
                              },
                            }}
                          >
                            <Table
                              aria-label="simple table"
                              sx={{
                                fontSize: "1.125rem",

                                boxShadow: "0px 0px 4px 0px #00000033",
                                // backgroundColor: "#FFFFFF",
                              }}
                            >
                              <TableHead sx={{ backgroundColor: "#1746A233" }}>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                      borderTopLeftRadius: "1rem",
                                    }}
                                  >
                                    Date
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                    }}
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                    }}
                                  >
                                    Report
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      color: "#1746A2",
                                      borderTopRightRadius: "1rem",
                                    }}
                                  >
                                    Download
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {detailData?.incident_history?.map((item) => (
                                  <TableRow
                                    sx={{ ":hover": { boxShadow: "tableRow" } }}
                                    key={item.id}
                                  >
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {item?.cleardate}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {item?.assetname}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {item?.incidstatus}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      <FileDownloadIcon
                                        onClick={() =>
                                          dispatch(
                                            DownloadPDF(
                                              item?.reporturl,
                                              item?.assetname
                                            )
                                          )
                                        }
                                        sx={{ cursor: "pointer" }}
                                        color="warning"
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableContainer>
                      </TextContainer>
                    </ServiceHistory>
                  )}
                </Box>
              )}

              {reportImage?.length > 0 && (
                <TopLeftContainer>
                  {reportImage?.length > 0 && (
                    <ReportSection sx={{ bgcolor: "white" }}>
                      <LeftBox sx={{ width: "98%" }}>
                        <SubTitle> Report </SubTitle>
                      </LeftBox>
                      <TableContainer
                        component={Paper}
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          // borderRadius: "30px",
                          borderRadius: " 0 0 30px 30px",
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  bgcolor: "#EFF5FE",
                                }}
                              >
                                File Type
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  bgcolor: "#EFF5FE",
                                }}
                              >
                                Report
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  bgcolor: "#EFF5FE",
                                }}
                              >
                                Download
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportImage?.length > 0 &&
                              reportImage?.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    <img
                                      src={`${
                                        item.imgid?.split(".")?.pop() === "pdf"
                                          ? pdfImage
                                          : item.imgurl
                                      }`}
                                      alt="Report"
                                      style={{ width: "50px" }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      color: "#FF731D",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {reportImageName[index]}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    <IconButton
                                      onClick={() =>
                                        handleDownload(item?.imgurl)
                                      }
                                    >
                                      <FileDownloadIcon
                                        sx={{ color: "#FF731D" }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </ReportSection>
                  )}
                </TopLeftContainer>
              )}
            </BottomContainer>
          </MultiWrapper>
          <ButtonBox>
            {userAccess?.role !== "Guest" && (
              <>
                {detailData?.incidstatus !== "Open" && (
                  <Link to={`/record-incident/${params.id}`}>
                    {" "}
                    <RButton
                      sx={{ bgcolor: "#1746A2", textDecoration: "none" }}
                      variant={"contained"}
                    >
                      Record Incident
                    </RButton>
                  </Link>
                )}

                {detailData?.incidstatus === "Open" && (
                  <Link to={`/clear-incident/${params.id}`}>
                    {" "}
                    <RButton
                      onClick={handleClick}
                      sx={{
                        color: "#FF731D",
                        border: "2px solid #FF731D",
                        textDecoration: "none",
                      }}
                      variant={"outlined"}
                    >
                      Clear Incident
                    </RButton>
                  </Link>
                )}

                <ServicesModal status={detailData?.servstatus} id={params.id} />

                <RButton
                  onClick={() =>
                    handleDownloadPDF2(
                      `${vajralogo}`,
                      detailData?.qrimg,
                      detailData?.name,
                      detailData?.code,
                      detailData?.brand,
                      detailData?.calibdate,
                      detailData?.calibduedate,
                      detailData?.calibmnth,
                      detailData?.calibreq,
                      detailData?.calibstatus,
                      detailData?.cmcamc,
                      detailData?.cmcamcdue,
                      detailData?.cmcamcstart,
                      detailData?.dept,
                      detailData?.dop,
                      detailData?.imgs,
                      detailData?.wimg,
                      // wrntimg,
                      detailData?.serialno,
                      detailData?.incidstatus,
                      detailData?.lifeyrs,
                      detailData?.modelno,
                      detailData?.price,
                      detailData?.servstatus,
                      detailData?.soldby,
                      detailData?.status
                    )
                  }
                  sx={{ bgcolor: "#1746A2", color: "white" }}
                  variant={"contained"}
                >
                  Print pdf
                </RButton>
              </>
            )}
          </ButtonBox>
        </>
      )}

      {/* <DeleteAssetModal
        handleDelete={handleDelete}
        setDelOpen={setDelOpen}
        delOpen={delOpen}
        handleDelOpen={handleDelOpen}
        handleDelClose={handleDelClose}
      /> */}

      <DeleteModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={handleDelete}
        text={text}
        // id={getId}
      />

      <ViewImageModal
        imageUrl={zoomImageUrl}
        handleClose={handleImageClose}
        open={openImage}
      />

      {/* <ViewImageModal  imageUrl={certificateImage[count2]?.imgurl} handleClose={handleImageClose} open={openImage} /> */}
    </Main>
  );
}

export default AssetDetails;
