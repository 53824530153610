import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TableCell,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import google from "../images/google.png";
// import apple from "../images/apple.png";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRegister } from "../redux/data/action";
import Navbar4 from "../components/Navbar4";
import Footer2 from "../components/Footer2";
import RegisterModal from "../components/RegisterModal";
import { CropLandscapeOutlined } from "@mui/icons-material";

const MainDiv = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

//   width: "1030px",
//   // height: "1200px",
//   display: "flex",

//   padding: "20px",
//   boxSizing: " border-box",

//   marginTop: "100px",
//   left: "calc(50% - 1240px/2)",
//   textAlign: "center",
//   border: "2px solid rgba(255, 115, 29, 0.5)",
//   filter: "drop-shadow(0px 4px 4px #C7C7C7)",
//   borderRadius: "60px",
//   flexDirection: "column",

//   background: "#faf5ee",

//   [theme.breakpoints.down("xl")]: {
//     width: "90%",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "100%",
//   },
// }));

const SelectWrapper = styled(Select)(({ theme }) => ({
  // marginTop: "2%",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 4px 4px 0px #C7C7C7",
  width: "1030px",
  justifyContent: "center",
  alignItems: "center",
  // height: '1163px',
  paddingTop: "20px",
  paddingBottom: "20px",
  borderRadius: "60px",
  gap: "10px",
  border: "2px solid rgba(255, 115, 29, 0.50)",
  marginTop: "2%",
  // border:"1px solid green",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    paddingBottom: "30px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
    paddingBottom: "30px",
    // border:"1px solid blue",
  },
}));

const FeedbackBox = styled(Box)(({ theme }) => ({
  // border: "1px solid red",
  width: "100%",
  bgcolor: "#EFF5FE",
  display: "flex",

  height: "100px",
  borderRadius: "10px",
  marginTop: "1%",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "100px",
    // border: "1px solid red",
  },

  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100px",
    // border:"5px solid green"
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100px",
    // border:"1px solid blue"
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100px",
    // border:"1px solid black"
  },
}));

const BoxText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  marginLeft: "10px",
  width: "100%",
  overflowWrap: "break-word",
  fontWeight: "400",
  textAlign: "left",
  lineHeight: "24px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    lineHeight: "24px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    lineHeight: "24px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    lineHeight: "24px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    lineHeight: "24px",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "980px",

  // display:'flex',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",

    // border:"4px solid red",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    // border:"4px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    // border:"1px solid blue",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {
    width: "350px",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "980px",
  // border:"8px solid red",
  // display:'flex',

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",

    // border:"4px solid red",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    // border:"4px solid black",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    // border:"1px solid blue",
  },
  [theme.breakpoints.down("xs")]: {
    width: "350px",
  },
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "6px",
  border: "1px solid rgba(126, 126, 126, 0.3)",
  "& fieldset": { border: "none" },

  [theme.breakpoints.down("xl")]: {
    width: "100%",

    // border:"1px solid black"
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",

    // border:"5px solid green"
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",

    // border:"1px solid blue"
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    // border:"1px solid black"
  },
}));
const FormInput2 = styled(TextField)(({ theme }) => ({
  width: "75%",
  borderRadius: "6px",
  border: "1px solid rgba(126, 126, 126, 0.3)",
  "& fieldset": { border: "none" },

  [theme.breakpoints.down("xl")]: {
    width: "75%",

    // border:"1px solid black"
  },
  [theme.breakpoints.down("lg")]: {
    width: "75%",

    // border:"5px solid green"
  },
  [theme.breakpoints.down("md")]: {
    width: "75%",

    // border:"1px solid blue"
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",

    // border:"1px solid black"
  },
}));
const AddressInput = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "100%",
  "& fieldset": { border: "none" },
  [theme.breakpoints.down("xl")]: {
    width: "100%",

    // border:"1px solid black"
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",

    // border:"5px solid green"
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",

    // border:"1px solid blue"
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    // border:"1px solid black"
  },
}));
const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  // gridTemplateColumns: "repeat(2,1fr)",
  justifyContent: "space-between",
  justifyItems: "space-between",
  width: "100%",
  alignItems: "center",
  columnGap: "80px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    // gridTemplateColumns: "repeat(2,1fr)",
    justifyItems: "center",
  },
  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "repeat(1,1fr)",
    flexDirection: "column",
    width: "100%",
    justifyItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    // gridTemplateColumns: "repeat(1,1fr)",
    width: "100%",
    justifyItems: "center",
  },
  [theme.breakpoints.down("xs")]: {
    // gridTemplateColumns: "repeat(1,1fr)",
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "10px",
  bgcolor: "#EFF5FE",
  color: "#1746A2",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "380px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "1030px",
  // paddingBottom: "40px",
  // border: "4px solid yellow",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
    // border: "1px solid green",
  },
}));

const BtnWrapper2 = styled(Stack)(({ theme }) => ({
  // border: "none",
  display: "flex",
  width: "1030px",
  flexDirection: "row",
  gap: "2%",
  marginBottom: "2%",
  borderRadius: "15px",
  alignItems: "center",
  justifyContent: "center",
  height: "100px",
  // border: "1px solid green",

  //
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    // border: "1px solid green",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100px",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid blue",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "150px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid black",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column",
    padding: "2%",
    alignItems: "center",
    height: "100px",
    marginTop: "45px",
    // border: "1px solid yellow",
  },
  [theme.breakpoints.down("xs")]: {
    width: "50%",
  },
}));

const Main = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  paddingTop: "120px",
  justifyContent: "center",
  // marginBottom: "40px",
  alignItems: "center",
  width: "100%",

  height: "100%",
  // paddingBottom: "20px",
  [theme.breakpoints.down("xl")]: {
    paddingTop: "60px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "20px",
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    marginBottom: "10px",
  },
}));
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Register = () => {
  const registerdata = useSelector((store) => store.data.register);
  const isLoading = useSelector((store) => store.data.isLoading);

  let userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const margin = { marginTop: "50%" };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [hospitalName, setHospitalName] = useState("");
  const [hospitalNameError, setHospitalNameError] = useState(false);
  const [other, setOther] = useState("");
  const [cityName, setCityName] = useState("");
  const [cityNameError, setCityNameError] = useState("");

  const [hospitalType, setHospitalType] = useState("");
  const [hospitalTypeError, setHospitalTypeError] = useState("");

  const [stateName, setStateName] = useState("");
  const [stateNameError, setStateNameError] = useState("");
  const [otherError, setOtherError] = useState("");
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [branches, setBranches] = useState([
    { id: uuidv4().slice(-12), name: "", rid: "" },
  ]);

  const [branchName, setBranchName] = useState("");
  const [branchError, setBranchError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleChange = (e) => {
    setHospitalType(e.target.value);
  };
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const handleCityName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      setCityName(value);
      setCityNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setCityNameError("Invalid city name");
    }
    if (value === "") {
      setCityNameError("Please fill the field");
    }
  };

  const handleStateName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      setStateName(value);
      setStateNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setStateNameError("Invalid State name");
    }
    if (value === "") {
      setStateNameError("Please fill the field");
    }
  };

  const handleHospitalType = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      setHospitalType(value);
      setHospitalTypeError("");
      setOtherError("");
    }
    if (value !== "" && !regex.test(value)) {
      setHospitalTypeError("Invalid Hospital type");
    }
    if (value === "") {
      setHospitalTypeError("Please fill the field");
    }
    if (value === "Others") {
      setOtherError("Fill the type here");
    }
  };

  const handleOthers = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      if (hospitalType === "Others") {
        setOther(value);
        setOtherError("");
      }
    }
    if (value === "") {
      setOtherError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value.trim();

    const regex = /^\d{10}$/;

    setPhone(value);
    if (regex.test(value)) {
      setPhoneError("");
    }
    if (!regex.test(value)) {
      setPhoneError("Invalid phone number");
    }
    if (value === "") {
      setPhoneError("Please fill the field");
    }
  };

  const handleAddress = (e) => {
    const value = e.target.value;
    setAddress(value);
    if (!value) {
      setAddressError("Address is required");
    } else {
      setAddressError("");
    }
  };

  const handlePin = (e) => {
    const value = e.target.value.trim();
    setPin(value);
    const pinCodeRegex = /^\d{6}$/;
    if (!value || !pinCodeRegex.test(value)) {
      setPinError("Please enter valid pin");
    } else {
      setPinError("");
    }
  };

  // const handleBranch = (e) => {
  //   const value = e.target.value.trim();
  //   setBranch(value);
  //   const pinCodeRegex =/^[a-zA-Z0-9 ]+$/;
  //   if (!value || !pinCodeRegex.test(value)) {
  //     setBranchError("Please enter valid pin");
  //   } else {
  //     setBranchError("");
  //   }
  // };

  const handleBranch = () => {
    const trimmedBranchNames = branches.map((branch) => branch.name.trim());

    if (trimmedBranchNames.some((name) => name === "")) {
      setBranchError("Please fill the field.");
      return;
    }

    setBranches((prevBranches) => [
      ...prevBranches,
      { id: uuidv4().slice(-12), name: "", rid: "" },
    ]);
    setBranchError("");
  };

  const removeBranch = (idToRemove) => {
    setBranches((prevBranches) =>
      prevBranches.filter((branch) => branch.id !== idToRemove)
    );
    setBranchError("");
  };

  const handleInputChange = (id, value) => {
    const regex = /^[a-zA-Z0-9 ]+$/;

    const isNameDuplicate = branches.some(
      (branch) =>
        branch.id !== id &&
        branch.name.trim().toLowerCase() === value.trim().toLowerCase()
    );

    setBranches((prevBranches) =>
      prevBranches.map((branch) =>
        branch.id === id
          ? {
              ...branch,
              name: value,
              rid: `${value.replace(/\s/g, "").substring(0, 4)}${id}`,
            }
          : branch
      )
    );

    if (value === "" || regex.test(value)) {
      if (isNameDuplicate) {
        setBranchError("Branch with this name already exists.");
      } else {
        setBranchError("");
        setBranches((prevBranches) =>
          prevBranches.map((branch) =>
            branch.id === id
              ? {
                  ...branch,
                  name: value,
                  rid: `${value.replace(/\s/g, "").substring(0, 4)}${id}`,
                }
              : branch
          )
        );
      }
    } else {
      setBranchError("Please fill the field.");
    }
  };

  const handleRegister = () => {
    cityName === "" && setCityNameError("Please fill the field");
    hospitalType === "" && setHospitalTypeError("Please fill the field");
    stateName === "" && setStateNameError("Please fill the field");
    phone === "" && setPhoneError("Please fill the field");
    address === "" && setAddressError("Please fill the field");
    pin === "" && setPinError("Please fill the field");
    const trimmedBranchNames = branches.map((branch) => branch.name.trim());

    if (trimmedBranchNames.some((name) => name === "")) {
      setBranchError("Please fill the field.");
    }

    if (
      address !== "" &&
      cityName !== "" &&
      stateName !== "" &&
      pin !== "" &&
      phone.length === 10 &&
      phone !== "" &&
      hospitalType !== "" &&
      hospitalTypeError === "" &&
      addressError === "" &&
      cityNameError === "" &&
      stateNameError === "" &&
      otherError === "" &&
      pinError === "" &&
      phoneError === "" &&
      branchError === ""
    ) {
      let postData = {
        userid: userData.userid,
        hospid: userData.hospid,
        city: cityName.trim(),
        hosptype: hospitalType === "Others" ? other : hospitalType.trim(),
        state: stateName.trim(),
        mobile: phone.trim(),
        zip: pin.trim(),
        address: address.trim(),
        branchnames: branches?.map((item) => item.name),
        branchids: branches?.map((item) => item.rid),
      };

      dispatch(postRegister(postData));
    }
  };

  useEffect(() => {
    if (registerdata === "AUTHENTICATION_ERROR") {
      navigate("/signin");
      window.location.reload();
    }
  }, [registerdata]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRegister(e);
    }
  };

  return (
    <>
      <Wrapper sx={{ bgcolor: "white" }}>
        <Navbar4 />
        <Main>
          <MainDiv>
            <InnerContainer sx={{ bgcolor: "white" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Register
              </Typography>
              <Typography variant="h6">
                Please enter the Hospital details.
              </Typography>
              <TextContainer>
                <FormWrapper
                  sx={{
                    padding: "0px",
                  }}>
                  <FormElement>
                    <FormTextLabel>Hospital Type</FormTextLabel>
                    {/* <FormInput
                      size="small"
                      sx={{ bgcolor: "#EFF5FE" }}
                      placeholder="Hospital Type"
                      value={hospitalType}
                    /> */}
                    <SelectWrapper
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      placeholder="Hospital Type"
                      sx={{
                        textAlign: "start",

                        "& fieldset": { border: "none" },
                        border: "1px solid rgba(23, 70, 162, 0.2)",
                        bgcolor: "#EFF5FE",
                      }}
                      displayEmpty
                      value={hospitalType}
                      onChange={handleHospitalType}>
                      <MenuItem
                        placeholder="clinic"
                        value={""}
                        sx={{ color: "#a9a9a9" }}>
                        Select Hospital Type
                      </MenuItem>
                      <MenuItem value={"Super Speciality"}>
                        Super Speciality
                      </MenuItem>
                      <MenuItem value={"Multi Speciality"}>
                        Multi Speciality
                      </MenuItem>
                      <MenuItem value={"Clinic"}>Clinic</MenuItem>{" "}
                      <MenuItem value={"Private Hospital"}>
                        Private Hospital
                      </MenuItem>
                      <MenuItem value={"Government Hospital"}>
                        Government Hospital
                      </MenuItem>
                      <MenuItem value={"NGO"}>NGO</MenuItem>
                      <MenuItem value={"Nursing Home"}>Nursing Home</MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </SelectWrapper>
                    {<HelperText>{hospitalTypeError}</HelperText>}
                    {hospitalType === "Others" && (
                      <>
                        {" "}
                        <FormInput
                          size="small"
                          sx={{ bgcolor: "#EFF5FE" }}
                          value={other}
                          onChange={handleOthers}
                          placeholder="Enter the type"
                        />
                        {
                          <HelperText
                            sx={{
                              color:
                                otherError === "Fill the type here" &&
                                "#1746A2",
                            }}>
                            {otherError}
                          </HelperText>
                        }{" "}
                      </>
                    )}
                  </FormElement>

                  <FormElement>
                    <FormTextLabel>City</FormTextLabel>
                    <FormInput
                      size="small"
                      sx={{ bgcolor: "#EFF5FE" }}
                      placeholder="e.g. Los Angeles"
                      value={cityName}
                      onChange={handleCityName}
                      onKeyPress={handleKeyPress}
                    />
                    {<HelperText>{cityNameError}</HelperText>}
                  </FormElement>
                </FormWrapper>

                <FormWrapper>
                  <FormElement>
                    <FormTextLabel>State</FormTextLabel>
                    <FormInput
                      size="small"
                      sx={{ bgcolor: "#EFF5FE" }}
                      value={stateName}
                      onChange={handleStateName}
                      onKeyPress={handleKeyPress}
                      placeholder="State"
                    />
                    {<HelperText>{stateNameError}</HelperText>}
                  </FormElement>

                  <FormElement>
                    <FormTextLabel>Whatsapp Number</FormTextLabel>
                    <FormInput
                      size="small"
                      sx={{ bgcolor: "#EFF5FE" }}
                      value={phone}
                      onChange={handlePhone}
                      onKeyPress={handleKeyPress}
                      placeholder="Whatsapp Number"
                    />
                    {<HelperText>{phoneError}</HelperText>}
                  </FormElement>
                </FormWrapper>

                <FormWrapper sx={{ alignItems: "start" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                    }}>
                    <Box>
                      {branches?.map((branch, index) => (
                        <FormElement
                          key={branch.id}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <FormTextLabel>Branch</FormTextLabel>
                          <Box
                            sx={{ display: "flex",  }}>
                            <FormInput2
                              size="small"
                              style={{ background: "#EFF5FE" }}
                              placeholder="Branch"
                              onKeyPress={handleKeyPress}
                              value={branch.name}
                              onChange={(e) =>
                                handleInputChange(branch.id, e.target.value)
                              }
                            />
                            <Box
                              sx={{
                               
                                display: "flex",
                                flexDirection: "row",
                              }}>
                              {branches?.length > 1 && (
                                <IconButton
                                  onClick={() => removeBranch(branch.id)}>
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                              )}

                              {index === branches?.length - 1 && (
                                <IconButton
                                  onClick={handleBranch}
                                  sx={{ width: "40px", height: "40px" }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                          {index === branches?.length - 1 && (
                            <HelperText>{branchError}</HelperText>
                          )}
                        </FormElement>
                      ))}
                    </Box>
                    {/* <IconButton onClick={handleBranch} sx={{width:'40px', height:'40px'}}  > <AddCircleOutlineIcon /></IconButton> */}
                  </Box>

                  <FormElement>
                    <FormTextLabel>Zip</FormTextLabel>
                    <FormInput
                      size="small"
                      sx={{ bgcolor: "#EFF5FE" }}
                      placeholder="Zip"
                      value={pin}
                      onChange={handlePin}
                      onKeyPress={handleKeyPress}
                    />
                    {<HelperText>{pinError}</HelperText>}
                  </FormElement>
                </FormWrapper>

                <FormWrapper>
                  {/* <FormElement>
                    <FormTextLabel>Branch</FormTextLabel>
                    <FormInput
                    
                      size="small"
                      sx={{ bgcolor: "#EFF5FE",width:'90%' }}
                      placeholder="Branch"
                      value={branchName}
                      onChange={handleBranch}
                      onKeyPress={handleKeyPress}
                    />
                    {<HelperText>{branchError}</HelperText>}
                  </FormElement> */}
                </FormWrapper>
              </TextContainer>
              <BottomContainer>
                <FormElement sx={{ width: "100%" }}>
                  <FormTextLabel>Address </FormTextLabel>
                  <FeedbackBox
                    sx={{
                      bgcolor: "#EFF5FE",
                      border: "1px solid rgba(126, 126, 126, 0.3)",
                    }}>
                    <BoxText>
                      <AddressInput
                        size="small"
                        placeholder="e.g. 123 Main Street, City, Country"
                        multiline
                        maxRows={10}
                        value={address}
                        onChange={handleAddress}
                        onKeyPress={handleKeyPress}
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      />
                    </BoxText>
                  </FeedbackBox>
                  {<HelperText>{addressError}</HelperText>}
                </FormElement>
              </BottomContainer>
            </InnerContainer>
            <UploadContainer sx={{}}>
              <>
                <BtnWrapper2 sx={{}}>
                  <Button
                    onClick={handleRegister}
                    disabled={isLoading}
                    size="large"
                    sx={{
                      borderRadius: "35px",
                      textTransform: "none",
                      bgcolor: "#1746A2",
                    }}
                    variant="contained">
                    Submit
                  </Button>
                </BtnWrapper2>
              </>
            </UploadContainer>
          </MainDiv>
        </Main>
        <Footer2 />
      </Wrapper>
      <RegisterModal handleClose={handleClose} status={registerdata} />
    </>
  );
};

export default Register;
