import {
  Box,
  Button,
  FormControl,
  FormLabel,
  OutlinedInput,
  IconButton,
  Typography,
  styled,
  FormHelperText,
  ListItem,
  Badge,
  Stack,
  TextField,
  Snackbar,
  ListItemAvatar,
} from "@mui/material";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  assetDetailsData,
  clearIncident,
  clearIncidentImageRemove,
  clearIncidentImageUpload,
  getIncidentDetail,
  incidentIssue,
} from "../redux/nbmData/action";

import { useState } from "react";
import { v4 as uuid } from "uuid";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AddIcon from "@mui/icons-material/Add";

import SuccessModal from "../components/SuccessModal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MuiAlert from "@mui/material/Alert";
const MainDiv = styled(Box)(({ theme }) => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "80%",
  justifyContent: "center",
  alignItems: "center",
  padding: "50px",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "15px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const FeedbackBox = styled(Box)(({ theme }) => ({
  width: "100%",
  bgcolor: "#EFF5FE",
  display: "flex",
  alignItems: "center",
  height: "100px",
  borderRadius: "10px",
  marginTop: "1%",
  border: "1px solid red",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100px",
  },
}));
const BoxText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",

  width: "100%",
  overflowWrap: "break-word",
  fontWeight: "400",
  textAlign: "left",

  height: "100%",

  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    width: "100%",

    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
}));

const PhotoDiv = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-evenly",
  alignItems: "center",
  height: "183px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "160px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const PhotoMain = styled(Box)(({ theme }) => ({
  marginTop: "20px",

  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    width: "100%",

    gap: "10px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",

    gap: "10px",
    // flexDirection:"column",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",

    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    flexDirection: "column",
  },
}));

const PhotoCard = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",

  borderRadius: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100px",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "20px",
  // display:'flex',

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    height: "100%",
    width: "350px",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const InnerTop = styled(Box)(({ theme }) => ({
  width: "980px",
  display: "flex",
  marginBottom: "5px",
  justifyContent: "right",
  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
    width: "740px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "580px",
    marginTop: "0px",
  },
  [theme.breakpoints.down("md")]: {
    width: "350px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "350px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "100%",

  border: "1px solid rgba(23, 70, 162, 0.20)",
  "& fieldset": { border: "none" },
  borderRadius: "6px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const FormInputs = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "100%",
  border: "1px solid rgba(23, 70, 162, 0.20)",
  borderRadius: "6px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));
const FormWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  columnGap: "100px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("xs")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const PhotoInner = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  bgcolor: "#EFF5FE",
  color: "#1746A2",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  textTransform: "none",
  bgcolor: "#ADC2EC",

  color: "#1746A2",
  height: "60px",
  "&:hover": {
    backgroundColor: "#ADC2EC",
    color: "#1746A2",
  },
  [theme.breakpoints.down("xl")]: {
    height: "45px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "1000px",
  paddingBottom: "40px",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const UploadImageBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  display: "flex",
  alignItems: "center",

  borderRadius: "8px",
  boxShadow: "0px 0px 2px #EAF1FF",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: "8px",
  width: "40px",
  height: "40px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    marginLeft: "4px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const DotedBox = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ImageOuterBox = styled(Box)(({ theme }) => ({
  //

  height: "120px",
  boxShadow: "0px 0px 2px #EAF1FF",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Photo = styled(Box)(({ theme }) => ({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));
const BtnWrapper2 = styled(Stack)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "2%",
  marginBottom: "2%",
  borderRadius: "15px",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",

  //
  [theme.breakpoints.down("xl")]: {
    width: "100%",

    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "150px",

    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "150px",

    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
    padding: "2%",
    alignItems: "center",
    height: "100px",
    marginTop: "45px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));
const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const Item1 = styled(ListItemAvatar)(({ theme }) => ({
  wordWrap: "break-word",
  width: "40%",

  fontWeight: "500",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100px",
  },
}));

const Item2 = styled(ListItemAvatar)(({ theme }) => ({
  wordWrap: "break-word",
  width: "53%",
  marginLeft: "4%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "160px",
  },
}));

function ClearIncident() {
  let incidentDetail = useSelector((store) => store.nbmData.incidentDetails);
  let incidentIssues = useSelector((store) => store.nbmData.incidentIssue);
  const detailData = useSelector((store) => store.nbmData.assetDetails);
  const response = useSelector((store) => store.nbmData.clearIncident);

  const token = localStorage.getItem("userToken");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [nameError, setNameError] = useState(null);
  const [isMobileNumberError, setIsMobileNumberError] = useState(null);
  const [isEmailError, setIsEmailError] = useState(null);
  const [isActionError, setIsActionError] = useState(null);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: `${"assetId_"}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(clearIncidentImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${"assetId_"}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: `${"assetId_"}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(clearIncidentImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const incidId = localStorage.getItem("incidentId");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const text = "Incident cleared";

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    localStorage.removeItem("incidentId");
    navigate(-1);
  };

  // const handleName = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[a-z ,.'-]+$/i;

  //   if (value === "" || regex.test(value)) {
  //     setName(value);
  //     setNameError(null);
  //   }
  //   if (value !== "" && !regex.test(value)) {
  //     setNameError("wrong  Name");
  //   }
  //   if (value === "") {
  //     setNameError("Please fill the field");
  //   }
  // };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;
    if (value === "") {
      setName(value);
      setNameError("Please enter a valid Name");
    } else if (value.trim() === "") {
      setName(value);
      setNameError("Invalid name");
    } else if (regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };
  // const handleAction = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[a-z ,.'-]+$/i;

  //   if (value === "" || regex.test(value)) {
  //     setAction(value);
  //     setIsActionError(null);
  //   }
  //   if (value !== "" && !regex.test(value)) {
  //     setIsActionError("");
  //   }
  //   if (value === "") {
  //     setIsActionError("Please fill the field");
  //   }
  // };
  const handleAction = (e) => {
    const value = e.target.value;
    const regex = /^[a-z 0-9,.'-]+$/i;

    if (value === "") {
      setAction(value);
      setIsActionError("Please enter a valid action");
    } else if (value.trim() === "") {
      setAction(value);
      setIsActionError("Invalid action");
    } else if (regex.test(value)) {
      setAction(value);
      setIsActionError("");
    } else {
      setIsActionError("Invalid action");
    }
  };
  const handleNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^\+?[0-9]{10}$/;

    if (value === "" || regex.test(value)) {
      setMobile(value);
      setIsMobileNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsMobileNumberError("wrong  Mobile number");
    }
    if (value === "") {
      setIsMobileNumberError("Please fill the field");
    }
  };
  // const handleEmail = (e) => {
  //   const value = e.target.value.trim();
  //   const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

  //   if (value === "" || regex.test(value)) {
  //     setEmail(value);
  //     setIsEmailError(null);
  //   }
  //   if (value !== "" && !regex.test(value)) {
  //     setIsEmailError("wrong  email");
  //   }
  //   if (value === "") {
  //     setIsEmailError("Please fill the field");
  //   }
  // };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (value === "") {
      setEmail(value);
      setIsEmailError("Please enter a valid email");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setEmail(value);
      setIsEmailError(null);
    } else {
      setIsEmailError("Enter valid email");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: incidId,
      branchid: userBranch?.id,
    };

    dispatch(getIncidentDetail(data, token));
    dispatch(incidentIssue(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  const handleDelete = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };
    dispatch(clearIncidentImageRemove(data));
    setFileUrl(fileUrl?.filter((el) => el.imageid !== id));
  };
  // const handleClicks = () => {
  //   setSnackbarOpen(false);
  //   setSnackbarMessage("");
  //   name === "" && setNameError("Please fill the field");
  //   mobile === "" && setIsMobileNumberError("Please fill the field");
  //   email === "" && setIsEmailError("Please fill the field");

  //   action === "" && setIsActionError("Please fill the field");

  //   if (name !== "" && mobile !== "" && email !== "" && action !== "") {
  //     if (
  //       nameError === null &&
  //       isMobileNumberError === null &&
  //       isEmailError === null &&
  //       isActionError === null
  //     ) {
  //       let datas = {
  //         hospid: userIds.hospid,
  //         username: userIds.username,
  //         userid: userIds.userid,
  //         userrole: userAccess.role,
  //         assetid: incidentDetail.assetid,
  //         incidid: incidentDetail.incidt_id,
  //         asset_name: detailData.name,
  //         assetcode: detailData.code,
  //         model_no: detailData.modelno,
  //         serial_no: detailData.serialno,
  //         department: detailData.dept,
  //         incid_startdate: incidentDetail.start_date,
  //         incid_starttime: incidentDetail.start_time,
  //         servicer_name: name.trim(),
  //         serviced_phone: mobile.trim(),
  //         serviced_email: email.trim(),
  //         action_taken: action.trim(),
  //         serviced_feedback: remark.length > 0 ? remark.trim() : "NA",
  //         cleared_images:
  //           uploadedImageName?.length > 0 ? uploadedImageName : "NA",
  //         incid_issue: incidentIssues.incdt_issue,
  //       };
  //       setOpenModal(true);
  //       setToggle(true);
  //       dispatch(clearIncident(datas, token));
  //     }
  //   } else {
  //     setSnackbarMessage("Please fill in all the required fields.");
  //     setSnackbarOpen(true);
  //   }
  // };

  const handleClicks = () => {
    const trimmedName = name.trim();
    const trimmedMobile = mobile.trim();

    const trimmedEmail = email.trim();
    const trimmedAction = action.trim();

    if (
      trimmedName === "" ||
      trimmedMobile === "" ||
      trimmedEmail === "" ||
      trimmedAction === ""
    ) {
      setNameError(trimmedName === "" ? "Please enter a valid Name" : null);
      setIsMobileNumberError(
        trimmedMobile === "" ? "Please enter a valid mobile number" : null
      );

      setIsEmailError(
        trimmedEmail === "" ? "Please enter a valid email" : null
      );

      setIsActionError(
        trimmedAction === "" ? "Please enter a valid action" : null
      );

      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }

    if (name !== "" && mobile !== "" && email !== "" && action !== "") {
      let datas = {
        hospid: userIds.hospid,
        username: userIds.username,
        userid: userIds.userid,
        userrole: userAccess.role,
        assetid: incidentDetail.assetid,
        incidid: incidentDetail.incidt_id,
        asset_name: detailData.name,
        assetcode: detailData.code,
        model_no: detailData.modelno,
        serial_no: detailData.serialno,
        department: detailData.dept,
        incid_startdate: incidentDetail.start_date,
        incid_starttime: incidentDetail.start_time,
        servicer_name: trimmedName,
        serviced_phone: trimmedMobile,
        serviced_email: trimmedEmail,
        action_taken: trimmedAction,
        branchid: userBranch?.id,
        serviced_feedback: remark.length > 0 ? remark.trim() : "NA",
        cleared_images:
          uploadedImageName?.length > 0 ? uploadedImageName : "NA",
        incid_issue: incidentIssues.incdt_issue,
      };
      setOpenModal(true);
      setToggle(true);
      dispatch(clearIncident(datas, token));
    }
  };

  useEffect(() => {
    if (response === "Updated" && name !== "") {
      setOpenModal(true);
    }
  }, [response]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "downloaded_image.jpg";
    link.click();
  };
  return (
    <MainDiv>
      <TopContainer>
        <TitleHead>Clear Incident </TitleHead>
      </TopContainer>
      <InnerContainer sx={{ bgcolor: "white" }}>
        <TextContainer>
          <InnerTop></InnerTop>

          <FormWrapper sx={{ position: "static" }}>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Name</Item1>:<Item2>{detailData?.name}</Item2>
            </ListItem>

            <ListItem sx={{ width: "100% ", padding: "0px" }}>
              <Item1>Serial no</Item1>:<Item2>{detailData?.serialno}</Item2>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Department</Item1>:<Item2>{detailData?.dept}</Item2>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Asset code</Item1>:<Item2>{detailData?.code}</Item2>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Date</Item1>:<Item2>{incidentDetail?.start_date}</Item2>
            </ListItem>
            <Box></Box>
          </FormWrapper>
          <FormWrapper
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              marginTop: "4%",
            }}>
            <FormElement sx={{ paddingRight: "2%" }}>
              <FormTextLabel
                sx={{
                  fontWeight: "400",
                }}>
                Issue Details{" "}
              </FormTextLabel>
              <Box
                sx={{
                  border: "1px solid rgba(126, 126, 126, 0.3)",
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                }}>
                <Typography
                  sx={{
                    color: "black",
                    paddingLeft: "1%",

                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}>
                  {" "}
                  {incidentIssues?.incdt_issue}
                </Typography>
              </Box>
            </FormElement>
          </FormWrapper>
          <FormWrapper>
            <PhotoMain>
              {incidentIssues?.incdt_imgs !== "NA" &&
                incidentIssues?.incdt_imgs !== "" && (
                  <PhotoInner>
                    <FormTextLabel sx={{ color: "#212427", fontWeight: "400" }}>
                      Issue Images
                    </FormTextLabel>
                    <PhotoDiv sx={{}}>
                      {incidentIssues?.incdt_imgs?.map((images) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}>
                          <PhotoCard as="img" src={images} alt="incImage" />
                          <IconButton onClick={() => handleDownload(images)}>
                            {" "}
                            <FileDownloadIcon color="warning" />
                          </IconButton>
                        </Box>
                      ))}
                    </PhotoDiv>
                  </PhotoInner>
                )}
            </PhotoMain>
          </FormWrapper>
          <FormWrapper
            sx={{
              padding: "0px",
            }}>
            <FormElement
              sx={{
                padding: "0px",
              }}>
              <FormTextLabel required>Name</FormTextLabel>
              <FormInput
                size="medium"
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="e.g. Arun"
                onChange={handleName}
              />
              {<HelperText>{nameError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormTextLabel required>Mobile Number</FormTextLabel>
              <FormInput
                size="medium"
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="e.g. 8971580265"
                onChange={handleNumber}
              />
              {<HelperText>{isMobileNumberError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormTextLabel required>Email</FormTextLabel>
              <FormInput
                size="medium"
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="e.g. example@gmail.com"
                onChange={handleEmail}
              />
              {<HelperText>{isEmailError}</HelperText>}
            </FormElement>
          </FormWrapper>
        </TextContainer>
        <BottomContainer>
          <FormControl sx={{}}>
            <>
              <FormTextLabel required>Action Taken</FormTextLabel>
              <FeedbackBox
                sx={{
                  bgcolor: "#EFF5FE",
                  border: "1px solid rgba(126, 126, 126, 0.3)",
                }}>
                <BoxText>
                  <FormInputs
                    multiline
                    maxRows={4}
                    placeholder="e.g Battery replaced."
                    onChange={handleAction}
                    sx={{ "& fieldset": { border: "none" } }}
                  />
                </BoxText>
              </FeedbackBox>
              {<HelperText>{isActionError}</HelperText>}

              <>
                <FormTextLabel>Remarks</FormTextLabel>
                <FeedbackBox
                  sx={{
                    bgcolor: "#EFF5FE",
                    border: "1px solid rgba(126, 126, 126, 0.3)",
                  }}>
                  <BoxText>
                    <FormInputs
                      multiline
                      maxRows={4}
                      placeholder="e.g. The issue was promptly addressed."
                      onChange={(e) => setRemark(e.target.value)}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </BoxText>
                </FeedbackBox>
              </>
            </>
          </FormControl>
        </BottomContainer>
      </InnerContainer>
      <UploadContainer sx={{}}>
        <>
          <ButtonWrapper sx={{}}>
            <>
              <UploadButton
                onClick={() => setPhotoFlag(!photoFlag)}
                sx={{
                  bgcolor: "#ADC2EC",
                }}>
                <AddPhotoAlternateIcon sx={{ color: "#1746A2" }} />
                Upload Photos
              </UploadButton>
            </>
          </ButtonWrapper>
          {photoFlag && (
            <>
              <ButtonWrapper
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <ImageOuterBox
                  sx={{
                    textAlign: "start",
                    color: "#1746A2",
                  }}>
                  Upload photos
                  <UploadImageBox
                    sx={{ border: " 1px solid rgba(23, 70, 162, 0.2)" }}>
                    {uploadedImageName?.length < 3 && (
                      <>
                        <DotedBox>
                          <label for="img">
                            {" "}
                            <AddIcon
                              sx={{ marginTop: "2%", cursor: "pointer" }}
                            />
                          </label>
                        </DotedBox>
                        <input
                          type="file"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",

                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handlePhotoUpload}
                        />
                      </>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {fileUrl?.map((item, index) => (
                        <ImageBox key={index} className="image-item">
                          <Badge
                            sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                            badgeContent={
                              <RemoveCircleOutlineIcon
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  color: "red",
                                }}
                                onClick={() => handleDelete(item?.imageid)}
                              />
                            }>
                            <Photo as="img" src={item?.image} alt={""} />
                          </Badge>
                        </ImageBox>
                      ))}
                    </Box>
                  </UploadImageBox>
                  <Box style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    <>
                      {photoFlag && (
                        <FormHelperText sx={{ color: "red" }}>
                          format should be either in jpg, jpeg or png
                        </FormHelperText>
                      )}
                    </>
                    {isPhotoError && (
                      <FormHelperText sx={{ color: "red" }}>
                        {isPhotoError}
                      </FormHelperText>
                    )}
                  </Box>
                </ImageOuterBox>
              </ButtonWrapper>
            </>
          )}

          <BtnWrapper2 sx={{}}>
            <Button
              size="large"
              onClick={() => navigate(-1)}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                border: "1px solid #1746A2",
                color: "#1746A2",
                bgcolor: "white",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#3c52b2",
                },
              }}
              variant="contained">
              Cancel
            </Button>
            <Button
              disabled={userAccess?.role === "Guest"}
              size="large"
              onClick={handleClicks}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              variant="contained">
              Submit
            </Button>
          </BtnWrapper2>
        </>
      </UploadContainer>
      <SuccessModal
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        response={response}
        text={text}
        toggle={toggle}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainDiv>
  );
}

export default ClearIncident;
