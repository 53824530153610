import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TablePagination,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import user from "../images/user.png";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, allUserList, logOut } from "../redux/data/action";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";

import DeleteUserModal from "./DeleteUserModal";
import DialogBox from "./DialogBox";
import NoData from "./NoData";
import TableSort from "./TableSort";
import Pagination from "./Pagination";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  minHeight: "400px",

  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "500px",
    marginRight: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "16px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  // gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",
  "& fieldset": { border: "none" },
  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "180px",
    height: "35px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const CustomButton = styled(Button)(({ theme }) => ({
  width: "120px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));
const User = () => {
  const userData = useSelector((store) => store.data.usersList);
  const status = useSelector((store) => store.data.deleteUser);
  const adminStatus = useSelector((store) => store.data.updateSuperAdmin);
  const token = localStorage.getItem("userToken");
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [openModal, setOpenModal] = useState(false);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const [getId, setId] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedRow, setSelectedRow] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [filterChanged, setFilterChanged] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const role = [...new Set(userData?.map((item) => item.role))];

  const [data, setData] = useState([]);
  const select = [...new Set(userData?.map((item) => item?.departs))];

  const uniqueDepartments = [...new Set(select.flat())];

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories?.includes(status)
        ? prevCategories?.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = data;

    filteredProducts = filteredProducts.filter(
      (product) =>
        product.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        product.departs.some((dep) => selectedCategories.includes(dep))
      );
    }
    if (selectedStatus?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedStatus?.includes(product.role)
      );
    }

    return filteredProducts;
  };

  const filteredProducts = filterProducts();

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);

  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  useEffect(() => {
    if (userData && userData?.length > 0) {
      setData(userData);
    }
  }, [userData]);

  const filteredProductss = data?.filter((product) => {
    if (userData?.length === 0) {
      return true;
    } else {
      return userData?.some((value) => value === product?.role);
    }
  });

  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [text, setText] = useState("user?");
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleButtonClick3 = () => {
    if (userIds.subs_plan === "Basic" && userData?.length >= 1) {
      setOpenModal(true);
    } else {
      navigate("/addUser");
    }
  };

  const handleLogout = () => {
    let data = {
      userid: userIds.userid,
    };
    dispatch(logOut(data, token));
    // localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.getItem("userToken");
    navigate("/signin");
    localStorage.setItem("logoutEvent", Date.now().toString());
    window.location.reload();
  };

  const handleDelete = () => {
    // let data = {
    //   adminid: userIds.userid,
    //   userid: getId.id,
    //   hospid: userIds.hospid,
    //   branchid: userBranch.id,
    //   branchname: userBranch.branch,
    //   emailid: getId.email,
    // };
    // dispatch(deleteUser(data))
    //   .then(() => {
    //     let data = {
    //       adminid: userIds.userid,
    //       hosp_id: userIds.hospid,
    //       branchid: userBranch.id,
    //     };
    //     dispatch(allUserList(data, token));
    //   })
    //   .catch((error) => {});

    // handleCloseDeleteModal();

    if (getId.id === userIds.userid) {
      let data = {
        adminid: userIds.userid,
        userid: getId.id,
        hospid: userIds.hospid,
        branchid: userBranch.id,
        branchname: userBranch.branch,
        emailid: getId.email,
      };
      dispatch(deleteUser(data))
        .then(() => {
          let data = {
            adminid: userIds.userid,
            hosp_id: userIds.hospid,
            branchid: userBranch.id,
          };
          dispatch(allUserList(data, token));
        })
        .catch((error) => {});
      handleLogout();
      handleCloseDeleteModal();
    } else {
      let data = {
        adminid: userIds.userid,
        userid: getId.id,
        hospid: userIds.hospid,
        branchid: userBranch.id,
        branchname: userBranch.branch,
        emailid: getId.email,
      };
      dispatch(deleteUser(data))
        .then(() => {
          let data = {
            adminid: userIds.userid,
            hosp_id: userIds.hospid,
            branchid: userBranch.id,
          };
          dispatch(allUserList(data, token));
        })
        .catch((error) => {});

      handleCloseDeleteModal();
    }
  };

  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(allUserList(data, token));
  }, [dispatch, status, navigate]);

  const handleSave = (value) => {
    let data = {
      departs: value.departs,
      designate: value.designate,
      email: value.email,
      username: value.username,
    };

    localStorage.setItem("editData", JSON.stringify(data));
  };

  const handleDeleteById = (item) => {
    setId(item);
    handleOpenDeleteModal();
  };

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.username;
      case "date":
        return item.date;
      default:
        return "";
    }
  };
  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  return (
    <Wrapper>
      <TopContainer>
        <TitleBox>
          <TitleHead>User List</TitleHead>
          {data?.length > 0 && (
            <Typography sx={{ color: "#1746A2" }}>
              {" "}
              {`Total : ${data?.length}`}
            </Typography>
          )}
        </TitleBox>
        {userData?.length > 0 && (
          <SortFilterContainer sx={{}}>
            {/* <Input type="date" onChange={(e)=>setDate(e.target.value)} /> */}
            <Container>
              <Search
                type="search"
                value={searchQuery}
                placeholder="Search"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      placeholder="Search"
                      aria-label="toggle password visibility"
                      edge="start">
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Container>

            <div>
              <IconButton
                id="basic-button"
                aria-controls={openSort ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSort ? "true" : undefined}
                onClick={handleSort}>
                <SortByAlphaIcon sx={{ color: "#FF731D" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorElSort}
                open={openSort}
                onClose={handleCloseSort}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}>
                <MenuItem sx={{ color: "#FF731D" }}>Sort by Name</MenuItem>
                <MenuItem onClick={() => sortDataByKey("username", true)}>
                  A-Z
                </MenuItem>
                <MenuItem onClick={() => sortDataByKey("username", false)}>
                  Z-A
                </MenuItem>
              </Menu>
            </div>

            <div>
              <IconButton
                id="basic-button"
                aria-controls={openFilter ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openFilter ? "true" : undefined}
                onClick={handleFilter}>
                <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorElFilter}
                open={openFilter}
                onClose={handleCloseFilter}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}>
                <MenuItem>
                  <FilterMain>
                    <FilterText display="block">Department</FilterText>

                    <FilterInnerContainer>
                      <FormWrapper>
                        {uniqueDepartments?.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="warning"
                                type="checkbox"
                                value={item}
                                checked={selectedCategories.includes(item)}
                                onChange={handleCategoryChange}
                                name="gilad"
                              />
                            }
                            label={item}
                          />
                        ))}
                      </FormWrapper>
                    </FilterInnerContainer>
                  </FilterMain>
                </MenuItem>
                <MenuItem>
                  <FilterMain>
                    <FilterText display="block">Role</FilterText>
                    <FilterInnerContainer>
                      <FormWrapper>
                        {role?.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="warning"
                                type="checkbox"
                                value={item}
                                checked={selectedStatus.includes(item)}
                                onChange={handleStatusChange}
                                name="gilad"
                              />
                            }
                            label={
                              item === "Super_Admin"
                                ? "Super Admin"
                                : item === "Branch_Admin"
                                ? "Branch Admin"
                                : item === "Admin"
                                ? "Department Admin"
                                : item === "Guest"
                                ? "Guest"
                                : item
                            }
                          />
                        ))}
                      </FormWrapper>
                    </FilterInnerContainer>
                  </FilterMain>
                </MenuItem>
              </Menu>
            </div>
          </SortFilterContainer>
        )}
      </TopContainer>

      {userData?.length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: " 15px ",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}>
          <Cards>
            <CardMedia
              component="img"
              sx={{ width: 200 }}
              image={user}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                sx={{ color: " #212427" }}
                color="text.secondary"
                gutterBottom>
                No user found.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => navigate("/adduser")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                disabled={userAccess?.addprod !== "Yes"}
                variant="contained">
                Add User
              </Button>
            </CardActions>
          </Cards>
        </BoxWrapper>
      ) : filteredProducts?.length === 0 ? (
        <>
          <NoData text={"user"} />
        </>
      ) : (
        <>
          <BoxWrapper style={{ overflowX: "auto" }}>
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "table",
                  tableLayout: "fixed",
                }}>
                <Table
                  sx={{
                    borderRadius: " 15px",
                    bgcolor: "white",
                    // border: "6px solid green",
                    whiteSpace: "nowrap",
                  }}>
                  <TableHead
                    // style={{ borderRadius: "30px" }}
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                    }}>
                    <TableRow
                      sx={{
                        borderRadius: " 15px 15px 0px 0px",
                        bgcolor: "rgba(40, 98, 212, 0.1)",
                      }}>
                      <TableHeading
                        sx={{
                          borderRadius: "15px 0px 0px 0px",
                        }}>
                        <TableSortLabel
                          active={true}
                          style={{ color: "#1746A2" }}
                          direction={sortDirection.name}
                          onClick={() => handleSortTable("name")}>
                          Name
                        </TableSortLabel>
                      </TableHeading>
                      <TableHeading sx={{}}>Role</TableHeading>
                      <TableHeading sx={{}}>Department</TableHeading>
                      <TableHeading sx={{}}>Designate</TableHeading>

                      <TableHeading sx={{}}>Email</TableHeading>
                      <TableHeading sx={{}}>Edit</TableHeading>
                      <TableHeading sx={{}}>Delete</TableHeading>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            "&:hover": {
                              backgroundColor: "#E8ECF6",
                            },
                          }}>
                          <TableRows>{item?.username}</TableRows>

                          <TableRows sx={{}}>
                            {item?.role === "Guest"
                              ? "Guest"
                              : item.role === "Admin"
                              ? "Department Admin"
                              : item?.role.replace(/_/g, " ")}
                          </TableRows>
                          <TableRows sx={{}}>
                            {" "}
                            {item?.departs && item.departs.join(", ")}
                          </TableRows>
                          <TableRows sx={{}}>{item?.designate}</TableRows>
                          <TableRows sx={{}}>{item?.email}</TableRows>

                          <TableRows>
                            <IconButton
                              disabled={
                                userAccess?.edituser !== "Yes" ||
                                (userAccess.role === "Owner" &&
                                  item?.role === "Super_Admin") ||
                                (userAccess.role === "Super_Admin" &&
                                  item?.role === "Super_Admin") ||
                                (userAccess.role === "Admin" &&
                                  item?.role === "Super_Admin") ||
                                (userAccess.role === "Admin" &&
                                  item?.role === "Branch_Admin") ||
                                (userAccess.role === "Admin" &&
                                  userAccess.departmentids !== "all" &&
                                  item?.role === "Admin") ||
                                (userAccess.role === "Admin" &&
                                  userAccess.departmentids !== "all" &&
                                  item?.role === "Guest") ||
                                (userAccess.role !== "Guest" &&
                                  userIds?.userid === item?.userid)
                              }>
                              <Link to={`/user/${item?.userid}`}>
                                <span
                                  onClick={() => handleSave(item)}
                                  style={{ cursor: "pointer" }}>
                                  <EditIcon
                                    sx={{
                                      color:
                                        userAccess?.edituser !== "Yes" ||
                                        userAccess?.edituser !== "Yes" ||
                                        (userAccess.role === "Owner" &&
                                          item?.role === "Super_Admin") ||
                                        (userAccess.role === "Super_Admin" &&
                                          item?.role === "Super_Admin") ||
                                        (userAccess.role === "Admin" &&
                                          item?.role === "Super_Admin") ||
                                        (userAccess.role === "Admin" &&
                                          item?.role === "Branch_Admin") ||
                                        (userAccess.role === "Admin" &&
                                          userAccess.departmentids !== "all" &&
                                          item?.role === "Admin") ||
                                        (userAccess.role === "Admin" &&
                                          userAccess.departmentids !== "all" &&
                                          item?.role === "Guest") ||
                                        (userAccess.role !== "Guest" &&
                                          userIds?.userid === item?.userid)
                                          ? "rgb(196, 206, 196)"
                                          : "#FF731D",
                                    }}
                                  />
                                </span>
                              </Link>
                            </IconButton>
                          </TableRows>

                          <TableRows>
                            <IconButton
                              disabled={
                                userAccess?.role === "Guest" ||
                                (userAccess.role === "Admin" &&
                                  item?.role === "Super_Admin") ||
                                (userAccess.role === "Admin" &&
                                  item?.role === "Branch_Admin") ||
                                (userAccess.role === "Admin" &&
                                  userAccess.departmentids !== "all" &&
                                  item?.role === "Admin" &&
                                  userIds?.userid !== item?.userid) ||
                                (userAccess.role === "Admin" &&
                                  userAccess.departmentids !== "all" &&
                                  item?.role === "Guest")
                              }>
                              <span
                                onClick={() => handleDeleteById(item)}
                                style={{ cursor: "pointer" }}>
                                <DeleteForeverIcon
                                  sx={{
                                    color:
                                      userAccess?.role === "Guest" ||
                                      (userAccess.role === "Admin" &&
                                        item?.role === "Super_Admin") ||
                                      (userAccess.role === "Admin" &&
                                        item?.role === "Branch_Admin") ||
                                      (userAccess.role === "Admin" &&
                                        userAccess.departmentids !== "all" &&
                                        item?.role === "Admin" &&
                                        userIds?.userid !== item?.userid) ||
                                      (userAccess.role === "Admin" &&
                                        userAccess.departmentids !== "all" &&
                                        item?.role === "Guest")
                                        ? "rgb(196, 206, 196)"
                                        : "#FF731D",
                                  }}
                                />
                              </span>
                            </IconButton>
                          </TableRows>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
              {userData?.length > rowsPerPage && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={userData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Box>
          </BoxWrapper>
          <Box sx={{ marginTop: "3%" }}>
            <CustomButton
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor:
                  userIds.subs_plan === "Basic" && userData?.length >= 1
                    ? "#FF731D"
                    : "#1746A2",

                "&:hover": {
                  background:
                    userIds.subs_plan === "Basic" && userData?.length >= 1
                      ? "#FF731D"
                      : "#1746A2",
                },
              }}
              disabled={
                userAccess?.adduser !== "Yes" || userAccess?.adduser === "Guest"
              }
              onClick={handleButtonClick3}
              variant="contained">
              Add User
            </CustomButton>
          </Box>
        </>
      )}
      <DeleteUserModal
        onDelete={handleDelete}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        text={text}
      />
      <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
    </Wrapper>
  );
};

export default User;
