import { useEffect, useState } from "react";
import { getUserData, userDetailCheck } from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";

const Checking = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setEmailError] = useState("");
  // const [loading, setLoading] = useState(false);
  const userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const response = useSelector((store) => store.data.userDetailCheck);
  const userList = useSelector((store) => store.data.userData);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  // Validation function
  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  useEffect(() => {
    let data = {
      userid: userData.userid,
      hosp_id: userData.hospid,
      branchid: userBranch.id,
    };
    dispatch(getUserData(data));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(response).length === 0) {
      // Email doesn't exist, show no error message
      setEmailError("");
      // Clear the name input when there is no response
      setName("");
    } else {
      if (response.role === "Owner" || response.role === "Super_Admin") {
        // Email already exists, show error message
        setEmailError("User already exists");
      } else if (response.role !== "Owner" || response.role !== "Super_Admin") {
        // Check if the user with the same email exists in the user list
        const existingUser = userList.find(
          (user) =>
            user.email &&
            user.email.toLowerCase() === response.email.toLowerCase()
        );


        if (existingUser) {
          // Check if branch ids match
          const branchIdFromLocalStorage = userBranch.id;
          const branchIdsFromResponse = response.bm_branchids;

          // Check if any branch id from the response matches the branch id from local storage
          const branchIdMatch = branchIdsFromResponse.some(
            (id) => id === branchIdFromLocalStorage
          );

          if (branchIdMatch) {
            // Branch IDs match, show user already exists
            setEmailError("User already exists");
          } else {
            // Same email exists but different branch, allow editing
            setEmailError("");
            // Auto-fill the name if the branch IDs don't match
            setName(existingUser.username);
          }
        } else {
          // User not found in the user list, allow editing
          setEmailError("");
          // If no user in the list, set the name from response (if response has a username)
          setName(response.username || "");
        }
      } else {
        // For other roles, allow editing
        setEmailError("");
      }
    }
    //  setLoading(false); // Set loading to false when response is received
  }, [response, userList]);

  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (newEmail.length === 0) {
      // Email is empty, no need to make the API call
      setEmailError(""); // Clear the error message
      setName(""); // Clear the name input
      return;
    }

    try {
      if (!validateEmail(newEmail)) {
        setEmailError("Invalid email format");
        setName(""); // Clear the name input
        return;
      }

      let data = {
        adminid: userData.userid,
        emailid: newEmail,
        hospid: userData.hospid,
        branchid: userBranch?.id,
      };

      // Set loading to true before making the API call
      //setLoading(true);

      // Call your API to check if email already exists
      await dispatch(userDetailCheck(data));
    } catch (error) {
      console.error("Error checking email:", error);
      //setLoading(false); // Set loading to false in case of an error
    }
  };

  return (
    <div>
      <label>Email:</label>
      <input type="email" value={email} onChange={handleEmailChange} />

      <br />

      <label>Name:</label>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={
          email.length === 0 || !validateEmail(email) || errorMessage !== ""
        }
      />

      {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
    </div>
  );
};

export default Checking;
