import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

import styled from "@emotion/styled";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

const Main = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "140px",
  width: "410px",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "270px",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const ReImage = styled(Box)(({ theme }) => ({
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bgcolor: "#FEE4E2",
  borderRadius: "50%",
  height: "40px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

export default function UserLogOut({
  handleCloseLogout,
  handleLogout,
  openLogout,
}) {
  return (
    <div>
      <Modal
        keepMounted
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style}>
          <Main>
            <TopBox>
              <ReImage sx={{ bgcolor: "#FEE4E2" }}>
                <LogoutOutlinedIcon sx={{ color: "#D92D20" }} />
              </ReImage>

              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    textAlign: "left",
                    fontSize: "18px",
                  }}>
                  Signing Out
                </Typography>

                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}>
                  Are you sure you want to Sign out this Session?
                </Typography>
              </Box>
            </TopBox>

            <Box
              sx={{
                color: "red",
                width: "100%",
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                marginTop: "10px",
              }}>
              <Button
                variant="outlined"
                onClick={handleCloseLogout}
                sx={{
                  borderRadius: "10px",

                  textTransform: "none",
                  color: "#344054",
                }}
                size="medium">
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={handleLogout}
                sx={{
                  borderRadius: "10px",

                  textTransform: "none",
                  bgcolor: "#D92D20",
                }}
                size="medium">
                Sign Out
              </Button>
            </Box>
          </Main>
        </Box>
      </Modal>
    </div>
  );
}
