import React from "react";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Home from "../pages/Home";
import Plans from "../pages/Plans";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import Items from "../pages/Items";
import Register from "../pages/Register";
import HomeContact from "../components/HomeContact";
import TermsAndService from "../components/TermsAndService";
import PrivacyPolicy from "../components/PrivacyPolicy";
import ServiceSupport from "../components/ServiceSupport";
import FAQ from "../components/FAQ";
import Disclaimer from "../components/Disclaimer";

function PublicRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<SignIn />} />
        <Route path="/" element={<Home />} />
        <Route path="/how-vajra-works" element={<Home />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/register" element={<Register />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/items" element={<Items />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<HomeContact />} />
        <Route path="/terms-and-conditions" element={<TermsAndService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route
          path="/biomedical-services-support"
          element={<ServiceSupport />}
        />
      </Routes>
    </>
  );
}

export default PublicRoute;
