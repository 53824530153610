import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import deleteimg from "../images/imageDel.png";

function ImageDeleteModal({ handleClickOpen, handleClose, open }) {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dialogStyle = {
    width: isSmallScreen ? "80vw" : "35vw",
    height: "140px",
    borderRadius: "8px",
  };

  const imageStyle = {
    width: "55px",
    height: "55px",
    marginRight: "2%",
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        // onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        PaperProps={{ style: isSmallScreen ? dialogStyle : dialogStyle }}>
        <DialogTitle id="alert-dialog-title" sx={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              marginRight: "2%",
              alignItems: "center",
              width: "100%",
            }}>
            <img src={deleteimg} alt="Delete Icon" style={imageStyle} />

            <DialogContent
              sx={{
                width: "100%",
                padding: "0px",
                paddingLeft: "2%",
                paddingTop: "2%",
              }}>
              <DialogContentText
                id="alert-dialog-description"
                style={{ width: "100%", color: "#3C424F" }}>
                Image deleted please click update button to update.
              </DialogContentText>
            </DialogContent>
          </div>
        </DialogTitle>

        <DialogActions
          sx={{
            width: "95%",

            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}>
          <Button
            onClick={handleClose}
            size="large"
            variant="contained"
            style={{
              color: "white",
              borderRadius: "37px",
              height: "30px",
              marginRight: "2%",
              textTransform: "none",
              backgroundColor: "#1746A2",
              marginBottom: "2%",
            }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
export default ImageDeleteModal;
