import React, { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Routes } from "react-router-dom";
import SignIn from "../pages/SignIn";
import Dashboard from "../pages/Dashboard";
import Register from "../pages/Register";
import User from "../components/User";
import AddUser from "../components/AddUser";
import { Edit } from "@mui/icons-material";
import EditPage from "../components/EditPage";
import SideBarPage from "../components/SideBarPage";
import IncidentList from "../pages/IncidentList";
import IncidentDetails from "../pages/IncidentDetails";
import AssetDetails from "../pages/AssetDetails";
import Main from "../pages/Main";
import Callibration from "../pages/Callibration";
import EditDepart from "../pages/EditDepart";
import AllAsset from "../pages/AllAsset";
import ServiceMedpick from "../pages/ServiceMedpick";
import ServiceOther from "../pages/ServiceOther";
import OpenServices from "../pages/OpenServices";
import ServiceList from "../pages/ServiceList";
import ServiceDetailPage from "../pages/ServiceDetailPage";
import ClearService from "../pages/ClearServicePage";
import ClearServicePage from "../pages/ClearServicePage";
import Department from "../pages/Department";
import EditAssetPage from "../pages/EditAssetPage";
import Contact from "../pages/Contact";
import Profile from "../pages/Profile";
import ProfilePage from "../pages/ProfilePage";
import Report from "../pages/Report";
import PmCheckList from "../pages/PmCheckList";
import UserManual from "../pages/UserManual";
import ClearIncident from "../pages/ClearIncident";
import RecordIncident from "../pages/RecordIncident";
import CalibrateNotRequired from "../pages/CalibrateNotRequired";
import NotCalibrated from "../pages/NotCalibrated";
import DepartmentAssetDetails from "../pages/DepartmentAssetDetails";
import CloseService from "../pages/CloseService";
import ClosedIncident from "../pages/ClosedIncident";
import OpenIncident from "../pages/OpenIncident";
import WarrantyStatus from "../pages/WarrantyStatus";
import AddDep from "../pages/AddDep";
import PmCheckListDemo from "../pages/PmCheckListDemo";
import {
  allUserList,
  getUserData,
  logOut,
  profile,
  retrieveAllAssets,
} from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";
import ReloginModal from "../components/ReloginModal";
import { Typography } from "@mui/material";
import ChooseBmOrNbm from "../pages/ChooseBmOrNbm";
import Depreciation from "../pages/Depreciation";
import { BulkUploadAsset } from "../pages/BulkUploadAsset";
import { BulkUploadDepartment } from "../pages/BulkUploadDepartment";
import AmcCmc from "../pages/AmcCmc";
import Plans from "../pages/Plans";

import Amc from "../pages/Amc";
import Cmc from "../pages/Cmc";
import AssetHistory from "../pages/AssetHistory";
import AllAssetHistory from "../pages/AllAssetHistory";

import TaskPage from "../pages/TaskPage";
import Vendor from "../pages/Vendor";
import ServiceComanies from "../pages/ServiceCompanies";
import VendorRelations from "../pages/VendorRelations";
import CheckinOut from "../pages/CheckinOut";
import CheckinOutDetailPage from "../pages/CheckinOutDetailPage";
import Calender from "../pages/Calender";
import AddAsset from "../pages/AddAsset";
import AssetPage from "../pages/AssetPageNotification";
import IncidentPage from "../pages/IncidentPageNotification";
import ServicePage from "../pages/ServicePageNotification";
import NotificationTab from "../pages/NotificationTab";
import Scan from "../pages/Scan";
import AssetDetailsCheck from "../pages/AssetDetailCheck";
import UserManualDetails from "../pages/UserManualDetails";
import PmChoose from "../pages/PmChoose";
import AccessCheckModal from "./../components/AccessCheckModal";
import DepartmentAssetDelete from "../pages/DepartmentAssetDelete";

function PrivateRoute() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const invalidToken = useSelector((store) => store?.data?.invalidToken);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let checkAccess = userAccess === null ? false : userAccess.role === "NA";

  const authenticationError = useSelector(
    (store) => store?.data?.authenticationError
  );
  const allAssets = useSelector((store) => store.data.allAssets);
  const userData = useSelector((store) => store.data.usersList);

  useEffect(() => {
    if (authenticationError === "AUTHENTICATION_ERROR" || !userBranch) {
      let data = {
        userid: userIds.userid,
      };
      dispatch(logOut(data, token));
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  }, [authenticationError, userBranch]);

  React.useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch?.id,
    };
    dispatch(allUserList(data, token));
  }, [dispatch]);

  // console.log("userData", userData);
  return (
    <>
      {invalidToken === "INVALID_TOKEN" ? (
        <ReloginModal status={invalidToken} />
      ) : (
        authenticationError !== "AUTHENTICATION_ERROR" &&
        (checkAccess ? (
          <AccessCheckModal status={checkAccess} text="Biomedical" />
        ) : (
          <Routes>
            <Route path="*" element={<Dashboard />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/home" element={<Dashboard />} />
            <Route path="/profile" element={<ProfilePage />} />

            {(userIds.role === "Super_Admin" || userIds.role === "Owner") && (
              <Route path="/edit-profile" element={<Profile />} />
            )}

            <Route path="/report" element={<Report />} />
            <Route path="/user" element={<User />} />
            <Route path="/user/:id" element={<EditPage />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard" ||
              (userIds.subs_plan === "Basic" && userData?.length < 1)) && (
              <Route path="/adduser" element={<AddUser />} />
            )}
            <Route path="/incident-list" element={<IncidentList />} />
            <Route path="/incident-list/:id" element={<IncidentDetails />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard" ||
              (userIds.subs_plan === "Basic" && allAssets?.length <= 49)) && (
              <Route path="/add-asset" element={<AddAsset />} />
            )}
            <Route path="/asset-detail/:id" element={<AssetDetailsCheck />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard") && (
              <Route path="/user-manual" element={<UserManual />} />
            )}
            <Route path="/department" element={<Main />} />
            <Route path="/calibration" element={<Callibration />} />
            <Route path="/not-calibrated" element={<NotCalibrated />} />
            <Route
              path="/calibration-not-required"
              element={<CalibrateNotRequired />}
            />
            <Route path="/add-dep" element={<AddDep />} />
            <Route path="/add-department" element={<Department />} />
            <Route path="/editDepart" element={<EditDepart />} />
            <Route path="/all-assets" element={<AllAsset />} />
            <Route path="/service-medpick/:id" element={<ServiceMedpick />} />
            <Route path="/service-others/:id" element={<ServiceOther />} />
            <Route path="/closed-service" element={<CloseService />} />
            <Route path="/closed-incident" element={<ClosedIncident />} />
            <Route path="/open-incident" element={<OpenIncident />} />
            <Route path="/open-service" element={<OpenServices />} />
            <Route path="/service-list" element={<ServiceList />} />
            <Route path="/service-list/:id" element={<ServiceDetailPage />} />
            <Route path="/clear-service/:id" element={<ClearServicePage />} />
            <Route path="/edit-asset/:id" element={<EditAssetPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pm-checklist" element={<PmChoose />} />
            <Route path="/pm-checklist/:id" element={<PmCheckList />} />
            <Route path="/depreciation" element={<Depreciation />} />
            {/* <Route path="/pm-checklist-demo" element={<PmCheckListDemo />} /> */}
            <Route path="/clear-incident/:id" element={<ClearIncident />} />
            <Route path="/record-incident/:id" element={<RecordIncident />} />
            <Route
              path="/department-asset-details/:id"
              element={<DepartmentAssetDetails />}
            />
            <Route
              path="/department-asset-delete-details/:id"
              element={<DepartmentAssetDelete />}
            />
            <Route path="/warranty-status/:id" element={<WarrantyStatus />} />
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard") && (
              <Route path="/bulk-upload-asset" element={<BulkUploadAsset />} />
            )}
            {(userIds.subs_plan === "Premium" ||
              userIds.subs_plan === "Standard") && (
              <Route
                path="/bulk-upload-department"
                element={<BulkUploadDepartment />}
              />
            )}
            <Route path="/amc-cmc" element={<AmcCmc />} />
            <Route path="/amc" element={<Amc />} />
            <Route path="/cmc" element={<Cmc />} />
            <Route path="/plans" element={<Plans />} />
            {<Route path="/asset-history" element={<AssetHistory />} />}
            {(userIds.role === "Super_Admin" || userIds.role === "Owner") && (
              <Route path="/asset-history-list" element={<AllAssetHistory />} />
            )}

            <Route path="/task" element={<TaskPage />} />
            <Route path="/vendor-relations/:id" element={<VendorRelations />} />
            <Route path="/checkin-checkout/:id" element={<CheckinOut />} />
            <Route
              path="/checkin-checkout-details/:id"
              element={<CheckinOutDetailPage />}
            />
            <Route path="/calendar" element={<Calender />} />
            {/* <Route path="/asset-notification/:id" element={<AssetPage />} />
            <Route path="/incident-notification" element={<IncidentPage />} />
            <Route path="/service-notification" element={<ServicePage />} /> */}
            <Route path="/notification" element={<NotificationTab />} />
            <Route path="/manual-details/:id" element={<UserManualDetails />} />
            <Route path="/scan" element={<Scan />} />
          </Routes>
        ))
      )}
    </>
  );
}

export default PrivateRoute;
