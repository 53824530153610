import {
  Box,
  FormControl,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  FormLabel,
  Typography,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Footer2 from "../components/Footer2";
import Navbar4 from "../components/Navbar4";
import { useDispatch } from "react-redux";
import { forgotAuth } from "../redux/auth/action";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { useMediaQuery } from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import Progress from "../components/Progress";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  width: "300px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const InnerDiv = styled(Box)(({ theme }) => ({
  width: "630px",
  height: "500px",

  [theme.breakpoints.down("xl")]: {
    width: "530px",
    height: "450px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "480px",
    height: "450px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  width: "460px",

  [theme.breakpoints.down("xl")]: {
    width: "370px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  width: "460px",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "370px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    height: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    height: "90%",
  },
}));

const Main = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  paddingTop: "120px",
  justifyContent: "center",
  marginBottom: "40px",
  alignItems: "center",
  width: "100%",

  height: "100%",
  // paddingBottom: "20px",
  [theme.breakpoints.down("xl")]: {
    marginBottom: "30px",
    paddingTop: "60px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "20px",
    flexDirection: "column",
    marginBottom: "10px",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    marginBottom: "10px",
  },
}));

const Previous = styled(Button)(({ theme }) => ({
  border: "0px solid transparent",
  textTransform: "none",
  height: "30px",
  fontSize: "16px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PreviousContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",

  justifyContent: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

function ForgotPassword() {
  const status = useSelector((store) => store.auth.forgotAuth);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const isLoading = useSelector((store) => store.auth.isLoading);

  const handleEmail = (e) => {
    const value = e.target.value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value === "" || regex.test(value.trim())) {
      setEmail(value.trim());
      setError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setError("wrong email");
    }
    if (value === "") {
      setError("Please fill the field");
    }
  };

  const handleClick = () => {
    // alert("reached")
    if (email !== "" && error === "") {
      // setError("");
      let data = {
        email: email,
      };

      dispatch(forgotAuth(data));
    }
    // if (email === "") {
    //   setError("Please enter your email.");
    // }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };

  return (
    <Wrapper sx={{ bgcolor: "white" }}>
      <Navbar4 />

      <Main>
        <InnerDiv
          sx={{
            borderRadius: "60px",
            boxShadow: "0px 4px 4px #C7C7C7",
            border: "1px solid rgba(255, 115, 29, 0.5)",
            margin: "auto",
            display: "grid",
            gridTemplateRows: "repeat(4,1fr)",
          }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: {
                xl: "32px",
                lg: "30px",

                md: "28px",
                sm: "24px",
                xs: "22px",
              },

              marginTop: "7%",
              color: "#1746A2",
            }}>
            {" "}
            Forgot Password
          </Typography>

          <Box
            sx={{
              margin: "auto",
              paddingBottom: "10px",
              width: {
                xl: "490px",
                lg: "360px",
                md: "360px",
                sm: "245px",
                xs: "90%",
              },
              height: {
                xl: "84px",
                lg: "60px",
                md: "60px",
                sm: "45px",
                xs: "45px",
              },
            }}>
            <Typography
              sx={{
                fontWeight: 500,

                fontSize: {
                  xl: "24px",
                  lg: "20px",
                  md: "20px",
                  sm: "14px",
                  xs: "14px",
                },
              }}>
            Please enter your email address to receive a verification code
            </Typography>
          </Box>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <CustomBox sx={{}}>
              <FormLabel sx={{ textAlign: "left", color: "#1746A2" }}>
                Email
              </FormLabel>
              <CustomInput
                size="small"
                placeholder="e.g. example@gmail.com"
                onChange={handleEmail}
                onKeyPress={handleKeyPress}
                sx={{
                  bgcolor: "#EFF5FE",
                  marginTop: "2%",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <EmailOutlinedIcon sx={{ color: "#787878" }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <HelperText>{error}</HelperText>
            </CustomBox>

            <Box sx={{ marginBottom: "40px" }}></Box>
            <Button
              variant="contained"
              disabled={isLoading}
              size="large"
              sx={{
                bgcolor: "#1746A2",
                textTransform: "none",
                width: "200px",
                color: "white",
                fontWeight: 500,
                fontSize: "16px",
                height: "40px",
                margin: "auto",
                borderRadius: "40px",
              }}
              onClick={handleClick}>
              Send
            </Button>
            {isLoading && <Progress />}

            <Typography
              onClick={() => navigate("/signin")}
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                marginTop: "10px",
                cursor: "pointer",

                color: "#004F95",
                "&:hover": {
                  transform: "scale3d(1.05, 1.05, 2)",
                  cursor: "pointer",
                  color: "#004F95",
                },
              }}>
              Back to <span style={{ color: "#F78117" }}>Sign in</span>
            </Typography>
          </FormControl>

          {!error && status.success && (
            <Typography
              sx={{
                fontWeight: "500",
                width: "90%",
                margin: "auto",
                fontSize: {
                  xl: "20px",
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },

                color: "#008D6F",
                marginTop: "8%",
                paddingBottom: "10px",
              }}>
              “Password Reset link has been Send to your Registered email ID”
            </Typography>
          )}

          {(!error && status.message) === "EMAIL_NOT_FOUND" && (
            <Typography
              sx={{
                fontWeight: "500",

                fontSize: {
                  xl: "20px",
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },

                color: "red",
                marginTop: "8%",
              }}>
              This email is not registered yet!
            </Typography>
          )}
          {(!error && status.message) === "The email address is invalid." && (
            <Typography
              sx={{
                fontWeight: "500",

                fontSize: {
                  xl: "20px",
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },

                color: "red",
                marginTop: "8%",
              }}>
              Not a valid email.
            </Typography>
          )}
          {error && (
            <Typography
              sx={{
                fontWeight: "500",

                fontSize: {
                  xl: "20px",
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },

                color: "red",
                marginTop: "8%",
              }}>
              {error}
            </Typography>
          )}
          {/* The email address is invalid. */}
        </InnerDiv>
      </Main>

      <Footer2 />
    </Wrapper>
  );
}

export default ForgotPassword;
