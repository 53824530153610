import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import logo3 from "../images/logo3.png";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

function Navbar4() {
  const navigate = useNavigate();

  let token = localStorage.getItem("userToken");

  const handleClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <AppBar>
      <Toolbar
        variant="dense"
        sx={{
          height: {
            xl: "80px",
            lg: "40px",
            md: "40px",
            sm: "40px",
            xs: "40px",
          },
          bgcolor: "#1746A2",

          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          as="img"
          onClick={() => navigate("/")}
          src={logo3}
          alt="icon3"
          sx={{
            height: {
              xl: "43px",
              lg: "25.71px",
              md: "25.71px",
              sm: "25.71px",
              xs: "25.71px",
            },
            width: {
              xl: "75.71px",
              lg: " 44.12px",
              md: " 44.12px",
              sm: " 44.12px",
              xs: " 44.12px",
            },
            marginLeft: "25px",
            cursor: "pointer",
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            marginRight: "30px",
            cursor: "pointer",
          }}>
          {token ? (
            <>
              <LogoutIcon
                onClick={handleClick}
                sx={{ color: "white", fontSize: "28px" }}
              />
              <Typography
                onClick={handleClick}
                sx={{ color: "white", fontSize: "20px", fontWeight: "400" }}>
                Log out
              </Typography>
            </>
          ) : (
            <Box
              onClick={() => navigate("/")}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                // padding: '8px',
                borderRadius: "8px",
              }}>
              <HomeIcon onClick={() => navigate("/")} />
              <Typography onClick={() => navigate("/")} variant="h6">
                Home
              </Typography>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar4;
