import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TableSortLabel,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import emp from "../images/noAsset.png";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkAssetDelete,
  deleteAsset,
  retrieveAllAssets,
  retrieveAsset,
} from "../redux/data/action";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import DialogBox from "../components/DialogBox";
import DeleteModal from "../components/DeleteModal";
import Pagination from "../components/Pagination";
import NoData from "../components/NoData";
import TableSort from "../components/TableSort";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "../components/FilterMenu";
import BulkDeleteDeleteModal from "../components/BulkDeleteModal";
import SuccessModal from "../components/SuccessModal";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  minHeight: "400px",
  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageCard = styled(Box)(({ theme }) => ({
  width: "320px",
  height: "320px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "151px",
    height: "151px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyButtonSet = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "500",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  // border: "1px solid green",
  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "20px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const SplitContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MultiSplitContainer = styled(Box)(({ theme }) => ({
  //

  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  bgcolor: "#1746A2",
  textTransform: "none",
  fontSize: "19px",
  padding: "2%",
  margin: "auto",
  borderRadius: "40px",
  color: "white",
  width: "130px",
  height: "46px",

  [theme.breakpoints.down("xl")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("md")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "130px",
    height: "46px",
  },
}));

const EmptyButton = styled(Button)(({ theme }) => ({
  bgcolor: "#1746A2",
  textTransform: "none",
  fontSize: "19px",
  padding: "2%",
  margin: "auto",
  borderRadius: "40px",
  color: "white",
  width: "140px",
  height: "46px",

  [theme.breakpoints.down("xl")]: {
    width: "140px",
    height: "46px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "140px",
    height: "46px",
  },
  [theme.breakpoints.down("md")]: {
    width: "140px",
    height: "46px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "46px",
  },
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",
  "& fieldset": { border: "none" },
  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "180px",
    height: "35px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const CountText = styled(Typography)(({ theme }) => ({
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  background: "#FF731D",
  textTransform: "none",
  color: "white",
  borderRadius: "15px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AllAsset = () => {
  const allAssets = useSelector((store) => store.data.retrieveAsset);
  const deletedStatus = useSelector((store) => store.data.bulkAssetDelete);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [getId, setGetId] = useState("");
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [successToggle, setSuccessToggle] = useState(false);
  const navigate = useNavigate();
  const deleted = useSelector((store) => store.data.deleteAsset);
  const department = [...new Set(allAssets?.map((item) => item.department))];
  const status = [...new Set(allAssets?.map((item) => item.status))];

  const amccmc = [...new Set(allAssets?.map((item) => item.cmcamc))];

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);

  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);

  const [toggle, setToggle] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAmcCmc, setSelectedAmcCmc] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const [anchorElSort2, setAnchorElSort2] = React.useState(null);
  const [filterChanged, setFilterChanged] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);
  const [anchorElFilter2, setAnchorElFilter2] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllClick = () => {
    const allAssetIds = data?.map((item) => item.assetid);

    if (selectAll) {
      // Deselect all
      setSelectedAssetIds([]);
    } else {
      // Select all
      setSelectedAssetIds(allAssetIds);
    }

    setSelectAll(!selectAll);
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Asset is not selected, add it to the array
      newSelected = [...selectedAssetIds, assetId];
    } else {
      // Asset is selected, remove it from the array
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
    }

    setSelectedAssetIds(newSelected);
  };

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
    console.log("filter data: ",newFilteredData);
  }, [filterValue]);

  const handleClickFilter2 = (event) => {
    setAnchorElFilter2(event.currentTarget);
  };

  const handleCloseFilter2 = () => {
    setAnchorElFilter2(null);
  };

  const handleFilter2 = (status) => {
    setFilterValue(status);
    setAnchorElFilter2(null);
    setPage(0);
    setFilterChanged(true);
  };
  const text = "asset";

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  const handleButtonClick = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      navigate("/bulk-upload-asset");
    }
  };
  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
      handleCloseSort2();
    } else {
      downloadCsvFile();
      handleCloseSort2();
    }
  };
  const handleButtonClick3 = () => {
    if (ids.subs_plan === "Basic" && allAssets?.length >= 50) {
      setOpenModal(true);
    } else {
      navigate("/add-asset");
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openSort2 = Boolean(anchorElSort2);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories?.includes(category)
        ? prevCategories?.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories?.includes(status)
        ? prevCategories?.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleAmcCmcChange = (e) => {
    const amccmc = e.target.value;
    setSelectedAmcCmc((prevCategories) =>
      prevCategories?.includes(amccmc)
        ? prevCategories?.filter((c) => c !== amccmc)
        : [...prevCategories, amccmc]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = [...data];

    filteredProducts = filteredProducts?.filter(
      (product) =>
        product?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.serialno.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedCategories?.includes(product.department)
      );
    }

    if (selectedStatus?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedStatus?.includes(product.status)
      );
    }
    if (selectedAmcCmc?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedAmcCmc?.includes(product.cmcamc)
      );
    }
    return filteredProducts;
  };

  const filteredProducts = filterProducts();

  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);

    setGetId(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleSort2 = (event) => {
    setAnchorElSort2(event.currentTarget);
  };
  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate.split("-").reverse().join("-"));
        return dateA - dateB;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate.split("-").reverse().join("-"));
        return dateB - dateA;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(retrieveAsset(data, token));
    setToggle(false);
  }, [dispatch, deleted, toggle, deletedStatus]);

  const handleDelete = (e, item, id) => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetid: getId,
      branchid: userBranch.id,
    };
    setToggle(true);
    dispatch(deleteAsset(data, token));
    setData(allAssets?.filter((el) => el?.assetid !== getId));
    handleCloseDeleteModal();
  };

  const handleEdit = () => {
    navigate(`/edit-asset/${getId}`);
  };

  useEffect(() => {
    if (allAssets && allAssets?.length > 0) {
      setData(allAssets);
    }
  }, [allAssets, deleted, deletedStatus]);

  const handleDeleteById = () => {
    handleClose();
    handleOpenDeleteModal();
    setToggle(true);
  };
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...allAssets].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    data.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(allAssets);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const theme = useTheme();
  const isMatch2 = useMediaQuery(theme.breakpoints.down("md"));

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.name;
      case "date":
        return item.cmcamcdate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  const handleBulkDelete = () => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetids: selectedAssetIds,
      branchid: userBranch.id,
    };
    dispatch(bulkAssetDelete(data));
    setToggle(true);
    setOpenBulkDeleteModal(false);
  };

  const handleSuccessCloseModal = () => {
    setSuccessToggle(false);
  };

  return (
    <Wrapper>
      {!isMatch2 ? (
        <TopContainer>
          <TitleBox>
            <TitleHead>All Assets</TitleHead>
            <CountText
              sx={{
                color: "#1746A2",
              }}>{`Total : ${allAssets.length}`}</CountText>
          </TitleBox>

          {allAssets?.length > 0 && (
            <SortFilterContainer>
              <SplitContainer>
                <MultiSplitContainer>
                  {selectedAssetIds?.length > 0 && (
                    <DeleteButton
                      disabled={userAccess.role === "Guest"}
                      variant="contained"
                      onClick={handleOpenBulkDeleteModal}
                      aria-label="delete">
                      Delete
                    </DeleteButton>
                  )}
                  <Container>
                    <Button
                      onClick={handleButtonClick}
                      disabled={
                        userAccess?.role !== "Admin" &&
                        userAccess?.role !== "Super_Admin" &&
                        userAccess?.role !== "Owner" &&
                        userAccess?.role !== "Branch_Admin"
                      }
                      sx={{
                        bgcolor:
                          ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                        borderRadius: "30px",
                        "&:hover": {
                          background:
                            ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                        },
                        width: "150px",
                        textTransform: "none",
                      }}
                      variant="contained">
                      Bulk Upload
                    </Button>
                  </Container>
                </MultiSplitContainer>

                <MultiSplitContainer>
                  <Container>
                    <Search
                      type="search"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            placeholder="Search"
                            aria-label="toggle password visibility"
                            edge="start">
                            <SearchOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Container>

                  <div>
                    <Tooltip title="Sort">
                      <IconButton
                        id="basic-button"
                        aria-controls={openSort ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSort ? "true" : undefined}
                        onClick={handleSort}>
                        <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSort}
                      open={openSort}
                      onClose={handleCloseSort}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem sx={{ color: "#FF731D" }}>Name Sort</MenuItem>
                      <MenuItem onClick={() => sortDataByKey("name", true)}>
                        A-Z
                      </MenuItem>
                      <MenuItem onClick={() => sortDataByKey("name", false)}>
                        Z-A
                      </MenuItem>
                      <MenuItem sx={{ color: "#FF731D" }}>Date Sort</MenuItem>
                      <MenuItem onClick={sortLowToHigh}>
                        Newest to oldest
                      </MenuItem>
                      <MenuItem onClick={sortHighToLow}>
                        Oldest to newest
                      </MenuItem>
                    </Menu>
                  </div>

                  <div>
                    <Tooltip title="Filter">
                      <IconButton
                        id="basic-button"
                        aria-controls={openFilter ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openFilter ? "true" : undefined}
                        onClick={handleFilter}>
                        <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElFilter}
                      open={openFilter}
                      onClose={handleCloseFilter}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block">Department</FilterText>

                          <FilterInnerContainer>
                            <FormWrapper>
                              {department?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedCategories.includes(
                                        item
                                      )}
                                      onChange={handleCategoryChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block">
                            Working Status
                          </FilterText>
                          <FilterInnerContainer>
                            <FormWrapper>
                              {status?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedStatus.includes(item)}
                                      onChange={handleStatusChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block">AMC/CMC </FilterText>
                          <FilterInnerContainer>
                            <FormWrapper>
                              {amccmc?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedAmcCmc.includes(item)}
                                      onChange={handleAmcCmcChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                    </Menu>
                  </div>

                  <div>
                    <Tooltip title="Export to csv">
                      <IconButton
                        id="basic-button"
                        aria-controls={openSort2 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSort2 ? "true" : undefined}
                        onClick={handleSort2}>
                        <BrowserUpdatedIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSort2}
                      open={openSort2}
                      onClose={handleCloseSort2}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem
                        onClick={handleButtonClick2}
                        sx={{
                          color:
                            ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                        }}>
                        Export to csv
                      </MenuItem>
                    </Menu>
                  </div>
                </MultiSplitContainer>
              </SplitContainer>
            </SortFilterContainer>
          )}
        </TopContainer>
      ) : (
        <TopContainer sx={{}}>
          {allAssets?.length > 0 && (
            <SortFilterContainer sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                  marginTop: "2%",
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    color: "#FF731D",
                    textAlign: "start",
                  }}>
                  <TitleHead>All Assets</TitleHead>
                </Typography>

                <Search
                  sx={{}}
                  type="search"
                  value={searchQuery}
                  placeholder="Search"
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        placeholder="Search"
                        aria-label="toggle password visibility"
                        edge="start">
                        {/* <SearchOutlinedIcon /> */}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                  {selectedAssetIds?.length > 0 && (
                    <IconButton
                      onClick={handleOpenBulkDeleteModal}
                      aria-label="delete">
                      <DeleteIcon sx={{ color: "#FF731D" }} />
                    </IconButton>
                  )}

                  <Button
                    size="small"
                    onClick={handleButtonClick}
                    disabled={userAccess?.role === "Guest"}
                    sx={{
                      bgcolor:
                        ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                      borderRadius: "30px",
                      "&:hover": {
                        background:
                          ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                      },

                      textTransform: "none",
                      height: "35px",
                    }}
                    variant="contained">
                    Bulk Upload
                  </Button>
                </Box>

                <Box sx={{ display: "flex" }}>
                  <div>
                    <Tooltip title="Sort">
                      <IconButton
                        id="basic-button"
                        aria-controls={openSort ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSort ? "true" : undefined}
                        onClick={handleSort}>
                        <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSort}
                      open={openSort}
                      onClose={handleCloseSort}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem sx={{ color: "#FF731D" }}>Name Sort</MenuItem>
                      <MenuItem onClick={() => sortDataByKey("name", true)}>
                        A-Z
                      </MenuItem>
                      <MenuItem onClick={() => sortDataByKey("name", false)}>
                        Z-A
                      </MenuItem>
                      <MenuItem sx={{ color: "#FF731D" }}>Date Sort</MenuItem>
                      <MenuItem onClick={sortLowToHigh}>
                        Newest to oldest
                      </MenuItem>
                      <MenuItem onClick={sortHighToLow}>
                        Oldest to newest
                      </MenuItem>
                    </Menu>
                  </div>

                  <div>
                    <Tooltip title="Filter">
                      <IconButton
                        id="basic-button"
                        aria-controls={openFilter ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openFilter ? "true" : undefined}
                        onClick={handleFilter}>
                        <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElFilter}
                      open={openFilter}
                      onClose={handleCloseFilter}
                      PaperProps={{
                        style: {
                          maxHeight: "450px",
                          width: "300px",
                          overflow: "auto",
                        },
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block">Department</FilterText>

                          <FilterInnerContainer style={{ overflowY: "auto" }}>
                            <FormWrapper>
                              {department?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedCategories.includes(
                                        item
                                      )}
                                      onChange={handleCategoryChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block">
                            Working Status
                          </FilterText>
                          <FilterInnerContainer style={{ overflowY: "auto" }}>
                            <FormWrapper>
                              {status?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedStatus.includes(item)}
                                      onChange={handleStatusChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block">AMC/CMC </FilterText>
                          <FilterInnerContainer style={{ overflowY: "auto" }}>
                            <FormWrapper>
                              {amccmc?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedAmcCmc.includes(item)}
                                      onChange={handleAmcCmcChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                    </Menu>
                  </div>

                  <div>
                    <Tooltip title="Export to csv">
                      <IconButton
                        id="basic-button"
                        aria-controls={openSort2 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSort2 ? "true" : undefined}
                        onClick={handleSort2}>
                        <BrowserUpdatedIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSort2}
                      open={openSort2}
                      onClose={handleCloseSort2}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem
                        onClick={handleButtonClick2}
                        sx={{
                          color:
                            ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                        }}>
                        Export to csv
                      </MenuItem>
                    </Menu>
                  </div>
                </Box>
              </Box>
            </SortFilterContainer>
          )}
        </TopContainer>
      )}

      {allAssets?.length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            padding: "20px",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}>
          <EmptyText
            sx={{ color: "#1746A2", fontWeight: "500" }}
            // color="text.secondary"
            gutterBottom>
            There are no assets on this page. Please add an asset.
          </EmptyText>

          <ImageCard as="img" src={emp} alt="empty" />
          <CardContent></CardContent>
          <EmptyButtonSet>
            <EmptyButton
              onClick={() => navigate("/")}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              variant="contained">
              Go to Home
            </EmptyButton>

            <EmptyButton
              onClick={() => navigate("/add-asset")}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              disabled={userAccess?.addprod !== "Yes"}
              variant="contained">
              Add Asset
            </EmptyButton>
          </EmptyButtonSet>
        </BoxWrapper>
      ) : filteredProducts?.length === 0 ? (
        <>
          <NoData text={"asset"} />
        </>
      ) : (
        <>
          <BoxWrapper style={{ overflowX: "auto" }}>
            <Box sx={{ overflow: "auto" }}>
              <TableContainer
                sx={{
                  width: "100%",
                  maxHeight: 540,
                }}>
                <Table
                  stickyHeader
                  sx={{
                    borderRadius: " 15px",
                    bgcolor: "white",
                    whiteSpace: "nowrap",
                  }}>
                  <TableHead
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                    }}>
                    <TableRow
                      sx={{
                        borderRadius: " 15px 15px 0px 0px",
                        bgcolor: "rgba(40, 98, 212, 0.1)",
                      }}>
                      {/* Add the select/deselect all checkbox in the header */}
                      <TableCell>
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedAssetIds.length > 0 &&
                            selectedAssetIds.length < data?.length
                          }
                          checked={selectedAssetIds.length === data?.length}
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all assets",
                          }}
                        />
                      </TableCell>

                      <TableHeading
                        sx={{
                         
                        }}>
                        <TableSortLabel
                          style={{ color: "#1746A2" }}
                          active={true}
                          direction={sortDirection.name}
                          onClick={() => handleSortTable("name")}>
                          Asset Name
                        </TableSortLabel>
                      </TableHeading>

                      <TableHeading>Serial No.</TableHeading>
                      <TableHeading>Brand</TableHeading>
                      <TableHeading>Department</TableHeading>
                      <TableHeading sx={{ textAlign: "center" }}>
                        Status
                      </TableHeading>
                      <TableHeading>
                        <TableSortLabel
                          style={{ color: "#1746A2" }}
                          active={true}
                          direction={sortDirection.date}
                          onClick={() => handleSortTable("date")}>
                          AMC/CMC
                        </TableSortLabel>
                      </TableHeading>
                      {selectedAssetIds?.length === 0 && (
                        <TableHeading
                          sx={{
                            borderRadius: "0px 15px 0px 0px",
                          }}></TableHeading>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow
                          key={item.assetid}
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            "&:hover": {
                              backgroundColor: "#E8ECF6",
                            },
                            textDecoration: "none",
                          }}>
                          {/* Add the checkbox for each row */}
                          <TableCell>
                            <Checkbox
                              checked={selectedAssetIds.includes(item.assetid)}
                              onChange={() =>
                                handleSelectRowClick(item.assetid)
                              }
                            />
                          </TableCell>

                          <TableRows
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{ maxWidth: 200, textDecoration: "none" }}>
                            {item.name}
                          </TableRows>
                          <TableRows
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{ maxWidth: 210, textDecoration: "none" }}>
                            {item.serialno}
                          </TableRows>
                          <TableRows
                            sx={{ textDecoration: "none" }}
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}>
                            {item.brand}
                          </TableRows>

                          <TableRows
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{ maxWidth: 200, textDecoration: "none" }}>
                            {item.department}
                          </TableRows>
                          <TableRows
                            style={{ whiteSpace: "nowrap" }}
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                            sx={{
                              textAlign: "center",
                              textDecoration: "none",
                            }}>
                            <Box
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: {
                                  xl: "18px",
                                  lg: "16px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                                height: "30px",
                                padding: "4px",
                                width: {
                                  xl: "150px",
                                  lg: "130px",
                                  md: "110px",
                                  sm: "100px",
                                },

                                border:
                                  item.status === "Working"
                                    ? "1px solid #00A884"
                                    : "1px solid #FF4B4B",
                                color:
                                  item.status === "Working"
                                    ? " #00A884"
                                    : " #FF4B4B",
                              }}>
                              {item.status}
                            </Box>
                          </TableRows>

                          <TableRows
                            sx={{ textDecoration: "none" }}
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}>
                            {" "}
                            {item.cmcamcdate}
                          </TableRows>
                          {selectedAssetIds?.length === 0 && (
                            <TableRows>
                              <Box>
                                <IconButton
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, item.assetid)}>
                                  <MoreVertIcon color="black" />
                                </IconButton>
                              </Box>
                            </TableRows>
                          )}

                          {/* Rest of your table row code... */}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                filteredProducts={filteredProducts}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </BoxWrapper>
          <Box sx={{ marginTop: "3%" }}>
            <SubmitButton
              size="large"
              sx={{
                borderRadius: "35px",
                textTransform: "none",

                bgcolor:
                  ids.subs_plan === "Basic" && allAssets?.length > 50
                    ? "#FF731D"
                    : "#1746A2",

                "&:hover": {
                  background:
                    ids.subs_plan === "Basic" && allAssets?.length > 50
                      ? "#FF731D"
                      : "#1746A2",
                },
              }}
              disabled={userAccess?.addprod !== "Yes"}
              onClick={handleButtonClick3}
              variant="contained">
              Add Asset
            </SubmitButton>
          </Box>
        </>
      )}

      <Menu
        elevation={0}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem
          disabled={userAccess?.editprod !== "Yes"}
          onClick={handleEdit}
          value="edit">
          <ModeOutlinedIcon sx={{ marginRight: "7%" }} />
          Edit
        </MenuItem>
        <MenuItem
          disabled={userAccess?.delprod !== "Yes"}
          value="delete"
          // onClick={handleDelete}

          onClick={handleDeleteById}>
          <DeleteOutlineOutlinedIcon
            sx={{
              marginRight: "7%",
            }}
          />
          Delete
        </MenuItem>

        <Link
          style={{ textDecoration: "none", color: "black" }}
          to={`/asset-detail/${getId}`}>
          <MenuItem value="view">
            <RemoveRedEyeOutlinedIcon
              sx={{
                marginRight: "7%",
              }}
            />
            View
          </MenuItem>
        </Link>
      </Menu>

      <DeleteModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={handleDelete}
        text={text}
        id={getId}
      />

      <BulkDeleteDeleteModal
        open={openBulkDeleteModal}
        onClose={handleCloseBulkDeleteModal}
        onDelete={handleBulkDelete}
        text={text}
        count={selectedAssetIds?.length}
      />

      <SuccessModal
        openModal={deletedStatus === "Deleted" || deleted === "Deleted"}
        handleCloseModal={handleSuccessCloseModal}
        response={"Deleted"}
        text={"Deleted"}
        toggle={successToggle}
      />

      <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
    </Wrapper>
  );
};

export default AllAsset;
