import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Plans from "../pages/Plans";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
// import UserPrivateRoute from './UserPrivateRoute'
import Dashboard from "../pages/Dashboard";
import ForgotPassword from "../pages/ForgotPassword";
import Register from "../pages/Register";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import SideBarPage2 from "../components/SideBarPage2";
import SideBarPage3 from "../nbmComponents/SideBarPage2";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../components/LoadingComponent";
import { profile } from "../redux/data/action";
import ReloginModal from "../components/ReloginModal";
import ChooseBmOrNbm from "../pages/ChooseBmOrNbm";
import ChooseRoute from "./ChooseRoute";
import ErrorModal from "../components/ErrorModal";
import AIChecks from "../pages/AIChecks";
import Check2 from "../pages/Check2";
import TimeOut from "../components/TimeOut";
import TimeOutModal from "../components/TimeOutModal";
import AccessCheckModal from "../components/AccessCheckModal";
import Notification2 from "../components/Notification2";


function MainRoutes() {
  const token = localStorage.getItem("userToken");
  const userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));




  const out = sessionStorage.getItem("TimeOut");
  const location = useLocation();
  const path = location.pathname;

  const bmOrNbm =
    sessionStorage.getItem("SbmOrNbm") || localStorage.getItem("bmOrNbm");

  const dispatch = useDispatch();
  const loading = useSelector((store) => store.data.isLoading);
  const error = useSelector((store) => store.data.error);
  const nbmerror = useSelector((store) => store.nbmData.error);
  const nbmloading = useSelector((store) => store.nbmData.isLoading);
  const checkToken = useSelector((store) => store.data.isLoading);

  let publicRoute = !token && <PublicRoute />;
  let pop = out && <TimeOutModal />;
  let Timeout = token && <TimeOut />;
  let Choose = token &&
    userData.city !== "NA" &&
    !bmOrNbm &&
    bmOrNbm !== "BM" &&
    bmOrNbm !== "NBM" && <ChooseRoute />;
  let Biomedical = token && userData.city !== "NA" && bmOrNbm === "BM" && (
    <SideBarPage2 />
  );
  let NonBiomedical = token && userData.city !== "NA" && bmOrNbm === "NBM" && (
    <SideBarPage3 />
  );



 





  return (
    <>
      {loading && bmOrNbm == "BM" && <LoadingComponent />}
      {nbmloading && bmOrNbm == "NBM" && <LoadingComponent />}
      {Object.keys(error)?.length > 0 && bmOrNbm == "BM" && <ErrorModal />}
      {Object.keys(nbmerror)?.length > 0 && bmOrNbm == "NBM" && <ErrorModal />}
      {publicRoute}
      {Choose}
      {Biomedical}
      {NonBiomedical}
      {Timeout}
      {pop}
      <AccessCheckModal />
     {token && <Notification2/>}
   
    </>
  );
}

export default MainRoutes;