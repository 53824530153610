import styled from "@emotion/styled";
import { Box } from "@mui/material";

import Navbar3 from "./Navbar3";
import Footer from "./Footer";

const MainDiv = styled(Box)(({ theme }) => ({
  bgcolor: "white",

  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",

  alignItems: "center",
  marginTop: "7%",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "9%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

function Disclaimer() {
  return (
    <>
      <MainDiv
        sx={{
          background: "#faf5ee",
        }}>
        <Navbar3 />
        <h1>Disclaimer</h1>
        <Box
          sx={{
            width: " 90%",
            marginBottom: "30px",
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}>
          <p>
            The information contained on https://vajra.software website (the
            “Service”) is for general information purposes only.
          </p>
          <p>
            vajra assumes no responsibility for errors or omissions in the
            content on the Service. In no event shall vajra be liable for any
            special, direct, indirect, consequential, or incidental damages or
            any damages whatsoever, whether in an action of contract, negligence
            or other tort, arising out of or in connection with the use of the
            Service or the contents of the Service.
          </p>
          <p>
            vajra reserves the right to make additions, deletions, or
            modifications to the contents on the Service at any time without
            prior notice. This Disclaimer for vajra has been created with the
            help of an online generator.
          </p>
          <p>
            vajra does not warrant that the website is free of viruses or other
            harmful components.
          </p>
          <h2>External links disclaimer</h2>
          <p>
            https://vajra.software website may contain links to external
            websites that are not provided or maintained by or in any way
            affiliated with vajra Please note that the vajra does not guarantee
            the accuracy, relevance, timeliness, or completeness of any
            information on these external websites.
          </p>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Footer />
        </Box>
      </MainDiv>
    </>
  );
}

export default Disclaimer;
