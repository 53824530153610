import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { getNotRequired } from "../redux/nbmData/action";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import empty from "../images/empty.png";
import Pagination from "../components/Pagination";
import NoData from "../components/NoData";
import TableSort from "../components/TableSort";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  minHeight: "400px",

  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  // [theme.breakpoints.down("xl")]: {},
  // [theme.breakpoints.down("lg")]: {},
  // [theme.breakpoints.down("md")]: {},
  // [theme.breakpoints.down("sm")]: {},
  // [theme.breakpoints.down("xs")]: {},

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  textAlign: "left",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "16px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",
  "& fieldset": { border: "none" },
  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "120px",
    height: "35px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const CalibrateNotRequired = () => {
  const notRequired = useSelector(
    (store) => store.nbmData.calibrateNotRequired
  );
  let userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [selectedRow, setSelectedRow] = useState(0);
  const [filterCalibrated, setFilterCalibrated] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [filterChanged, setFilterChanged] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filteredProductss;
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = data;

    filteredProducts = filteredProducts.filter(
      (product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedCategories.includes(product.department)
      );
    }

    return filteredProducts;
  };

  const filteredProducts = filterProducts();

  const handleSelectRow = (rowIndex) => {
    setSelectedRow(rowIndex);
  };
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);

  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  useEffect(() => {
    if (notRequired && notRequired?.length > 0) {
      setData(notRequired);
    }
  }, [notRequired]);

  const select = [...new Set(notRequired?.map((item) => item?.department))];

  const filteredProductss = data?.filter((product) => {
    if (filterCalibrated?.length === 0) {
      return true;
    } else {
      return filterCalibrated?.some((value) => value === product?.department);
    }
  });

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(getNotRequired(data, token));
  }, [dispatch]);

  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.name;
      case "date":
        return item.date;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );
  return (
    <Wrapper>
      <TopContainer sx={{}}>
        <TitleBox>
          <TitleHead> Not Required</TitleHead>
          {data?.length > 0 && (
            <Typography
              sx={{ color: "#1746A2" }}>{`Total : ${data?.length}`}</Typography>
          )}
        </TitleBox>
        {notRequired?.length > 0 && (
          <SortFilterContainer sx={{}}>
            {/* <Input type="date" onChange={(e)=>setDate(e.target.value)} /> */}
            <Container>
              <Search
                type="search"
                value={searchQuery}
                placeholder="Search"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      placeholder="Search"
                      aria-label="toggle password visibility"
                      edge="start">
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Container>

            <div>
              <IconButton
                id="basic-button"
                aria-controls={openSort ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSort ? "true" : undefined}
                onClick={handleSort}>
                <SortByAlphaIcon sx={{ color: "#FF731D" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorElSort}
                open={openSort}
                onClose={handleCloseSort}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}>
                <MenuItem sx={{ color: "#FF731D" }}>Sort by Name</MenuItem>
                <MenuItem onClick={() => sortDataByKey("name", true)}>
                  A-Z
                </MenuItem>
                <MenuItem onClick={() => sortDataByKey("name", false)}>
                  Z-A
                </MenuItem>
              </Menu>
            </div>

            <div>
              <IconButton
                id="basic-button"
                aria-controls={openFilter ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openFilter ? "true" : undefined}
                onClick={handleFilter}>
                <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorElFilter}
                open={openFilter}
                onClose={handleCloseFilter}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}>
                <MenuItem>
                  <FilterMain>
                    <FilterText display="block">Department</FilterText>

                    <FilterInnerContainer>
                      <FormWrapper>
                        {select?.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="warning"
                                type="checkbox"
                                value={item}
                                checked={selectedCategories.includes(item)}
                                onChange={handleCategoryChange}
                                name="gilad"
                              />
                            }
                            label={item}
                          />
                        ))}
                      </FormWrapper>
                    </FilterInnerContainer>
                  </FilterMain>
                </MenuItem>
              </Menu>
            </div>
          </SortFilterContainer>
        )}
      </TopContainer>
      {notRequired?.length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: " 15px ",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}>
          <Cards>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={empty}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                sx={{ color: " #212427" }}
                color="text.secondary"
                gutterBottom>
                No assets in calibration
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => navigate("/home")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                variant="contained">
                Go to Home
              </Button>
            </CardActions>
          </Cards>
        </BoxWrapper>
      ) : filteredProducts?.length === 0 ? (
        <>
          <NoData text={"asset"} />
        </>
      ) : (
        <>
          <BoxWrapper style={{ overflowX: "auto" }}>
            <Box sx={{ overflow: "auto" }}>
              <TableContainer
                sx={{
                  width: "100%",

                  maxHeight: 540,
                }}>
                <Table
                  stickyHeader
                  sx={{
                    borderRadius: " 15px",
                    bgcolor: "white",

                    whiteSpace: "nowrap",
                  }}>
                  <TableHead
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                    }}>
                    <TableRow
                      selected={selectedRow === 0}
                      onClick={() => handleSelectRow(0)}
                      sx={{
                        borderRadius: " 15px 15px 0px 0px",
                        bgcolor: "rgba(146, 25, 118, 0.1)",
                      }}>
                      <TableHeading
                        sx={{
                          borderRadius: "15px 0px 0px 0px",
                        }}>
                        <TableSortLabel
                          active={true}
                          style={{ color: "#1746A2" }}
                          direction={sortDirection.name}
                          onClick={() => handleSortTable("name")}>
                          Asset Name
                        </TableSortLabel>
                      </TableHeading>
                      <TableHeading sx={{}}>Asset code</TableHeading>
                      <TableHeading sx={{}}>Serial no</TableHeading>
                      <TableHeading sx={{}}>Department</TableHeading>

                      <TableHeading sx={{ textAlign: "center" }}>
                        Status
                      </TableHeading>
                      <TableHeading sx={{}}>Required</TableHeading>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      ?.map((item, index) => (
                        <TableRow
                          key={item?.assetid}
                          component={Link}
                          to={`/asset-detail/${item?.assetid}`}
                          onClick={() => handleSelectRow(0)}
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            textDecoration: "none",
                          }}>
                          <TableRows>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.name}
                            </Link>
                          </TableRows>
                          <TableRows sx={{}}>{item?.assetcode}</TableRows>
                          <TableRows sx={{}}>{item?.serialno}</TableRows>
                          <TableRows sx={{}}>{item?.department}</TableRows>

                          <TableRows
                            sx={{ textAlign: "center" }}
                            style={{ whiteSpace: "nowrap" }}>
                            <Box
                              sx={{
                                borderRadius: "30px",

                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: {
                                  xl: "18px",
                                  lg: "16px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                                padding: "2px",
                                height: "30px",
                                width: { xl: "150px", md: "145px" },
                                margin: "auto",
                                border:
                                  item.status === "Working"
                                    ? "1px solid #00A884"
                                    : "1px solid #FF4B4B",
                                color:
                                  item.status === "Working"
                                    ? " #00A884"
                                    : " #FF4B4B",
                              }}>
                              Not Calibrated
                            </Box>
                          </TableRows>
                          <TableRows style={{ whiteSpace: "nowrap" }}>
                            {" "}
                            Not Required
                          </TableRows>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                filteredProducts={filteredProducts}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </BoxWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default CalibrateNotRequired;
