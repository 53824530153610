import React from "react";
import { Modal, Typography, Button, Box } from "@mui/material";
import { logOut } from "../redux/data/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const NoUserFound = ({ open }) => {
  const onClose = () => {};
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  const handleLogout = () => {
    let data = {
      userid: userIds.userid,
    };
    dispatch(logOut(data, token));
    // localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.getItem("userToken");
    navigate("/signin");
    localStorage.setItem('logoutEvent', Date.now().toString());
    window.location.reload()

  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          gap: "20px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: "20px",
          minWidth: "300px",
          textAlign: "center",
        }}>
        <Typography color="#1746A2" variant="h6">
          No user found.
        </Typography>
        <Typography color="#FF731D" variant="body1">
          Please contact the super admin to choose a user.
        </Typography>
        <Box>
          <Button
            size="medium"
            sx={{
              width: "120px",
              margin: "auto",
              textTransform: "none",
              bgcolor: "#1746A2",
            }}
            variant="contained"
            onClick={handleLogout}>
            Okay
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default NoUserFound;
