import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  FormLabel,
  MenuItem,
  Select,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Reportempty from "../images/Reportempty.png";
import TableContainer from "@mui/material/TableContainer";

import { useDispatch, useSelector } from "react-redux";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { DownloadPDF, serviceReport } from "../redux/data/action";

import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { useNavigate } from "react-router-dom";

import DialogBox from "../components/DialogBox";
import TopBox from "../components/TopBox";
import Pagination from "../components/Pagination";
import NoData from "../components/NoData";
import TableSort from "../components/TableSort";

const MainWrapper = styled(Box)(({ theme }) => ({
  // ,
  width: "100%",
  height: "100%",
  bgcolor: "#faf5ee",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));
const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "90%",

    gap: "2%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "300px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "300px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const UpperContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",

  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  marginTop: "20px",
  width: "90%",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "30px",
  gap: "10px",

  height: "100%",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
    // marginTop: "70px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBox = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "space-between",
  width: "100%",
  alignItems: "end",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",

    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column",
    gap: "50px",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",

  bgcolor: "#EFF5FE",
  color: "#1746A2",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Boxes = styled(Box)(({ theme }) => ({
  // border: "4px solid yellow",

  height: "100%",
  width: "40%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "40%",
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    //
  },
}));

const SelectWrapper = styled(Select)(({ theme }) => ({
  width: "370px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("md")]: {
    height: "50px",
    width: "100%",
    // border: "1px solid orange",
  },
  [theme.breakpoints.down("sm")]: {
    height: "50px",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Report = () => {
  const serviceData = useSelector((store) => store.data?.serviceReport);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(0);
  const [report, setReport] = useState("");

  const [anchorElSort2, setAnchorElSort2] = React.useState(null);
  const handleSelectRow = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [filterChanged, setFilterChanged] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filteredProducts;
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };
  const uniqueMonths = [
    ...new Set(serviceData.map((item) => item.cleardate.split("-")[1])),
  ].sort();
  const uniqueYears = [
    ...new Set(serviceData.map((item) => item.cleardate.split("-")[2])),
  ].sort();
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };

  useEffect(() => {
    if (serviceData && serviceData?.length > 0) {
      setData(serviceData);
    }
  }, [serviceData]);

  const handleChange = (e) => {
    setShow(true);
    setReport(e.target.value);
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };
    let report = e.target.value;
    setData([]);

    dispatch(serviceReport(data, report, token));
  };

  const select = [...new Set(serviceData?.map((item) => item?.department))];

  const handleButtonClick2 = () => {
    if (userIds.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      downloadCsvFile();
    }
  };
  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.cleardate.split("-").reverse().join("-"));
      const dateB = new Date(b.cleardate.split("-").reverse().join("-"));
      return dateA - dateB;
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.cleardate.split("-").reverse().join("-"));
      const dateB = new Date(b.cleardate.split("-").reverse().join("-"));
      return dateB - dateA;
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const filterIncidents = () => {
    let filteredIncidents = [...data];

    if (searchQuery) {
      filteredIncidents = filteredIncidents.filter(
        (incident) =>
          incident.assetname
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          incident.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategories.length > 0) {
      filteredIncidents = filteredIncidents.filter((incident) =>
        selectedCategories.includes(incident.department)
      );
    }

    if (selectedYear) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentYear = incident.cleardate.split("-")[2];
        return incidentYear === selectedYear;
      });
    }
    if (selectedMonth) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentMonth = incident.cleardate.split("-")[1];
        return incidentMonth === String(selectedMonth).padStart(2, "0");
      });
    }

    return filteredIncidents;
  };
  const filteredProducts = filterIncidents();

  const downloadCsvFile = () => {
    const csvContent =
      "assetname,department,assetcode,serialno,cleartime,cleardate\n" +
      filteredProducts
        .map(
          (item) =>
            `${item.assetname},${item.department},${item.assetcode},${item.serialno},${item.cleartime},${item.cleardate}`
        )
        .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "assets.csv";
    downloadLink.click();
    handleCloseSort2();
  };
  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.assetname;
      case "date":
        return item.cleardate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );
  return (
    <>
      <MainWrapper>
        <TopContainer sx={{}}>
          <TopBox
            data={serviceData}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            selectedMonth={selectedMonth}
            handleMonthChange={handleMonthChange}
            uniqueMonths={uniqueMonths}
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            uniqueYears={uniqueYears}
            sortDataByKey={sortDataByKey}
            sortLowToHigh={sortLowToHigh}
            sortHighToLow={sortHighToLow}
            department={select}
            selectedCategories={selectedCategories}
            handleButtonClick2={handleButtonClick2}
            ids={userIds}
            handleCategoryChange={handleCategoryChange}
            text={`${report} report`}
          />
        </TopContainer>
        <InnerContainer sx={{}}>
          <UpperContainer
            sx={{ bgcolor: "white", borderRadius: "15px", padding: "20px" }}>
            <InnerBox sx={{}}>
              <Boxes sx={{ display: "flex", flexDirection: "column" }}>
                <FormTextLabel>Select Type of Report</FormTextLabel>
                <SelectWrapper
                  disabled={userAccess?.role === "Guest"}
                  onChange={handleChange}
                  displayEmpty
                  value={report}
                  sx={{
                    bgcolor: "#EFF5FE",
                    "& fieldset": { border: "none" },
                    border: "1px solid rgba(162, 104, 23, 0.2)",
                    textAlign: "left",
                  }}
                  inputProps={{ "aria-label": "Without label" }}>
                  <MenuItem value="" disabled>
                    <Typography variant="body1">Select Report</Typography>
                  </MenuItem>

                  <MenuItem value={"service"}>
                    <Typography variant="body1"> Service Report</Typography>
                  </MenuItem>
                  <MenuItem value={"incident"}>
                    <Typography variant="body1"> Incident Report</Typography>
                  </MenuItem>
                  <MenuItem value={"calibration"}>
                    <Typography variant="body1"> Calibration Report</Typography>
                  </MenuItem>
                </SelectWrapper>
              </Boxes>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "end",

                  height: "100%",
                }}>
                <Button
                  variant="contained"
                  size="large"
                  disabled={userAccess?.role === "Guest"}
                  startIcon={
                    <AddCircleSharpIcon sx={{ color: "white", fontSize: 30 }} />
                  }
                  onClick={() => navigate("/pm-checklist")}
                  sx={{
                    borderRadius: "25px",
                    bgcolor:
                      userAccess?.role === "Guest"
                        ? "grey"
                        : "rgba(23, 70, 162, 0.96)",
                    textTransform: "none",
                  }}>
                  PM Check List
                </Button>
              </Box>
            </InnerBox>
          </UpperContainer>
          {serviceData?.length === 0 && show ? (
            <BoxWrapper
              style={{ overflowX: "auto" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: " 15px ",
                justifyContent: "center",
                alignItems: "center",
                //
                minHeight: "400px",
                //
              }}>
              <Cards>
                <CardMedia
                  component="img"
                  sx={{ width: 100 }}
                  image={Reportempty}
                  alt="green iguana"
                />

                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}>
                  <Box
                    onClick={""}
                    sx={{
                      borderRadius: "35px",
                      textTransform: "none",
                    }}
                    variant="contained">
                    No reports
                  </Box>
                  <Button
                    onClick={() => navigate("/home")}
                    sx={{
                      borderRadius: "35px",
                      textTransform: "none",
                      bgcolor: "#1746A2",
                    }}
                    variant="contained">
                    Go to Home
                  </Button>
                </CardActions>
              </Cards>
            </BoxWrapper>
          ) : filteredProducts?.length === 0 && show ? (
            <>
              <NoData text={"report"} />
            </>
          ) : (
            <>
              {data?.length > 0 && show && (
                <BoxWrapper style={{}}>
                  <Box sx={{ overflowX: "auto" }}>
                    <TableContainer
                      sx={{
                        width: "100%",
                        maxHeight: 540,
                      }}>
                      <Table
                        stickyHeader
                        sx={{
                          borderRadius: " 15px",
                          bgcolor: "white",

                          whiteSpace: "nowrap",
                        }}>
                        <TableHead
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                          }}>
                          <TableRow
                            selected={selectedRow === 0}
                            onClick={() => handleSelectRow(0)}
                            sx={{
                              borderRadius: " 15px 15px 0px 0px",
                              bgcolor: "rgba(146, 25, 118, 0.1)",
                            }}>
                            <TableHeading
                              sx={{
                                borderRadius: "15px 0px 0px 0px",
                              }}>
                              <TableSortLabel
                                active={true}
                                style={{ color: "#1746A2" }}
                                direction={sortDirection.name}
                                onClick={() => handleSortTable("name")}>
                                Asset Name
                              </TableSortLabel>
                            </TableHeading>
                            <TableHeading sx={{}}>Asset code</TableHeading>
                            <TableHeading sx={{}}>Serial no</TableHeading>

                            <TableHeading sx={{}}>Department</TableHeading>
                            <TableHeading sx={{}}>
                              <TableSortLabel
                                active={true}
                                style={{ color: "#1746A2" }}
                                direction={sortDirection.date}
                                onClick={() => handleSortTable("date")}>
                                Date/Time
                              </TableSortLabel>
                            </TableHeading>

                            <TableHeading sx={{ textAlign: "center" }}>
                              Download
                            </TableHeading>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredProducts
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            //.reverse()
                            .map((item, index) => (
                              <TableRow
                                key={index}
                                onClick={() => handleSelectRow(0)}
                                sx={{
                                  borderRadius: " 15px 15px 0px 0px",
                                  //
                                }}>
                                <TableRows sx={{}}>
                                  {" "}
                                  {item?.assetname}
                                </TableRows>
                                <TableRows sx={{}}>
                                  {" "}
                                  {item?.assetcode}
                                </TableRows>
                                <TableRows sx={{}}> {item?.serialno}</TableRows>

                                <TableRows sx={{}}>
                                  {item?.department}
                                </TableRows>
                                <TableRows sx={{}}>
                                  <Box>
                                    {item?.cleardate}
                                    <br />
                                    {item?.cleartime}
                                  </Box>
                                </TableRows>

                                <TableRows
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <DownloadForOfflineRoundedIcon
                                      sx={{ color: "rgba(255, 115, 29, 1)" }}
                                    />
                                  </Box>
                                </TableRows>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      filteredProducts={filteredProducts}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Box>
                </BoxWrapper>
              )}
            </>
          )}
        </InnerContainer>
      </MainWrapper>
      <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default Report;
