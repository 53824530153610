import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  Menu,
  styled,
  Typography,
  OutlinedInput,
  FormGroup,
  Container,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  textTransform: "capitalize",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    // fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    // fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",

    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",

    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",
  "& fieldset": { border: "none" },
  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "180px",
    height: "35px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Boxes = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",

  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));
const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const CountText = styled(Typography)(({ theme }) => ({
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopBox = ({
  onSearch,
  onSortChange,
  onFilterChange,
  data,
  searchQuery,
  handleSearchChange,
  selectedMonth,
  handleMonthChange,
  uniqueMonths,
  selectedYear,
  handleYearChange,
  uniqueYears,
  department,
  selectedCategories,
  handleCategoryChange,
  showOnlyExpired,
  setShowOnlyExpired,
  showNotExpired,
  setShowNotExpired,
  handleButtonClick2,
  ids,
  sortDataByKey,
  sortLowToHigh,
  sortHighToLow,
  sortLowToHigh1,
  sortHighToLow1,
  text,
  status,
  selectedStatus,
  handleStatusChange,
}) => {
  const theme = useTheme();
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [adjustAnchorEl, setAdjustAnchorEl] = useState(null);
  const handleSortMenuClick = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleFilterMenuClick = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleAdjustMenuClick = (event) => {
    setAdjustAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElSort(null);
    setAnchorElFilter(null);
    setAdjustAnchorEl(null);
  };
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch2 = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {!isMatch2 ? (
        <TopContainer sx={{}}>
          <TitleBox>
            <TitleHead>{text}</TitleHead>
            {data?.length > 0 && (
              <CountText>{`Total : ${data?.length}`}</CountText>
            )}
          </TitleBox>

          {data?.length > 0 && (
            <SortFilterContainer>
              <Boxes>
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <Select
                    sx={{
                      bgcolor: "#1746A2",
                      color: "white",
                      borderRadius: "30px",
                      "& fieldset": { border: "none" },

                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    displayEmpty
                    value={selectedMonth}
                    onChange={handleMonthChange}>
                    <MenuItem value="">All Month</MenuItem>

                    {uniqueMonths?.map((month) => (
                      <MenuItem key={month} value={month}>
                        {new Date(0, parseInt(month, 10) - 1).toLocaleString(
                          "en-US",
                          {
                            month: "long",
                          }
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 80 }} size="small">
                  <Select
                    sx={{
                      bgcolor: "#1746A2",
                      color: "white",
                      borderRadius: "30px",
                      "& fieldset": { border: "none" },

                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    displayEmpty
                    value={selectedYear}
                    onChange={handleYearChange}>
                    <MenuItem value="">Year</MenuItem>

                    {uniqueYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Boxes>
              <Boxes>
                <Container>
                  <Search
                    type="search"
                    value={searchQuery}
                    placeholder="Search"
                    onChange={handleSearchChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          placeholder="Search"
                          aria-label="toggle password visibility"
                          edge="start">
                          {/* <SearchOutlinedIcon /> */}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Container>

                <div variant="outlined">
                  <Tooltip title="Sort">
                    <IconButton aria-label="sort" onClick={handleSortMenuClick}>
                      <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorElSort}
                    open={Boolean(anchorElSort)}
                    onClose={handleClose}>
                    <MenuItem sx={{ color: "#FF731D" }}>Sort by Name</MenuItem>
                    <MenuItem onClick={() => sortDataByKey("assetname", true)}>
                      A-Z
                    </MenuItem>
                    <MenuItem onClick={() => sortDataByKey("assetname", false)}>
                      Z-A
                    </MenuItem>
                    <MenuItem sx={{ color: "#FF731D" }}>Sort by date</MenuItem>
                    <MenuItem onClick={sortLowToHigh}>
                      Newest to oldest
                    </MenuItem>
                    <MenuItem onClick={sortHighToLow}>
                      Oldest to newest
                    </MenuItem>
                  </Menu>
                </div>
                <div variant="outlined">
                  <Tooltip title="Filter">
                    <IconButton
                      aria-label="filter"
                      onClick={handleFilterMenuClick}>
                      <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorElFilter}
                    open={Boolean(anchorElFilter)}
                    onClose={handleClose}>
                    <MenuItem>
                      <FilterMain>
                        <FilterText display="block">Department</FilterText>

                        <FilterInnerContainer>
                          <FormWrapper>
                            {department?.map((item) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="warning"
                                    type="checkbox"
                                    value={item}
                                    checked={selectedCategories.includes(item)}
                                    onChange={handleCategoryChange}
                                    name="gilad"
                                  />
                                }
                                label={item}
                              />
                            ))}
                          </FormWrapper>
                        </FilterInnerContainer>
                      </FilterMain>
                    </MenuItem>
                    {status && (
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block"> Status</FilterText>
                          <FilterInnerContainer>
                            <FormWrapper>
                              {status?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedStatus.includes(item)}
                                      onChange={handleStatusChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                    )}
                  </Menu>
                </div>
                <div variant="outlined">
                  <Tooltip title="Export to csv">
                    <IconButton
                      aria-label="adjust"
                      onClick={handleAdjustMenuClick}>
                      <BrowserUpdatedIcon sx={{ color: "#FF731D" }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={adjustAnchorEl}
                    open={Boolean(adjustAnchorEl)}
                    onClose={handleClose}>
                    {" "}
                    <MenuItem
                      onClose={handleClose}
                      onClick={handleButtonClick2}
                      sx={{
                        color:
                          ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                      }}>
                      Export to csv
                    </MenuItem>
                  </Menu>
                </div>
              </Boxes>
            </SortFilterContainer>
          )}
        </TopContainer>
      ) : (
        <TopContainer sx={{}}>
          {data?.length > 0 && (
            <SortFilterContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                  marginTop: "2%",
                }}>
                <TitleBox>
                  <TitleHead>{text}</TitleHead>
                  {data?.length > 0 && (
                    <Typography>{`Total : ${data?.length}`}</Typography>
                  )}
                </TitleBox>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}>
                  <Search
                    type="search"
                    value={searchQuery}
                    placeholder="Search"
                    onChange={handleSearchChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          placeholder="Search"
                          aria-label="toggle password visibility"
                          edge="start">
                          {/* <SearchOutlinedIcon /> */}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                  <FormControl sx={{ minWidth: 80 }} size="small">
                    <Select
                      sx={{
                        bgcolor: "#1746A2",
                        color: "white",
                        borderRadius: "30px",
                        "& fieldset": { border: "none" },

                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "12px",
                          padding: "2.5px 6px",
                        },
                      }}
                      displayEmpty
                      value={selectedMonth}
                      onChange={handleMonthChange}>
                      <MenuItem value="">Month</MenuItem>

                      {uniqueMonths.map((month) => (
                        <MenuItem key={month} value={month}>
                          {new Date(0, parseInt(month, 10) - 1).toLocaleString(
                            "en-US",
                            {
                              month: "long",
                            }
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: 70 }} size="small">
                    <Select
                      sx={{
                        bgcolor: "#1746A2",
                        color: "white",
                        borderRadius: "30px",

                        "& fieldset": { border: "none" },

                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "12px",
                          padding: "2.5px 3px",
                        },
                      }}
                      displayEmpty
                      value={selectedYear}
                      onChange={handleYearChange}>
                      <MenuItem value="">Year</MenuItem>

                      {uniqueYears.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1px",
                  }}>
                  <div>
                    <Tooltip title="Sort">
                      <IconButton
                        aria-label="sort"
                        onClick={handleSortMenuClick}>
                        <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorElSort}
                      open={Boolean(anchorElSort)}
                      onClose={handleClose}>
                      <MenuItem sx={{ color: "#FF731D" }}>
                        Sort by Name
                      </MenuItem>
                      <MenuItem
                        onClick={() => sortDataByKey("assetname", true)}>
                        A-Z
                      </MenuItem>
                      <MenuItem
                        onClick={() => sortDataByKey("assetname", false)}>
                        Z-A
                      </MenuItem>
                      <MenuItem sx={{ color: "#FF731D" }}>
                        Sort by date
                      </MenuItem>
                      <MenuItem onClick={sortLowToHigh}>
                        Newest to oldest
                      </MenuItem>
                      <MenuItem onClick={sortHighToLow}>
                        Oldest to newest
                      </MenuItem>
                    </Menu>
                  </div>
                  <div variant="outlined">
                    <Tooltip title="Filter">
                      <IconButton
                        aria-label="filter"
                        onClick={handleFilterMenuClick}>
                        <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorElFilter}
                      open={Boolean(anchorElFilter)}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: "450px",
                          width: "300px",
                          overflow: "auto",
                        },
                      }}>
                      <MenuItem>
                        <FilterMain>
                          <FilterText display="block">Department</FilterText>
                          <FilterInnerContainer style={{ overflowY: "auto" }}>
                            <FormWrapper>
                              {department?.map((item) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="warning"
                                      type="checkbox"
                                      value={item}
                                      checked={selectedCategories.includes(
                                        item
                                      )}
                                      onChange={handleCategoryChange}
                                      name="gilad"
                                    />
                                  }
                                  label={item}
                                />
                              ))}
                            </FormWrapper>
                          </FilterInnerContainer>
                        </FilterMain>
                      </MenuItem>
                      {status && (
                        <MenuItem>
                          <FilterMain>
                            <FilterText display="block">Status</FilterText>
                            <FilterInnerContainer style={{ overflowY: "auto" }}>
                              <FormWrapper>
                                {status?.map((item) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="warning"
                                        type="checkbox"
                                        value={item}
                                        checked={selectedStatus.includes(item)}
                                        onChange={handleStatusChange}
                                        name="gilad"
                                      />
                                    }
                                    label={item}
                                  />
                                ))}
                              </FormWrapper>
                            </FilterInnerContainer>
                          </FilterMain>
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                  <div variant="outlined">
                    <Tooltip title="Export to csv">
                      <IconButton
                        aria-label="adjust"
                        onClick={handleAdjustMenuClick}>
                        <BrowserUpdatedIcon sx={{ color: "#FF731D" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={adjustAnchorEl}
                      open={Boolean(adjustAnchorEl)}
                      onClose={handleClose}>
                      {" "}
                      <MenuItem
                        onClick={handleButtonClick2}
                        onClose={handleClose}
                        sx={{
                          color:
                            ids.subs_plan === "Basic" ? "#FF731D" : "#1746A2",
                        }}>
                        Export to csv
                      </MenuItem>
                    </Menu>
                  </div>
                </Box>
              </Box>
            </SortFilterContainer>
          )}
        </TopContainer>
      )}
    </>
  );
};

export default TopBox;
