import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import taskFailed from "../images/taskFailed.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DownloadPDF, checkIn, checkInOut } from "../redux/data/action";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { RxDragHandleDots2 } from "react-icons/rx";
import { HiDownload } from "react-icons/hi";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import TableSort from "../components/TableSort";
import FilterMenu from "../components/FilterMenu";
import Pagination from "../components/Pagination";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "blue",
  },
}));
const Wrapper = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ElementRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 150,
    whiteSpace: "nowrap",

    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const EmptyContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "400px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "300px",
  height: "300px",

  [theme.breakpoints.down("xl")]: {
    width: "250px",
    height: "250px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("md")]: {
    width: "180px",
    height: "180px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "end",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));
const SplitContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",

  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));
const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    // fontSize: "16px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    textDecoration: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

function CheckIn() {
  const checkInOutData = useSelector((store) => store.data.checkInOutData);
  const checkInData = useSelector((store) => store.data.checkInData);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const [data, setData] = useState(checkInOutData);

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElSort1, setAnchorElSort1] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [filterChanged, setFilterChanged] = useState(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const [anchorElFilter2, setAnchorElFilter2] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? checkInOutData.filter((row) => row.status === filterValue)
      : checkInOutData;
    setData(newFilteredData);
  }, [filterValue]);

  const handleClickFilter2 = (event) => {
    setAnchorElFilter2(event.currentTarget);
  };

  const handleCloseFilter2 = () => {
    setAnchorElFilter2(null);
  };

  const handleFilter2 = (status) => {
    setFilterValue(status);
    setAnchorElFilter2(null);
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories.includes(status)
        ? prevCategories.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const openSort = Boolean(anchorElSort);

  const openFilter = Boolean(anchorElFilter);

  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const sortHighToLow = () => {
    let sorted = data.sort((a, b) => {
      const dateA = new Date(a.checkoutdate.split("-").reverse().join("-"));
      const dateB = new Date(b.checkoutdate.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sorted);
    handleCloseSort();
  };

  const sortLowToHigh = () => {
    let sorted = data.sort((a, b) => {
      const dateA = new Date(a.checkoutdate.split("-").reverse().join("-"));
      const dateB = new Date(b.checkoutdate.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sorted);
    handleCloseSort();
  };

  const sortHighToLow1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.checkindate !== "NA" && b.checkindate !== "NA") {
        const dateA = new Date(a.checkindate.split("-").reverse().join("-"));
        const dateB = new Date(b.checkindate.split("-").reverse().join("-"));
        return dateA - dateB;
      } else if (a.checkindate === "NA" && b.checkindate !== "NA") {
        return 1;
      } else if (a.checkindate !== "NA" && b.checkindate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.checkindate !== "NA" && b.checkindate !== "NA") {
        const dateA = new Date(a.checkindate.split("-").reverse().join("-"));
        const dateB = new Date(b.checkindate.split("-").reverse().join("-"));
        return dateB - dateA;
      } else if (a.checkindate === "NA" && b.checkindate !== "NA") {
        return 1;
      } else if (a.checkindate !== "NA" && b.checkindate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const department = [...new Set(checkInOutData?.map((item) => item.depart))];
  const status = [...new Set(checkInOutData?.map((item) => item.status))];

  const handleClick = (checkId) => {
    handleSubmit(checkId);
  };
  const handleSubmit = (checkId) => {
    let data = {
      hospid: userIds.hospid,

      checkid: checkId.checkid,
      assetid: checkId?.assetid,
      userid: userIds.userid,
      username: userIds.username,
      userrole: userAccess.role,
      branchid: userBranch.id,
    };
    dispatch(checkIn(data));
  };
  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(checkInOut(data));
  }, [dispatch, checkInData]);

  useEffect(() => {
    if (checkInOutData && checkInOutData?.length > 0) {
      setData(checkInOutData);
    }
  }, [checkInOutData]);

  const filterProducts = () => {
    let filteredProducts = data;

    filteredProducts = filteredProducts.filter(
      (product) =>
        product.assetname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedCategories.includes(product.depart)
      );
    }
    if (selectedStatus.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedStatus.includes(product.status)
      );
    }

    return filteredProducts;
  };
  const filteredProducts = filterProducts();
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.assetname;
      case "date":
        return item.checkoutdate;

      case "duedate":
        return item.checkindate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );
  return (
    <Wrapper sx={{}}>
      <TopContainer sx={{}}>
        <TitleBox>
          <TitleHead>Check in</TitleHead>
          {data?.length > 0 && (
            <Typography
              sx={{ color: "#1746A2" }}>{`Total : ${data?.length}`}</Typography>
          )}
        </TitleBox>
        {checkInOutData?.length > 0 && (
          <SortFilterContainer>
            <SplitContainer>
              <Container>
                <Search
                  type="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        placeholder="Search"
                        aria-label="toggle password visibility"
                        edge="start">
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Container>

              <div>
                <IconButton
                  id="basic-button"
                  aria-controls={openSort ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSort ? "true" : undefined}
                  onClick={handleSort}>
                  <SortByAlphaIcon sx={{ color: "#FF731D" }} />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElSort}
                  open={openSort}
                  onClose={handleCloseSort}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem sx={{ color: "#FF731D" }}>Sort by Name</MenuItem>
                  <MenuItem onClick={() => sortDataByKey("assetname", true)}>
                    A-Z
                  </MenuItem>
                  <MenuItem onClick={() => sortDataByKey("assetname", false)}>
                    Z-A
                  </MenuItem>
                  <MenuItem sx={{ color: "#FF731D" }}>
                    Sort by checkout date
                  </MenuItem>
                  <MenuItem onClick={sortLowToHigh}>Newest to oldest</MenuItem>
                  <MenuItem onClick={sortHighToLow}>Oldest to newest</MenuItem>

                  <MenuItem sx={{ color: "#FF731D" }}>
                    Sort by checkin date
                  </MenuItem>
                  <MenuItem onClick={sortLowToHigh1}>Newest to oldest</MenuItem>
                  <MenuItem onClick={sortHighToLow1}>Oldest to newest</MenuItem>
                </Menu>
              </div>

              <div>
                <IconButton
                  id="basic-button"
                  aria-controls={openFilter ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openFilter ? "true" : undefined}
                  onClick={handleFilter}>
                  <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElFilter}
                  open={openFilter}
                  onClose={handleCloseFilter}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem>
                    <FilterMain>
                      <FilterText display="block">Hospitals</FilterText>

                      <FilterInnerContainer>
                        <FormWrapper>
                          {department?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  color="warning"
                                  type="checkbox"
                                  value={item}
                                  checked={selectedCategories.includes(item)}
                                  onChange={handleCategoryChange}
                                  name="gilad"
                                />
                              }
                              label={item}
                            />
                          ))}
                        </FormWrapper>
                      </FilterInnerContainer>
                    </FilterMain>
                  </MenuItem>
                  <MenuItem>
                    <FilterMain>
                      <FilterText display="block">Status</FilterText>

                      <FilterInnerContainer>
                        <FormWrapper>
                          {status?.map((item, index) => {
                            let label = "";
                            if (item === "check_out") {
                              label = "Check In";
                            } else if (item === "check_in") {
                              label = "Completed";
                            } else {
                              label = item;
                            }

                            return (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    color="warning"
                                    type="checkbox"
                                    value={item}
                                    checked={selectedStatus.includes(item)}
                                    onChange={handleStatusChange}
                                    name="gilad"
                                  />
                                }
                                label={label}
                              />
                            );
                          })}
                        </FormWrapper>
                      </FilterInnerContainer>
                    </FilterMain>
                  </MenuItem>
                </Menu>
              </div>
            </SplitContainer>
          </SortFilterContainer>
        )}
      </TopContainer>
      <BoxWrapper style={{ overflowX: "auto" }}>
        {checkInOutData === undefined ||
        checkInOutData?.length === 0 ||
        filteredProducts.length === 0 ? (
          <EmptyContainer sx={{ bgcolor: "white" }}>
            <ImageBox as="img" src={taskFailed} alt="empty" />

            <Typography
              sx={{ color: " #212427" }}
              color="text.secondary"
              gutterBottom>
              No check in data found!
            </Typography>
          </EmptyContainer>
        ) : (
          <>
            <TableContainer
              sx={{
                width: "100%",
                borderRadius: "5px",

                maxHeight: 400,
              }}>
              <Table
                stickyHeader
                sx={{
                  borderRadius: " 15px",
                  bgcolor: "white",
                }}>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#1746A2E5" }}>
                    <TableCell style={{ color: "white" }}>
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2", whiteSpace: "nowrap" }}
                        direction={sortDirection.name}
                        onClick={() => handleSortTable("name")}>
                        Asset Name
                      </TableSortLabel>
                    </TableCell>

                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      {" "}
                      Asset Code
                    </TableCell>
                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      Department
                    </TableCell>
                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      {" "}
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.date}
                        onClick={() => handleSortTable("date")}>
                        Checked Out
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{ color: "#1746A2", whiteSpace: "nowrap" }}>
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.duedate}
                        onClick={() => handleSortTable("duedate")}>
                        Checked In
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={{ color: "#1746A2" }}>User</TableCell>
                    <TableCell
                      style={{ color: "#1746A2", textAlign: "center" }}>
                      Status
                      <Tooltip title="Filter Status">
                        <IconButton onClick={handleClickFilter2} size="small">
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>
                      <FilterMenu
                        anchorEl={anchorElFilter2}
                        onClose={handleCloseFilter2}
                        onFilter={handleFilter2}
                        status={status}
                      />
                    </TableCell>

                    <TableCell style={{ color: "#1746A2" }}>Pdf</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )

                    ?.map((item, index) => (
                      <TableRow key={index}>
                        <TableRows
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          {" "}
                          {item.assetname}
                        </TableRows>
                        <TableRows
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          {" "}
                          {item.assetcode}
                        </TableRows>
                        <TableRows
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          {" "}
                          {item.depart}
                        </TableRows>
                        <TableRows
                          style={{ whiteSpace: "nowrap" }}
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          {" "}
                          {item.checkoutdate} <br /> {item.checkouttime}
                        </TableRows>

                        {item.checkindate !== item?.checkintime ? (
                          <TableRows
                            style={{ whiteSpace: "nowrap" }}
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}>
                            <></>
                            {item.checkindate} <br /> {item.checkintime}
                          </TableRows>
                        ) : (
                          <TableRows
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}>
                            NA
                          </TableRows>
                        )}
                        {item.checkin_username === "NA" ? (
                          <TableRows
                            style={{ whiteSpace: "nowrap" }}
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}>
                            {" "}
                            {item.checkout_username}
                          </TableRows>
                        ) : (
                          <TableRows
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}>
                            {item.checkin_username}
                          </TableRows>
                        )}

                        {item.status === "check_out" ? (
                          <TableRows sx={{ textAlign: "center" }}>
                            <Box
                              onClick={() => handleClick(item)}
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                height: "30px",
                                padding: "4px",

                                width: "100px",
                                bgcolor: "#FF4B4B",

                                border: "1px solid #FF4B4B",

                                color: "white",
                              }}>
                              <Typography variant="caption" display="block">
                                Check in
                              </Typography>
                            </Box>
                          </TableRows>
                        ) : (
                          <TableRows
                            sx={{ textAlign: "center" }}
                            style={{ whiteSpace: "nowrap" }}>
                            <Box
                              sx={{
                                cursor: "pointer",
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                height: "30px",
                                width: "100px",
                                bgcolor: "#00A884",

                                border: "1px solid #00A884",

                                color: "white",
                              }}>
                              <Typography variant="caption" display="block">
                                Completed
                              </Typography>
                            </Box>
                          </TableRows>
                        )}
                        {item.status === "check_out" ? (
                          <TableRows>
                            <Tooltip
                              title="Check in asset to get pdf"
                              placement="top"
                              classes={{ tooltip: classes.customTooltip }}
                              sx={{}}>
                              <IconButton>
                                <RxDragHandleDots2
                                  style={{
                                    fontSize: "15px",
                                    color: " #FF731D",
                                    transform: "rotate(90deg)",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableRows>
                        ) : (
                          <TableRows>
                            <IconButton>
                              <HiDownload
                                onClick={() =>
                                  dispatch(
                                    DownloadPDF(
                                      item?.report_url,
                                      item?.assetname
                                    )
                                  )
                                }
                                style={{
                                  fontSize: "20px",
                                  color: " #FF731D",
                                }}
                              />
                            </IconButton>
                          </TableRows>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* {checkInOutData?.length > rowsPerPage && (
              <TablePagination
                style={{ width: "95%" }}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={checkInOutData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )} */}
            <Pagination
              filteredProducts={filteredProducts}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </BoxWrapper>

      {/* <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}>
        <DialogTitle
          id="dialog-title"
          sx={{
            bgcolor: "#2E58AA;",
            borderRadius: " 15px 15px 0px 0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            height: "50px",
          }}>
          CHECK IN
        </DialogTitle>

        <DialogContent>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            sx={{ padding: "20px" }}>
            <DemoContainer
              components={["DatePicker", "TimePicker"]}
              sx={{ color: "#FF731D", "& fieldset": { border: "none" } }}>
              <DemoItem label="Date">
                <div style={{ backgroundColor: "#EFF5FE" }}>
                  <DatePicker
                    sx={{ width: "180px" }}
                    views={["year", "month", "day"]}
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                  />
                </div>
              </DemoItem>
              <DemoItem label="Time">
                <div style={{ backgroundColor: "#EFF5FE" }}>
                  <TimePicker
                    sx={{ width: "180px" }}
                    placeholder="Time"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={selectedTime} // Set the value from state
                    onChange={(time) => setSelectedTime(time)} // Update state on change
                  />
                </div>
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
          {errorMessage && (
            <Typography
              variant="caption"
              style={{ color: "red", marginTop: "20px" }}>
              {errorMessage}
            </Typography>
          )}
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",

            padding: "20px",
          }}>
          <Button
            variant="contained"
            size="medium"
            sx={{
              bgcolor: "white",
              border: "1px solid #FF731D",
              color: "#FF731D",
              borderRadius: "13px ",
              "&:hover": {
                transform: "scale3d(1.05, 1.05, 2)",
                cursor: "pointer",
                bgcolor: "white",
                border: "1px solid #FF731D",
                color: "#FF731D",
                marginLeft: "2%",
              },
            }}
            onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              bgcolor: "#FF731D",
              color: "white",
              "&:hover": {
                transform: "scale3d(1.05, 1.05, 2)",
                cursor: "pointer",
                bgcolor: "#FF731D",
                color: "white",
              },
            }}
            onClick={handleSubmit}
            style={{ marginLeft: "10px", borderRadius: "13px " }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog> */}
    </Wrapper>
  );
}

export default CheckIn;
