import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DownloadPDF, userManual } from "../redux/nbmData/action";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import "react-multi-carousel/lib/styles.css";

import pdf from "../images/pdf icon.png";

import { useNavigate } from "react-router-dom";
const MainDiv = styled(Box)(({ theme }) => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  overflow: "auto",
  width: "100%",
  borderRadius: "15px",
  maxHeight: "800px",

  background: "white",
  padding: "2%",
  display: "grid",
  gap: "10px",
  gridTemplateColumns: "repeat(3,1fr)",

  [theme.breakpoints.down("xl")]: {
    // width: "100%",
    gridTemplateColumns: "repeat(3,1fr)",
  },
  [theme.breakpoints.down("lg")]: {
    // width: "100%",
    gridTemplateColumns: "repeat(3,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    // width: "100%",
    gridTemplateColumns: "repeat(1,1fr)",
    // width: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "97%",
    display: "grid",
    gridTemplateColumns: "repeat(1,1fr)",
    justifyItems: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  // border: "1px solid blue",
  width: "90%",
  justifyContent: "center",
  alignItems: "center",
  maxHeight: "1163px",
  // paddingTop: "20px",
  // paddingBottom: "20px",
  borderRadius: "30px",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
    // padding: "2%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "80%",
  },
}));
const TitleHeading = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonText = styled(Button)(({ theme }) => ({
  height: "10px",
  minWidth: "200px",

  color: "black",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  // height:'140px',
  width: "100%",

  // overflow:'revert',

  overflowX: "scroll",
  overflowY: "hidden",

  scrollbarWidth: "thin",
  // "&::-webkit-scrollbar": {
  //   // width: "0.4em",
  //   height: "5px",
  //   width: "1px",
  //   // minHeight: "100px",
  // },
  // "&::-webkit-scrollbar-track": {
  //   backgroundColor: "transparent",
  //   borderRadius: "30px",
  //   // maxHeight: "300px",
  //   // paddingTop: "50px",
  // },
  // // ::-webkit-scrollbar-track {max-height: 50%; height: 50%;}
  // "&::-webkit-scrollbar-thumb": {
  //   backgroundColor: "#888",
  //   borderRadius: "30px",
  //   // maxHeight: "300px",
  //   // border: "1px solid red",
  //   // marginTop: "2%",
  // },
  // "&::-webkit-scrollbar-thumb:hover": {
  //   background: "#555",
  // },

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // border: "4px solid blue",
    width: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "320px",
    gap: "0px",
    // border: "2px solid blue",
  },
  [theme.breakpoints.down("xs")]: {
    width: "320px",
  },
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Boxes = styled(Box)(({ theme }) => ({
  padding: "20px",
  height: "130px ",
  display: "flex",
  // border: "1px solid red",
  // width: "300px",

  [theme.breakpoints.down("xl")]: {
    height: "130px ",
  },
  [theme.breakpoints.down("lg")]: {
    height: "120px ",
  },
  [theme.breakpoints.down("md")]: {
    height: "110px ",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // border: "1px solid red",
  },
  [theme.breakpoints.down("xs")]: {
    // border: "10px solid blue",
  },
}));

const ImageCard = styled(Box)(({ theme }) => ({
  // width: "300px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",

  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Slider = styled(Box)(({ theme }) => ({
  // width: "300px",
  width: "90%",
  // border: "1px solid red",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const UserManual = () => {
  const userManualData = useSelector((store) => store.nbmData?.userManual);
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [manualData, setManualData] = useState([]);
  const [productName, setProductName] = useState("");
  const [brandName, setBrandName] = useState([]);
  const localStorageId = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const [brandData, setBrandData] = useState([]);

  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const [manualBrand, setManualBrand] = useState("");
  const search = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = userManualData?.manuallist?.filter((item) => {
        return item?.product_name.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });

      let data = {
        manuallist: results,
      };

      setManualBrand(data);
    } else {
      setManualBrand(userManualData);

      // If the text field is empty, show all users
    }

    setProductName(keyword);
  };

  const handleScrollLeft = () => {
    const container = containerRef.current;
    const scrollAmount = 200; // Adjust this value to control the scroll amount
    container.scrollLeft -= scrollAmount;
    setScrollLeft(container.scrollLeft);
  };

  const handleScrollRight = () => {
    const container = containerRef.current;
    const scrollAmount = 200; // Adjust this value to control the scroll amount
    container.scrollLeft += scrollAmount;
    setScrollLeft(container.scrollLeft);
  };

  const handleFilter = (clickedname) => {
    const filtered = userManualData?.manuallist?.filter(
      (item) => item?.brand_name === clickedname?.brand_name
    );

    if (filtered?.length !== "") {
      let data = {
        manuallist: filtered,
      };
      setManualBrand(data);
    } else {
      setManualBrand(userManualData);
    }
  };

  const manual = userManualData?.brandlist?.map((el) => el?.brand_icon_url);

  for (let i = 0; i < manual?.length; i++) {}

  useEffect(() => {
    let data = {
      hospid: localStorageId?.hospid,
      userid: localStorageId?.userid,
    };
    dispatch(userManual(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (
      userManualData &&
      userManualData?.brandlist?.length > 0 &&
      userManualData?.manuallist?.length > 0
    ) {
      setManualData(userManualData);
    }
  }, [userManualData]);
  useEffect(() => {
    if (userManualData?.brandlist?.length > 0) {
      setBrandData(userManualData?.brandlist);
    }
  }, [userManualData?.brandlist]);
  useEffect(() => {
    if (userManualData?.manuallist?.length > 0) {
      let data = {
        manuallist: userManualData?.manuallist,
      };
      setManualBrand(data);
    }
  }, [userManualData?.manuallist]);

  const arrowStyle = {
    background: "transparent",
    border: 0,
    color: "black",
    fontSize: "80px",
  };

  return (
    <MainDiv>
      <TopContainer>
        <TitleHeading>User Manual</TitleHeading>

        <SortFilterContainer>
          <Container>
            <Search
              type="search"
              value={productName}
              placeholder="Search"
              onChange={search}
              sx={{
                width: 230,
                height: "40px",
                border: "1px solid rgba(23, 70, 162, 0.5)",
                bgcolor: "white",

                borderRadius: "36px",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    placeholder="Search"
                    aria-label="toggle password visibility"
                    edge="start">
                    <SearchOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Container>
        </SortFilterContainer>
      </TopContainer>
      <Slider
        sx={{
          // border: "1px solid rgba(23, 70, 162, 0.50)",
          bgcolor: "white",
          borderRadius: "10px",
          marginTop: "10px",
          marginBottom: "10px",
        }}>
        <Box
          sx={{
            borderRadius: "5px",
            marginTop: "10px",
            width: "100%",
            display: "flex",
          }}>
          <IconButton onClick={handleScrollLeft}>
            <ChevronLeftIcon />
          </IconButton>
          <TopBox
            sx={{ bgcolor: "white", cursor: "pointer" }}
            ref={containerRef}>
            {brandData?.map((imageUrl, index) => (
              <>
                <ImageCard onClick={() => handleFilter(imageUrl)}>
                  <Box
                    as="img"
                    sx={{ height: "80px", width: "80px" }}
                    src={imageUrl?.brand_icon_url}
                  />

                  <ButtonText value={imageUrl?.brand_name}>
                    {imageUrl?.brand_name}
                  </ButtonText>
                </ImageCard>
              </>
            ))}
          </TopBox>
          <IconButton onClick={handleScrollRight}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Slider>

      <InnerContainer sx={{ bgcolor: "white" }}>
        <BoxWrapper>
          {manualBrand?.manuallist?.map((el) => (
            <Boxes
              key={el?.prod_id}
              onClick={() =>
                dispatch(DownloadPDF(el?.manual_link, el?.product_name))
              }
              sx={{
                textAlign: "left",
                boxShadow: "0px 4px 8px 0px rgba(0, 79, 149, 0.25)",
                borderRadius: "10px",
                cursor: "pointer",
              }}>
              <Box sx={{ width: "90%" }}>
                <Typography variant="body2" sx={{ color: "#004F95" }}>
                  {el?.product_name}
                </Typography>
                <Typography variant="body2">{el?.brand_name}</Typography>
                <Typography variant="body2">{el?.model_no}</Typography>
              </Box>
              <Box
                as="img"
                sx={{
                  height: "50%",
                  marginTop: "20px",
                }}
                src={pdf}
              />
            </Boxes>
          ))}{" "}
        </BoxWrapper>

        <>
          <Box sx={{ marginTop: "3%", marginBottom: "8%" }}>
            <Button
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              variant="contained"
              onClick={() => navigate("/contact")}>
              Request Manual
            </Button>
          </Box>
        </>
      </InnerContainer>
    </MainDiv>
  );
};

export default UserManual;
