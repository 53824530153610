import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  FormHelperText,
  Input,
  OutlinedInput,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { OutboxOutlined } from "@mui/icons-material";
import pdfImage from "../images/pdfImage.png";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TableBody from "@mui/material/TableBody";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { v4 as uuid } from "uuid";
import TableContainer from "@mui/material/TableContainer";
import emptyAsset from "../images/emptyAsset.png";
import { useDispatch, useSelector } from "react-redux";
import {
  assetDetailsData,
  createIncident,
  getIncidentHistory,
  recordIncidentImageDelete,
  recordIncidentImageUpload,
} from "../redux/nbmData/action";
import SuccessModal from "../components/SuccessModal";
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Record = styled(Box)(({ theme }) => ({
  height: "300px",
  padding: "10px",
  borderRadius: "30px",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const History = styled(Box)(({ theme }) => ({
  height: "300px",
  padding: "10px",

  overflow: "auto",
  borderRadius: "30px",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",
  [theme.breakpoints.down("xl")]: {
    height: "300px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: "10px",
  justifyContent: "center",
  width: "70%",
  gap: "30px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "16px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "16px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "16px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const IssueInput = styled(OutlinedInput)(({ theme }) => ({
  bgcolor: "white",
  width: "100%",
  height: "306px",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "300px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomBox = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  width: "70%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const HistoryCard = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  display: "flex",

  alignItems: "center",
  margin: "auto",
  marginBottom: "10px",
  border: "1px solid #000",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",
  width: "95%",
  height: "129px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fonteight: "500",
  textAlign: "left",
  color: "black",
  [theme.breakpoints.down("xl")]: {
    fonteight: "500",
    fontSize: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const MiniText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fonteight: "400",
  textAlign: "left",
  color: "black",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "14px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "14px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "14px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const NarrowText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "left",
  fonteight: "400",
  color: "black",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "14px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "14px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "14px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "14px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerCard = styled(Box)(({ theme }) => ({
  width: "85%",
  paddingLeft: "5px",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "left",
  flexDirection: "column",

  height: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const StatusButton = styled(Button)(({ theme }) => ({
  borderRadius: "24px",
  background: "#00A884",
  color: "white",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextContainer = styled(Box)(({ theme }) => ({
  // height: "70%",
  width: "100%",
  display: "flex",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentTable = styled(Table)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableCard = styled(TableCell)(({ theme }) => ({
  width: 10,
  // border:'2px solid green',
  borderBottom: "0",
  fontSize: "16px",
  padding: "10px",
  paddingLeft: "16px",
  [theme.breakpoints.down("xl")]: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: "5px",
    fontSize: "12px",
    paddingBottom: "5px",
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: "5px",
    fontSize: "12px",
    paddingBottom: "5px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: "5px",

    paddingBottom: "5px",
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "15px",
  textTransform: "none",
  bgcolor: "#ADC2EC",
  color: "#1746A2",
  "&:hover": {
    backgroundColor: "#ADC2EC",
    color: "#1746A2",
  },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadImageBox = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(23, 70, 162, 0.2)",
  boxShadow: "0px 0px 2px #EAF1FF",
  width: "232px",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  // height:'100%'
  borderRadius: "8px",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "164px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "164px",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: "8px",
  width: "40px",
  height: "40px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    marginLeft: "4px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const DotedBox = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  cursor: "pointer",

  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ImageOuterBox = styled(Box)(({ theme }) => ({
  width: "282px",

  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "186px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "186px",
  },
}));

const Photo = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  marginBottom: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  justifyContent: "end",

  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  [theme.breakpoints.down("sm")]: {},
}));

function RecordIncident() {
  let params = useParams();
  let assetId = params.id;
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const detailData = useSelector((store) => store.nbmData.assetDetails);
  const token = localStorage.getItem("userToken");
  const historyDetails = useSelector((store) => store.nbmData.incidentHistory);
  const response = useSelector((store) => store.nbmData.createIncident);

  const [fileUrl, setFileUrl] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState([]);
  const [issue, setIssue] = useState("");
  const [isIssueError, setIsIssueError] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  const [certificateFlag, setCertificateFlag] = useState(false);
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [isCertificateError, setIsCertificateError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(false);
  const text = "Recorded Incident";
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    navigate(-1);
  };
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        //setIsPhotoError("Greater than 2 MB can't be uploaded");

        // Compress the image if size is greater than 2MB
        compressImage(file);

        //return false;
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${"assetId_"}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(recordIncidentImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${"assetId_"}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${"assetId_"}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(recordIncidentImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    dispatch(recordIncidentImageDelete(data));
    setFileUrl(fileUrl?.filter((item) => item?.imageid !== id));
  };

  const handleIssue = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;: ]+$/;

    if (value === "" || regex.test(value)) {
      setIssue(value);
      setIsIssueError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsIssueError("Invalid character");
    }
    if (value === "") {
      setIsIssueError("Please fill the field");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(getIncidentHistory(data, token));
  }, [dispatch]);

  const handleSubmit = () => {
    issue === "" && setIsIssueError("Please fill the field");
    if (issue !== "" && isIssueError === "") {
      let data = {
        hospid: userIds?.hospid,
        hospname: userIds?.hospname,
        username: userIds?.username,
        userid: userIds?.userid,
        userrole: userAccess?.role,
        departid:detailData?.departid,
        assetname: detailData?.name,
        assetcode: detailData?.code,
        serialno: detailData?.serialno,
        branchid: userBranch?.id,
        assetid: params?.id,
        departname: detailData?.dept,
        incident_description: issue,
        incident_images:
          fileUrl?.length > 0
            ? fileUrl?.map((item) => {
                return item?.imageid;
              })
            : "NA",
      };
      setOpenModal(true);
      setToggle(true);
      dispatch(createIncident(data));
    }
  };

  useEffect(() => {
    if (response === "Inserted" && issue !== "") {
      setOpenModal(true);
    }
  }, [response]);

  return (
    <Wrapper>
      <TopContainer>
        <LeftBox
          sx={{
            width: historyDetails?.length === 0 ? "100%" : "50%",
          }}>
          <TitleHead textAlign={"left"}>Record Incident</TitleHead>
          <Record
            sx={{
              bgcolor: "white",
              width: historyDetails?.length === 0 ? "100%" : "100%",
            }}>
            <TextContainer>
              <TableContainer sx={{}}>
                <IncidentTable aria-label="custom pagination table">
                  <TableBody>
                    <TableRow>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        Asset Name
                      </TableCard>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        :
                      </TableCard>
                      <TableCard style={{ width: 10 }}>
                        {detailData?.name}
                      </TableCard>
                    </TableRow>

                    <TableRow>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        Brand
                      </TableCard>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        :
                      </TableCard>
                      <TableCard style={{ width: 10 }}>
                        {detailData?.brand}
                      </TableCard>
                    </TableRow>

                    <TableRow>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        Department
                      </TableCard>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        :
                      </TableCard>
                      <TableCard style={{ width: 10 }}>
                        {detailData?.dept}
                      </TableCard>
                    </TableRow>

                    <TableRow>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        Serial Number
                      </TableCard>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        :
                      </TableCard>
                      <TableCard style={{ width: 10 }}>
                        {detailData?.serialno}
                      </TableCard>
                    </TableRow>

                    <TableRow>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        Model Number
                      </TableCard>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        :
                      </TableCard>
                      <TableCard style={{ width: 10 }}>
                        {detailData?.modelno}
                      </TableCard>
                    </TableRow>

                    <TableRow>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        Asset code
                      </TableCard>
                      <TableCard
                        sx={{ color: "#1746A2" }}
                        component="th"
                        scope="row">
                        :
                      </TableCard>
                      <TableCard style={{ width: 10 }}>
                        {detailData?.code}
                      </TableCard>
                    </TableRow>
                  </TableBody>
                </IncidentTable>
              </TableContainer>
            </TextContainer>
          </Record>
        </LeftBox>
        {historyDetails?.length !== 0 && (
          <RightBox
            sx={{
              width: historyDetails?.length !== 0 ? "50%" : "",
            }}>
            <TitleHead textAlign={"left"}>History</TitleHead>
            <History
              sx={{
                bgcolor: "white",
                width: historyDetails?.length !== 0 ? "100%" : "",
              }}>
              {historyDetails !== 0 &&
                historyDetails?.map((item) => (
                  <HistoryCard>
                    <InnerCard>
                      <SubTitle>{item?.assetname}</SubTitle>
                      <MiniText>
                        <Typography>
                          Resolved time: {item?.resolvetime}
                        </Typography>
                      </MiniText>
                      <NarrowText>Date: {item?.incidcleardate}</NarrowText>
                    </InnerCard>
                    <StatusButton
                      size="small"
                      sx={{ textTransform: "none", marginRight: "2%" }}>
                      Cleared
                    </StatusButton>
                  </HistoryCard>
                ))}
            </History>
          </RightBox>
        )}
      </TopContainer>

      <BottomBox>
        <TitleHead textAlign={"left"}>Describe the issue</TitleHead>
        <IssueInput
          onChange={handleIssue}
          sx={{ bgcolor: "#EFF5FE" }}
          placeholder="Enter issue here..."
        />
        <HelperText>{isIssueError}</HelperText>
      </BottomBox>

      <BottomBox>
        <>
          <ButtonWrapper sx={{}}>
            <>
              <UploadButton
                onClick={() => setPhotoFlag(!photoFlag)}
                sx={{
                  bgcolor: "#ADC2EC",
                }}>
                <AddPhotoAlternateIcon sx={{ color: "#1746A2" }} />
                Upload Photos
              </UploadButton>
            </>
          </ButtonWrapper>
          {photoFlag && (
            <>
              <ButtonWrapper
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <ImageOuterBox
                  sx={{
                    textAlign: "start",
                    color: "#1746A2",
                  }}>
                  Upload photos
                  <UploadImageBox>
                    {uploadedImageName?.length < 3 && (
                      <>
                        <DotedBox>
                          <label for="img">
                            {" "}
                            <AddIcon
                              sx={{ marginTop: "2%", cursor: "pointer" }}
                            />
                          </label>
                        </DotedBox>
                        <input
                          type="file"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",

                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handlePhotoUpload}
                        />
                      </>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {fileUrl?.map((item, index) => (
                        <ImageBox key={index} className="image-item">
                          <Badge
                            sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                            badgeContent={
                              <RemoveCircleIcon
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  color: "red",
                                }}
                                onClick={() => removePhoto(item?.imageid)}
                              />
                            }>
                            <Photo as="img" src={item?.image} alt={""} />
                          </Badge>
                        </ImageBox>
                      ))}
                    </Box>
                  </UploadImageBox>
                  <Box style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    <>
                      {photoFlag && (
                        <HelperText color="#1746A2">
                          format should be either in jpg, jpeg or png
                        </HelperText>
                      )}
                    </>
                    {isPhotoError && (
                      <HelperText sx={{ color: "red" }}>
                        {isPhotoError}
                      </HelperText>
                    )}
                  </Box>
                </ImageOuterBox>
              </ButtonWrapper>
            </>
          )}

          <ButtonBox>
            <Button
              variant="contained"
              sx={{
                borderRadius: "35px",
                bgcolor: "white",
                textTransform: "none",
                color: "#3c52b2",
                border: "1px solid #1746A2",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#3c52b2",
                },
              }}
              onClick={() => navigate(-1)}
              size="large">
              Cancel
            </Button>

            <Button
              size="large"
              disabled={userAccess?.role === "Guest"}
              onClick={handleSubmit}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              variant="contained">
              Submit
            </Button>
          </ButtonBox>
        </>
      </BottomBox>
      <SuccessModal
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        response={response}
        text={text}
        toggle={toggle}
      />
    </Wrapper>
  );
}

export default RecordIncident;
