import styled from "@emotion/styled";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import assetIcon from "../images/assetIcon.png";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";
import ProgressBar from "@ramonak/react-progress-bar";

import { Amc, Cmc, getDashboard } from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HalfRoundedChart from "../components/HalfChart";
import Unassigned from "../components/Unassigned";
import NoUserFound from "../components/NoUserFound";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  bgcolor: "white",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Main = styled(Box)(({ theme }) => ({
  bgcolor: "white",
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "90%",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "500px",
    //
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const DashBox = styled(Box)(({ theme }) => ({
  paddingTop: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: "#FF731D",
  fontWeight: "500",
  textAlign: "left",
  fontSize: "30px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SimpleText = styled(Typography)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "20px",
  textAlign: "left",
  fontWeight: "500",
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BarGraphBox = styled(Box)(({ theme }) => ({
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
  borderRadius: "20px",
  // border: "1px solid rgba(247, 129, 23, 0.25)",
  background: "#FFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  // width: "610px",

  width: "50%",
  height: " 400px",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",
  [theme.breakpoints.down("xl")]: {
    // width: "540px",
    width: "50%",
    height: "400px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "500px",
    // width: "100%",
    height: "400px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "324px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "324px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const AlphaBox = styled(Box)(({ theme }) => ({
  marginTop: "18px",
  display: "flex",
  gap: "18px",

  width: "90%",
  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BetaBox = styled(Box)(({ theme }) => ({
  bgcolor: "white",
  marginTop: "18px",
  display: "flex",
  gap: "18px",
  width: "90%",

  // justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const GamaBox = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "40%",
  gap: "18px",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    width: "500px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightBarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "20%",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: "18px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "500px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBarBox = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "40%",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: "18px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "500px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DownTimeBox = styled(Box)(({ theme }) => ({
  padding: "10px",

  minWidth: "100%",
  minHeight: "196px",
  borderRadius: "20px",
  // border: "1px solid rgba(247, 129, 23, 0.25)",
  bgcolor: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",

  [theme.breakpoints.down("xl")]: {
    // width: "370px",

    minHeight: "196px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "500px",
    minHeight: "196px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "196px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "196px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
  // border: "1px solid rgba(247, 129, 23, 0.25)",
  bgcolor: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",
  [theme.breakpoints.down("xl")]: {
    // width: "370px",
    height: "196px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "500px",
    height: "196px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "196px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentInner = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  justifyContent: "space-evenly",
  gap: "15px",
  padding: "10px",
  // height: "200px",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TotalIncidents = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  width: "70%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TotalText = styled(Typography)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "left",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CalibrationBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  width: "100%",
  height: "156px",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  border: "1px solid rgba(247, 129, 23, 0.25)",
  bgcolor: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingTop: "10px",
    height: "156px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AmcCmcBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  width: "100%",
  height: "116px",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  flexDirection: "column",
  border: "1px solid rgba(247, 129, 23, 0.25)",
  bgcolor: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingTop: "10px",
    height: "126px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const WarrantyBox = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  width: "100%",
  height: "100px",
  paddingTop: "10px",

  borderRadius: "20px",
  border: "1px solid rgba(247, 129, 23, 0.25)",
  bgcolor: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",

  [theme.breakpoints.down("xl")]: {
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "500px",
    // height: "196px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const WarrantyText = styled(Typography)(({ theme }) => ({
  color: "#212427",
  fontSize: "16px",

  fontWeight: 500,

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
// WarrantySet

const WarrantySet = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "9px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBarBox = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  gap: "10px",
  width: "95%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AmcCmcInnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  // flexDirection: "column",
  gap: "10px",
  width: "95%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PiChartBox = styled(Box)(({ theme }) => ({
  paddingTop: "10px",
  paddingBottom: "10px",
  width: "50%",
  // width: "688px",
  height: "100%",
  borderRadius: "20px",
  border: "1px solid rgba(247, 129, 23, 0.25)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",

  [theme.breakpoints.down("xl")]: {
    // width: "518px",
    width: "50%",
    // height: "351px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "500px",
    // height: "351px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "241px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyBoxWrapper = styled(Box)(({ theme }) => ({
  height: "80%",
  width: "100%",
  display: "flex",
  padding: "20px",
  gap: "20px",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AssetServicesBox = styled(Box)(({ theme }) => ({
  paddingTop: "10px",
  paddingBottom: "10px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "20px",
  border: "1px solid rgba(247, 129, 23, 0.25)",
  bgcolor: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",

  [theme.breakpoints.down("xl")]: {
    // minWidth: "212px",
    minWidth: "180px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "500px",
    // minWidth: "212px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "180px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ChartOuter = styled(Box)(({ theme }) => ({
  width: "500px",
  height: "300px",

  marginBottom: "20px",

  [theme.breakpoints.down("xl")]: {
    width: "400px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "400px",
    height: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "300px",
    height: "205px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "270px",
    height: "205px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const BarBox = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "space-between",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const OuterBarBox = styled(Box)(({ theme }) => ({
  width: "40%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleNumber = styled(Box)(({ theme }) => ({
  width: "50%",

  paddingLeft: "10px",
  // margin:'auto',

  // width: "247px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    // width:"140px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const CustomTabs = styled(Tab)(({ theme }) => ({
  height: "20px",
  textTransform: "none",
  color: "#212427",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const UltraSubTitle = styled(Typography)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "left",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TimeBox = styled(Box)(({ theme }) => ({
  height: "80%",

  width: "100%",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Time = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "500",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const DetailText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  // color: "#212427",
  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CalibrationText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "550",

  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  color: "#212427",
  fontSize: "16px",

  fontWeight: 500,

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DownleTimeText = styled(Typography)(({ theme }) => ({
  color: "#212427",
  fontSize: "16px",

  fontWeight: 500,

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AddAsset = styled(Button)(({ theme }) => ({
  borderRadius: "28px",

  textTransform: "none",
  fontSize: "18px",

  fontWeight: 500,

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "50px",
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubBox = styled(Box)(({ theme }) => ({
  height: "50%",
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerSubBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90%",

  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PiControl = styled(Box)(({ theme }) => ({
  //

  width: "500px",
  height: "340px",

  [theme.breakpoints.down("xl")]: {
    width: "500px",
    height: "330px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "400px",
    height: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "250px",
    height: "200px",
    //
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PiWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BarControle = styled(Box)(({ theme }) => ({
  width: "40%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentBarBox = styled(Box)(({ theme }) => ({
  paddingLeft: "10px",
  display: "flex",
  width: "100%",

  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentBarTextBox = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "40%",
  justifyContent: "space-between",
  marginLeft: "20px",
  gap: "30px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "135px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const GraphTop = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export const data = [
  ["Departments", "Incident"],
  ["Departments Asset", 11],
  ["ECG Asset", 2],
  ["ECHO Asset", 2],
  ["Op Asset", 2],
  ["ICU Asset", 7],
];

const options = {
  is3D: true,
  legend: {
    textStyle: {
      fontSize: 15,
    },
    alignment: "center",
    margin: "10px",
    padding: "10px",
    itemMargin: "5px",
    itemTextPadding: "5px",
    itemCheckboxGap: 20,
    itemGap: 10,
  },
  chartArea: { left: 10, top: 20, width: "90%", height: "90%" },
  pieSliceText: "percentage",
  sliceVisibilityThreshold: 0.02,
  tooltip: {
    textStyle: {},
  },
  slices: {
    0: { color: "rgb(141, 202, 246)" },
    1: { color: "rgb(209, 170, 251)" },
    2: { color: "rgb(96, 206, 169)" },
    3: { color: "rgb(253, 158, 2)" },
    4: { color: "rgb(254, 210, 149)" },
  },
  enableInteractivity: true,
  pieHole: 0.4,
};

function Dashboard() {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  const [assigned, setAssigned] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  // let accessChecker = Object.keys(userAccess)?.length===0;
  const dashboardData = useSelector((store) => store.data.dashboard);
  const [value, setValue] = React.useState("day");
  const [selectedGraph, setSelectedGraph] = React.useState([]);
  const [weeksCount, setWeeksCount] = React.useState(null);
  const [assetDetails, setAssetDetails] = React.useState(null);

  const [totalAsset, setTotalAsset] = useState("");
  const [incidents, setIncidents] = React.useState(null);
  const [serviceCounts, setServiceCounts] = React.useState(null);

  const [departIds, setDepartIds] = React.useState(null);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });

  const [department, setDepartCounts] = useState([]);
  const [dayCounts, setDayCounts] = useState(null);
  const [monthCount, setMonthCount] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboardData && dashboardData?.departcounts) {
      setDepartCounts(dashboardData?.departcounts);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (
      dashboardData &&
      dashboardData?.assetcounts &&
      dashboardData?.assetcounts.length > 0
    ) {
      const totalAssetsValue = parseInt(
        dashboardData?.assetcounts[0].totalassets,
        10
      );
      setTotalAsset(totalAssetsValue);
    }
  }, [dashboardData]);

  const options2 = {
    vAxis: {
      title: "Count",
      minValue: 0,
      maxValue: 100,
      ticks: [0, 25, 50, 75, 100],
    },
    legend: { position: "none" },
    chart: {
      chartType: "Bar",
    },
  };

  const convertData = (data) => {
    const convertedData = [["", "Incident"]];
    data?.forEach(([label, value]) => {
      const numberValue = Number(value);
      if (!isNaN(numberValue)) {
        convertedData.push([label, numberValue]);
      }
    });

    return convertedData;
  };

  const departmentData = convertData(dashboardData?.departcounts);

  const days = convertData(dayCounts);
  const weeks = convertData(weeksCount);
  const months = convertData(monthCount);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchname: userBranch.branch,
      branchid: userBranch.id,
    };
    dispatch(getDashboard(data, token));
  }, []);

  useEffect(() => {
    if (
      dashboardData?.assetcounts?.length > 0 &&
      dashboardData?.assetcounts?.length !== undefined
    ) {
      setAssetDetails(dashboardData?.assetcounts[0]);
    }
    if (
      dashboardData?.servicecounts?.length > 0 &&
      dashboardData?.servicecounts?.length !== undefined
    ) {
      setServiceCounts(dashboardData?.servicecounts[0]);
    }

    if (
      dashboardData?.departids?.length > 0 &&
      dashboardData?.departids?.length !== undefined
    ) {
      setDepartIds(dashboardData?.departids);
    }

    if (
      dashboardData?.incidents?.length > 0 &&
      dashboardData?.incidents?.length !== undefined
    ) {
      setIncidents(dashboardData?.incidents[0]);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (
      dashboardData?.incidentweekcounts?.length > 0 &&
      dashboardData?.incidentweekcounts !== undefined
    ) {
    }
    setWeeksCount(dashboardData?.incidentweekcounts);
    setDayCounts(dashboardData?.incidentcounts);
    setMonthCount(dashboardData?.incidentmonthcounts);

    if (dashboardData?.totaldowntime !== undefined) {
      setDowntime(dashboardData?.totaldowntime);
    }
  }, [dashboardData]);

  let notCleared = incidents?.openincidents;
  let cleared = incidents?.clearedincidents;
  let totalIncident = incidents?.totalincidents;
  const incidentPercentage = (count) => {
    if (count == 0) {
      return 0;
    }
    if (count != 0) {
      const totalCount = Number(cleared) + Number(notCleared);

      return (count / totalCount) * 100;
    }
  };

  const clearedCount = incidentPercentage(cleared);
  const notClearedCount = incidentPercentage(notCleared);

  let calibrated = assetDetails?.calibrated;
  let notCalibrated = assetDetails?.notcalibrated;
  let notRequired = assetDetails?.notrequired;

  const calibrationPercentage = (count) => {
    const totalCount =
      Number(calibrated) + Number(notCalibrated) + Number(notRequired);

    return (count / totalCount) * 100;
  };

  const calibratedCount = calibrationPercentage(calibrated);
  const notCalibratedCount = calibrationPercentage(notCalibrated);
  const notRequiredCount = calibrationPercentage(notRequired);

  let totalAmc = assetDetails?.total_amcs;
  let totalCmc = assetDetails?.total_cmcs;

  const amcCmcPercentage = (count) => {
    const totalCount = Number(totalAmc) + Number(totalCmc);

    return (count / totalCount) * 100;
  };

  const amcPercentage = amcCmcPercentage(totalAmc);
  const cmcPercentage = amcCmcPercentage(totalCmc);

  const handleDepartmentId = (value) => {
    const id = departIds?.find((item) => {
      return item[0] === value;
    });
    let departId = id[1];
    navigate(`/department-asset-details/${departId}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "day") {
      // alert("day")
      setSelectedGraph(days);
    }
    if (newValue === "week") {
      // alert("week")
      setSelectedGraph(weeks);
    }
    if (newValue === "month") {
      // alert("month")
      setSelectedGraph(months);
    }
  };

  useEffect(() => {
    if (
      dashboardData === "AUTHENTICATION_ERROR" ||
      userAccess?.act_status === "Inctive"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  }, [dashboardData, navigate, userAccess]);

  const convertTime = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } =
      downtime;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} mon${totalDuration.months() > 1 ? "s" : ""}`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };
  // useEffect(() => {
  //   let data = {
  //     hospid: userIds.hospid,
  //     userid: userIds.userid,
  //     branchid: userBranch?.id,
  //     depart_ids: userAccess.departmentids,
  //   };
  //   dispatch(Cmc(data, token));
  //   dispatch(Amc(data, token));
  // }, [dispatch,dashboardData]);

  const total = department.reduce(
    (accumulator, [_, number]) => accumulator + parseInt(number),
    0
  );

  let modifiedDepartmentData = [...departmentData];

  if (departmentData.length >= 6) {
    modifiedDepartmentData = [
      ...departmentData,
      ["Others", totalAsset - total],
    ];
  }

  useEffect(() => {
    // setAssigned(userAccess?.departments[0]==="UN_ASSIGNED")

    if (userAccess && Object.keys(userAccess)?.length !== 0) {
      setAssigned(userAccess?.departments[0] === "UN_ASSIGNED");
    }
    if (userAccess && Object.keys(userAccess)?.length === 0) {
      setUserToggle(Object.keys(userAccess)?.length === 0);
    }
  }, [userAccess]);

  return (
    <>
      {dashboardData !== "AUTHENTICATION_ERROR" && (
        <Wrapper>
          <Main>
            <TopBox>
              <DashBox>
                <TitleText>Biomedical Dashboard</TitleText>
                <SimpleText>Monitor your machines easily.</SimpleText>
              </DashBox>
            </TopBox>

            <AlphaBox>
              <BarGraphBox>
                <GraphTop sx={{}}>
                  <UltraSubTitle
                    sx={{
                      // marginLeft: "35px",
                      marginTop: { sm: "5px", xs: "5px" },
                    }}>
                    Total Incident Record
                  </UltraSubTitle>
                  <Box sx={{ marginBottom: "20px" }}>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#F78117",
                        },
                      }}
                      sx={{ height: "10px" }}
                      value={value}
                      onChange={handleChange}>
                      <CustomTabs value="day" label="Day" />
                      <CustomTabs value="week" label="Week" />
                      <CustomTabs value="month" label="Month" />
                    </Tabs>
                  </Box>
                </GraphTop>
                <ChartOuter>
                  <Chart
                    style={{}}
                    chartType="Bar"
                    width="100%"
                    height="100%"
                    data={value === "day" ? days : selectedGraph}
                    options={options2}
                  />
                </ChartOuter>
              </BarGraphBox>

              <PiChartBox sx={{ bgcolor: "white" }}>
                <Box sx={{ marginLeft: "20px", width: "90%" }}>
                  <UltraSubTitle>Department Asset</UltraSubTitle>
                </Box>

                {assetDetails?.totalassets === undefined ||
                departmentData?.length <= 1 ? (
                  <EmptyBoxWrapper>
                    <EmptyText>
                      Please add your department and check the asset details.
                    </EmptyText>
                    {departmentData?.length <= 1 && (
                      <AddAsset
                        disabled={userAccess?.adddepart !== "Yes"}
                        onClick={() => navigate("/add-department")}
                        variant="contained"
                        sx={{
                          color: "white",
                          bgcolor: "#1746A2",
                        }}>
                        Add Department
                      </AddAsset>
                    )}
                    {departmentData?.length > 1 && (
                      <AddAsset
                        disabled={userAccess && userAccess?.addprod !== "Yes"}
                        onClick={() => navigate("/add-asset")}
                        variant="contained"
                        sx={{
                          color: "white",
                          bgcolor: "#1746A2",
                        }}>
                        Add Asset
                      </AddAsset>
                    )}
                  </EmptyBoxWrapper>
                ) : (
                  <PiWrapper>
                    <PiControl sx={{ cursor: "pointer" }}>
                      <Chart
                        chartType="PieChart"
                        // data={departmentData}
                        data={modifiedDepartmentData}
                        options={options}
                        margin-top="10px"
                        width={"95%"}
                        height="100%"
                        chartEvents={[
                          {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                              const selection = chartWrapper
                                .getChart()
                                .getSelection();
                              if (selection?.length) {
                                const selectedRow = selection[0]?.row;
                                const selectedDepartment =
                                  modifiedDepartmentData[selectedRow + 1][0];

                                if (selectedDepartment === "Others") {
                                  navigate("/all-assets");
                                } else {
                                  handleDepartmentId(selectedDepartment);
                                }
                              }
                            },
                          },
                        ]}
                      />
                    </PiControl>
                  </PiWrapper>
                )}
              </PiChartBox>
            </AlphaBox>

            <BetaBox>
              <LeftBarBox>
                <DownTimeBox
                  sx={{ bgcolor: "white", cursor: "pointer" }}
                  onClick={() => navigate("/closed-incident")}>
                  <Box sx={{ marginLeft: "20px" }}>
                    <UltraSubTitle>Total Downtime</UltraSubTitle>
                  </Box>
                  <TimeBox>
                    <Box
                      sx={{
                        display: "flex",

                        width: "80%",
                        gap: "20px",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}>
                      <ManageHistoryIcon
                        sx={{
                          fontSize: {
                            xl: "60px",
                            lg: "60px",
                            md: "40px",
                            sm: "60px",
                            xs: "40px",
                          },
                          color: "#FF8F4A",
                        }}
                      />
                      <Box>
                        <DownleTimeText>
                          Total Assets in downtime{" "}
                        </DownleTimeText>
                        <Time>{convertTime()} </Time>
                      </Box>

                      {convertTime() === "" && (
                        <Typography sx={{ color: "rgb(200, 200, 200)" }}>
                          {" "}
                          Not available{" "}
                        </Typography>
                      )}
                    </Box>
                  </TimeBox>
                </DownTimeBox>
                <IncidentBox sx={{ bgcolor: "white" }}>
                  <Box sx={{ width: "90%" }}>
                    <UltraSubTitle>Incident Management</UltraSubTitle>
                  </Box>
                  <IncidentInner>
                    <TotalIncidents onClick={() => navigate("/incident-list")}>
                      <ArticleOutlinedIcon
                        sx={{
                          fontSize: "40px",
                          color: "#FF731D",
                          cursor: "pointer",
                        }}
                      />
                      <TotalText sx={{ cursor: "pointer" }}>
                        Total Incident :{" "}
                        <span sx={{ color: "#FF731D" }}>
                          {totalIncident === undefined ? 0 : totalIncident}
                        </span>{" "}
                      </TotalText>
                    </TotalIncidents>
                    <IncidentBarBox
                      onClick={() => navigate("/closed-incident")}>
                      {" "}
                      <BarControle>
                        <ProgressBar
                          bgColor="#61CEAA"
                          isLabelVisible={false}
                          height="20px"
                          width="100%"
                          completed={isNaN(clearedCount) ? 0 : clearedCount}
                        />
                      </BarControle>{" "}
                      <IncidentBarTextBox>
                        <Typography sx={{ fontSize: "14px" }}>
                          <span style={{ color: "rgb(97, 206, 170)" }}>
                            {cleared === undefined ? 0 : cleared}
                          </span>{" "}
                          Cleared
                        </Typography>{" "}
                      </IncidentBarTextBox>{" "}
                    </IncidentBarBox>

                    <IncidentBarBox onClick={() => navigate("/open-incident")}>
                      {" "}
                      <BarControle>
                        <ProgressBar
                          bgColor="#E56D82"
                          isLabelVisible={false}
                          height="20px"
                          width="100%"
                          completed={
                            isNaN(notClearedCount) ? 0 : notClearedCount
                          }
                        />{" "}
                      </BarControle>
                      <IncidentBarTextBox>
                        <Typography sx={{ fontSize: "14px" }}>
                          <span style={{ color: "#E56D82" }}>
                            {notCleared === undefined ? 0 : notCleared}
                          </span>{" "}
                          Not Cleared
                        </Typography>{" "}
                      </IncidentBarTextBox>{" "}
                    </IncidentBarBox>
                  </IncidentInner>
                </IncidentBox>
              </LeftBarBox>

              <RightBarBox>
                <AssetServicesBox sx={{ bgcolor: "white", cursor: "pointer" }}>
                  <SubBox>
                    <Box sx={{ marginLeft: "20px", width: "90%" }}>
                      <UltraSubTitle>Asset</UltraSubTitle>
                    </Box>

                    <InnerSubBox onClick={() => navigate("/all-assets")}>
                      <Box
                        as="img"
                        src={assetIcon}
                        sx={{
                          width: "40px",
                          heigth: "40px",
                          marginTop: "15px",
                        }}
                      />
                      <DetailText sx={{ cursor: "pointer" }}>
                        Total Asset :{" "}
                        <span style={{ color: "#FF731D" }}>
                          {assetDetails?.totalassets === undefined
                            ? 0
                            : assetDetails?.totalassets}
                        </span>
                      </DetailText>
                    </InnerSubBox>
                  </SubBox>
                  <SubBox>
                    <Box sx={{ marginLeft: "20px", width: "90%" }}>
                      <UltraSubTitle>Services</UltraSubTitle>
                    </Box>

                    <InnerSubBox>
                      <SettingsOutlinedIcon
                        onClick={() => navigate("/service-list/")}
                        sx={{
                          cursor: "pointer",
                          fontSize: {
                            xl: "60px",
                            lg: "50px",
                            md: "40px",
                            sm: "40px",
                            xs: "40px",
                          },
                          color: "#FF8F4A",
                        }}
                      />
                      <DetailText
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/service-list/")}>
                        Total Service :{" "}
                        <span style={{ color: "#FF731D" }}>
                          {serviceCounts?.totalservice === undefined
                            ? 0
                            : serviceCounts?.totalservice}
                        </span>
                      </DetailText>
                      <DetailText
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/open-service")}>
                        Open Service :{" "}
                        <span style={{ color: "#FF731D" }}>
                          {isNaN(serviceCounts?.servicerequested)
                            ? 0
                            : serviceCounts?.servicerequested}
                        </span>
                      </DetailText>
                      <DetailText
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/closed-service/")}>
                        Closed Service :{" "}
                        <span style={{ color: "#FF731D" }}>
                          {serviceCounts?.servicecleared === undefined
                            ? 0
                            : serviceCounts?.servicecleared}
                        </span>
                      </DetailText>
                    </InnerSubBox>
                  </SubBox>
                </AssetServicesBox>
              </RightBarBox>

              <GamaBox>
                <CalibrationBox sx={{ bgcolor: "white" }}>
                  <Box sx={{ width: "90%" }}>
                    <UltraSubTitle>Calibration status</UltraSubTitle>
                  </Box>
                  <InnerBarBox>
                    <BarBox>
                      {" "}
                      <OuterBarBox onClick={() => navigate("/calibration")}>
                        <ProgressBar
                          isLabelVisible={false}
                          height="12px"
                          bgColor="#61CEAA"
                          completed={
                            isNaN(calibratedCount) ? 0 : calibratedCount
                          }
                        />
                      </OuterBarBox>
                      <TitleNumber
                        sx={{
                          display: "flex",
                          // alignItems:'center',
                          // width: "140px",

                          // justifyContent: "space-between",
                          // marginLeft: "20px",
                          gap: "20px",
                        }}>
                        <CalibrationText sx={{ color: "#61CEAA" }}>
                          {calibrated === undefined ? 0 : calibrated}
                        </CalibrationText>{" "}
                        <CalibrationText>Calibrated</CalibrationText>
                      </TitleNumber>{" "}
                    </BarBox>
                    <BarBox onClick={() => navigate("/not-calibrated")}>
                      {" "}
                      <OuterBarBox sx={{}}>
                        <ProgressBar
                          isLabelVisible={false}
                          height="12px"
                          bgColor="#1746A2"
                          completed={
                            isNaN(notCalibratedCount) ? 0 : notCalibratedCount
                          }
                        />
                      </OuterBarBox>
                      <TitleNumber
                        sx={{
                          display: "flex",

                          // alignItems:'center',
                          // width: "180px",
                          // justifyContent: "space-between",
                          // marginLeft: "20px",
                          gap: "20px",
                        }}>
                        <CalibrationText sx={{ color: "#1746A2" }}>
                          {notCalibrated === undefined ? 0 : notCalibrated}
                        </CalibrationText>{" "}
                        <CalibrationText> Not Calibrated</CalibrationText>
                      </TitleNumber>{" "}
                    </BarBox>
                    <BarBox
                      onClick={() => navigate("/calibration-not-required")}>
                      {" "}
                      <OuterBarBox sx={{}}>
                        <ProgressBar
                          isLabelVisible={false}
                          height="12px"
                          bgColor="#E56D82"
                          completed={
                            isNaN(notRequiredCount) ? 0 : notRequiredCount
                          }
                        />
                      </OuterBarBox>
                      <TitleNumber
                        sx={{
                          display: "flex",
                          //
                          // width: "180px",
                          // justifyContent: "space-between",

                          // marginLeft: "20px",
                          gap: "20px",
                        }}>
                        <CalibrationText sx={{ color: "#E56D82" }}>
                          {notRequired === undefined ? 0 : notRequired}
                        </CalibrationText>{" "}
                        <CalibrationText>Not Required</CalibrationText>
                      </TitleNumber>{" "}
                    </BarBox>
                  </InnerBarBox>
                </CalibrationBox>
                <AmcCmcBox sx={{ bgcolor: "white" }}>
                  <Box sx={{ width: "90%" }}>
                    <UltraSubTitle>AMC/CMC</UltraSubTitle>
                  </Box>

                  <AmcCmcInnerBox>
                    <HalfRoundedChart
                      total={assetDetails?.total_amcs}
                      name={"Amc"}
                      percentage={amcPercentage}
                      id={"chart1"}
                    />
                    <HalfRoundedChart
                      total={assetDetails?.total_cmcs}
                      name={"Cmc"}
                      percentage={cmcPercentage}
                      id={"chart2"}
                    />
                  </AmcCmcInnerBox>
                </AmcCmcBox>
                <WarrantyBox
                  onClick={() => navigate(`/warranty-status/all`)}
                  sx={{ bgcolor: "white" }}>
                  <Box sx={{ marginLeft: "20px" }}>
                    <UltraSubTitle>Warranty</UltraSubTitle>
                  </Box>
                  <WarrantySet>
                    <Box
                      sx={{
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "space-evenly",
                        gap: "20px",
                        width: "100%",
                      }}>
                      <ArticleIcon
                        sx={{
                          fontSize: "40px",
                          color: "#5F9DF7",
                          cursor: "pointer",
                        }}
                      />
                      <Box sx={{ padding: "10px" }}>
                        <WarrantyText>
                          Check asset warranty status{" "}
                        </WarrantyText>
                      </Box>
                    </Box>
                  </WarrantySet>
                </WarrantyBox>
              </GamaBox>
            </BetaBox>
          </Main>
        </Wrapper>
      )}

      <Unassigned open={assigned} />
      <NoUserFound open={userToggle} />
    </>
  );
}

export default Dashboard;
