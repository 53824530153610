import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

export default function EditAssetModal({ status, handleCloseModal, toggle }) {
  return (
    <div>
      <Modal
        keepMounted
        open={(status === "Updated" || status === "Updation Error") && toggle}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style}>
          {status === "Updated" && (
            <Typography sx={{ mt: 2, color: "green", textAlign: "center" }}>
              Asset updated successfully.
            </Typography>
          )}
          {status === "Updation Error" && (
            <Typography sx={{ mt: 2, color: "green", textAlign: "center" }}>
              No updation made.
            </Typography>
          )}
          {
            <Box
              sx={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10px",
              }}>
              <Button
                variant="contained"
                onClick={handleCloseModal}
                sx={{
                  borderRadius: "35px",
                  margin: "auto",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                size="large">
                Done
              </Button>
            </Box>
          }
        </Box>
      </Modal>
    </div>
  );
}
