import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  IconButton,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import pdfImage from "../images/pdfImage.png";

import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuid } from "uuid";

import styled from "@emotion/styled";
import ButtonGroup from "@mui/material/ButtonGroup";

import { MdAssignmentAdd } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  AddAssetReport,
  assetDeleteReport,
  assetDetailsData,
  updateReport,
} from "../redux/nbmData/action";
import { useDispatch, useSelector } from "react-redux";
const ImageSet = styled(Box)(({ theme }) => ({
  width: "183px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MenuList = styled(MenuItem)(({ theme }) => ({
  fontSize: "14px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const UpdateReport = ({ assetId }) => {
  const detailData = useSelector((store) => store.nbmData.assetDetails);

  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const params = useParams();
  const [assetReport, setAssetReport] = useState([]);
  const dispatch = useDispatch();
  const [reportImage, setReportImage] = useState([]);
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data));
  }, [dispatch]);

  const initialRow = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    imageid: "",
    image: "",
    report_name: "select_report",
  };
  const [reportData, setReportData] = useState([initialRow]);
  const [openReport, setOpenReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [fileError, setFileError] = useState("");

  const [dataToSend, setDataToSend] = useState([initialRow]);
  const [newRow, setNewRow] = useState({ ...initialRow });

  const handleOpenReport = () => {
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
    const filteredData = dataToSend.filter((item) => item.image !== "");
    setAssetReport(filteredData);
    setFileError("");
  };

  const handleAddRow = () => {
    setReportData([...reportData, { ...newRow }]);
    setDataToSend([...reportData, { ...newRow }]);
    setNewRow({ ...initialRow });
  };
  const handleRemoveRow = (index) => {
    const updatedData = [...reportData];
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };
    dispatch(assetDeleteReport(dataDelete));
    updatedData.splice(index, 1);
    setReportData(updatedData);
    setDataToSend(updatedData);
    setFileError("");
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    const updatedData = [...reportData];

    if (file) {
      const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
      const fileType = file.type.split("/")[1];

      if (allowedTypes.includes(fileType)) {
        const splitId = uuid().substring(0, 6);
        const imageid = assetId.substring(0, 6);
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          updatedData[index].imageid = `${imageid}_${splitId}.${fileType}`;
          updatedData[index].image = dataUrl;
          setReportData(updatedData);
          setDataToSend(updatedData);
          let assetRep = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: updatedData[index].imageid,
            image: updatedData[index].image,
            branchid: userBranch?.id,
          };

          dispatch(AddAssetReport(assetRep));
          setFileError("");
        };
        reader.readAsDataURL(file);
      } else {
        setFileError("Please upload pdf, jpg, jpeg or png file");
      }
    } else {
      updatedData[index].imageid = "";
      updatedData[index].image = "";
      setReportData(updatedData);
      setDataToSend(updatedData);
    }
  };

  const handleClearImage = (index) => {
    const updatedData = [...reportData];
    updatedData[index].image = "";
    setReportData(updatedData);
    setDataToSend(updatedData);
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };

    dispatch(assetDeleteReport(dataDelete));
  };
  const handleReportName = (event, index) => {
    const updatedData = [...reportData];
    const selectedReportName = event.target.value;
    updatedData[index].report_name = selectedReportName;
    setReportData(updatedData);
    setDataToSend(updatedData);

    let assetRep = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      image: updatedData[index].image,
      branchid: userBranch?.id,
    };

    dispatch(AddAssetReport(assetRep));
    setSelectedReport(selectedReportName);
    setFileError("");
  };

  const handleUpload = () => {
    const filteredData = dataToSend.filter((item) => item.image !== "");

    const hasInvalidReport = filteredData.some(
      (item) => item.report_name === "select_report"
    );

    if (hasInvalidReport) {
      setFileError("Please select a valid report before submitting.");
      return;
    }

    const hasEmptyField = dataToSend.some(
      (item) =>
        Object.values(item).some((value) => value === "") ||
        Object.values(item).some((value) => value === undefined)
    );

    if (hasEmptyField) {
      setFileError("Please fill in all fields before submitting.");
      return;
    }
    setFileError("");
    handleCloseReport();

    const data = {
      assetid: assetId,
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      username: userIds.username,

      report_names:
        filteredData?.length === 0
          ? []
          : filteredData?.map((item) => {
              return item.report_name;
            }),
      report_ids:
        filteredData?.length === 0
          ? []
          : filteredData?.map((item) => {
              return item.imageid;
            }),
    };


    dispatch(updateReport(data))
      .then(() => {
        let data = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          assetid: params.id,
          branchid: userBranch?.id,
        };
        dispatch(assetDetailsData(data));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setReportData(
      detailData?.reportimgs === "NA" || detailData?.reportimgs === ""
        ? []
        : detailData?.reportimgs?.map((item, index) => {
            return {
              imageid: item.imgid,
              image: item.imgurl,
              report_name: detailData?.reportnames[index],
            };
          })
    );
  }, [detailData]);
  const handleOpenReportAndAddRow = () => {
    handleOpenReport();
    handleAddRow();
  };

  useEffect(() => {
    if (detailData?.reportimgs) {
      setReportImage(detailData?.reportimgs);
    }

    // setReportImageName
  }, [detailData]);

  return (
    <div
      style={{
        display: "flex",
        marginRight: "2%",
      }}>
      <Button
        onClick={
          reportData?.length === 0
            ? handleOpenReportAndAddRow
            : handleOpenReport
        }
        sx={{
          bgcolor: "rgba(23, 70, 162, 0.96)",
          width: "240px",

          borderRadius: "28px",
          "&:hover": {
            bgcolor: "rgba(23, 70, 162, 0.96)",
            color: "white",
          },
        }}>
        <MdAssignmentAdd style={{ color: "white", fontSize: "20px" }} />
        <Typography
          sx={{
            fontWeight: 500,
            color: "white",
            textTransform: "none",
            marginLeft: "2%",
          }}>
          {" "}
          Add Report
        </Typography>
      </Button>

      <Dialog
        open={openReport}
        // onClose={handleCloseReport}
      >
        <DialogContent>
          <Box>
            <Box
              sx={{
                width: "100%",
                padding: "10px",
                justifyContent: "space-between",
                display: "flex",
              }}>
              <Typography sx={{ color: "#FF731D" }}>Add Report</Typography>
            </Box>

            {reportData?.map((row, index) => (
              <div
                style={{ display: "flex", padding: "10px", gap: "10px" }}
                key={index}>
                {row.image ? (
                  <ImageSet
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      bgcolor: "#EFF5FE",

                      height: "45px",
                      borderRadius: "10px",
                      minWidth: "183px",
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        width: "90%",
                      }}>
                      <Box
                        width={"50px"}
                        height={"45px"}
                        as="img"
                        src={`${
                          row.imageid?.split(".")?.pop() === "pdf"
                            ? pdfImage
                            : row.image
                        }`}
                        alt="Selected"
                      />
                    </Box>

                    <Button
                      onClick={() => handleClearImage(index)}
                      sx={{
                        bgcolor: "#1746A2",
                        height: "45px",
                        borderRadius: "0px 10px 10px 0px",
                        minWidth: "55px",
                        "&:hover": {
                          bgcolor: "#1746A2",
                        },
                      }}>
                      <IconButton>
                        <CancelIcon sx={{ color: "white" }} />
                      </IconButton>
                    </Button>
                  </ImageSet>
                ) : (
                  <>
                    <input
                      type="file"
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={(event) => handleImageUpload(event, index)}
                      style={{ display: "none" }}
                      id="file-upload"
                    />

                    <label htmlFor="file-upload">
                      <ButtonGroup
                        component="span"
                        sx={{
                          textTransform: "none",
                          height: "45px",
                          borderRadius: "10px",
                          width: "183px",
                        }}
                        aria-label="split button">
                        <Button
                          component="span"
                          sx={{
                            bglocor: "#EFF5FE",
                            color: "black",
                            textTransform: "none",
                            borderRadius: "10px 0 0 10px",
                            height: "45px",
                          }}>
                          <Typography sx={{ fontSize: "12px" }}>
                            {" "}
                            Upload img/pdf
                          </Typography>
                        </Button>
                        <Button
                          component="span"
                          sx={{
                            bgcolor: "#1746A2",
                            height: "45px",
                            borderRadius: "0px 10px 10px 0px",
                            "&:hover": {
                              bgcolor: "#1746A2",
                            },
                          }}
                          aria-label="select merge strategy"
                          aria-haspopup="menu">
                          <FileUploadIcon sx={{ color: "white" }} />
                        </Button>
                      </ButtonGroup>
                    </label>
                  </>
                )}

                <Box
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                    minWidth: "210px",
                  }}>
                  <Select
                    style={{ width: "200px" }}
                    sx={{ height: "45px", fontSize: "15px" }}
                    value={row.report_name}
                    onChange={(event) => handleReportName(event, index)}>
                    <MenuList value="select_report" disabled>
                      Select Report
                    </MenuList>
                    <MenuList value="Service Report">Service Report</MenuList>
                    <MenuList value="Installation Report">
                      Installation Report
                    </MenuList>
                    <MenuList value="Warranty Document">
                      Warranty Document
                    </MenuList>
                    <MenuList value="Calibration Document">
                      Calibration Document
                    </MenuList>
                    <MenuList value="Service Invoice">Service Invoice</MenuList>
                    <MenuList value="Others">Others</MenuList>
                  </Select>
                </Box>

                <IconButton onClick={() => handleRemoveRow(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <FormHelperText>{fileError}</FormHelperText>

            <Box
              sx={{
                display: "flex",
                width: "100%",

                paddingTop: "10px",
                paddingLeft: "10px",
                justifyContent: "space-between",
              }}>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Button
                  sx={{ textTransform: "none", bgcolor: "#1746A2" }}
                  variant="contained"
                  onClick={handleAddRow}>
                  Add Row
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    "&:hover": {
                      bgcolor: "#1746A2",
                      color: "white",
                    },
                  }}
                  onClick={handleUpload}>
                  Submit
                </Button>
              </Box>
              <Button
                sx={{ color: "#FF731D", textTransform: "none" }}
                onClick={handleCloseReport}>
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateReport;
