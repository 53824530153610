import {
  Typography,
  Box,
  FormControl,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import feature1 from "../images/feature1.png";
import feature2 from "../images/feature2.png";
import feature3 from "../images/feature3.png";
import feature4 from "../images/feature4.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postAuth } from "../redux/auth/action";
import { useSelector } from "react-redux";
import Navbar4 from "../components/Navbar4";
import Footer2 from "../components/Footer2";
import styled from "@emotion/styled";
import Progress from "../components/Progress";

const Main = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "row",
  paddingTop: "20px",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  gap: "25px",
  height: "100%",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    height: "1600px",
    marginTop: "60px",
    marginBottom: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "120%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox = styled(Box)(({ theme }) => ({
  width: "560px",
  height: "600px",

  [theme.breakpoints.down("xl")]: {
    width: "430px",
    height: "500px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "380px",
    height: "500px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "340px",
    height: "500px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "330px",
    height: "500px",
  },
}));

const RightBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  width: "530px",
  minHeight: "570px",

  [theme.breakpoints.down("xl")]: {
    width: "430px",
    minHeight: "500px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "380px",
    minHeight: "502px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SignInput = styled(OutlinedInput)(({ theme }) => ({
  width: "460px",
  height: "42px",

  marginBottom: "6px",
  [theme.breakpoints.down("xl")]: {
    width: "330px",
    height: "40px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "300px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const CustomLabel = styled(FormLabel)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "200px",
  height: "50px",
  fontSize: "18px",
  [theme.breakpoints.down("xl")]: {
    width: "200px",
    height: "40px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: "26px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "20px",
  marginBottom: "10px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  width: "300px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

function SignUp() {
  const signup = useSelector((store) => store.auth.signUpAuth);
  const isLoading = useSelector((store) => store.auth.isLoading);
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [error, setError] = useState("");
  const [hospital, setHospital] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [showEmail, setShowEmail] = useState("");
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  const [hospitalError, setHospitalError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");

  const handleSaveTime = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem("savedTime", currentTime);
    setIsButtonDisabled(true);
  };

  const handleClick = () => {
    hospital === "" && setHospitalError("Please fill the field");
    email === "" && setEmailError("Please fill the field");
    name === "" && setNameError("Please fill the field");
    phone === "" && setPhoneError("Please fill the field");
    password === "" && setPasswordError("Please fill the field");

    if (
      name !== "" &&
      phone !== "" &&
      email !== "" &&
      password !== "" &&
      hospital !== "" &&
      hospitalError === "" &&
      emailError === "" &&
      nameError === "" &&
      passwordError === "" &&
      phoneError === ""
    ) {
      let data = {
        full_name: name.trim(),
        phone: phone.trim(),
        email: email.trim(),
        password: password.trim(),
        hospital_name: hospital.trim(),
      };

      dispatch(postAuth(data));
      handleSaveTime();
    }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    setEmail(value.trim());
    setShowEmail(value.trim());
    if (regex.test(value.trim())) {
      setEmailError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setEmailError("Invalid email");
    }
    if (value === "") {
      setEmailError("Please fill all field");
    }
  };

  // const handleHospitalName = (e) => {
  //   let value = e.target.value;
  //   const regex = /^[a-zA-Z0-9 -]+$/;
  //   if (value === "" || regex.test(value)) {
  //     setHospital(value);

  //     setHospitalError("");
  //   }
  //   if (value !== "" && !regex.test(value)) {
  //     setHospitalError("Invalid hospital name");
  //   }
  //   if (hospital.length < 6) {
  //     setHospitalError("Invalid hospital name");
  //   }
  //   if (value === "") {
  //     setHospitalError("Please fill all field");
  //   }
  // };

  const handleName = (e) => {
    let value = e.target.value;
    const maxLength = 50;
    const regex = /^[A-Za-z\s]+$/;
    setName(value);
    if (value === "") {
      setNameError("Please fill the field");
      // } else if (value.length < 3) {
      //   setNameError("Invalid hospital name (minimum 3 characters)");
    } else if (value.length >= maxLength) {
      setNameError("Hospital name should not exceed 50 characters");
    } else if (!regex.test(value)) {
      setNameError("Invalid characters in hospital name");
    } else {
      setNameError("");
    }
  };

  const handleHospitalName = (e) => {
    const value = e.target.value;
    const maxLength = 100;
    const regex = /^[a-zA-Z0-9 -]+$/;

    setHospital(value);

    if (value === "") {
      setHospitalError("Please fill the field");
    } else if (value.length < 6) {
      setHospitalError("Invalid hospital name (minimum 6 characters)");
    } else if (value.length >= maxLength) {
      setHospitalError("Hospital name should not exceed 100 characters");
    } else if (!regex.test(value)) {
      setHospitalError("Invalid characters in hospital name");
    } else {
      setHospitalError("");
    }
  };

  const handlePhone = (e) => {
    let value = e.target.value;
    const regex = /^\d{10}$/;
    setPhone(value.trim());
    if (value === "" || regex.test(value.trim())) {
      setPhoneError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setPhoneError("Invalid Number");
    }
    if (value === "") {
      setPhoneError("Please fill all field");
    }
  };

  const handlePassword = (e) => {
    const value = e.target.value;
    let regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

    setPassword(value);
    if (value === "" || regex.test(value)) {
      setPassword(value);

      setPasswordError("");
    }
    if (value !== "" && !regex.test(value)) {
      setPasswordError(
        // "Include 8 characters, one special characters, uppercase, smallercase and number."

        "Password must have at least 8 characters,  1 lowercase letter,  uppercase letter,  number, and 1 special character (!”#$%&'()*+,-./:;<=>?@[]^_`{|}~)"
      );
    }
    if (value === "") {
      setPasswordError("Please fill all field");
    }
  };

  // const handlePassword = (e) => {
  //   const value = e.target.value;
  //   setPassword(value);
  //   if (value.length >= 6) {
  //     setError("");
  //   }
  //   if (value.length < 6) {
  //     setError("Password must have 6 characters");
  //   }
  //   if (value === "") {
  //     setError("Please fill the field");
  //   }
  // };

  useEffect(() => {
    if (signup?.success) {
      setHospital("");
      setName("");
      setEmail("");
      setPassword("");
      setPhone("");
    }
  }, [signup]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };
  return (
    <Wrapper sx={{ bgcolor: "white" ,}}>
      <Navbar4 />
      <Main sx={{ bgcolor: "white" }}>
        {/* .............left.............. */}
        <LeftBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            
          }}>
          <TitleText>Features</TitleText>

          <Box sx={{ display: "flex", }}>
            <Box
              as="img"
              src={feature1}
              sx={{
                width: {
                  xl: "50px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                height: {
                  xl: "60px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                
              }}></Box>
            <Box sx={{ marginLeft: "20px" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#1746A2",
                  fontSize: {
                    xl: "24px",
                    lg: "20px",
                    md: "18px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}>
                Digitize
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                }}>
                A Bird eye view of the entire Biomedical Operations.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
          <Box
              as="img"
              src={feature2}
              sx={{
                width: {
                  xl: "50px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                height: {
                  xl: "60px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                
              }}></Box>
            <Box sx={{ marginLeft: "20px" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#1746A2",
                  fontSize: {
                    xl: "24px",
                    lg: "20px",
                    md: "18px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}>
                Track
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                }}>
                Track the product life cycle and service history.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              as="img"
              src={feature3}
              sx={{
                width: {
                  xl: "50px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                height: {
                  xl: "60px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                
              }}></Box>
            <Box sx={{ marginLeft: "20px" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#1746A2",
                  fontSize: {
                    xl: "24px",
                    lg: "20px",
                    md: "18px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}>
                AMC Extensions
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                }}>
                Extend AMC's for the equipment at discounted prices.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              as="img"
              src={feature4}
              sx={{
                width: {
                  xl: "50px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                height: {
                  xl: "60px",
                  lg: "40px",
                  md: "40px",
                  sm: "40px",
                  xs: "40px",
                },
                
              }}></Box>
            <Box sx={{ marginLeft: "20px" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#1746A2",
                  fontSize: {
                    xl: "24px",
                    lg: "20px",
                    md: "18px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}>
                Biomedical Budget
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                }}>
                Regulate and save budget through analysed data and informed
              </Typography>
            </Box>
          </Box>
        </LeftBox>

        {/* .............right.................. */}
        <RightBox
          border={"1px solid rgba(255, 115, 29, 0.5)"}
          sx={{
            borderRadius: "60px",
            boxShadow: "0px 4px 4px #C7C7C7",
            bgcolor: "white",
            display: "flex",

            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}>
          <TitleText>Get Started</TitleText>

          <SubTitle
            sx={{
              fontWeight: "500",
              textAlign: "center",

              color: "#004F95",
            }}>
            Create your new Account
          </SubTitle>

          <Box
            sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
            variant="outlined">
            <CustomLabel sx={{ textAlign: "left", color: "#212427" }}>
              Hospital Name
            </CustomLabel>
            <SignInput
              size="small"
              value={hospital}
              placeholder="e.g. XYZ Hospital"
              onChange={handleHospitalName}
              sx={{
                bgcolor: "#EFF5FE",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled>
                    <LocationCityOutlinedIcon sx={{ color: "#787878" }} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <HelperText>{hospitalError}</HelperText>
            <CustomLabel sx={{ textAlign: "left", color: "#212427" }}>
              Name
            </CustomLabel>
            <SignInput
              size="small"
              value={name}
              placeholder="e.g. Jhone"
              onChange={handleName}
              onKeyPress={handleKeyPress}
              sx={{
                bgcolor: "#EFF5FE",
              }}
              inputProps={{ maxLength: 50 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled>
                    <PermIdentityIcon sx={{ color: "#787878" }} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <HelperText>{nameError}</HelperText>
            <CustomLabel sx={{ textAlign: "left", color: "#212427" }}>
              Email
            </CustomLabel>
            <SignInput
              value={email}
              type="email"
              size="small"
              onKeyPress={handleKeyPress}
              placeholder="e.g. xyz@gmail.com"
              onChange={handleEmail}
              sx={{
                bgcolor: "#EFF5FE",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled>
                    <EmailOutlinedIcon sx={{ color: "#787878" }} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <HelperText>{emailError}</HelperText>
            <CustomLabel sx={{ textAlign: "left", color: "#212427" }}>
              Mobile Number
            </CustomLabel>
            <SignInput
              value={phone}
              type="number"
              size="small"
              onKeyPress={handleKeyPress}
              placeholder="e.g. 8971580265"
              onChange={handlePhone}
              sx={{
                bgcolor: "#EFF5FE",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled>
                    <PhoneEnabledOutlinedIcon sx={{ color: "#787878" }} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <HelperText>{phoneError}</HelperText>
            <CustomLabel sx={{ textAlign: "left", color: "#212427" }}>
              Password
            </CustomLabel>
            <SignInput
              value={password}
              size="small"
              placeholder="e.g. aBcH0sp1t@l"
              onKeyPress={handleKeyPress}
              type={showPassword ? "text" : "password"}
              onChange={handlePassword}
              sx={{
                bgcolor: "#EFF5FE",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ marginRight: "2px" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <HelperText>{passwordError}</HelperText>
          </Box>
          <Box
            sx={{
              width: {
                xl: "460px",
                lg: "330px",
                md: "330px",
                sm: "330px",
                xs: "330px",
              },
              margin: "auto",
            }}>
            {!signup?.success && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#EA232B",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  marginTop: {
                    xl: "3%",
                    lg: "1%",
                    md: "1%",
                    sm: "1%",
                    xs: "1%",
                  },
                  marginBottom: {
                    xl: "3%",
                    lg: "2%",
                    md: "1%",
                    sm: "1%",
                    xs: "1%",
                  },
                }}>
                {signup?.message}
              </Typography>
            )}

            {signup?.success && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#008D6F",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  marginTop: {
                    xl: "3%",
                    lg: "1%",
                    md: "1%",
                    sm: "1%",
                    xs: "1%",
                  },
                  marginBottom: {
                    xl: "3%",
                    lg: "2%",
                    md: "1%",
                    sm: "1%",
                    xs: "1%",
                  },
                }}>
                An email with a verification link was just sent to your email id{" "}
                {showEmail}
              </Typography>
            )}

            {error && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#EA232B",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  marginTop: {
                    xl: "3%",
                    lg: "1%",
                    md: "1%",
                    sm: "1%",
                    xs: "1%",
                  },
                  marginBottom: {
                    xl: "3%",
                    lg: "2%",
                    md: "1%",
                    sm: "1%",
                    xs: "1%",
                  },
                }}>
                {error}
              </Typography>
            )}
          </Box>

          <Button
            onClick={handleClick}
            variant="contained"
            disabled={isLoading}
            size="large"
            sx={{
              width: {
                xl: "190px",
                lg: "180px",
                md: "160px",
                sm: "160px",
                xs: "160px",
              },
              // height: {
              //   xl: "50px",
              //   lg: "46px",
              //   md: "46px",
              //   sm: "46px",
              //   xs: "46px",
              // },
              bgcolor: "#1746A2",
              fontWeight: 500,
              fontFamily: "Poppins",
              textTransform: "none",

              fontSize: "16px",
              color: "white",
              borderRadius: "40px",
              margin: "auto",
            }}>
            Sign up
          </Button>
          {isLoading && <Progress />}

          <Link style={{ textDecoration: "none" }} to={"/signin"}>
            <Typography
              sx={{
                marginTop: "10px",
                fontWeight: 500,
                fontSize: "16px",
                cursor: "pointer",
                color: "#004F95",
                marginBottom: {
                  xl: "1%",
                  lg: "1%",
                  md: "3%",
                  sm: "3%",
                  xs: "3%",
                },
                "&:hover": {
                  transform: "scale3d(1.05, 1.05, 2)",
                  cursor: "pointer",
                  color: "#004F95",
                },
              }}>
              Already have an account?{" "}
              <span
                // onClick={() => navigate("/signin")}
                style={{ color: "#F78117" }}>
                Sign in
              </span>
            </Typography>
          </Link>
        </RightBox>
      </Main>
      <Footer2 />
    </Wrapper>
  );
}

export default SignUp;
