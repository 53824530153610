import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SwitchCameraIcon from "@mui/icons-material/SwitchCamera";
import SwitchCameraOutlinedIcon from "@mui/icons-material/SwitchCameraOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddchartIcon from "@mui/icons-material/Addchart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import BroadcastOnHomeIcon from "@mui/icons-material/BroadcastOnHome";
import BedroomChildOutlinedIcon from "@mui/icons-material/BedroomChildOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { ImTarget } from "react-icons/im";
import { CgNotes } from "react-icons/cg";
import { MdMiscellaneousServices } from "react-icons/md";

import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { TbReportSearch } from "react-icons/tb";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import vajra from "../images/vajra.png";
import { Route, Router, Routes, useLocation } from "react-router";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import NbmPrivateRoutes from "../routes/NbmPrivateRoutes";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { DialogTitle, Menu } from "@material-ui/core";
import { isMoment } from "moment";
import PrivateRoute from "./../routes/PrivateRoute";

import { useState } from "react";
import { useEffect } from "react";
import UserLogOut from "../components/UserLogOut";
import {
  getUserData,
  logOut,
  retrieveAllAssets,
} from "../redux/nbmData/action";

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: "#1746A2",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  width: drawerWidth,
});

const closedMixin = (theme) => ({
  background: "#1746A2",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  width: theme.spacing(7),
});

const StyledList = styled(List)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  "&, & .MuiListItemIcon-root": {
    color: "white",
  },
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "white",
    "&, & .MuiListItemIcon-root": {
      color: "#1746A2",
    },
  },
  // hover states
});

const SmallSwitch = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  width: "60px",
  textTransform: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // position: "sticky",
  top: 0,
  zIndex: 1100,
  bgcolor: "#faf5ee",

  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const SideBarPage2 = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }),
}));

const Previous = styled(Button)(({ theme }) => ({
  border: "0px solid transparent",
  textTransform: "none",
  height: "30px",
  fontSize: "16px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PreviousContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "50%",

  justifyContent: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const allAssets = useSelector((store) => store.data.allAssets);
  const userData = useSelector((store) => store.data.userData);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [open, setOpen] = React.useState(false);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick = () => {
    if (userIds.subs_plan === "Basic") {
      setOpenModal(true);
      handleDrawerClose();
    } else {
      navigate("/user-manual");
      handleDrawerClose();
    }
  };
  const handleButtonClick2 = () => {
    if (userIds.subs_plan === "Basic" && allAssets?.length >= 50) {
      setOpenModal(true);
      handleDrawerClose();
      handleClose();
    } else {
      navigate("/add-asset");
      handleDrawerClose();
    }
  };
  const handleButtonClick3 = () => {
    if (userIds.subs_plan === "Basic" && userData?.length >= 1) {
      setOpenModal(true);
      handleDrawerClose();
      handleClose();
    } else {
      navigate("/adduser");
      handleDrawerClose();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openLogout, setOpenLogout] = React.useState(false);
  const handleOpenLogout = () => {
    setAnchorE2(null);
    setOpenLogout(true);
  };
  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const token = localStorage.getItem("userToken");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open2 = Boolean(anchorE2);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = location.pathname;
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [opens, setOpens] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const bmOrNbm = localStorage.getItem("bmOrNbm");

  const handleChange = () => {
    sessionStorage.setItem("SbmOrNbm", "BM");
    localStorage.setItem("bmOrNbm", "BM");

    window.location.reload();
    window.location.replace("/home");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (isMobileScreen) {
      setOpen(false);
    }
  };
  const handleDrawerClose2 = () => {
    setOpen(false);
  };

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClicks = () => {
    setOpens(!opens);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));
    // localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.getItem("userToken");
    navigate("/signin");
    localStorage.setItem('logoutEvent', Date.now().toString());
    window.location.reload()
    
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch2 = useMediaQuery(theme.breakpoints.down("md"));
  React.useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
    };

    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);
  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(getUserData(data, token));
  }, [dispatch]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SideBarPage2
        sx={{
          bgcolor: "transparent",
        }}
        position="fixed"
        open={open}>
        {isMatch ? (
          <>
            <Toolbar
              elevation={0}
              sx={{
                boxShadow: "none",
                bgcolor: "#1746A2",
              }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}>
                <MenuIcon />
              </IconButton>

              {path !== "/home" && (
                <PreviousContainer>
                  {isMatch ? (
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBackIosIcon
                        sx={{ fontSize: "20px", color: "white" }}
                      />
                    </IconButton>
                  ) : (
                    <Previous onClick={() => navigate(-1)}>
                      <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "25px" }} />{" "}
                      Previous
                    </Previous>
                  )}
                </PreviousContainer>
              )}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <IconButton onClick={handleChange}>
                  <SmallSwitch sx={{ bgcolor: "white" }}>Bio</SmallSwitch>
                </IconButton>

                <Box
                  variant="h6"
                  color="black"
                  onClick={() => navigate("/contact")}
                  sx={{
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    cursor: "pointer",
                  }}>
                  {/* <DiamondOutlinedIcon sx={{ color: "white" }} /> */}
                </Box>
                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    cursor: "pointer",
                  }}>
                  <AddCircleOutlineOutlinedIcon sx={{ color: "white" }} />

                  {/* {!anchorEl ? <ExpandMore /> : <ExpandLess />} */}
                </Box>
              </Box>
            </Toolbar>
          </>
        ) : (
          <>
            {" "}
            <Toolbar sx={{}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}>
                <MenuIcon />
              </IconButton>

              {path !== "/home" && (
                <PreviousContainer>
                  {isMatch ? (
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  ) : (
                    <Previous onClick={() => navigate(-1)}>
                      <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "25px" }} />{" "}
                      Previous
                    </Previous>
                  )}
                </PreviousContainer>
              )}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Box
                  variant="h6"
                  color="black"
                  sx={{
                    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "36px",
                    // width: "20px",
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    cursor: "pointer",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  onClick={handleChange}>
                  {isMatch2 ? "Switch" : " Switch to Biomedical"}
                </Box>
                {/* <Box
                  variant="h6"
                  color="black"
                  sx={{
                    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "36px",
                    width: "150px",
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    cursor: "pointer",
                  }}>
                  <DiamondOutlinedIcon sx={{ color: "#FF731D" }} />
                  Upgrade
                </Box> */}
                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "36px",
                    width: "100px",
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    cursor: "pointer",
                  }}>
                  <AddCircleOutlineOutlinedIcon sx={{ color: "#FF731D" }} />
                  Add
                  {!anchorEl ? <ExpandMore /> : <ExpandLess />}
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={anchorEl}
                  onClose={handleClose}
                  sx={{ marginTop: "2%" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                    },
                  }}>
                  <MenuItem
                    onClick={handleButtonClick2}
                    component={Link}
                    to="/add-asset"
                    button
                    selected={"/add-asset" === path}>
                    {" "}
                    Asset
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/add-department"
                    button
                    selected={"/add-department" === path}>
                    {" "}
                    Departments
                  </MenuItem>
                  <MenuItem
                    onClick={handleButtonClick3}
                    component={Link}
                    to="/adduser"
                    button
                    selected={"/adduser" === path}>
                    {" "}
                    User
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/pm-checklist"
                    button
                    selected={"/pm-checklist" === path}>
                    {" "}
                    PM Checklist
                  </MenuItem>

                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/vendor-relations/vendor"
                    button
                    selected={"/vendor-relations/vendor" === path}>
                    {" "}
                    Vendor/services
                  </MenuItem>

                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/task"
                    button
                    selected={"/task" === path}>
                    {" "}
                    Task
                  </MenuItem>
                </Menu>

                <Box
                  variant="h6"
                  color="black"
                  id="basic-button"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                  sx={{
                    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "36px",
                    minWidth: "150px",
                    padding: "20px",
                    height: "38px",
                    marginRight: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    cursor: "pointer",
                  }}>
                  <AccountCircleOutlinedIcon sx={{ color: "#FF731D" }} />
                  {userIds.username}
                  {!open2 ? <ExpandMore /> : <ExpandLess />}
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={handleClose2}
                  sx={{ marginTop: "5%", top: "24px" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                    },
                  }}>
                  <MenuItem
                    onClick={handleClose2}
                    component={Link}
                    to="/profile"
                    button
                    sx={{}}
                    selected={"/profile" === path}>
                    {" "}
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleOpenLogout}
                    component={Link}
                    // to="/signin"
                    button
                    // selected={"/signin" === path}
                  >
                    {" "}
                    Log out
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </>
        )}
      </SideBarPage2>
      <Drawer
        variant="permanent"
        sx={{
          position: {
            xl: "static",
            lg: "static",
            md: "static",
            sm: "static",
            xs: "absolute",
          },
        }}
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose2}>
        <DrawerHeader>
          {open && (
            <Box
              onClick={() => navigate("/home")}
              as="img"
              margin={"auto"}
              src={vajra}
              alt="logo"
              sx={{ height: "44px", width: "80px", cursor: "pointer" }}
            />
          )}

          <IconButton onClick={handleDrawerClose2}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              open && <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        {/* <Divider /> */}
        <List></List>
        {/* <Divider /> */}

        <StyledList>
          <ListItem
            disablePadding
            component={Link}
            to="/home"
            button
            selected={"/home" === path}
            sx={{}}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>

              <ListItemText primary={"Home"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/all-assets"
            button
            selected={"/all-assets" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon sx={{}}>
                <BroadcastOnHomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Assets"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/department"
            button
            selected={"/department" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <LanOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Departments"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/incident-list"
            button
            selected={"/incident-list" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <AnalyticsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Incidents"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/service-list"
            button
            selected={"/service-list" === path}
            onClick={handleDrawerClose}>
            <ListItemButton sx={{ height: "40px" }}>
              <ListItemIcon>
                <MdMiscellaneousServices size={25} />
              </ListItemIcon>
              <ListItemText primary={"Services"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={Link}
            to="/calibration"
            button
            selected={"/calibration" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <ImTarget size={20} />
              </ListItemIcon>
              <ListItemText primary={"Calibrations"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={Link}
            to="/user"
            button
            selected={"/user" === path}>
            <ListItemButton sx={{ height: "40px" }} onClick={handleDrawerClose}>
              <ListItemIcon>
                <Groups2OutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItemButton>
          </ListItem>
        </StyledList>
      </Drawer>
      <UserLogOut
        handleCloseLogout={handleCloseLogout}
        handleLogout={handleLogout}
        openLogout={openLogout}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p:3,
          paddingBottom: "10px",
        }}>
        <DrawerHeader sx={{ bgcolor: "#faf5ee" }} />

        {<NbmPrivateRoutes />}
      </Box>
    </Box>
  );
}
