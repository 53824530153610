import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  TableSortLabel,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useDispatch, useSelector } from "react-redux";

import { serviceList } from "../redux/nbmData/action";

import empty from "../images/empty.png";

import DialogBox from "../components/DialogBox";
import TopBox from "../components/TopBox";
import Pagination from "../components/Pagination";
import NoData from "../components/NoData";
import TableSort from "../components/TableSort";
import FilterMenu from "../components/FilterMenu";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  minHeight: "400px",

  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ServiceList = () => {
  const serviceData = useSelector((store) => store?.nbmData?.serviceList);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  const token = localStorage.getItem("userToken");

  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [anchorElSort2, setAnchorElSort2] = React.useState(null);

  const [filterChanged, setFilterChanged] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const uniqueMonths = [
    ...new Set(
      serviceData
        .filter((item) => item.servdate !== "NA")
        .map((item) => item.servdate.split("-")[1])
    ),
  ].sort();
  const uniqueYears = [
    ...new Set(serviceData.map((item) => item.servdate.split("-")[2])),
  ];
  const [anchorElFilter2, setAnchorElFilter2] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? serviceData.filter((row) => row.serv_status === filterValue)
      : serviceData;
    setData(newFilteredData);
  }, [filterValue]);

  const handleClickFilter2 = (event) => {
    setAnchorElFilter2(event.currentTarget);
  };

  const handleCloseFilter2 = () => {
    setAnchorElFilter2(null);
  };

  const handleFilter2 = (status) => {
    setFilterValue(status);
    setAnchorElFilter2(null);
    setPage(0);
    setFilterChanged(true);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      downloadCsvFile();
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.servdate !== "NA" && b.servdate !== "NA") {
        const dateA = new Date(a.servdate.split("-").reverse().join("-"));
        const dateB = new Date(b.servdate.split("-").reverse().join("-"));
        return dateA - dateB;
      } else if (a.servdate === "NA" && b.servdate !== "NA") {
        return 1;
      } else if (a.servdate !== "NA" && b.servdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.servdate !== "NA" && b.servdate !== "NA") {
        const dateA = new Date(a.servdate.split("-").reverse().join("-"));
        const dateB = new Date(b.servdate.split("-").reverse().join("-"));
        return dateB - dateA;
      } else if (a.servdate === "NA" && b.servdate !== "NA") {
        return 1;
      } else if (a.servdate !== "NA" && b.servdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const department = [...new Set(serviceData?.map((item) => item.dept))];
  const status = [...new Set(serviceData?.map((item) => item.serv_status))];
  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(serviceList(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (serviceData && serviceData?.length > 0) {
      setData(serviceData);
    }
  }, [serviceData]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories.includes(status)
        ? prevCategories.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = data;

    // Filter by search query
    filteredProducts = filteredProducts.filter(
      (product) =>
        product.assetname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Filter by category
    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedCategories.includes(product.dept)
      );
    }

    // Filter by status
    if (selectedStatus?.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedStatus.includes(product.serv_status)
      );
    }

    if (selectedYear) {
      filteredProducts = filteredProducts.filter((service) => {
        const serviceYear = service.servdate.split("-")[2];
        return serviceYear === selectedYear;
      });
    }
    if (selectedMonth) {
      filteredProducts = filteredProducts.filter((service) => {
        const serviceMonth = service.servdate.split("-")[1];
        return serviceMonth === String(selectedMonth).padStart(2, "0");
      });
    }
    return filteredProducts;
  };

  const filteredProducts = filterProducts();
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    filteredProducts.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(serviceData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.assetname;
      case "date":
        return item.servdate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  return (
    <>
      <>
        <Wrapper>
          <TopBox
            data={serviceData}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            selectedMonth={selectedMonth}
            handleMonthChange={handleMonthChange}
            uniqueMonths={uniqueMonths}
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            uniqueYears={uniqueYears}
            sortDataByKey={sortDataByKey}
            sortLowToHigh={sortLowToHigh}
            sortHighToLow={sortHighToLow}
            status={status}
            selectedStatus={selectedStatus}
            department={department}
            selectedCategories={selectedCategories}
            handleStatusChange={handleStatusChange}
            handleButtonClick2={handleButtonClick2}
            ids={ids}
            handleCategoryChange={handleCategoryChange}
            text="Service List"
          />

          {serviceData?.length === 0 ? (
            <BoxWrapper
              style={{ overflowX: "auto" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: " 15px ",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "500px",
              }}>
              <Cards>
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={empty}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    sx={{ color: " #212427" }}
                    color="text.secondary"
                    gutterBottom>
                    No reported service .
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => navigate("/home")}
                    sx={{
                      borderRadius: "35px",
                      textTransform: "none",
                      bgcolor: "#1746A2",
                    }}
                    variant="contained">
                    Go to Home
                  </Button>
                </CardActions>
              </Cards>
            </BoxWrapper>
          ) : filteredProducts?.length === 0 ? (
            <>
              <NoData text={"service"} />
            </>
          ) : (
            <>
              <BoxWrapper style={{ overflowX: "auto" }}>
                <Box sx={{ overflow: "auto" }}>
                  <TableContainer
                    sx={{
                      width: "100%",
                      maxHeight: 540,
                    }}>
                    <Table
                      stickyHeader
                      sx={{
                        borderRadius: " 15px",
                        bgcolor: "white",
                        // border: "6px solid green",
                        whiteSpace: "nowrap",
                      }}>
                      <TableHead
                        // style={{ borderRadius: "30px" }}
                        sx={{
                          borderRadius: " 15px 15px 0px 0px",
                        }}>
                        <TableRow
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            bgcolor: "rgba(40, 98, 212, 0.1)",
                          }}>
                          <TableHeading
                            sx={{
                              borderRadius: "15px 0px 0px 0px",
                            }}>
                            <TableSortLabel
                              active={true}
                              style={{ color: "#1746A2" }}
                              direction={sortDirection.name}
                              onClick={() => handleSortTable("name")}>
                              Asset Name
                            </TableSortLabel>
                          </TableHeading>
                          <TableHeading sx={{}}>Asset code</TableHeading>
                          <TableHeading sx={{}}>Department</TableHeading>
                          <TableHeading sx={{ textAlign: "center" }}>
                            Status
                            <Tooltip title="Filter Status">
                              <IconButton
                                onClick={handleClickFilter2}
                                size="small">
                                <FilterListIcon />
                              </IconButton>
                            </Tooltip>
                            <FilterMenu
                              anchorEl={anchorElFilter2}
                              onClose={handleCloseFilter2}
                              onFilter={handleFilter2}
                              status={status}
                            />
                          </TableHeading>

                          <TableHeading sx={{}}>
                            {" "}
                            <TableSortLabel
                              active={true}
                              style={{ color: "#1746A2" }}
                              direction={sortDirection.date}
                              onClick={() => handleSortTable("date")}>
                              Date/Time
                            </TableSortLabel>
                          </TableHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProducts
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          // .reverse()
                          .map((item, index) => (
                            <TableRow
                              key={item?.servid}
                              component={Link}
                              to={`/service-list/${item?.servid}`}
                              sx={{
                                borderRadius: " 15px 15px 0px 0px",
                                "&:hover": {
                                  backgroundColor: "#E8ECF6",
                                },
                                textDecoration: "none",
                              }}>
                              <TableRows>
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  to={`/service-list/${item?.servid}`}>
                                  {item?.assetname}
                                </Link>
                              </TableRows>
                              <TableRows sx={{}}>{item?.assetcode}</TableRows>

                              <TableRows sx={{}}>{item?.dept}</TableRows>

                              <TableRows sx={{ textAlign: "center" }}>
                                <Box
                                  sx={{
                                    borderRadius: "30px",
                                    margin: "auto",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: {
                                      xl: "18px",
                                      lg: "16px",
                                      md: "14px",
                                      sm: "12px",
                                      xs: "10px",
                                    },
                                    height: "30px",
                                    width: "100px",

                                    border:
                                      item?.serv_status === "Open"
                                        ? "1px solid red"
                                        : "1px solid #00A884",

                                    color:
                                      item?.serv_status === "Open"
                                        ? "red"
                                        : "#00A884",
                                  }}>
                                  {item.serv_status}
                                </Box>
                              </TableRows>

                              <TableRows sx={{}}>
                                {item.servdate === item?.servtime ? (
                                  <>NA</>
                                ) : (
                                  <>
                                    <>{item?.servdate}</>
                                    <br />
                                    <>{item?.servtime}</>
                                  </>
                                )}
                              </TableRows>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Pagination
                    filteredProducts={filteredProducts}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </BoxWrapper>
            </>
          )}
        </Wrapper>
        <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
      </>
    </>
  );
};

export default ServiceList;
