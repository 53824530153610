import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  ListItem,
  ListItemAvatar,
  OutlinedInput,
  TextField,
  styled,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  checkOut,
  departmentAlreadySaved,
  retrieveAllAssets,
} from "../redux/data/action";

import { useEffect, useState } from "react";

import SuccessPopUp from "../components/SuccessPopUp";

const SelectWrapper = styled(Autocomplete)(({ theme }) => ({
  width: "100%",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "56px",
  },
}));

const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "2%",

  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "60%",
  justifyContent: "center",
  alignItems: "center",

  padding: "20px 30px 20px 30px",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {
    width: "60%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("md")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",

    minWidth: "340px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const TextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "#1746A2",
  marginTop: "2%",
  fontSize: "20px",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {
    height: "350px",
    width: "100%",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  marginTop: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  height: "50px",
  "& fieldset": { border: "none" },
  borderRadius: "5px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "56px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Item = styled(ListItemAvatar)(({ theme }) => ({
  wordWrap: "break-word",
  width: "65%",
  marginLeft: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const FormElement2 = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  width: "45%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "45%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const SubmitButton = styled(Box)(({ theme }) => ({
  height: "100%",
  justifyContent: "start",
  alignItems: "end",
  display: "flex",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
    marginTop: "2%",
    marginBottom: "3%",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const Department = ({ data }) => {
  const allDepartment = useSelector((store) => store.data.alreadySaved);
  const allAssets = useSelector((store) => store.data.allAssets);
  const status = useSelector((store) => store.data.checkoutData);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();

  const [previousDepartment, setPreviousDepartment] = useState("");
  const [previousDepartmentId, setPreviousDepartmentId] = useState("");

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const [destination, setDestination] = useState("");

  const [formErrors, setFormErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    destination: "",
  });

  const isValidEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };

  const isValidMobile = (value) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "name":
        setName(value);
        break;

      case "destination":
        setDestination(value);
        break;
      case "mobile":
        setMobile(value);
        break;
      case "email":
        setEmail(value);
        break;

      default:
        break;
    }

    if (name === "name" && value.trim() === "") {
      setFormErrors({ ...formErrors, [name]: "Name is required" });
    } else if (name === "destination" && value.trim() === "") {
      setFormErrors({ ...formErrors, [name]: "Destination is required" });
    } else if (name === "email" && !isValidEmail(value)) {
      setFormErrors({ ...formErrors, [name]: "Invalid email address" });
    } else if (name === "mobile" && !isValidMobile(value)) {
      setFormErrors({ ...formErrors, [name]: "Invalid mobile number" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedOption) {
      setErrorMessage("Please select an asset.");
      return;
    }

    if (
      name.trim() !== "" &&
      destination.trim() !== "" &&
      email.trim() !== "" &&
      mobile.trim() !== ""
    ) {
      if (Object.values(formErrors).every((error) => !error)) {
        let data = {
          hospid: ids?.hospid,
          hospname: ids.hospname,
          assetid: selectedOption.assetid,
          assetname: selectedOption.name,
          assetcode: selectedOption.assetcode,
          depart: previousDepartment,
          departid: previousDepartmentId,
          to_where: destination,
          cont_name: name,
          cont_email: email,
          cont_phone: mobile,
          userid: ids?.userid,
          username: ids?.username,
          userrole: ids?.role,
          branchid: userBranch?.id,
        };

        dispatch(checkOut(data))
          // setSuccessModalOpen(true);
          .then(() => {
            setFlag(true);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
      }
    } else {
      setFormErrors({
        ...formErrors,
        name: name.trim() === "" ? "Name is required" : "",
        destination: destination.trim() === "" ? "Destination is required" : "",
        email: email.trim() === "" ? "Email is required" : "",
        mobile: mobile.trim() === "" ? "Mobile is required" : "",
      });
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);

    const filteredOptions = allAssets.filter((option) => {
      return (
        option.name.toLowerCase().includes(newInputValue.toLowerCase()) ||
        option.assetcode.toLowerCase().includes(newInputValue.toLowerCase())
      );
    });

    setOptions(filteredOptions);
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (newValue) {
      setPreviousDepartment(newValue.department);

      const matchingDepartment = allDepartment.find(
        (department) => department.departname === newValue.department
      );

      if (matchingDepartment) {
        setPreviousDepartmentId(matchingDepartment.departid);
      } else {
        setPreviousDepartmentId("");
      }

      setErrorMessage("");
    } else {
      setPreviousDepartment("");
      setPreviousDepartmentId("");
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState(allAssets);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(departmentAlreadySaved(data, token));
    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (allAssets && allAssets?.length > 0 && allAssets?.length > 0) {
      setOptions(allAssets);
    }
  }, [allAssets]);
  const handleCloseSuccess = () => {
    setFlag(false);
    navigate("/checkin-checkout/checkin");
  };
  return (
    <>
      <MainDiv>
        <InnerContainer sx={{ bgcolor: "white" }}>
          <TextContainer>
            <FormWrapper>
              <FormElement>
                <TextLabel>Asset Name / Code</TextLabel>
                <SelectWrapper
                  displayEmpty
                  sx={{
                    bgcolor: "#EFF5FE",
                    "& fieldset": { border: "none" },
                    borderRadius: "5px",
                  }}
                  id="search-autocomplete"
                  inputProps={{ "aria-label": "Without label" }}
                  options={options}
                  getOptionLabel={(option) =>
                    option.name + " - " + option.assetcode
                  }
                  inputValue={searchTerm}
                  onInputChange={handleInputChange}
                  onChange={handleOptionChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="e.g. Ventilator/V10-20HI1-0"
                      variant="outlined"
                    />
                  )}
                />
                {errorMessage && <HelperText>{errorMessage}</HelperText>}
              </FormElement>
            </FormWrapper>
          </TextContainer>
          <BottomContainer>
            {selectedOption && (
              <FormWrapper>
                <FormElement>
                  <TextLabel>Product Details</TextLabel>
                  {/* <Box
                    variant="outlined"
                    sx={{
                      padding: 2,
                      borderColor: "orange",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      marginBottom: 2,
                      textAlign: "start",
                      minHeight: "50px",
                    }}>
                    {selectedOption && (
                      <div>
                        <span
                          style={{
                            width: "150px",
                            color: "#1746A2",
                            display: "inline-block",
                          }}>
                          Name
                        </span>
                        :&nbsp;{selectedOption.name}
                      </div>
                    )}
                    {selectedOption && (
                      <div>
                        <span
                          style={{
                            width: "150px",
                            color: "#1746A2",
                            display: "inline-block",
                          }}>
                          Asset Code
                        </span>
                        :&nbsp;{selectedOption.assetcode}
                      </div>
                    )}

                    {previousDepartment && (
                      <div>
                        <span
                          style={{
                            color: "#1746A2",
                            display: "inline-block",
                            width: "150px",
                          }}>
                          Department
                        </span>
                        :&nbsp;{previousDepartment}
                      </div>
                    )}
                    {selectedOption && (
                      <div>
                        <span
                          style={{
                            width: "150px",
                            color: "#1746A2",
                            display: "inline-block",
                          }}>
                          Serial No
                        </span>
                        :&nbsp;{selectedOption.serialno}
                      </div>
                    )}
                    {selectedOption && (
                      <div>
                        <span
                          style={{
                            width: "150px",
                            color: "#1746A2",
                            display: "inline-block",
                          }}>
                          Brand
                        </span>
                        :&nbsp;{selectedOption.brand}
                      </div>
                    )}
                  </Box> */}

                  <FormWrapper
                    sx={{
                      position: "static",
                      border: "2px solid orange",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "12px",
                    }}>
                    <ListItem sx={{ padding: "0px" }}>
                      <ListItemAvatar sx={{ width: "35%", color: "#1746A2" }}>
                        Name
                      </ListItemAvatar>
                      :<Item>{selectedOption?.name}</Item>
                    </ListItem>
                    <ListItem sx={{ padding: "0px" }}>
                      <ListItemAvatar sx={{ width: "35%", color: "#1746A2" }}>
                        Asset code
                      </ListItemAvatar>
                      :<Item>{selectedOption?.assetcode}</Item>
                    </ListItem>

                    <ListItem sx={{ padding: "0px" }}>
                      <ListItemAvatar sx={{ width: "35%", color: "#1746A2" }}>
                        Department
                      </ListItemAvatar>
                      :<Item>{selectedOption?.department}</Item>
                    </ListItem>
                    {selectedOption?.serialno !== "NA" && (
                      <ListItem sx={{ width: "100% ", padding: "0px" }}>
                        <ListItemAvatar sx={{ width: "35%", color: "#1746A2" }}>
                          Serial no
                        </ListItemAvatar>
                        :<Item>{selectedOption?.serialno}</Item>
                      </ListItem>
                    )}
                    {selectedOption?.brand !== "NA" && (
                      <ListItem sx={{ padding: "0px" }}>
                        <ListItemAvatar sx={{ width: "35%", color: "#1746A2" }}>
                          Brand
                        </ListItemAvatar>
                        :<Item>{selectedOption?.brand}</Item>
                      </ListItem>
                    )}
                    <Box></Box>
                  </FormWrapper>

                  {<HelperText>{}</HelperText>}
                </FormElement>
              </FormWrapper>
            )}

            <FormWrapper>
              <FormElement>
                <TextLabel>Product Destination</TextLabel>

                <OutlinedInput
                  multiline
                  maxRows={10}
                  fullWidth
                  placeholder="e.g. 2nd Street, Np nagar, 3rd cross, Bangalore, Karnataka, Pin-680081"
                  name="destination"
                  value={destination}
                  sx={{ bgcolor: "#EFF5FE", "& fieldset": { border: "none" } }}
                  onChange={handleChange}
                />
                {<HelperText>{formErrors.destination}</HelperText>}
              </FormElement>
            </FormWrapper>
            <FormWrapper>
              <FormElement2>
                <TextLabel>Name </TextLabel>

                <FormInput
                  fullWidth
                  placeholder="e.g. Mohan"
                  name="name"
                  value={name}
                  sx={{ bgcolor: "#EFF5FE" }}
                  onChange={handleChange}
                />
                {<HelperText>{formErrors.name}</HelperText>}
              </FormElement2>

              <FormElement2>
                <TextLabel>Mobile</TextLabel>
                <FormInput
                  fullWidth
                  placeholder="e.g. 8971580265"
                  name="mobile"
                  value={mobile}
                  sx={{ bgcolor: "#EFF5FE" }}
                  onChange={handleChange}
                />
                {<HelperText>{formErrors.mobile}</HelperText>}
              </FormElement2>
            </FormWrapper>

            <FormWrapper>
              <FormElement2>
                <TextLabel>Email</TextLabel>
                <FormInput
                  fullWidth
                  placeholder="e.g. example@gmail.com"
                  name="email"
                  type="email"
                  value={email}
                  sx={{ bgcolor: "#EFF5FE" }}
                  onChange={handleChange}
                />
                {<HelperText>{formErrors.email}</HelperText>}
              </FormElement2>
            </FormWrapper>
          </BottomContainer>
          <SubmitButton sx={{}}>
            {" "}
            <TextLabel> </TextLabel>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                bgcolor: "#FF731D",
                color: "white",
                borderRadius: "13px",
                textTransform: "none",
                "&:hover": {
                  color: "white",
                  bgcolor: "#FF731D",
                },
              }}>
              Check Out
            </Button>
          </SubmitButton>
        </InnerContainer>
        {/* 
        <Dialog
          open={successModalOpen}
          onClose={handleCloseSuccessModal}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          PaperProps={{ style: isSmallScreen ? dialogStyle : dialogStyle }}>
          <DialogTitle id="dialog-title"></DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <Box sx={{ width: "60%", height: 250 }}>
              <img
                src={assethistory}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <DialogContentText
              id="dialog-description"
              style={{ color: "rgba(0, 168, 132, 1)", marginTop: "2%" }}>
              Submitted Successfully
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Button
              onClick={handleCloseSuccessModal}
              variant="contained"
              sx={{
                color: "white",
                bgcolor: "rgba(23, 70, 162, 1)",
                borderRadius: "30px",
                textTransform: "none",
                marginBottom: "2%",
                "&:hover": {
                  color: "white",
                  bgcolor: "rgba(23, 70, 162, 1)",
                },
              }}>
              Done
            </Button>
          </DialogActions>
        </Dialog> */}

        <SuccessPopUp
          open={status?.success}
          close={handleCloseSuccess}
          flag={flag}
          text="Submitted Successfully"
        />
      </MainDiv>
    </>
  );
};

export default Department;
