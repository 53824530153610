import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

export default function EditUserModal({ status, handleCloseModal, toggle }) {
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        keepMounted
        open={(status === "Updated" && toggle)||(status&&toggle)}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style}>
          {((status === "Updated")||(status)) && (
            <Typography sx={{ mt: 2, color: "green", textAlign: "center" }}>
              Updated Successfully.
            </Typography>
          )}
          {
            <Box
              sx={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10px",
              }}>
              <Button
                variant="contained"
                onClick={() => navigate("/user")}
                sx={{
                  borderRadius: "35px",
                  margin: "auto",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                size="large">
                Done
              </Button>
            </Box>
          }
        </Box>
      </Modal>
    </div>
  );
}
