import { Box, Button, Tooltip, Typography } from "@mui/material";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { profile } from "../redux/data/action";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
} from "@mui/material";
import hospPic from "../images/hospPic.png";
const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  padding: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TopContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  justifyContent: "space-between",

  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {
    width: "70%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "70%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "97%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "70%",
  justifyContent: "center",
  alignItems: "center",

  paddingBottom: "20px",
  borderRadius: "20px",
  gap: "10px",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {
    width: "70%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "20px",
  padding: "20px",
  paddingTop: 0,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: 0,
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const StatusButton = styled(Button)(({ theme }) => ({
  color: "rgba(255, 75, 75, 1)",
  borderRadius: "18px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "128px",
    height: "26px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "128px",
    height: "26px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    borderBottom: "none",

    whiteSpace: "wrap",
    wordWrap: "break-word",
    maxWidth: 300,
    fontSize: "16px",

    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",

      maxWidth: 180,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
}));

const TableRow1 = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    borderBottom: "none",

    whiteSpace: "wrap",
    wordWrap: "break-word",
    maxWidth: 300,
    fontSize: "16px",
    paddingLeft: "120px",
    paddingRight: "20px",

    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "80px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",

      paddingLeft: "50px",
      paddingRight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
}));
const Dividers = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    borderBottom: "none",
    paddingLeft: "40px",
    paddingRight: "20px",
    //

    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      paddingLeft: "80px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 200,
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

function ProfilePage() {
  const navigate = useNavigate();
  const profileData = useSelector((store) => store?.data?.profile);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
    };

    dispatch(profile(data));
  }, [dispatch]);

  const Profile = {
    "Hospital Name": profileData.hospname,
    Username: profileData.username,
    "Hospital Type": profileData.hosptype,
    Email: profileData.email,
    "Whatsapp Number": profileData.mobile,
    State: profileData.state,
    City: profileData.city,
    Designation: profileData.designate,
    Zip: profileData.zip,
    Address: profileData.address,
    "Subscription plan": userIds.subs_plan,
    "Subscription  date": userIds.subs_date,
    "Subscription expiry date": userIds.subs_expdate,
  };

  return (
    <MainDiv>
      <TopContainer>
        <TitleHead>Profile</TitleHead>
      </TopContainer>
      <InnerContainer sx={{ bgcolor: "white" }}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "90%",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {" "}
            <img
              src={hospPic}
              alt="Images 2"
              style={{ height: "130px", width: "180px", marginLeft: "8%" }}
            />
          </Box>
          {(userAccess?.role === "Super_Admin" ||
            userAccess?.role === "Owner") && (
            <>
              <Box
                sx={{
                  width: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "2%",
                }}
                onClick={() => navigate("/edit-profile")}>
                <Tooltip title="Edit profile">
                  <StatusButton>
                    <CreateOutlinedIcon />
                  </StatusButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>

        <TextContainer>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: "#FF731D", marginBottom: "4%" }}>
            {profileData.hospname}
          </Typography>
          <TableContainer style={{ overflowX: "auto", maxWidth: "100%" }}>
            <Table sx={{ minWidth: "300px" }}>
              <TableBody>
                {Object.entries(Profile).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableRow1
                      component="th"
                      scope="row"
                      sx={{ color: "#1746A2" }}>
                      {key}
                    </TableRow1>

                    <Dividers style={{ paddingRight: "20px" }}>:</Dividers>

                    <TableRows>{value}</TableRows>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TextContainer>
      </InnerContainer>
    </MainDiv>
  );
}

export default ProfilePage;
