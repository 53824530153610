// import {
//   Box,
//   Button,
//   Card,
//   CardActions,
//   CardContent,
//   CardMedia,
//   Checkbox,
//   FormControlLabel,
//   FormGroup,
//   IconButton,
//   InputAdornment,
//   Menu,
//   MenuItem,
//   OutlinedInput,
//   TableSortLabel,
//   Tooltip,
//   Typography,
//   styled,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableContainer from "@mui/material/TableContainer";

// import TableRow from "@mui/material/TableRow";

// import { useDispatch, useSelector } from "react-redux";

// import { AssetHistoryRetrieve } from "../redux/data/action";

// import empty from "../images/empty.png";

// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import DialogBox from "../components/DialogBox";
// import Pagination from "../components/Pagination";
// import TableSort from "../components/TableSort";
// const MainWrapper = styled(Box)(({ theme }) => ({
//   // ,
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));
// const Wrapper = styled(Box)(({ theme }) => ({
//   width: "100%",

//   display: "flex",
//   minHeight: "400px",

//   padding: "2%",
//   flexDirection: "column",
//   alignItems: "center",
//   background: "#faf5ee",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const BoxWrapper = styled(Box)(({ theme }) => ({
//   marginTop: "2%",

//   width: "100%",
//   borderRadius: "15px",

//   background: "white",
//   [theme.breakpoints.down("xl")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "800px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "500px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "370px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "280px",
//   },
// }));

// const TableHeading = styled(TableCell)(({ theme }) => ({
//   color: "#1746A2",
//   fontSize: "20px",
//   fontWeight: "500",
//   textAlign: "start",
//   bgcolor: "rgba(40, 98, 212, 0.1)",
//   // ,
//   [theme.breakpoints.down("xl")]: {
//     fontSize: "18px",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));
// const TableRows = styled(TableCell)(({ theme }) => ({
//   "&.MuiTableCell-root": {
//     fontSize: "18px",
//     fontWeight: "400",
//     color: "#212427",
//     textAlign: "start",
//     maxWidth: 150,
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     transition: "all 0.3s ease",
//     "&:hover": {
//       maxWidth: "none",
//       whiteSpace: "normal",
//       overflow: "visible",
//     },
//     [theme.breakpoints.down("xl")]: {
//       fontSize: "14px",
//     },
//     [theme.breakpoints.down("lg")]: {
//       fontSize: "12px",
//       whiteSpace: "wrap",
//       wordWrap: "break-word",
//       "&:hover": {
//         maxWidth: 130,
//         whiteSpace: "wrap",
//         overflow: "hidden",
//       },
//     },
//     [theme.breakpoints.down("md")]: {},
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "12px",
//       whiteSpace: "wrap",
//       wordWrap: "break-word",
//     },
//     [theme.breakpoints.down("xs")]: {},
//   },
// }));
// const Cards = styled(Card)(({ theme }) => ({
//   width: "688px",
//   height: "351px",
//   fontFamily: "Poppins",
//   fontStyle: "normal",
//   fontWeight: "500",
//   fontSize: "18px",
//   boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {
//     width: "80%",
//     height: "400px",
//     fontSize: "16px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "80%",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "80%",
//     // border: "1px solid orange",
//     marginTop: "2%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     marginTop: "2%",

//     fontSize: "18px",

//     width: "80%",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const TopContainer = styled(Box)(({ theme }) => ({
//   //
//   width: "100%",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   paddingLeft: "10px",
//   paddingRight: "10px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "column",
//     alignItems: "start",
//     gap: "10px",

//     paddingBottom: "10px",
//   },
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const TitleHead = styled(Typography)(({ theme }) => ({
//   fontSize: "24px",
//   fonteight: "400",
//   color: "#FF731D",

//   [theme.breakpoints.down("xl")]: {
//     fonteight: "400",
//     fontSize: "22px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     fonteight: "400",
//     fontSize: "22px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("md")]: {
//     fonteight: "500",
//     fontSize: "18px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fonteight: "500",
//     fontSize: "18px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const SortFilterContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "10px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "85%",
//     justifyContent: "end",
//     alignItems: "end",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterInnerContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "10px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterMain = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "10px",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FilterText = styled(Typography)(({ theme }) => ({
//   fontSize: "18px",
//   color: "#1746A2",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const FormWrapper = styled(FormGroup)(({ theme }) => ({
//   display: "grid",
//   gridTemplateColumns: "repeat(2,1fr)",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const Search = styled(OutlinedInput)(({ theme }) => ({
//   width: 230,
//   height: "40px",
//   border: "1px solid rgba(23, 70, 162, 0.5)",
//   bgcolor: "white",

//   borderRadius: "36px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "140px",
//   },
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const TitleBox = styled(Typography)(({ theme }) => ({
//   width: "100%",
//   display: "flex",
//   paddingRight: "10px",

//   justifyContent: "space-between",
//   alignItems: "center",
//   // flexDirection: "column",
//   // alignItems: "start",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     paddingRight: "0px",
//     width: "500px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "330px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     width: "280px",
//   },
// }));

// const AllAssetHistory = () => {
//   const assetHistoryData = useSelector(
//     (store) => store?.data?.assetHistoryRetrieveData
//   );
//   let userBranch =
//     JSON.parse(sessionStorage.getItem("userBranch")) ||
//     JSON.parse(localStorage.getItem("userBranch"));

//     let userAccess =
//     JSON.parse(sessionStorage.getItem("user_access")) ||
//     JSON.parse(localStorage.getItem("user_access"));

//   const token = localStorage.getItem("userToken");
//   let ids =
//     JSON.parse(sessionStorage.getItem("userData")) ||
//     JSON.parse(localStorage.getItem("userData"));
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);

//   const [anchorElSort, setAnchorElSort] = React.useState(null);
//   const [anchorElFilter, setAnchorElFilter] = React.useState(null);
//   const openSort = Boolean(anchorElSort);
//   const openFilter = Boolean(anchorElFilter);

//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(7);

//   const [filterChanged, setFilterChanged] = useState(false);

//   const [openModal, setOpenModal] = useState(false);

//   const handleCloseModal = () => {
//     setOpenModal(false);
//   };

//   const handleChangePage = (event, newPage) => {
//     if (filterChanged) {
//       setPage(0);
//       setFilterChanged(false);
//     } else {
//       const filteredIncidents = filterProducts();
//       const newPageIndexStart = newPage * rowsPerPage;
//       const newPageIndexEnd = newPageIndexStart + rowsPerPage;
//       const currentPageIncidents = filteredIncidents.slice(
//         newPageIndexStart,
//         newPageIndexEnd
//       );

//       const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

//       if (newPage >= newPageCount) {
//         newPage = newPageCount - 1;
//       }

//       setPage(newPage);
//     }
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//     setFilterChanged(true);
//   };
//   const handleSort = (event) => {
//     setAnchorElSort(event.currentTarget);
//   };
//   const handleFilter = (event) => {
//     setAnchorElFilter(event.currentTarget);
//   };

//   const handleCloseSort = () => {
//     setAnchorElSort(null);
//   };

//   const handleCloseFilter = () => {
//     setAnchorElFilter(null);
//   };
//   const sortHighToLow = () => {
//     const sortedData = [...data].filter((item) => item.date !== "NA");
//     let sorted = sortedData.sort((a, b) => {
//       const dateA = new Date(a.date.split("-").reverse().join("-"));
//       const dateB = new Date(b.date.split("-").reverse().join("-"));
//       return dateA - dateB;
//     });

//     setData(sorted);
//     handleCloseSort();
//   };

//   const sortLowToHigh = () => {
//     const sortedData = [...data].filter((item) => item.date !== "NA");
//     let sorted = sortedData.sort((a, b) => {
//       const dateA = new Date(a.date.split("-").reverse().join("-"));
//       const dateB = new Date(b.date.split("-").reverse().join("-"));
//       return dateB - dateA;
//     });

//     setData(sorted);
//     handleCloseSort();
//   };

//   const department = [
//     ...new Set(assetHistoryData?.map((item) => item.p_depart)),
//   ];
//   const status = [...new Set(assetHistoryData?.map((item) => item.to_depart))];
//   useEffect(() => {
//     let data = {
//       hospid: ids?.hospid,
//       userid: ids?.userid,
//       branchid: userBranch?.id,
//       depart_ids: userAccess.departmentids
//     };
//     dispatch(AssetHistoryRetrieve(data, token));
//   }, [dispatch]);

//   useEffect(() => {
//     if (assetHistoryData && assetHistoryData?.length > 0) {
//       setData(assetHistoryData);
//     }
//   }, [assetHistoryData]);

//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedCategories, setSelectedCategories] = useState([]);
//   const [selectedStatus, setSelectedStatus] = useState([]);

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//     setPage(0);
//     setFilterChanged(true);
//   };
//   const handleCategoryChange = (e) => {
//     const category = e.target.value;
//     setSelectedCategories((prevCategories) =>
//       prevCategories.includes(category)
//         ? prevCategories.filter((c) => c !== category)
//         : [...prevCategories, category]
//     );
//     setPage(0);
//     setFilterChanged(true);
//   };
//   const handleStatusChange = (e) => {
//     const status = e.target.value;
//     setSelectedStatus((prevCategories) =>
//       prevCategories.includes(status)
//         ? prevCategories.filter((c) => c !== status)
//         : [...prevCategories, status]
//     );
//     setPage(0);
//     setFilterChanged(true);
//   };

//   const filterProducts = () => {
//     let filteredProducts = data;

//     filteredProducts = filteredProducts.filter(
//       (product) =>
//         product.assetname.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
//     );

//     if (selectedCategories?.length > 0) {
//       filteredProducts = filteredProducts.filter((product) =>
//         selectedCategories.includes(product.p_depart)
//       );
//     }

//     if (selectedStatus?.length > 0) {
//       filteredProducts = filteredProducts.filter((product) =>
//         selectedStatus.includes(product.to_depart)
//       );
//     }

//     return filteredProducts;
//   };

//   const filteredProducts = filterProducts();
//   const sortDataByKey = (key, ascending) => {
//     const sortedData = [...data].sort((a, b) => {
//       const valueA = a[key].trim().toLowerCase();
//       const valueB = b[key].trim().toLowerCase();
//       return ascending
//         ? valueA.localeCompare(valueB)
//         : valueB.localeCompare(valueA);
//     });
//     setData(sortedData);
//     handleCloseSort();
//   };
//   const extractProperties = (item, column) => {
//     switch (column) {
//       case "name":
//         return item.assetname;
//       case "date":
//         return item.date;
//       default:
//         return "";
//     }
//   };

//   const { handleSortTable, sortDirection } = TableSort(
//     data,
//     setData,
//     extractProperties,
//     setPage,
//     setFilterChanged
//   );
//   return (
//     <>
//       <MainWrapper>
//         <Wrapper>
//           <TopContainer sx={{}}>
//             <TitleBox>
//               <Box sx={{ display: "flex", flexDirection: "column" }}>
//                 <TitleHead>Asset History</TitleHead>
//                 <Typography
//                   sx={{
//                     textAlign: "left",
//                     color: "#1746A2",
//                   }}>{`Total : ${data?.length}`}</Typography>
//               </Box>
//               <Search
//                 type="search"
//                 placeholder="Search"
//                 value={searchQuery}
//                 onChange={handleSearchChange}
//                 startAdornment={
//                   <InputAdornment position="start">
//                     <IconButton
//                       placeholder="Search"
//                       aria-label="toggle password visibility"
//                       edge="start">
//                       <SearchOutlinedIcon />
//                     </IconButton>
//                   </InputAdornment>
//                 }
//               />
//             </TitleBox>

//             {assetHistoryData?.length > 0 && (
//               <SortFilterContainer>
//                 <Button
//                   variant="contained"
//                   sx={{
//                     bgcolor: "#FF731D",
//                     color: "white",
//                     borderRadius: "13px",
//                     textTransform: "none",
//                     width: "180px",
//                   }}
//                   onClick={() => navigate("/asset-history")}>
//                   Change Department
//                 </Button>

//                 <div>
//                   <Tooltip title="Sort">
//                     <IconButton
//                       id="basic-button"
//                       aria-controls={openSort ? "basic-menu" : undefined}
//                       aria-haspopup="true"
//                       aria-expanded={openSort ? "true" : undefined}
//                       onClick={handleSort}>
//                       <SortByAlphaIcon sx={{ color: "#FF731D" }} />
//                     </IconButton>
//                   </Tooltip>
//                   <Menu
//                     id="basic-menu"
//                     anchorEl={anchorElSort}
//                     open={openSort}
//                     onClose={handleCloseSort}
//                     MenuListProps={{
//                       "aria-labelledby": "basic-button",
//                     }}>
//                     <MenuItem sx={{ color: "#FF731D" }}>Sort by Name</MenuItem>
//                     <MenuItem onClick={() => sortDataByKey("assetname", true)}>
//                       A-Z
//                     </MenuItem>
//                     <MenuItem onClick={() => sortDataByKey("assetname", false)}>
//                       Z-A
//                     </MenuItem>
//                     <MenuItem sx={{ color: "#FF731D" }}>Sort by Date</MenuItem>
//                     <MenuItem onClick={sortLowToHigh}>
//                       Newest to oldest
//                     </MenuItem>
//                     <MenuItem onClick={sortHighToLow}>
//                       Oldest to newest
//                     </MenuItem>
//                   </Menu>
//                 </div>

//                 <div>
//                   <Tooltip title="Filter">
//                     <IconButton
//                       id="basic-button"
//                       aria-controls={openFilter ? "basic-menu" : undefined}
//                       aria-haspopup="true"
//                       aria-expanded={openFilter ? "true" : undefined}
//                       onClick={handleFilter}>
//                       <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
//                     </IconButton>
//                   </Tooltip>
//                   <Menu
//                     id="basic-menu"
//                     anchorEl={anchorElFilter}
//                     open={openFilter}
//                     onClose={handleCloseFilter}
//                     MenuListProps={{
//                       "aria-labelledby": "basic-button",
//                     }}>
//                     <MenuItem>
//                       <FilterMain>
//                         <FilterText display="block">
//                           Previous Department
//                         </FilterText>

//                         <FilterInnerContainer>
//                           <FormWrapper>
//                             {department?.map((item) => (
//                               <FormControlLabel
//                                 control={
//                                   <Checkbox
//                                     color="warning"
//                                     type="checkbox"
//                                     value={item}
//                                     checked={selectedCategories.includes(item)}
//                                     onChange={handleCategoryChange}
//                                     name="gilad"
//                                   />
//                                 }
//                                 label={item}
//                               />
//                             ))}
//                           </FormWrapper>
//                         </FilterInnerContainer>
//                       </FilterMain>
//                     </MenuItem>
//                     <MenuItem>
//                       <FilterMain>
//                         <FilterText display="block">
//                           Current Department
//                         </FilterText>
//                         <FilterInnerContainer>
//                           <FormWrapper>
//                             {status?.map((item) => (
//                               <FormControlLabel
//                                 control={
//                                   <Checkbox
//                                     color="warning"
//                                     type="checkbox"
//                                     value={item}
//                                     checked={selectedStatus.includes(item)}
//                                     onChange={handleStatusChange}
//                                     name="gilad"
//                                   />
//                                 }
//                                 label={item}
//                               />
//                             ))}
//                           </FormWrapper>
//                         </FilterInnerContainer>
//                       </FilterMain>
//                     </MenuItem>
//                   </Menu>
//                 </div>
//               </SortFilterContainer>
//             )}
//           </TopContainer>

//           {filteredProducts?.length === 0 ? (
//             <BoxWrapper
//               style={{ overflowX: "auto" }}
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 borderRadius: " 15px ",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 minHeight: "500px",
//               }}>
//               <Cards>
//                 <CardMedia
//                   component="img"
//                   sx={{ width: 151 }}
//                   image={empty}
//                   alt="green iguana"
//                 />
//                 <CardContent>
//                   <Typography
//                     sx={{ color: " #212427" }}
//                     color="text.secondary"
//                     gutterBottom>
//                     No asset history .
//                   </Typography>
//                 </CardContent>
//                 <CardActions>
//                   <Button
//                     onClick={() => navigate("/home")}
//                     sx={{
//                       borderRadius: "35px",
//                       textTransform: "none",
//                       bgcolor: "#1746A2",
//                     }}
//                     variant="contained">
//                     Go to Home
//                   </Button>
//                 </CardActions>
//               </Cards>
//             </BoxWrapper>
//           ) : (
//             <>
//               <BoxWrapper style={{ overflowX: "auto" }}>
//                 <Box sx={{ overflow: "auto" }}>
//                   <TableContainer
//                     sx={{
//                       width: "100%",
//                       maxHeight: 540,
//                     }}>
//                     <Table
//                       stickyHeader
//                       sx={{
//                         borderRadius: " 15px",
//                         bgcolor: "white",
//                         // border: "6px solid green",
//                         whiteSpace: "nowrap",
//                       }}>
//                       <TableHead
//                         // style={{ borderRadius: "30px" }}
//                         sx={{
//                           borderRadius: " 15px 15px 0px 0px",
//                         }}>
//                         <TableRow
//                           sx={{
//                             borderRadius: " 15px 15px 0px 0px",
//                             bgcolor: "rgba(40, 98, 212, 0.1)",
//                           }}>
//                           <TableHeading
//                             sx={{
//                               borderRadius: "15px 0px 0px 0px",
//                             }}>
//                             <TableSortLabel
//                               active={true}
//                               style={{ color: "#1746A2" }}
//                               direction={sortDirection.name}
//                               onClick={() => handleSortTable("name")}>
//                               Asset Name
//                             </TableSortLabel>
//                           </TableHeading>
//                           <TableHeading sx={{}}>Asset code</TableHeading>
//                           <TableHeading sx={{}}>
//                             Previous Department
//                           </TableHeading>
//                           <TableHeading sx={{}}>
//                             Current Department
//                           </TableHeading>

//                           <TableHeading sx={{}}>
//                             <TableSortLabel
//                               active={true}
//                               style={{ color: "#1746A2" }}
//                               direction={sortDirection.date}
//                               onClick={() => handleSortTable("date")}>
//                               Date
//                             </TableSortLabel>
//                           </TableHeading>
//                           <TableHeading sx={{}}>Time</TableHeading>
//                           <TableHeading sx={{}}>User</TableHeading>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {filteredProducts
//                           ?.slice(
//                             page * rowsPerPage,
//                             page * rowsPerPage + rowsPerPage
//                           )
//                           // .reverse()
//                           .map((item, index) => (
//                             <TableRow
//                               key={item?.servid}
//                               sx={{
//                                 borderRadius: " 15px 15px 0px 0px",
//                                 "&:hover": {
//                                   backgroundColor: "#E8ECF6",
//                                 },
//                                 textDecoration: "none",
//                               }}>
//                               <TableRows
//                                 component={Link}
//                                 to={`/asset-detail/${item.assetid}`}
//                                 sx={{ textDecoration: "none" }}>
//                                 {item?.assetname}
//                               </TableRows>
//                               <TableRows sx={{}}>{item?.assetcode}</TableRows>

//                               <TableRows style={{ color: "#FF4B4B" }}>
//                                 {item?.p_depart}
//                               </TableRows>
//                               <TableRows style={{ color: "#00A884" }}>
//                                 {item?.to_depart}
//                               </TableRows>
//                               <TableRows style={{ whiteSpace: "nowrap" }}>
//                                 {item?.date}
//                               </TableRows>
//                               <TableRows style={{ whiteSpace: "nowrap" }}>
//                                 {item?.time}
//                               </TableRows>
//                               <TableRows style={{ whiteSpace: "nowrap" }}>
//                                 {item?.username}
//                               </TableRows>
//                             </TableRow>
//                           ))}
//                       </TableBody>
//                     </Table>
//                   </TableContainer>

//                   <Pagination
//                     filteredProducts={filteredProducts}
//                     rowsPerPage={rowsPerPage}
//                     page={page}
//                     handleChangePage={handleChangePage}
//                     handleChangeRowsPerPage={handleChangeRowsPerPage}
//                   />
//                 </Box>
//               </BoxWrapper>
//             </>
//           )}
//         </Wrapper>

//         <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
//       </MainWrapper>
//     </>
//   );
// };

// export default AllAssetHistory;

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TableSortLabel,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";

import { useDispatch, useSelector } from "react-redux";

import { AssetHistoryRetrieve } from "../redux/data/action";

import empty from "../images/empty.png";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DialogBox from "../components/DialogBox";
import Pagination from "../components/Pagination";
import TableSort from "../components/TableSort";
import TopBox from "../components/TopBox";
const MainWrapper = styled(Box)(({ theme }) => ({
  // ,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  minHeight: "400px",

  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#faf5ee",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "20px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));
const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TopContainer = styled(Box)(({ theme }) => ({
  //
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  color: "#FF731D",

  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "85%",
    justifyContent: "end",
    alignItems: "end",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Boxes = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
  },
  [theme.breakpoints.down("sm")]: {
   
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBox = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  paddingRight: "10px",

  justifyContent: "space-between",
  alignItems: "center",
  // flexDirection: "column",
  // alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    paddingRight: "0px",
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const AllAssetHistory = () => {
  const assetHistoryData = useSelector(
    (store) => store?.data?.assetHistoryRetrieveData
  );
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const [filterChanged, setFilterChanged] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [anchorElSort2, setAnchorElSort2] = React.useState(null);

  const uniqueMonths = [
    ...new Set(assetHistoryData.map((item) => item.date.split("-")[1])),
  ].sort();
  const uniqueYears = [
    ...new Set(assetHistoryData.map((item) => item.date.split("-")[2])),
  ].sort();

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      downloadCsvFile();
    }
  };
  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);

      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const sortHighToLow = () => {
    const sortedData = [...data].filter((item) => item.date !== "NA");
    let sorted = sortedData.sort((a, b) => {
      const dateA = new Date(a.date.split("-").reverse().join("-"));
      const dateB = new Date(b.date.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sorted);
    handleCloseSort();
  };

  const sortLowToHigh = () => {
    const sortedData = [...data].filter((item) => item.date !== "NA");
    let sorted = sortedData.sort((a, b) => {
      const dateA = new Date(a.date.split("-").reverse().join("-"));
      const dateB = new Date(b.date.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sorted);
    handleCloseSort();
  };

  const department = [
    ...new Set(assetHistoryData?.map((item) => item.p_depart)),
  ];
  const status = [...new Set(assetHistoryData?.map((item) => item.to_depart))];
  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
    };
    dispatch(AssetHistoryRetrieve(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (assetHistoryData && assetHistoryData?.length > 0) {
      setData(assetHistoryData);
    }
  }, [assetHistoryData]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories.includes(status)
        ? prevCategories.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = data;

    filteredProducts = filteredProducts.filter(
      (product) =>
        product.assetname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedCategories.includes(product.p_depart)
      );
    }

    if (selectedStatus?.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedStatus.includes(product.to_depart)
      );
    }
    if (selectedYear) {
      filteredProducts = filteredProducts.filter((incident) => {
        const incidentYear = incident.date.split("-")[2];
        return incidentYear === selectedYear;
      });
    }

    if (selectedMonth) {
      filteredProducts = filteredProducts.filter((incident) => {
        const incidentMonth = incident.date.split("-")[1];
        return incidentMonth === String(selectedMonth).padStart(2, "0");
      });
    }

    return filteredProducts;
  };

  const filteredProducts = filterProducts();
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    filteredProducts.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };
  const downloadCsvFile = () => {
    const csvData = jsonToCsv(assetHistoryData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };
  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.assetname;
      case "date":
        return item.date;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );
  return (
    <>
      <MainWrapper>
        <Wrapper>
          <TopContainer >
            <TopBox
              data={assetHistoryData}
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
              selectedMonth={selectedMonth}
              handleMonthChange={handleMonthChange}
              uniqueMonths={uniqueMonths}
              selectedYear={selectedYear}
              handleYearChange={handleYearChange}
              uniqueYears={uniqueYears}
              sortDataByKey={sortDataByKey}
              sortLowToHigh={sortLowToHigh}
              sortHighToLow={sortHighToLow}
              department={department}
              selectedCategories={selectedCategories}
              handleButtonClick2={handleButtonClick2}
              ids={ids}
              handleCategoryChange={handleCategoryChange}
              text={`Asset History`}
            />
            <Boxes >
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#FF731D",
                  color: "white",
                  borderRadius: "13px",
                  textTransform: "none",
                  width: "180px",
                  whiteSpace: "nowrap",
                }}
                onClick={() => navigate("/asset-history")}>
                Change Department
              </Button>
            </Boxes>
          </TopContainer>

          {filteredProducts?.length === 0 ? (
            <BoxWrapper
              style={{ overflowX: "auto" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: " 15px ",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "500px",
              }}>
              <Cards>
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={empty}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    sx={{ color: " #212427" }}
                    color="text.secondary"
                    gutterBottom>
                    No asset history .
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => navigate("/home")}
                    sx={{
                      borderRadius: "35px",
                      textTransform: "none",
                      bgcolor: "#1746A2",
                    }}
                    variant="contained">
                    Go to Home
                  </Button>
                </CardActions>
              </Cards>
            </BoxWrapper>
          ) : (
            <>
              <BoxWrapper style={{ overflowX: "auto" }}>
                <Box sx={{ overflow: "auto" }}>
                  <TableContainer
                    sx={{
                      width: "100%",
                      maxHeight: 540,
                    }}>
                    <Table
                      stickyHeader
                      sx={{
                        borderRadius: " 15px",
                        bgcolor: "white",

                        // border: "6px solid green",
                        whiteSpace: "nowrap",
                      }}>
                      <TableHead
                        // style={{ borderRadius: "30px" }}
                        sx={{
                          borderRadius: " 15px 15px 0px 0px",
                        }}>
                        <TableRow
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            bgcolor: "rgba(40, 98, 212, 0.1)",
                          }}>
                          <TableHeading
                            sx={{
                              borderRadius: "15px 0px 0px 0px",
                            }}>
                            <TableSortLabel
                              active={true}
                              style={{ color: "#1746A2" }}
                              direction={sortDirection.name}
                              onClick={() => handleSortTable("name")}>
                              Asset Name
                            </TableSortLabel>
                          </TableHeading>
                          <TableHeading sx={{}}>Asset code</TableHeading>
                          <TableHeading sx={{}}>
                            Previous Department
                          </TableHeading>
                          <TableHeading sx={{}}>
                            Current Department
                          </TableHeading>

                          <TableHeading sx={{}}>
                            <TableSortLabel
                              active={true}
                              style={{ color: "#1746A2" }}
                              direction={sortDirection.date}
                              onClick={() => handleSortTable("date")}>
                              Date
                            </TableSortLabel>
                          </TableHeading>
                          <TableHeading sx={{}}>Time</TableHeading>
                          <TableHeading sx={{}}>User</TableHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProducts
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          // .reverse()
                          .map((item, index) => (
                            <TableRow
                              key={item?.servid}
                              sx={{
                                borderRadius: " 15px 15px 0px 0px",
                                "&:hover": {
                                  backgroundColor: "#E8ECF6",
                                },
                                textDecoration: "none",
                              }}>
                              <TableRows
                                component={Link}
                                to={`/asset-detail/${item.assetid}`}
                                sx={{ textDecoration: "none" }}>
                                {item?.assetname}
                              </TableRows>
                              <TableRows sx={{}}>{item?.assetcode}</TableRows>

                              <TableRows style={{ color: "#FF4B4B" }}>
                                {item?.p_depart}
                              </TableRows>
                              <TableRows style={{ color: "#00A884" }}>
                                {item?.to_depart}
                              </TableRows>
                              <TableRows style={{ whiteSpace: "nowrap" }}>
                                {item?.date}
                              </TableRows>
                              <TableRows style={{ whiteSpace: "nowrap" }}>
                                {item?.time}
                              </TableRows>
                              <TableRows style={{ whiteSpace: "nowrap" }}>
                                {item?.username}
                              </TableRows>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Pagination
                    filteredProducts={filteredProducts}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </BoxWrapper>
            </>
          )}
        </Wrapper>

        <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
      </MainWrapper>
    </>
  );
};

export default AllAssetHistory;
