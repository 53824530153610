import React, { useEffect, useState } from "react";
import { Modal, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import pdfImage from "../images/pdfImage.png";

const ViewImageModal = ({ imageUrl, handleClose, open }) => {
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    function isPDFLink() {
      // Check if ".pdf" is present in the link
      let fileLink = imageUrl.toLowerCase().includes(".pdf");
      if (fileLink) {
        setFileType("PDF");
      } else {
        setFileType(null);
      }
    }
    isPDFLink();
  }, [imageUrl]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box
          display="flex"
          flexDirection="column"
          marginTop={"-20px"}
          gap={"5px"}
          justifyContent={"center"}
          alignItems="flex-end">
          <IconButton
            edge="end"
            color="inherit"
            sx={{ bgcolor: "white" }}
            onClick={handleClose}>
            <CloseIcon sx={{ color: "red" }} />
          </IconButton>
          {fileType === "PDF" ? (
            <img
              src={pdfImage}
              alt="PDF_Image"
              style={{ width: "100px", height: "100px" }}
            />
          ) : (
            <img
              src={imageUrl}
              alt="Zoomed_Image"
              style={{ maxWidth: "90%", maxHeight: "90vh" }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ViewImageModal;
