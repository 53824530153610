import {
  Box,
  Button,
  FormControl,
  FormLabel,
  OutlinedInput,
  Table,
  Typography,
  styled,
  FormHelperText,
  ListItem,
  ListItemAvatar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Badge,
  Stack,
  TextField,
  Paper,
  IconButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  assetDetailsData,
  clearService,
  clearServiceImgDel,
  clearServiceReceiptDelete,
  serviceImageUpload,
  serviceIssue,
} from "../redux/nbmData/action";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";

import SuccessModal from "../components/SuccessModal";
import { clearServiceReceipt } from "./../redux/nbmData/action";
import MuiAlert from "@mui/material/Alert";
const MainDiv = styled(Box)(({ theme }) => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "80%",
  justifyContent: "center",
  alignItems: "center",
  padding: "50px",
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "15px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const FeedbackBox = styled(Box)(({ theme }) => ({
  width: "100%",
  bgcolor: "#EFF5FE",
  display: "flex",
  alignItems: "center",
  height: "100px",
  borderRadius: "10px",
  marginTop: "1%",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100px",
  },
}));
const BoxText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",

  width: "100%",
  overflowWrap: "break-word",
  fontWeight: "400",
  textAlign: "left",

  height: "100%",

  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    width: "100%",
    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    width: "100%",

    overflowWrap: "break-word",
    fontWeight: "400",
    height: "100%",
  },
}));

const PhotoDiv = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-evenly",
  alignItems: "center",
  height: "183px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "160px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const PhotoMain = styled(Box)(({ theme }) => ({
  marginTop: "20px",

  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    width: "100%",

    gap: "10px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",

    gap: "10px",
    // flexDirection:"column",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",

    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    flexDirection: "column",
  },
}));

const PhotoCard = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",

  borderRadius: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100px",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "20px",
  // display:'flex',

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    height: "100%",
    width: "350px",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const InnerTop = styled(Box)(({ theme }) => ({
  width: "980px",
  display: "flex",
  marginBottom: "5px",
  justifyContent: "right",
  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
    width: "740px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "580px",
    marginTop: "0px",
  },
  [theme.breakpoints.down("md")]: {
    width: "350px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "350px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  height: "50px",
  border: "1px solid rgba(23, 70, 162, 0.20)",
  "& fieldset": { border: "none" },
  borderRadius: "6px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "50px",
  },
}));
const FormInputs = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "100%",
  border: "1px solid rgba(23, 70, 162, 0.20)",
  borderRadius: "6px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));
const FormWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  columnGap: "100px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("xs")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const MainTable = styled(Table)(({ theme }) => ({
  textTransform: "none",
  marginTop: "2%",

  paddingBottom: "10px",
  display: "flex",
  justifyContent: "space-between",

  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    textAlign: "start",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const PhotoInner = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  bgcolor: "#EFF5FE",
  color: "#1746A2",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  textTransform: "none",
  bgcolor: "#ADC2EC",

  color: "#1746A2",
  height: "60px",
  "&:hover": {
    backgroundColor: "#ADC2EC",
    color: "#1746A2",
  },
  [theme.breakpoints.down("xl")]: {
    height: "45px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  paddingBottom: "40px",
  marginTop: "2%",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
    // border: "1px solid green",
  },
}));

const UploadImageBox = styled(Box)(({ theme }) => ({
  // border: "4px solid blue",

  padding: "10px",
  display: "flex",
  alignItems: "center",

  borderRadius: "8px",
  boxShadow: "0px 0px 2px #EAF1FF",
  bgcolor: "#efecea",
  width: "fit-content",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    // border: "4px solid blue",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: "8px",
  width: "40px",
  height: "40px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    marginLeft: "4px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const DotedBox = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ImageOuterBox = styled(Box)(({ theme }) => ({
  //

  // height: "120px",

  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Photo = styled(Box)(({ theme }) => ({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));
const BtnWrapper2 = styled(Stack)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "2%",
  marginBottom: "2%",
  borderRadius: "15px",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",

  //
  [theme.breakpoints.down("xl")]: {
    width: "100%",

    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "150px",

    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "150px",

    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
    padding: "2%",
    alignItems: "center",
    height: "100px",
    marginTop: "45px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));
const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
  },
}));
const PaidBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
    alignItems: "start",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  inputBox: {
    marginTop: theme.spacing(2),

    width: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    padding: "20px",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  },
}));
const Item1 = styled(ListItemAvatar)(({ theme }) => ({
  wordWrap: "break-word",
  width: "40%",

  fontWeight: "500",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100px",
  },
}));

const Item2 = styled(ListItemAvatar)(({ theme }) => ({
  wordWrap: "break-word",
  width: "53%",
  marginLeft: "4%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "160px",
  },
}));

function ClearServicePage() {
  const issueData = useSelector((store) => store.nbmData.serviceIssue);
  const detailData = useSelector((store) => store.nbmData.assetDetails);
  const response = useSelector((store) => store?.nbmData?.clearService);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const servid = localStorage.getItem("serviceId");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState([]);
  const [receiptUrl, setReceiptUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  const [receiptFlag, setReceiptFlag] = useState(false);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const [nameError, setNameError] = useState("");
  const [isMobileNumberError, setIsMobileNumberError] = useState("");
  const [isCompanyError, setIsCompanyError] = useState("");
  const [isIssueError, setIsIssueError] = useState("");
  const [isActionError, setIsActionError] = useState("");
  const [isStatusError, setIsStatusError] = useState("");

  const [amccmError, setAmccmcError] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [software, setSoftware] = useState("");
  const [company, setCompany] = useState("");
  const [amcCmc, setAmcCmc] = useState("");

  const [status, setStatus] = useState("");
  const [issue, setIssue] = useState("");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");

  const dispatch = useDispatch();
  const params = useParams();
  const [isPaid, setIsPaid] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState("");
  const [showError, setShowError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const text = "Service cleared";
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const classes = useStyles();

  const [receipt, setReceipt] = useState("");

  const handlePaidChange = (event) => {
    if (event.target.value === "no") {
      setAmount("");
      setFile("");
    }
    setIsPaid(event.target.value);
    setShowError(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setIsStatusError(false);
  };

  const handleAmountChange = (event) => {
    const newValue = event.target.value;

    // Validate the input (you can customize this part)
    if (newValue.match(/^\d*$/) || newValue === "") {
      setAmount(newValue);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const handleRemoveFile = () => {
    setFile("");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    localStorage.removeItem("serviceId");
    navigate(-1);
  };
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(serviceImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              params.id + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: params.id + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(serviceImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const handleReceiptUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 3 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImageReceipt(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          // setFileUrl([...fileUrl, sendData]);
          setFile(file);
          setReceipt(sendData);
          dispatch(clearServiceReceipt(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImageReceipt = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              params.id + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: params.id + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch.id,
              };
              setFile(file);
              setReceipt(sendData);

              dispatch(clearServiceReceipt(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const handleDelete = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    dispatch(clearServiceImgDel(data));
    setFileUrl(receiptUrl?.filter((el) => el?.imageid !== id));
  };
  const handleDeleteReceipt = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };
    dispatch(clearServiceReceiptDelete(data));
    setReceipt(receipt.imageid !== id);
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;

    if (value === "") {
      setName(value);
      setNameError("Please enter a valid Name");
    } else if (value.trim() === "") {
      setName(value);
      setNameError("Invalid name");
    } else if (regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };

  const handleCompany = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;

    if (value === "") {
      setCompany(value);
      setIsCompanyError("Please enter a valid company");
    } else if (value.trim() === "") {
      setCompany(value);
      setIsCompanyError("Invalid company");
    } else if (regex.test(value)) {
      setCompany(value);
      setIsCompanyError("");
    } else {
      setIsCompanyError("Invalid company");
    }
  };

  const handleNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^\+?[0-9]{10}$/;

    if (value === "") {
      setMobile(value);
      setIsMobileNumberError("Please enter a valid mobile number");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setMobile(value);
      setIsMobileNumberError("");
    } else {
      setIsMobileNumberError("Invalid mobile number");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (value === "") {
      setEmail(value);
      setIsEmailError("Please enter a valid email");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setEmail(value);
      setIsEmailError(null);
    } else {
      setIsEmailError("Enter valid email");
    }
  };

  const handleIssue = (e) => {
    const value = e.target.value;
    const regex = /^[a-z 0-9,.'-]+$/i;

    if (value === "") {
      setIssue(value);
      setIsIssueError("Please enter a valid issue");
    } else if (value.trim() === "") {
      setIssue(value);
      setIsIssueError("Invalid issue");
    } else if (regex.test(value)) {
      setIssue(value);
      setIsIssueError("");
    } else {
      setIsIssueError("Invalid issue");
    }
  };
  const handleAction = (e) => {
    const value = e.target.value;
    const regex = /^[a-z 0-9,.'-]+$/i;

    if (value === "") {
      setAction(value);
      setIsActionError("Please enter a valid action");
    } else if (value.trim() === "") {
      setAction(value);
      setIsActionError("Invalid action");
    } else if (regex.test(value)) {
      setAction(value);
      setIsActionError("");
    } else {
      setIsActionError("Invalid action");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      servid: servid,
      branchid: userBranch?.id,
    };
    dispatch(serviceIssue(data, token));
  }, [dispatch]);

  const handleClicks = () => {
    const trimmedName = name.trim();
    const trimmedMobile = mobile.trim();
    const trimmedCompany = company.trim();
    const trimmedEmail = email.trim();
    const trimmedAction = action.trim();
    const trimmedIssue = issue.trim();
    const trimmedStatus = status.trim();

    if (
      trimmedName === "" ||
      trimmedMobile === "" ||
      trimmedCompany === "" ||
      trimmedEmail === "" ||
      trimmedAction === "" ||
      trimmedIssue === "" ||
      trimmedStatus === ""
    ) {
      setNameError(trimmedName === "" ? "Please enter a valid Name" : null);
      setIsMobileNumberError(
        trimmedMobile === "" ? "Please enter a valid mobile number" : null
      );
      setIsCompanyError(
        trimmedCompany === "" ? "Please enter a valid company" : null
      );
      setIsEmailError(
        trimmedEmail === "" ? "Please enter a valid email" : null
      );
      setIsIssueError(
        trimmedIssue === "" ? "Please enter a valid issue" : null
      );
      setIsStatusError(
        trimmedStatus === "" ? "Please enter a valid status" : null
      );
      setIsActionError(
        trimmedAction === "" ? "Please enter a valid action" : null
      );
      setShowError(true);

      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }

    if (
      !isPaid ||
      (isPaid === "yes" && (!amount || receipt === "" || receipt === false))
    ) {
      setShowError(true);
      return;
    }
    if (
      name !== "" &&
      mobile !== "" &&
      company !== "" &&
      issue !== "" &&
      action !== "" &&
      status !== ""
    ) {
      let datas = {
        assetid: params.id,
        hospid: userIds.hospid,
        serviceid: servid,
        userid: userIds.userid,
        username: userIds.username,
        swversion: software === "" ? "NA" : software.trim(),
        paidservice: isPaid,
        asset_name: detailData.name,
        serial_no: detailData.serialno,
        model_no: detailData.modelno,
        department: detailData.dept,
        req_date: issueData.reqdate,
        req_time: issueData.reqtime,
        branchid: userBranch?.id,
        servicecost: amount?.length > 0 ? amount : "NA",
        "amc/camc": amcCmc === "" ? "NA" : amcCmc,
        fixstatus: status,
        probidentify: trimmedIssue,
        action_taken: trimmedAction,
        servicer_name: trimmedName,
        company: trimmedCompany,
        phone: trimmedMobile,
        email: trimmedEmail,
        feedback: remark === "" ? "NA" : remark.trim(),
        clearimages: uploadedImageName?.length > 0 ? uploadedImageName : "NA",
        receipt_id: receipt ? receipt.imageid : "NA",
        assetcode: detailData.code,
      };
      setOpenModal(true);
      setToggle(true);
      dispatch(clearService(datas, token));
    }
  };

  useEffect(() => {
    if (response === "Updated" && name !== "") {
    }
  }, [response]);
  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.download = "downloaded_image.jpg";
    link.click();
  };
  useEffect(() => {}, [snackbarOpen, snackbarMessage]);
  return (
    <MainDiv>
      <TopContainer>
        <TitleHead>Clear Service </TitleHead>
      </TopContainer>
      <InnerContainer sx={{ bgcolor: "white" }}>
        <TextContainer>
          <InnerTop></InnerTop>
          <FormWrapper sx={{ position: "static" }}>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Name</Item1>:<Item2>{detailData?.name}</Item2>
            </ListItem>

            <ListItem sx={{ width: "100% ", padding: "0px" }}>
              <Item1>Serial no</Item1>:<Item2>{detailData?.serialno}</Item2>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Department</Item1>:<Item2>{detailData?.dept}</Item2>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Asset code</Item1>:<Item2>{detailData?.code}</Item2>
            </ListItem>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Date</Item1>:<Item2>{issueData?.reqdate}</Item2>
            </ListItem>
            <Box></Box>
            <ListItem sx={{ padding: "0px" }}>
              <Item1>Requested to</Item1>:<Item2>{issueData?.reqemail}</Item2>
            </ListItem>
          </FormWrapper>
          <FormWrapper
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              marginTop: "2%",
            }}>
            <FormElement sx={{ paddingRight: "2%" }}>
              <FormTextLabel>Issue Details </FormTextLabel>
              <Box
                sx={{
                  border: "1px solid rgba(126, 126, 126, 0.3)",
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                }}>
                <Typography
                  sx={{
                    color: "black",
                    paddingLeft: "1%",

                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}>
                  {" "}
                  {issueData?.issue}
                </Typography>
              </Box>
            </FormElement>
          </FormWrapper>
          <FormWrapper>
            <PhotoMain>
              {issueData?.issueimgs !== "NA" && issueData?.issueimgs !== "" && (
                <PhotoInner>
                  <FormTextLabel sx={{ color: "#212427", fontWeight: "400" }}>
                    Issue Image
                  </FormTextLabel>
                  <PhotoDiv sx={{ border: "2px solid rgba(0, 168, 132, 0.5)" }}>
                    {issueData?.issueimgs?.map((images) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}>
                        <PhotoCard as="img" src={images} alt="incImage" />
                        <IconButton onClick={() => handleDownload(images)}>
                          {" "}
                          <FileDownloadIcon color="warning" />
                        </IconButton>
                      </Box>
                    ))}
                  </PhotoDiv>
                </PhotoInner>
              )}
            </PhotoMain>
          </FormWrapper>
          <FormWrapper
            sx={{
              padding: "0px",
            }}>
            <FormElement
              sx={{
                padding: "0px",
              }}>
              <FormTextLabel required>Service Incharge Name</FormTextLabel>
              <FormInput
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="Name"
                onChange={handleName}
              />
              {<HelperText>{nameError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormTextLabel required>Mobile Number</FormTextLabel>
              <FormInput
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="e.g. 8971580265"
                onChange={handleNumber}
              />
              {<HelperText>{isMobileNumberError}</HelperText>}
            </FormElement>

            <FormElement>
              <FormTextLabel required>Email</FormTextLabel>
              <FormInput
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="e.g. example@gmail.com"
                onChange={handleEmail}
              />
              {<HelperText>{isEmailError}</HelperText>}
            </FormElement>

            <FormElement>
              <FormTextLabel>Software Version</FormTextLabel>
              <FormInput
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="Software Version"
                onChange={(e) => setSoftware(e.target.value)}
              />
            </FormElement>
            <FormElement>
              <FormTextLabel required>Company</FormTextLabel>
              <FormInput
                sx={{ bgcolor: "#EFF5FE" }}
                placeholder="Company"
                onChange={handleCompany}
              />
              {<HelperText>{isCompanyError}</HelperText>}
            </FormElement>
          </FormWrapper>

          <MainTable sx={{}}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ color: "#FF731D" }}>
                AMC/CMC
              </FormLabel>
              <RadioGroup
                row
                onChange={(e) => setAmcCmc(e.target.value)}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="yes"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
              {<HelperText>{amccmError}</HelperText>}
            </FormControl>
            <PaidBox sx={{}}>
              <FormLabel
                required
                id="demo-row-radio-buttons-group-label"
                sx={{ color: "#FF731D" }}>
                Paid Service
              </FormLabel>

              <FormControl component="fieldset">
                <RadioGroup
                  name="paid"
                  value={isPaid}
                  sx={{
                    display: "flex",

                    flexDirection: "row",
                  }}
                  onChange={handlePaidChange}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
                {showError && !isPaid && (
                  <HelperText>Please select an option for "Paid"</HelperText>
                )}
              </FormControl>

              {isPaid === "yes" && (
                <Box mt={3} className={classes.inputBox}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",

                      width: "100%",
                    }}>
                    <Box>
                      <Typography
                        sx={{
                          color: "#FF731D",
                          textAlign: "start",
                          marginBottom: "2%",
                        }}>
                        Service cost
                      </Typography>

                      <TextField
                        style={{
                          width: 150,
                          height: "45px",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        value={amount}
                        onChange={handleAmountChange}
                        error={showError && !amount}
                        sx={{
                          bgcolor: "#EFF5FE",
                          // border: "1px solid rgba(23, 70, 162, 0.20)",
                          "& fieldset": { border: "none" },
                          textAlign: "center",
                          borderRadius: "6px",
                        }}
                        placeholder="Rs : 3,000"
                      />
                      {showError && !amount && (
                        <HelperText>Amount is required</HelperText>
                      )}
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          color: "#FF731D",
                          textAlign: "start",
                          marginBottom: "2%",
                        }}>
                        Receipt
                      </Typography>
                      <input
                        disabled={receipt !== "" && receipt !== false}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        onChange={handleReceiptUpload}
                        style={{ display: "none" }}
                        id="file-upload"
                      />
                      <label htmlFor="file-upload">
                        <Button
                          disabled={receipt !== "" && receipt !== false}
                          variant="outlined"
                          component="span"
                          style={{
                            height: "45px",
                            color:
                              receipt !== "" && receipt !== false
                                ? "rgb(222,222,222)"
                                : "#60A1FF",
                            textTransform: "none",
                          }}>
                          Pdf / Img
                        </Button>
                      </label>
                    </Box>
                  </Box>
                  {receipt && (
                    <>
                      <Typography
                        sx={{
                          color: "#FF731D",
                          textAlign: "start",
                          marginTop: "15px",
                        }}>
                        File
                      </Typography>
                      <Paper
                        elevation={0}
                        style={{
                          width: "100%",
                        }}>
                        <>
                          {file.type.startsWith("image") ? (
                            <div
                              style={{
                                maxWidth: "30%",
                                height: "100px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                boxShadow:
                                  " 0px 1px 4px 0px rgba(0, 0, 0, 0.25)",

                                marginTop: "2%",
                              }}>
                              <Badge
                                sx={{
                                  color: "rgba(255, 115, 29, 0.5)",

                                  height: "100%",
                                }}
                                badgeContent={
                                  <RemoveCircleIcon
                                    sx={{
                                      width: "15px",
                                      height: "15px",
                                      color: "red",
                                    }}
                                    onClick={() =>
                                      handleDeleteReceipt(receipt.imageid)
                                    }
                                  />
                                }>
                                <img
                                  src={receipt.image}
                                  alt="Uploaded"
                                  style={{ maxWidth: "100px" }}
                                />
                              </Badge>
                            </div>
                          ) : (
                            <div
                              style={{
                                maxWidth: "100%",
                                height: "45px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "10px",
                                boxShadow:
                                  " 0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                              }}>
                              <Typography
                                sx={{
                                  color: "#60A1FF",
                                  textAlign: "start",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}>
                                Receipt-{params.id}.pdf
                              </Typography>

                              <IconButton
                                onClick={() =>
                                  handleDeleteReceipt(receipt.imageid)
                                }>
                                <CloseIcon style={{ color: "black" }} />
                              </IconButton>
                            </div>
                          )}
                        </>
                      </Paper>
                    </>
                  )}
                  {showError &&
                    (!amount || receipt === "" || receipt === false) && (
                      <HelperText>Please fill both fields</HelperText>
                    )}
                </Box>
              )}
            </PaidBox>

            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ color: "#FF731D" }}
                required>
                Status
              </FormLabel>
              <RadioGroup
                value={status}
                onChange={handleStatusChange}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="fixed"
                  control={<Radio size="small" />}
                  label="Fixed"
                />
                <FormControlLabel
                  value="not fixed"
                  control={<Radio size="small" />}
                  label="Not fixed"
                />
              </RadioGroup>
              {<HelperText>{isStatusError}</HelperText>}
            </FormControl>
          </MainTable>
        </TextContainer>
        <BottomContainer>
          <FormControl sx={{}}>
            <FormTextLabel required>Problem Identified </FormTextLabel>
            <FeedbackBox
              sx={{
                bgcolor: "#EFF5FE",
                border: "1px solid rgba(126, 126, 126, 0.3)",
              }}>
              <BoxText>
                <FormInputs
                  multiline
                  maxRows={4}
                  placeholder="Problem Identified"
                  onChange={handleIssue}
                  sx={{
                    "& fieldset": { border: "none" },
                    //
                  }}
                />
              </BoxText>
            </FeedbackBox>
            {<HelperText>{isIssueError}</HelperText>}
            <>
              <FormTextLabel required>Action Taken</FormTextLabel>
              <FeedbackBox
                sx={{
                  bgcolor: "#EFF5FE",
                  border: "1px solid rgba(126, 126, 126, 0.3)",
                }}>
                <BoxText>
                  <FormInputs
                    multiline
                    maxRows={4}
                    placeholder="Action Taken"
                    onChange={handleAction}
                    sx={{ "& fieldset": { border: "none" } }}
                  />
                </BoxText>
              </FeedbackBox>
              {<HelperText>{isActionError}</HelperText>}

              <>
                <FormTextLabel>Remarks</FormTextLabel>
                <FeedbackBox
                  sx={{
                    bgcolor: "#EFF5FE",
                    border: "1px solid rgba(126, 126, 126, 0.3)",
                  }}>
                  <BoxText>
                    <FormInputs
                      multiline
                      maxRows={4}
                      placeholder="Remarks"
                      onChange={(e) => setRemark(e.target.value)}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </BoxText>
                </FeedbackBox>
              </>
            </>
          </FormControl>
        </BottomContainer>
      </InnerContainer>
      <UploadContainer sx={{}}>
        <>
          <ButtonWrapper sx={{}}>
            <>
              <UploadButton
                onClick={() => setPhotoFlag(!photoFlag)}
                sx={{
                  bgcolor: "#ADC2EC",
                }}>
                <AddPhotoAlternateIcon sx={{ color: "#1746A2" }} />
                Upload Photos
              </UploadButton>
            </>
          </ButtonWrapper>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box>
              {" "}
              {photoFlag && (
                <>
                  <ButtonWrapper
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <ImageOuterBox
                      sx={{
                        textAlign: "start",
                        color: "#1746A2",
                      }}>
                      Upload Photos
                      <UploadImageBox
                        sx={{ border: " 1px solid rgba(23, 70, 162, 0.2)" }}>
                        {uploadedImageName?.length < 3 && (
                          <>
                            <DotedBox>
                              <label for="img1">
                                {" "}
                                <AddIcon
                                  sx={{ marginTop: "2%", cursor: "pointer" }}
                                />
                              </label>
                            </DotedBox>
                            <input
                              type="file"
                              name="uploadfile"
                              id="img1"
                              style={{
                                display: "none",

                                width: "42px",
                                height: "42px",
                              }}
                              onChange={handlePhotoUpload}
                            />
                          </>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          {fileUrl?.map((item, index) => (
                            <ImageBox key={index} className="image-item">
                              <Badge
                                sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                                badgeContent={
                                  <RemoveCircleIcon
                                    sx={{
                                      width: "10px",
                                      height: "10px",
                                      color: "red",
                                    }}
                                    onClick={() => handleDelete(item.imageid)}
                                  />
                                }>
                                <Photo as="img" src={item.image} alt={""} />
                              </Badge>
                            </ImageBox>
                          ))}
                        </Box>
                      </UploadImageBox>
                      <Box style={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        <>
                          {photoFlag && (
                            <FormHelperText sx={{ color: "red" }}>
                              format should be either in jpg, jpeg or png
                            </FormHelperText>
                          )}
                        </>
                        {isPhotoError && (
                          <FormHelperText sx={{ color: "red" }}>
                            {isPhotoError}
                          </FormHelperText>
                        )}
                      </Box>
                    </ImageOuterBox>
                  </ButtonWrapper>
                </>
              )}
            </Box>

            <Box></Box>
            <Box>
              {receiptFlag && (
                <>
                  <ButtonWrapper
                    sx={{
                      display: "flex",
                    }}>
                    <ImageOuterBox
                      sx={{
                        textAlign: "start",
                        color: "#1746A2",
                      }}>
                      Upload receipt
                      <UploadImageBox
                        sx={{ border: " 1px solid rgba(23, 70, 162, 0.2)" }}>
                        <>
                          <DotedBox>
                            <label for="img">
                              {" "}
                              <AddIcon
                                sx={{ marginTop: "2%", cursor: "pointer" }}
                              />
                            </label>
                          </DotedBox>
                          <input
                            accept="application/pdf"
                            id="file-upload"
                            type="file"
                            onChange={handlePhotoUpload}
                          />

                          <label htmlFor="file-upload">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span">
                              Upload PDF
                            </Button>
                          </label>
                        </>
                      </UploadImageBox>
                    </ImageOuterBox>
                  </ButtonWrapper>
                </>
              )}
            </Box>
          </Box>

          <BtnWrapper2 sx={{}}>
            <Button
              size="large"
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                border: "1px solid #1746A2",
                color: "#1746A2",
                bgcolor: "white",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#3c52b2",
                },
              }}
              onClick={() => navigate(-1)}
              variant="contained">
              Cancel
            </Button>
            <Button
              size="large"
              disabled={
                nameError ||
                isMobileNumberError ||
                isStatusError ||
                isActionError
              }
              onClick={handleClicks}
              sx={{
                borderRadius: "35px",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              variant="contained">
              Submit
            </Button>
          </BtnWrapper2>
        </>
      </UploadContainer>

      <SuccessModal
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        response={response}
        text={text}
        toggle={toggle}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainDiv>
  );
}

export default ClearServicePage;
