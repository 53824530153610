import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Button,
  OutlinedInput,
  Typography,
  FormHelperText,
  TextField,
} from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { FormLabel } from "@mui/material";
import phone from "../images/phone.png";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAuth, getVerificationLink } from "../redux/auth/action";
import { useSelector } from "react-redux";
import Navbar4 from "../components/Navbar4";
import { Link, useNavigate } from "react-router-dom";
import Footer2 from "../components/Footer2";
import styled from "@emotion/styled";
import RegisterModal from "../components/RegisterModal";
import Progress from "../components/Progress";

const Main = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "row",
  paddingTop: "20px",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",

  height: "100%",
  // paddingBottom: "20px",
  [theme.breakpoints.down("xl")]: {
    marginTop: "0px",
    flexDirection: "row",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",
    // minHeight:'100%'
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    height: "950px",
    marginTop: "30px",
    flexDirection: "column",
    gap: "25px",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    marginBottom: "10px",
  },
}));

const InnerBox = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    marginTop: "105px",
    flexDirection: "row",
    gap: "40px",
    marginBottom: "40px",
  },
  [theme.breakpoints.down("lg")]: {
    marginTop: "115px",
    flexDirection: "row",
    gap: "40px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "40px",
    flexDirection: "column",
    gap: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "40px",
    flexDirection: "column",
    gap: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    gap: "20px",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {
    height: "0%",
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const SignInput = styled(OutlinedInput)(({ theme }) => ({
  width: "370px",
  height: "42px",

  marginBottom: "6px",
  [theme.breakpoints.down("xl")]: {
    width: "330px",
    height: "40px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "300px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const RightBox = styled(Box)(({ theme }) => ({
  width: "450px",
  padding: "10px",
  height: "70%",
  maxHeight: "500px",

  [theme.breakpoints.down("xl")]: {
    height: "70%",
    // minHeight: "450px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "70%",
    minHeight: "400px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const LeftBox = styled(Box)(({ theme }) => ({
  width: "510px",
  height: "450px",
  // border:'1px solid red',
  [theme.breakpoints.down("xl")]: {
    width: "430px",
    height: "450px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "380px",
    height: "450px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "450px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "340px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "320px",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "300px",

  [theme.breakpoints.down("xl")]: {
    width: "300px",
    height: "300px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "300px",
    height: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "300px",
    height: "300px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "300px",
    height: "300px",
  },
}));

function SignIn() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [savedTime, setSavedTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let token = localStorage.getItem("userToken");

  const navigate = useNavigate();

  const signin = useSelector((store) => store.auth.signInAuth);
  const verification = useSelector((store) => store.auth.getLink);
  const isLoading = useSelector((store) => store.auth.isLoading);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isUsernameError, setIsUsernameError] = useState("");
  const [isPasswordError, setIsPasswordError] = useState("");
  const dispatch = useDispatch();





  const handleUserName = (e) => {
    const value = e.target.value;
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (value === "" || regex.test(value.trim())) {
      setUserName(value.trim());
      setIsUsernameError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setIsUsernameError("Enter valid email");
    }
    if (value === "") {
      setIsUsernameError("Please fill the field");
    }
  };

  const handlePassword = (e) => {
    const value = e.target.value;

    if (value.length >= 6) {
      setPassword(value.trim());
      setIsPasswordError("");
    }
    if (value.trim().length < 6) {
      setIsPasswordError("Password must have 6 characters");
    }
    if (value === "") {
      setIsPasswordError("Please fill the field");
    }
  };

  const handleClick = () => {
    if (userName !== "" && password !== "") {
      if (
        password.length >= 6 &&
        isUsernameError === "" &&
        isPasswordError === ""
      ) {
        let data = {
          email: userName.trim(),
          password: password.trim(),
        };

        dispatch(getAuth(data));
        localStorage.setItem('loginEvent', Date.now().toString());
      }
    }
    userName === "" && setIsUsernameError("Please fill the field");
    password === "" && setIsPasswordError("Please fill the field");
  };

  useEffect(() => {
    if (signin?.message === "Incomplete" && signin?.success === false) {
      navigate("/register");
      window.location.reload();
    }
    if (signin?.message === "LoggedIn" && signin?.success === true) {
      navigate("/select");
      // window.location.reload();
    }
  }, [token, navigate, signin, token]);

  useEffect(() => {
    const savedTime = localStorage.getItem("savedTime");
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - savedTime;

    if (savedTime && timeDifference < 60000) {
      setIsButtonDisabled(true);
      setRemainingTime(Math.ceil((60000 - timeDifference) / 1000));

      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - savedTime;
        const remainingSeconds = Math.ceil((60000 - timeDifference) / 1000);

        if (remainingSeconds > 0) {
          setRemainingTime(remainingSeconds);
        } else {
          localStorage.removeItem("savedTime");
          setSavedTime(null);
          setIsButtonDisabled(false);
          setRemainingTime(0);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isButtonDisabled]);

  const handleNavigate = () => {
    navigate("/signup");
    window.location.reload();
  };
  const handleSaveTime = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem("savedTime", currentTime);
    setIsButtonDisabled(true);
  };

  const handleSend = () => {
    if (userName !== "") {
      let data = {
        email: userName,
      };
      dispatch(getVerificationLink(data));
      handleSaveTime();
      setIsUsernameError("");
    }
    if (userName === "") {
      setIsUsernameError("Please fill the field");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };

  return (
    <Wrapper sx={{ bgcolor: "white" }}>
      <Navbar4 />
      <Main sx={{ bgcolor: "white" }}>
        {/* 1st */}

        <LeftBox sx={{}}>
          <Typography
            sx={{
              fontSize: {
                xl: "30px",
                lg: "25px",
                md: "25px",
                sm: "25px",
                xs: "25px",
              },

              fontweight: 600,
              fontFamily: "Poppins",
            }}>
            Welcome to Vajra{" "}
          </Typography>
          <Box
            sx={{
              width: {
                xl: "400px",
                lg: "260px",
                md: "260px",
                sm: "260px",
                xs: "260px",
              },
              margin: "auto",
            }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                margin: "auto",
                fontWeight: 500,
                fontSize: {
                  xl: "20px",
                  lg: "14px",
                  md: "14px",
                  sm: "14px",
                  xs: "14px",
                },
                marginTop: { sm: "20px", xs: "20px" },
                marginBottom: { sm: "20px", xs: "20px" },
                color: "#FF731D",
              }}>
              The Best Hospital Asset Management Application ever
            </Typography>
          </Box>

          <ImageBox as="img" src={phone} alt="phoneImage" />
        </LeftBox>

        {/* 2nd */}
        <RightBox
          border={"2px solid #FF731D"}
          sx={{
            borderRadius: "60px",
            boxShadow: "0px 4px 4px #C7C7C7",
            border: "1px solid rgba(255, 115, 29, 0.5)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}>
          <Typography
            sx={{
              fontWeight: 600,
              marginTop: "10px",
              fontSize: {
                xl: "30px",
                lg: "20px",
                md: "20px",
                sm: "20px",
                xs: "20px",
              },
              fontFamily: "Poppins",
            }}>
            Get Started
          </Typography>
          <Box
            sx={{ margin: "auto", display: "flex", flexDirection: "column" }}>
            <FormLabel
              sx={{
                textAlign: "left",
                color: "#212427",
              }}>
              Email
            </FormLabel>
            <SignInput
              size="small"
              placeholder="e.g. abc@gmail.com"
              onChange={handleUserName}
              onKeyPress={handleKeyPress}
              sx={{
                bgcolor: "#EFF5FE",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled>
                    <PermIdentityIcon sx={{ color: "#787878" }} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <HelperText>{isUsernameError}</HelperText>
            <FormLabel sx={{ textAlign: "left", color: "#212427" }}>
              Password
            </FormLabel>

            <SignInput
              size="small"
              placeholder="e.g. aBcH0sp1t@l"
              onChange={handlePassword}
              onKeyPress={handleKeyPress}
              sx={{
                bgcolor: "#EFF5FE",
              }}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ marginRight: "2px" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <HelperText>{isPasswordError}</HelperText>
            <FormLabel
              sx={{
                textAlign: "right",
                color: "#1746A2",
                marginTop: "6px",
                "&:hover": {
                  transform: "scale3d(1.05, 1.05, 2)",
                  marginTop: "5px",

                  cursor: "pointer",
                  color: "#1746A2",
                },
              }}
              onClick={() => navigate("/forgot")}>
              Forgot password?
            </FormLabel>
          </Box>

          {signin?.message !== "USER_DISABLED" &&
            signin?.message !== "EMAIL_NOT_VERIFIED" &&
            signin?.message !== "LoggedIn" &&
            signin?.message !== "Inactive" && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#EA232B",
                  fontFamily: "Poppins",
                }}>
                {signin?.message}{" "}
              </Typography>
            )}

          {signin?.message === "Inactive" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#EA232B",
                fontFamily: "Poppins",
              }}>
              Account is not active please wait for some time or contact Admin
            </Typography>
          )}

          {signin?.message === "USER_DISABLED" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#EA232B",
                fontFamily: "Poppins",
              }}>
              Your account is not activated yet
            </Typography>
          )}

          {signin?.message === "EMAIL_NOT_VERIFIED" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#EA232B",
                fontFamily: "Poppins",
              }}>
              Your email is not verified yet.{" "}
            </Typography>
          )}

          {signin?.message === "NoUser" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#EA232B",
                fontFamily: "Poppins",
              }}>
              Your account is deleted contact Admin.
            </Typography>
          )}

          {verification === "Sent" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#EA232B",
                fontFamily: "Poppins",
              }}>
              Please check your mail
            </Typography>
          )}

          {signin?.message === "Your email is not verified yet." && (
            <Button
              disabled={isButtonDisabled}
              onClick={handleSend}
              sx={{ textTransform: "none" }}>
              Get verification link {remainingTime === 0 ? "" : remainingTime}
            </Button>
          )}

          {signin?.message === "EMAIL_NOT_VERIFIED" && (
            <Button
              disabled={isButtonDisabled}
              onClick={handleSend}
              sx={{ textTransform: "none" }}>
              Get verification link {remainingTime === 0 ? "" : remainingTime}
            </Button>
          )}

          <Button
            onClick={handleClick}
            disabled={isLoading}
            variant="contained"
            size="large"
            sx={{
              width: {
                xl: "190px",
                lg: "180px",
                md: "160px",
                sm: "160px",
                xs: "160px",
              },
              bgcolor: "#1746A2",
              fontWeight: 500,
              fontFamily: "Poppins",
              textTransform: "none",

              fontSize: "16px",
              color: "white",
              borderRadius: "40px",
              margin: "auto",
            }}>
            Sign in
          </Button>
          {isLoading && <Progress />}

          <Typography
            onClick={handleNavigate}
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              cursor: "pointer",
              marginBottom: "10px",

              color: "#004F95",
              "&:hover": {
                transform: "scale3d(1.05, 1.05, 2)",
                cursor: "pointer",
                color: "#004F95",
              },
            }}>
            New to vajra? <span style={{ color: "#F78117" }}>Sign up</span>
          </Typography>
        </RightBox>
      </Main>
      <Footer2 />
    </Wrapper>
  );
}

export default SignIn;
