import React, { useEffect, useState } from 'react';
import { requestForToken ,onMessageListener } from './../firebase';
import { getMessaging, onMessage } from 'firebase/messaging';


function Notification2() {

  const [existingNotifications, setExistingNotifications] = useState([]);

  useEffect(() => {
    const notificationsFromStorage = JSON.parse(localStorage.getItem('notification')) || [];
    setExistingNotifications(notificationsFromStorage);

    // Clean up the effect
    return () => {
      const notificationsFromStorage = JSON.parse(localStorage.getItem('notification')) || [];
    setExistingNotifications(notificationsFromStorage);
    };
  }, []);



console.log("existingNotifications",existingNotifications)




const [notification, setNotification] = useState([...existingNotifications]);

requestForToken()

useEffect(()=>{
  onMessageListener()
},[])





  

  return (
    <div>


    </div>
   
  )
}

export default Notification2