import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";

import { FormHelperText, FormLabel, OutlinedInput, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { retrieveAllAssets } from "../redux/data/action";
import { postVendorService } from "./../redux/data/action";

import ServiceSuccess from "./ServiceSuccess";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  width: "500px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "270px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const HalfInput = styled(OutlinedInput)(({ theme }) => ({
  width: "230px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "270px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const Bottom = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  gap: "10px",
  justifyContent: "end",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CustomBottom = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  textTransform: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const SpecialDropDowne = styled(Stack)(({ theme }) => ({
  border: "none",
  width: "500px",
  minHeight: "50px",
  marginBottom: "5px",
  [theme.breakpoints.down("xl")]: {
    width: "500px",
    minHeight: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    minHeight: "50px",
  },
  [theme.breakpoints.down("md")]: {
    width: "270px",
    minHeight: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "270px",
    minHeight: "50px",
  },
}));

const TitleBox = styled(Box)(({ theme }) => ({
  width: "90%",

  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  paddingBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  textAlign: "left",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

export default function AddServiceCompanyModal({ openModal, handleClose }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [selectedAssets, setSelectedAssets] = React.useState([]);
  const allAssets = useSelector((store) => store.data.allAssets);
  const status = useSelector((store) => store.data.postVendorService);

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [flag, setFlag] = React.useState(false);
  const [isAssetNameError, setIsAssetNameError] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");

  const handleAssetSelection = (event, newValue) => {
    setSelectedAssets(newValue);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(retrieveAllAssets(data));
  }, [dispatch]);

  const handleEmail = (e) => {
    const value = e.target.value;
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    // const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setEmailError("Wrong email");
    }
    if (value === "") {
      setEmailError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const regex = /^\d{10}$/;
    setPhone(value);
    if (value === "" || regex.test(value)) {
      setPhone(value);
      setPhoneError("");
    }
    if (value !== "" && !regex.test(value)) {
      setPhoneError("Invalid Phone Number");
    }
    if (value === "") {
      setPhoneError("Please fill the field");
    }
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    if (value === "" || regex.test(value)) {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Invalid name");
    }
    if (value === "") {
      setNameError("Please fill the field");
    }
  };

  const handleSubmit = () => {
    if (
      phoneError === "" &&
      emailError === "" &&
      nameError === "" &&
      isAssetNameError === "" &&
      name !== "" &&
      email !== "" &&
      phone !== ""
    ) {
      let data = {
        hospid: userIds.hospid,
        hospname: userIds?.hospname,
        userid: userIds?.userid,
        username: userIds?.username,
        compname: name.trim(),
        mobile: phone.trim(),
        email: email.trim(),
        branchid: userBranch?.id,
        assetname:
          selectedAssets?.length === 0
            ? "NA"
            : selectedAssets?.map((item) => {
                return item.name;
              }),
        assetcode:
          selectedAssets?.length === 0
            ? "NA"
            : selectedAssets?.map((item) => {
                return item.assetcode;
              }),
        assetid:
          selectedAssets?.length === 0
            ? "NA"
            : selectedAssets?.map((item) => {
                return item.assetid;
              }),
      };

      dispatch(postVendorService(data));
      setEmail("");
      setEmailError("");
      setPhone("");
      setPhoneError("");
      setName("");
      setNameError("");
      setIsAssetNameError("");
      setSelectedAssets([]);
      setFlag(true);
    }
  };

  const handleCloseSuccess = () => {
    setFlag(false);
  };

  return (
    <div>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Box
            sx={{
              color: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              flexDirection: "column",
              marginTop: "20px",
            }}>
            <InnerBox>
              <TitleBox>
                <TitleHead>Add Service</TitleHead>
              </TitleBox>

              <FormElement>
                <FormTextLabel>Company Name</FormTextLabel>
                <CustomInput
                  sx={{ bgcolor: "#EFF5FE" }}
                  onChange={handleName}
                  value={name}
                  placeholder="e.g. SJ Company"
                />
                <HelperText>{nameError}</HelperText>
              </FormElement>
              <FormWrapper>
                <FormElement>
                  <FormTextLabel>Phone</FormTextLabel>
                  <HalfInput
                    sx={{ bgcolor: "#EFF5FE" }}
                    onChange={handlePhone}
                    value={phone}
                    placeholder="e.g. 8971580265"
                  />
                  <HelperText>{phoneError}</HelperText>
                </FormElement>

                <FormElement>
                  <FormTextLabel>Email</FormTextLabel>
                  <HalfInput
                    sx={{ bgcolor: "#EFF5FE" }}
                    onChange={handleEmail}
                    value={email}
                    placeholder="e.g. example@gmail.com"
                  />
                  <HelperText>{emailError}</HelperText>
                </FormElement>
              </FormWrapper>

              <FormElement>
                <FormTextLabel>Asset Name/code</FormTextLabel>
                <SpecialDropDowne>
                  <Autocomplete
                    sx={{ bgcolor: "#EFF5FE" }}
                    multiple
                    id="checkboxes-tags-demo"
                    options={allAssets}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={selectedAssets}
                    onChange={handleAssetSelection}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name} ({option.assetcode})
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="e.g. Ventilator/V10-H1-009"
                      />
                    )}
                  />
                </SpecialDropDowne>
                <HelperText>{isAssetNameError}</HelperText>
              </FormElement>

              <Bottom>
                <CustomBottom
                  sx={{ border: "1px solid #1746A2", color: "#1746A2" }}
                  onClick={handleClose}
                  variant="outlined"
                  size="medium">
                  Cancel
                </CustomBottom>

                <CustomBottom
                  disabled={
                    nameError ||
                    emailError ||
                    phoneError ||
                    !name ||
                    !phone ||
                    !email
                  }
                  onClick={() => {
                    handleClose();
                    handleSubmit();
                  }}
                  sx={{ bgcolor: "#1746A2" }}
                  variant="contained"
                  size="medium">
                  Submit
                </CustomBottom>
              </Bottom>
            </InnerBox>
          </Box>
        </ModalBox>
      </Modal>

      <ServiceSuccess
        open={status?.success}
        close={handleCloseSuccess}
        flag={flag}
        text={"Service"}
      />
    </div>
  );
}
