import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import {
  assetDetailsData,
  departmentAssetDetails,
  getDepartmentData,
} from "../redux/nbmData/action";
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { Card, CardContent, Typography, Container } from "@mui/material";

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CustomButton = styled(Button)(({ theme }) => ({
  // width:'120px',
  borderRadius: "20px",
  background: "#0E4FCD",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  width: "270px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  // border: "1px solid green",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    // width: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    // width: "380px",
  },
  [theme.breakpoints.down("xs")]: {
    // width: "380px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const CustomStack = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  textAlign: "center",
  color: "#FF731D",
  marginBottom: "3%",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "16px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

function Depreciation() {
  const departmentData = useSelector((store) => store.nbmData.department) || [];
  const [showDetail, setShowDetail] = useState(false);
  const departAssetData = useSelector(
    (store) => store.nbmData.departmentAssetDetails
  );
  const detailData = useSelector((store) => store.nbmData.assetDetails);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const [usefulLife, setUsefulLife] = useState("");
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [price, setPrice] = useState("");
  const [isPriceError, setIsPriceError] = useState("");
  const [isUsefulLifeError, setIsUsefulLifeError] = useState("");
  const [yearlyData, setYearlyData] = useState([]);
  const [assetId, setAssetId] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [data, setData] = useState({});

  const [toggle, setToggle] = useState(false);
  const filteredDepartments = departmentData.filter(
    (department) => parseInt(department.totalassets) > 0
  );
  const handleUsefulLife = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setUsefulLife(value);
    if (value === "" || regex.test(value)) {
      setUsefulLife(value);
      setIsUsefulLifeError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsUsefulLifeError("Enter valid number");
    }
    if (value === "") {
      setIsUsefulLifeError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      deptid: selected,
    };

    dispatch(departmentAssetDetails(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(detailData)?.length > 0) {
      setData(detailData);
    }
  }, [detailData]);

  const calculateDepreciationAndExpense = () => {
    price === "NA" && setIsPriceError("Enter valid price");
    usefulLife === "NA" && setIsUsefulLifeError("Enter valid year");
    price === "" && setIsPriceError("Please fill the field");
    usefulLife === "" && setIsUsefulLifeError("Please fill the field");
    if (
      price !== "NA" &&
      usefulLife !== "NA" &&
      price !== "" &&
      usefulLife !== "" &&
      isPriceError === "" &&
      isUsefulLifeError === ""
    ) {
      let usefulLifeCount = usefulLife;
      const depreciationPercentage = (2 / usefulLifeCount) * 100;
      let remainingPrice = price;

      const data = [];
      for (let year = 1; year <= usefulLifeCount; year++) {
        const depreciationExpense = Math.floor(
          (depreciationPercentage / 100) * remainingPrice
        );
        data.push({ year, depreciationExpense });
        remainingPrice -= depreciationExpense;
      }

      setYearlyData(data);
      setShow(true);
    }
  };

  const handleDepartment = (e, item) => {
    if (item !== null && item !== undefined) {
      setSelectedId(item.id);
      setDepartment(item.name);
      setShow(false);
      setShowDetail(false);
      setYearlyData([]);
      setAssetValue("");
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess?.departmentids,
      };
      dispatch(getDepartmentData(data, token));
      setData({});
    }
  };

  const handleAssetName = (e, item) => {
    if (item !== null && item !== undefined) {
      setAssetId(item.value);
      setToggle(true);
      setAsset(item.name);
      setAssetValue(item.label);
      setShowDetail(true);

    }
    if (item === null && item === undefined) {
      setShow(false);
     
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      deptid: selectedId,
      branchid: userBranch?.id,
    };

    dispatch(departmentAssetDetails(data, token));
  }, [dispatch, selectedId]);

  useEffect(() => {
    setUsefulLife(detailData?.lifeyrs);
    setPrice(detailData?.price);
  }, [detailData]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: assetId,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
    setToggle(false);
  }, [dispatch, assetId, toggle]);

  let print = {
    name: detailData.name,
    dop: detailData?.dop,
    price: price,
    usefulLife: usefulLife,
    department: detailData.dept,
    yearlyData: yearlyData,
  };

  const downloadPDF = (asset) => {
    const pdf = new jsPDF();

    // Set background color
    pdf.setFillColor(255, 251, 244); // #FFFBF4 color
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    // Title
    pdf.setTextColor(0, 0, 0); // Black color for title
    pdf.setFontSize(16);
    pdf.text(
      "Calculated  Depreciation  Expense",
      pdf.internal.pageSize.width / 2,
      18,
      "center"
    );
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(14, 79, 205); // #0E4FCD color
    pdf.line(
      pdf.internal.pageSize.width / 2 - 90,
      25,
      pdf.internal.pageSize.width / 2 + 90,
      25
    );

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 50);
    pdf.setTextColor(0, 0, 0); // Black color for values
    pdf.text(`${asset.name}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Date of Purchase:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.dop}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Price:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.price}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Useful Life:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.usefulLife} years`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.department}`, 70, 90);

    // Additional Title and Underline above the table
    pdf.setTextColor(14, 79, 205); // #0E4FCD color
    pdf.setFontSize(16);
    pdf.text("Depreciation", pdf.internal.pageSize.width / 2, 105, "center");
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(
      pdf.internal.pageSize.width / 2 - 30,
      110,
      pdf.internal.pageSize.width / 2 + 30,
      110
    );

    // Add table for yearlyData
    const data = asset.yearlyData.map((item) => [
      `Depreciation Expense for year : ${item.year}`,
      `Expense : ${item.depreciationExpense}`,
    ]);

    pdf.autoTable({
      body: data,
      startY: 120,
    });

    pdf.save("Depreciation.pdf");
  };



  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <TitleHead>Calculate depreciation expense</TitleHead>
      {yearlyData?.length > 0 && show && (
        <Box sx={{ width: "90%", display: "flex", justifyContent: "end" }}>
          <CustomButton
            variant="contained"
            onClick={() => downloadPDF(print)}
            endIcon={<DownloadIcon />}>
            Download
          </CustomButton>
        </Box>
      )}
      <CustomStack>
        <FormElement>
          <FormTextLabel>Department</FormTextLabel>
          <FormWrapper>
            <Autocomplete
              sx={{ bgcolor: "#EFF5FE" }}
              id="free-solo-demo"
              onChange={handleDepartment}
              placeholder="e.g. ICU Department"
              options={filteredDepartments?.map((option) => ({
                label: `${option?.departname}`,
                name: option?.departname,
                id: option?.departid,
              }))}
              renderInput={(params) => (
                <TextField {...params} placeholder="e.g. ICU Department" />
              )}
            />
          </FormWrapper>
        </FormElement>

        <FormElement>
          <FormTextLabel>Asset Name</FormTextLabel>

          <FormWrapper>
            <Autocomplete
              sx={{ bgcolor: "#EFF5FE" }}
              id="free-solo-demo"
              onChange={handleAssetName}
              value={assetValue}
              placeholder="e.g. Ventilator"
              options={departAssetData?.map((option) => ({
                label: `${option?.name} (${option?.assetcode})`,
                value: option?.assetid,
                name: option?.name,
              }))}
              renderInput={(params) => (
                <TextField {...params} placeholder="e.g. Ventilator" />
              )}
            />
          </FormWrapper>
        </FormElement>
      </CustomStack>

      {Object.keys(data)?.length > 0 && showDetail && (
        <BoxWrapper style={{ overflowX: "auto" }}>
          <Box sx={{ overflow: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
              }}>
              <Table
                sx={{
                  borderRadius: " 15px",
                  bgcolor: "white",
                  whiteSpace: "nowrap",
                }}>
                <TableHead
                  sx={{
                    borderRadius: " 15px 15px 0px 0px",
                  }}>
                  <TableRow
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                      bgcolor: "rgba(40, 98, 212, 0.1)",
                    }}>
                    <TableHeading>Asset Name</TableHeading>

                    <TableHeading>Date of purchase</TableHeading>
                    <TableHeading>Price</TableHeading>
                    <TableHeading>Useful Life</TableHeading>
                    <TableHeading>Depreciation</TableHeading>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                      "&:hover": {
                        backgroundColor: "#E8ECF6",
                      },
                    }}>
                    <TableRows>{data?.name}</TableRows>
                    <TableRows>{data?.dop}</TableRows>
                    <TableRows>
                      <Box>
                        {
                          <Input
                            sx={{ width: "100px", borderBottom: "none" }}
                            value={price}
                            placeholder="e.g. 200000"
                            onChange={handlePrice}
                          />
                        }{" "}
                        <Typography sx={{ color: "red" }}>
                          {isPriceError}
                        </Typography>
                      </Box>
                    </TableRows>
                    <TableRows>
                      <Box>
                        <Input
                          sx={{ width: "50px", borderBottom: "none" }}
                          value={usefulLife}
                          placeholder="e.g. 6"
                          onChange={handleUsefulLife}
                        />
                        <Typography sx={{ color: "red" }}>
                          {isUsefulLifeError}
                        </Typography>
                      </Box>
                    </TableRows>
                    <TableRows>
                      <Button
                        onClick={calculateDepreciationAndExpense}
                        variant="contained">
                        Calculate
                      </Button>
                    </TableRows>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </BoxWrapper>
      )}
      { showDetail && usefulLife <= 2 && (
        <Typography sx={{ color: "red", paddingTop: "10px" }}>
          {" "}
          To obtain accurate calculations, please input a useful life value of 3
          or greater.{" "}
        </Typography>
      )}
      {yearlyData?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}>
          <Stack sx={{ marginTop: "10px", gap: "10px" }}>
            {yearlyData?.map((item) => (
              <tr>
                <Container key={item?.year} maxWidth="sm">
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" component="div" gutterBottom>
                        Depreciation Expense for Year {item?.year}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        Expense: ₹ {item?.depreciationExpense}
                      </Typography>
                    </CardContent>
                  </Card>
                </Container>
              </tr>
            ))}
          </Stack>
        </Box>
      )}

      
    </Box>
  );
}

export default Depreciation;
