// import styled from "@emotion/styled";
// import {
//   Badge,
//   Box,
//   Button,
//   FormControl,
//   FormControlLabel,
//   FormHelperText,
//   FormLabel,
//   Input,
//   InputLabel,
//   MenuItem,
//   OutlinedInput,
//   Radio,
//   RadioGroup,
//   IconButton,
//   Select,
//   Typography,
//   ButtonGroup,
//   Snackbar,
//   Stack,
//   Autocomplete,
//   TextField,
// } from "@mui/material";
// import FileUploadIcon from "@mui/icons-material/FileUpload";

// import DeleteIcon from "@mui/icons-material/Delete";
// import CancelIcon from "@mui/icons-material/Cancel";
// import React, { useEffect, useState } from "react";
// import ImageUploading from "react-images-uploading";
// import moment from "moment";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import { v4 as uuid } from "uuid";
// import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
// import pdfImage from "../images/pdfImage.png";
// import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
// import { useDispatch } from "react-redux";
// import {
//   AddAssetReport,
//   CreateQRCode,
//   addAsset,
//   assetDeleteReport,
//   deleteCertificate,
//   deleteImage,
//   editAssetFun,
//   getAsset,
//   getDepartmentData,
//   uploadImage,
//   uploadQrCodeImage,
//   uploadedCertificate,
// } from "../redux/data/action";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import AddAssetModal from "../components/AddAssetModal";
// import EditAssetModal from "../components/EditAssetModal";
// import AddReport from "./AddReport";
// import ImageDeleteModal from "../components/ImageDeleteModal";
// import MuiAlert from "@mui/material/Alert";
// const FormInput = styled(OutlinedInput)(({ theme }) => ({
//   border: "none",
//   width: "370px",
//   height: "50px",
//   [theme.breakpoints.down("xl")]: {
//     width: "360px",
//     height: "50px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "330px",
//     height: "56px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "330px",
//     height: "56px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "330px",
//     height: "56px",
//   },
// }));

// const FormSelect = styled(Select)(({ theme }) => ({
//   textAlign: "left",
//   border: "none",
//   width: "370px",
//   height: "50px",
//   [theme.breakpoints.down("xl")]: {
//     width: "360px",
//     height: "50px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "330px",
//     height: "56px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "330px",
//     height: "56px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "330px",
//     height: "56px",
//   },
// }));
// const NotesInput = styled(OutlinedInput)(({ theme }) => ({
//   border: "none",
//   width: "800px",
//   height: "174px",
//   [theme.breakpoints.down("xl")]: {
//     width: "700px",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "330px",
//     height: "174px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "330px",
//     height: "174px",
//   },
// }));

// const FormWrapper = styled(Box)(({ theme }) => ({
//   display: "grid",
//   gridTemplateColumns: "repeat(2,1fr)",
//   justifyItems: "center",
//   justifyContent: "space-evenly",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     gridTemplateColumns: "repeat(1,1fr)",
//   },
//   [theme.breakpoints.down("sm")]: {
//     gridTemplateColumns: "repeat(1,1fr)",
//   },
//   [theme.breakpoints.down("xs")]: {
//     gridTemplateColumns: "repeat(1,1fr)",
//   },
// }));

// const Main = styled(Box)(({ theme }) => ({
//   // border: "2px solid blue",
//   display: "flex",
//   flexDirection: "column",
//   width: "900px",
//   paddingBottom: "40px",
//   borderRadius: "30px",
//   background: "#FFF",
//   boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",

//   [theme.breakpoints.down("xl")]: {
//     padding: "20px",
//     width: "850px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     padding: "20px",
//     width: "750px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "380px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "380px",
//   },
// }));

// const FormTextLabel = styled(FormLabel)(({ theme }) => ({
//   textAlign: "left",
//   marginTop: "30px",
//   color: "#1746A2",

//   [theme.breakpoints.down("xl")]: {
//     marginTop: "10px",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const CheckBoxTextLabel = styled(FormLabel)(({ theme }) => ({
//   textAlign: "left",
//   fontSize: "22px",
//   fontWeight: "400",
//   marginTop: "30px",
//   color: "#FF731D",

//   [theme.breakpoints.down("xl")]: {
//     fontSize: "18px",
//     marginTop: "10px",
//     fontWeight: "400",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.down("sm")]: {},
// }));

// const NotesTextLabel = styled(FormLabel)(({ theme }) => ({
//   textAlign: "left",
//   fontSize: "22px",
//   marginTop: "30px",
//   color: "#FF731D",

//   [theme.breakpoints.down("xl")]: {
//     fontSize: "18px",
//     marginTop: "20px",
//     fontWeight: "400px",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     fontSize: "16px",
//     fontWeight: "400px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "16px",
//     fontWeight: "400px",
//   },
// }));

// const FormElement = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));
// const BottomElement = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));
// const FormHalfElement = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "row",
//   gap: "20px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     gap: "10px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     gap: "10px",
//   },
// }));

// const HalfInputDiv = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const HalfInput = styled(OutlinedInput)(({ theme }) => ({
//   bgcolor: "white",
//   width: "165px",
//   height: "50px",

//   [theme.breakpoints.down("xl")]: {
//     width: "165px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "160px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "160px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "160px",
//   },
// }));

// const CheckBoxContainer = styled(Box)(({ theme }) => ({
//   // border: '4px solid blue',
//   width: "370px",
//   minHeight: "50px",

//   [theme.breakpoints.down("xl")]: {
//     width: "360px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "330px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "330px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "330px",
//   },
// }));

// const OuterBox = styled(Box)(({ theme }) => ({
//   // border: '1px solid green',
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const UploadButton = styled(Button)(({ theme }) => ({
//   borderRadius: "15px",
//   textTransform: "none",
//   bgcolor: "#ADC2EC",
//   color: "#1746A2",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const FormRadio = styled(FormControl)(({ theme }) => ({
//   display: "flex",
//   justifyContent: "left",
//   bgcolor: "red",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const DateBox = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const DueDateBox = styled(Box)(({ theme }) => ({
//   display: "flex",
//   justifyContent: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const YesDateBox = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: "5px",
//   justifyContent: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const CustomText = styled(Typography)(({ theme }) => ({
//   fontSize: "12px",
//   textAlign: "left",
//   fontWeight: "400px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const InnerTitle = styled(Typography)(({ theme }) => ({
//   fontSize: "12px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const UploadContainer = styled(Box)(({ theme }) => ({
//   marginTop: "10px",
//   display: "flex",
//   flexDirection: "column",
//   width: "900px",
//   paddingBottom: "40px",

//   [theme.breakpoints.down("xl")]: {
//     width: "850px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "750px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "380px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "380px",
//   },
// }));

// const UploadImageBox = styled(Box)(({ theme }) => ({
//   // border: "4px solid blue",
//   border: "1px solid rgba(23, 70, 162, 0.2)",
//   boxShadow: "0px 0px 2px #EAF1FF",
//   margin: "2.5px",
//   width: "232px",
//   padding: "10px",
//   display: "flex",
//   alignItems: "center",

//   borderRadius: "8px",
//   bgcolor: "#efecea",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "160px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "160px",
//   },
// }));

// const ImageBox = styled(Box)(({ theme }) => ({
//   marginLeft: "8px",
//   width: "40px",
//   height: "40px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "30px",
//     marginLeft: "4px",
//     height: "30px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "30px",
//     height: "30px",
//   },
// }));

// const DotedBox = styled(Box)(({ theme }) => ({
//   border: "1px dotted black",
//   cursor: "pointer",

//   width: "40px",
//   height: "40px",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "30px",
//     height: "30px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "30px",
//     height: "30px",
//   },
// }));

// const ImageOuterBox = styled(Box)(({ theme }) => ({
//   width: "282px",
//   minHeight: "1px",

//   bgcolor: "#efecea",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "186px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "186px",
//   },
// }));

// const ImageRightOuterBox = styled(Box)(({ theme }) => ({
//   width: "282px",

//   minHeight: "1px",
//   display: "flex",
//   justifyContent: "right",
//   alignItems: "end",
//   flexDirection: "column",
//   bgcolor: "#efecea",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "186px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "186px",
//   },
// }));

// const ImageWarningText = styled(FormHelperText)(({ theme }) => ({
//   width: "232px",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "166px",
//   },
//   [theme.breakpoints.down("sm")]: {},
// }));

// const FlexBox = styled(Box)(({ theme }) => ({
//   // border: "3px solid black",

//   display: "flex",
//   justifyContent: "space-between",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const AssetReportBox = styled(Box)(({ theme }) => ({
//   // border: "3px solid black",
//   // width: "300px",
//   padding: "10px",
//   // display: "flex",
//   // justifyContent: "space-between",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const ImageSet = styled(Box)(({ theme }) => ({
//   width: "140px",
//   // border:'2px solid red',
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const Photo = styled(Box)(({ theme }) => ({
//   border: "1px dotted black",
//   width: "40px",
//   height: "40px",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "30px",
//     height: "30px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "30px",
//     height: "30px",
//   },
// }));

// const ButtonWrapper = styled(Box)(({ theme }) => ({
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const PhotoText = styled(Box)(({ theme }) => ({
//   fontSize: "14px",
//   fontWeight: "500",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const CustomLabel = styled(Typography)(({ theme }) => ({
//   fontSize: "16px",
//   fontWeight: "500",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const HelperText = styled(FormHelperText)(({ theme }) => ({
//   color: "red",

//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {},
//   [theme.breakpoints.down("sm")]: {},
// }));

// const SubmitButton = styled(Button)(({ theme }) => ({
//   bgcolor: "#1746A2",
//   textTransform: "none",
//   fontSize: "16px",

//   borderRadius: "40px",

//   width: "130px",
//   height: "46px",
//   // "&:hover": {
//   //   transform: "scale3d(1.05, 1.05, 2)",
//   //   cursor: "pointer",
//   //   color: "#FFF7E9",
//   //   bgcolor: "#F78117",
//   // },

//   [theme.breakpoints.down("xl")]: {
//     width: "130px",
//     height: "46px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "130px",
//     height: "46px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "130px",
//     height: "46px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "130px",
//     height: "46px",
//   },
// }));

// const TitleHead = styled(Typography)(({ theme }) => ({
//   fontSize: "24px",
//   fonteight: "400",
//   marginBottom: "10px",
//   color: "#FF731D",
//   [theme.breakpoints.down("xl")]: {
//     fonteight: "400",
//     fontSize: "22px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     fonteight: "400",
//     fontSize: "22px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("md")]: {
//     fonteight: "500",
//     fontSize: "20px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fonteight: "500",
//     fontSize: "20px",
//     lineHeight: "33px",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const TopContainers = styled(Box)(({ theme }) => ({
//   // border:"1px solid green",
//   width: "900px",
//   display: "flex",
//   justifyContent: "space-between",
//   marginLeft: "10px",
//   marginRight: "10px",
//   marginTop: "2%",
//   [theme.breakpoints.down("xl")]: {
//     width: "850px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "750px",
//     border: "none",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "390px",
//     border: "none",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "390px",
//     border: "none",
//   },
//   [theme.breakpoints.down("xs")]: {},
// }));

// const ButtonBox = styled(Box)(({ theme }) => ({
//   display: "flex",
//   width: "100%",

//   // flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",

//   gap: "40px",
//   marginTop: "20px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     marginTop: "10px",
//     gap: "20px",
//     flexDirection: "column-reverse",
//   },
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

// const SpecialDropDowne = styled(Stack)(({ theme }) => ({
//   border: "none",
//   width: "370px",
//   height: "50px",
//   [theme.breakpoints.down("xl")]: {
//     width: "360px",
//     height: "50px",
//   },
//   [theme.breakpoints.down("lg")]: {
//     width: "330px",
//     height: "56px",
//   },
//   [theme.breakpoints.down("md")]: {
//     width: "330px",
//     height: "56px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "330px",
//     height: "56px",
//   },
// }));

// function EditAssetPage() {
//   const params = useParams();
//   const navigate = useNavigate();
//   const editData = useSelector((store) => store.data.getAsset);

//   const status = useSelector((store) => store.data.editAsset);
//   const [open, setOpen] = useState(false);
//   const [assetReport, setAssetReport] = useState([]);
//   const [fileError, setFileError] = useState("");
//   const handleAssetReport = (data) => {
//     setAssetReport(data);
//   };
//   let userAccess = JSON.parse(sessionStorage.getItem("user_access")) || JSON.parse(localStorage.getItem("user_access"));
//   const [toggle, setToggle] = useState(false);
//   const dispatch = useDispatch();
//   const token = localStorage.getItem("userToken");
//   let userIds = JSON.parse(localStorage.getItem("userData"));
//   const departmentData = useSelector((store) => store.data.department) || [];
//   const [department, setDepartment] = useState("");
//   const [preDepartment, setPreDepartment] = useState("");
//   const [photoFlag, setPhotoFlag] = useState(false);
//   const [certificateFlag, setCertificateFlag] = useState(false);
//   const [fileUrl, setFileUrl] = useState([]);
//   const [certificateUrl, setCertificateUrl] = useState([]);
//   const [name, setName] = useState([]);
//   const [warranty, setWarranty] = useState("");
//   const [assetName, setAssetName] = useState("");
//   const [dateOfPurchase, setDateOfPurchase] = useState("");
//   const [brand, setBrand] = useState("");
//   const [usefullLife, setUsefullLife] = useState("");
//   const [serialNumber, setSerialNumber] = useState("");
//   const [modelNumber, setModelNumber] = useState("");
//   const [soldBy, setSoldBy] = useState("");
//   const [price, setPrice] = useState("");
//   const [assetType, setAssetType] = useState("");
//   const [assetStatus, setAssetStatus] = useState("");
//   const [calibrationStatus, setCalibrationStatus] = useState("");
//   const [cmcAmc, setCmcAmc] = useState("");
//   const [cmcAmcStartDate, setCmcAmcStartDate] = useState("");
//   const [cmcAmcEndDate, setCmcAmcEndDate] = useState("");
//   const [calibrationDate, setCalibrationDate] = useState("");

//   const [calibrationStartDate, setCalibrationStartDate] = useState("");
//   const [calibrationEndDate, setCalibrationEndDate] = useState("");

//   const [required, setRequired] = useState("");
//   const [dueDate, setDueDate] = useState("");
//   const [departmentId, setDepartmentId] = useState("");
//   const [departmentPreId, setDepartmentPreId] = useState("");
//   const [notes, setNotes] = useState("");
//   const [isAssetError, setIsAssetError] = useState(null);
//   const [isSerialNumberError, setIsSerialNumberError] = useState(null);
//   const [isModelNumberError, setIsModelNumberError] = useState(null);
//   const [isBrandError, setIsBrandError] = useState(null);
//   const [isSoldByError, setIsSoldByError] = useState(null);
//   const [isWarrantyError, setIsWarrantyError] = useState(null);
//   const [isPriceError, setIsPriceError] = useState(null);
//   const [isDueDateError, setIsDueDateError] = useState(null);
//   const [isAmcEndDateError, setIsAmcEndDateError] = useState(null);
//   const [isCmcEndDateError, setIsCmcEndDateError] = useState(null);
//   const [isAssetTypeError, setIsAssetTypeError] = useState(null);
//   const [isAssetStatusError, setIsAssetStatusError] = useState(null);
//   const [isCalibrationStatusError, setIsCalibrationStatusError] =
//     useState(null);

//   const [warrantyStartDate, setWarrantyStartDate] = useState("");
//   const [warrantyEndDate, setWarrantyEndDate] = useState("");

//   const [isAssetCodeError, setIsAssetCodeError] = useState(null);
//   const [isUsefullLifeError, setIsUsefullLifeError] = useState(null);
//   const [isCmcAmcError, setIsCmcAmcError] = useState(null);
//   const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);
//   const [isRequiredError, setIsRequiredError] = useState(null);
//   const [isDepartmentError, setIsDepartmentError] = useState(null);
//   const [isDateOfPurchaseError, setIsDateOfPurchaseError] = useState(null);
//   const [isPhotoError, setIsPhotoError] = useState(null);
//   const [isCertificateError, setIsCertificateError] = useState(null);

//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
//   });
//   const [assetCode, setAssetCode] = useState();
//   let idSplit = params.id.substring(0, 4);
//   const initialRow = {
//     hospid: userIds.hospid,
//     userid: userIds.userid,
//     imageid: "",
//     image: "",
//     report_name: "Service Report",
//   };

//   const [openReport, setOpenReport] = useState(false);

//   const [dropDown, setDropDown] = useState("");
//   const [reportData, setReportData] = useState([initialRow]);
//   const [dataToSend, setDataToSend] = useState([initialRow]);
//   const [newRow, setNewRow] = useState({ ...initialRow });

//   const handlePhotoUpload = (e) => {
//     const singlefile = e.target.files[0];

//     const newFiles = Array.from(e.target.files).filter((file) => {
//       const fileType = file.type.split("/")[1];
//       const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
//       const allowedSize = 2 * 1024 * 1024; // 2 MB

//       if (allowedTypes.indexOf(file.type) === -1) {
//         setIsPhotoError(`Can't upload ${fileType} file.`);
//         return false;
//       }

//       if (file.size > allowedSize) {
//         compressImage(file);

//         // return false;
//       } else {
//         setIsPhotoError(null);
//         const reader = new FileReader();

//         reader.onloadend = () => {
//           let sendData = {
//             hospid: userIds?.hospid,
//             userid: userIds?.userid,
//             imageid: `${idSplit}` + uuid() + `.${fileType}`,
//             image: reader.result,
//           };
//           setFileUrl([...fileUrl, sendData]);

//           dispatch(uploadImage(sendData, token));
//         };
//         reader.readAsDataURL(singlefile);
//       }
//     });
//   };

//   const compressImage = (file) => {
//     const reader = new FileReader();

//     reader.onload = (e) => {
//       const img = new Image();
//       img.src = e.target.result;

//       img.onload = () => {
//         const canvas = document.createElement("canvas");
//         const maxWidth = 800;
//         const maxHeight = 600;
//         let width = img.width;
//         let height = img.height;

//         if (width > height) {
//           if (width > maxWidth) {
//             height *= maxWidth / width;
//             width = maxWidth;
//           }
//         } else {
//           if (height > maxHeight) {
//             width *= maxHeight / height;
//             height = maxHeight;
//           }
//         }

//         canvas.width = width;
//         canvas.height = height;
//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0, width, height);

//         canvas.toBlob(
//           (blob) => {
//             const compressedFile = new File(
//               [blob],
//               `${idSplit}` + uuid() + ".jpg",
//               {
//                 type: "image/jpeg",
//               }
//             );

//             const reader = new FileReader();
//             reader.onloadend = () => {
//               let sendData = {
//                 hospid: userIds?.hospid,
//                 userid: userIds?.userid,
//                 imageid: `${idSplit}` + uuid() + ".jpg",
//                 image: reader.result,
//               };
//               setFileUrl([...fileUrl, sendData]);

//               dispatch(uploadImage(sendData, token));
//             };
//             reader.readAsDataURL(compressedFile);
//           },
//           "image/jpeg",
//           0.7
//         );
//       };
//     };

//     reader.readAsDataURL(file);
//   };
//   const removePhoto = (id) => {
//     let data = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       imageid: id,
//     };

//     dispatch(deleteImage(data));
//     setFileUrl(fileUrl.filter((item) => item.imageid !== id));
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleCertificateUpload = (e) => {
//     const singlefile = e.target.files[0];

//     const splitId = params.id.substring(0, 4);
//     const newFiles = Array.from(e.target.files).filter((file) => {
//       const fileType = file.type.split("/")[1];
//       const allowedTypes = [
//         "image/jpeg",
//         "image/jpg",
//         "image/png",
//         "application/pdf",
//       ];
//       const allowedSize = 2 * 1024 * 1024; // 2 MB
//       if (allowedTypes.indexOf(file.type) === -1) {
//         setIsCertificateError(`Can't upload ${fileType} file.`);
//         return false;
//       }

//       if (file.size > allowedSize) {
//         setIsCertificateError("Greater than 2 mb can't be uploaded");
//         return false;
//       } else {
//         setIsCertificateError(null);
//         const reader = new FileReader();

//         reader.onloadend = () => {
//           //   setCertificateUrl([...fileUrl,reader.result]);

//           let sendData = {
//             hospid: userIds.hospid,
//             userid: userIds.userid,
//             imageid: `${splitId}_` + uuid() + `.${fileType}`,
//             image: reader.result,
//           };
//           let checkData = {
//             hospid: userIds.hospid,
//             userid: userIds.userid,
//             imageid: sendData.imageid,
//             image: reader.result,
//             fileType: fileType,
//           };

//           setCertificateUrl([...certificateUrl, checkData]);
//           dispatch(uploadedCertificate(sendData));
//         };
//         reader.readAsDataURL(singlefile);
//       }
//     });
//   };

//   const removeCertificate = (id) => {
//     let data = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       imageid: id,
//     };
//     dispatch(deleteCertificate(data));
//     setCertificateUrl(certificateUrl.filter((item) => item.imageid !== id));
//     setOpen(true);
//   };

//   useEffect(() => {
//     let data = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//     };
//     dispatch(getDepartmentData(data, token));
//   }, [dispatch]);

//   const handleName = (e) => {
//     const value = e.target.value;
//     const regex = /^[a-zA-Z ]+$/;
//     if (value === "" || regex.test(value)) {
//       setName(value);
//     }
//   };

//   const handleAssetName = (e) => {
//     const value = e.target.value;
//     const regex = /^[a-zA-Z0-9\s-]+$/;

//     setAssetName(value);
//     if (value === "" || regex.test(value)) {
//       setAssetName(value);
//       setIsAssetError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsAssetError("wrong asset Name");
//     }
//     if (value === "") {
//       setIsAssetError("Please fill the field");
//     }
//   };

//   const handleSerialNumber = (e) => {
//     const value = e.target.value;
//     const regex = /^[A-Za-z0-9 -]+$/;
//     setSerialNumber(value);
//     if (regex.test(value) || value === "") {
//       setSerialNumber(value);
//       setIsSerialNumberError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsSerialNumberError("wrong serial number");
//     }
//   };

//   const handleModelNumber = (e) => {
//     const value = e.target.value;
//     const regex = /^[A-Za-z0-9 -]+$/;
//     setModelNumber(value);
//     if (regex.test(value) || value === "") {
//       setModelNumber(value);
//       setIsModelNumberError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsModelNumberError("wrong model number");
//     }
//   };

//   const handleBrand = (e) => {
//     const value = e.target.value;
//     const regex = /^[A-Za-z0-9 -]+$/;
//     setBrand(value);
//     if (value === "" || regex.test(value)) {
//       setBrand(value);
//       setIsBrandError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsBrandError("wrong brand Name");
//     }
//   };

//   const handleSoldBy = (e) => {
//     const value = e.target.value;
//     const regex = /^[a-zA-Z0-9 ]+$/;

//     if (value === "" || regex.test(value)) {
//       setSoldBy(value);
//       setIsSoldByError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsSoldByError("Enter valid details");
//     }
//     // if (value === "") {
//     //   setIsSoldByError("Please fill the field");
//     // }
//   };

//   const handleWarranty = (e) => {
//     const value = e.target.value;
//     const regex = /^[0-9]*$/;

//     if (value === "" || regex.test(value)) {
//       setWarranty(value);
//       setIsWarrantyError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsWarrantyError("Enter valid warranty month");
//     }
//     // if (value === "") {
//     //   setIsDateOfPurchaseError("");
//     // }
//     if (value !== "" && dateOfPurchase === "") {
//       setIsDateOfPurchaseError("Please fill the field");
//     }
//   };

//   const handlePrice = (e) => {
//     const value = e.target.value;
//     const regex = /^[0-9]*$/;
//     setPrice(value);
//     if (value === "" || regex.test(value)) {
//       setPrice(value);
//       setIsPriceError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsPriceError("Enter valid price");
//     }
//     if (value === "") {
//       setIsPriceError("Please fill the field");
//     }
//   };

//   const handleUsefulLife = (e) => {
//     const value = e.target.value.trim();
//     const regex = /^[1-9]\d*$/;
//     setUsefullLife(value.trim());
//     if (value.trim() === "" || regex.test(value.trim())) {
//       setUsefullLife(value.trim());
//       setIsUsefullLifeError(null);
//     }
//     if (value.trim() !== "" && !regex.test(value.trim())) {
//       setIsUsefullLifeError("Enter valid number");
//     }
//     if (value.trim() === "") {
//       setIsUsefullLifeError(null);
//       setIsPriceError(null);
//       setIsDateOfPurchaseError(null);
//     }
//     if (value.trim() !== "" && price === "") {
//       setIsPriceError("Please fill the field");
//     }
//     if (value.trim() !== "" && dateOfPurchase === "") {
//       setIsDateOfPurchaseError("Please fill the field");
//     }
//   };

//   const handleDueDate = (e) => {
//     const value = e.target.value;
//     const regex = /^[0-9]*$/;

//     if (value === "" || regex.test(value)) {
//       setDueDate(value);
//       setIsDueDateError(null);
//     }
//     if (value !== "" && !regex.test(value)) {
//       setIsDueDateError("Enter valid month count");
//     }
//     if (value > 99) {
//       setIsDueDateError("Enter valid month count");
//     }
//     if (calibrationDate == "") {
//       setIsCalibrationDateError("Please fill the field");
//     }
//   };

//   const handleNotes = (e) => {
//     const value = e.target.value;
//     setNotes(value);
//   };

//   const handleDepartment = (event,value) => {

//     setDepartmentPreId(editData?.departid);
//     if(value !== null && value !== undefined){
//       setDepartmentId(value.id);
//       setDepartment(value.name);

//       if (value.name !== ""&& value.name !== null) {
//         setIsDepartmentError(null);
//       }

//       if (editData?.departid === value?.id) {
//         setDepartmentPreId("NA");
//       }
//       if (editData?.departid !== value?.id) {
//         setDepartmentPreId(editData?.departid);
//       }

//       if (editData?.dept === value.name) {
//         setPreDepartment("NA");
//       }
//       if (editData?.dept !== value.name) {
//         setPreDepartment(editData?.dept);
//       }
//     }

//   };

//   const handleAmcCmcStartDateChange = (event) => {
//     setCmcAmcStartDate(event.target.value);
//     amcValidateDateRange(event.target.value, cmcAmcEndDate);
//   };

//   const handleAmcCmcEndDateChange = (event) => {
//     setCmcAmcEndDate(event.target.value);
//     amcValidateDateRange(cmcAmcStartDate, event.target.value);
//   };

//   const amcValidateDateRange = (startDate, endDate) => {
//     if (new Date(startDate) > new Date(endDate)) {
//       setIsAmcEndDateError("Invalid date Range");
//     }
//     if (new Date(startDate) <= new Date(endDate)) {
//       setIsAmcEndDateError(null);
//       setIsCmcEndDateError(null);
//     }
//   };

//   const calibrationValidateDateRange = (startDate, endDate) => {
//     if (new Date(startDate) > new Date(endDate)) {
//       setIsCalibrationDateError("Invalid date Range");
//     }
//     if (new Date(startDate) <= new Date(endDate)) {
//       setIsCalibrationDateError(null);
//       setIsCalibrationDateError(null);
//       // setIsCmcEndDateError(null);
//     }
//   };

//   const handleCalibrationStartDate = (event) => {
//     setCalibrationStartDate(event.target.value);
//     calibrationValidateDateRange(event.target.value, calibrationEndDate);
//   };

//   const handleCalibrationEndDate = (event) => {
//     setCalibrationEndDate(event.target.value);
//     calibrationValidateDateRange(calibrationStartDate, event.target.value);
//   };

//   const handleAmcCmc = (e) => {
//     const value = e.target.value;
//     setCmcAmc(value);
//     if (value === "None") {
//       setIsCmcEndDateError(null);
//       setIsAmcEndDateError(null);
//       setCmcAmcEndDate("");
//       setCmcAmcStartDate("");
//     }
//     if (value === "AMC") {
//       setIsCmcEndDateError("Please enter the date");
//       setIsAmcEndDateError(null);
//       if (cmcAmcStartDate !== "" && cmcAmcEndDate !== "") {
//         setIsCmcEndDateError(null);
//       }
//       if (
//         cmcAmcStartDate === "Invalid date" &&
//         cmcAmcEndDate === "Invalid date"
//       ) {
//         setIsCmcEndDateError("Please enter the date");
//       }
//     }
//     if (value === "CMC") {
//       setIsAmcEndDateError("Please enter the date");
//       setIsCmcEndDateError(null);
//       if (cmcAmcStartDate !== "" && cmcAmcEndDate !== "") {
//         setIsAmcEndDateError(null);
//       }
//       if (
//         cmcAmcStartDate === "Invalid date" &&
//         cmcAmcEndDate === "Invalid date"
//       ) {
//         setIsAmcEndDateError("Please enter the date");
//       }
//     }
//     if (value !== "") {
//       setIsCmcAmcError(null);
//     }
//   };

//   const handleRequired = (e) => {
//     let value = e.target.value;
//     if (value !== "") {
//       setRequired(value);
//       setIsRequiredError(null);
//     }
//   };

//   const handleCalibrationStatus = (e) => {
//     let value = e.target.value;
//     setCalibrationStatus(value);
//     if (value === "No") {
//       setCalibrationStartDate("");
//       setCalibrationEndDate("");
//       setIsRequiredError("");
//       setIsDueDateError(null);
//       setIsCalibrationDateError(null);
//     }
//     if (value === "Yes") {
//       setRequired("NA");
//       setIsRequiredError(null);
//       setCalibrationEndDate("");
//       setCalibrationStartDate("");
//       setIsCalibrationDateError("Please fill the field");
//     }
//     if (value !== "") {
//       setIsCalibrationStatusError(null);
//     }
//   };

//   const handleAssetStatus = (e) => {
//     const value = e.target.value;
//     setAssetStatus(value);
//     if (value !== "") {
//       setIsAssetStatusError(null);
//     }
//   };

//   const handleDepartmentId = (item) => {
//     setDepartmentPreId(editData?.departid);
//     setDepartmentId(item.departid);
//     if (item !== "") {
//       setIsDepartmentError(null);
//     }

//     if (editData?.departid === item?.departid) {
//       setDepartmentPreId("NA");
//     }
//     if (editData?.departid !== item?.departid) {
//       setDepartmentPreId(editData?.departid);
//     }
//   };

//   const handleAssetType = (e) => {
//     const value = e.target.value;
//     setAssetType(value);
//     if (value !== "") {
//       setIsAssetTypeError(null);
//     }
//   };

//   const handlePurchaseDate = (e) => {
//     const value = e.target.value;
//     setDateOfPurchase(value);
//     if (value !== "") {
//       setIsDateOfPurchaseError(null);
//     }
//     // if (value === "") {
//     //   setIsDateOfPurchaseError("Please fill the field");
//     // }
//   };

//   const handleCalibrationDate = (e) => {
//     let value = e.target.value;
//     setCalibrationDate(value);
//     if (value !== "") {
//       setIsCalibrationDateError(null);
//     }
//   };

//   const handleAssetCode = (e) => {
//     const value = e.target.value;
//     setAssetCode(value);
//     if (value !== "") {
//       setAssetCode(value);
//       setIsAssetCodeError(null);
//     }
//     if (value === "") {
//       setIsAssetCodeError("Please fill the field");
//     }
//   };

//   const handleSubmit = () => {
//     let rData = reportData?.filter((item) => {
//       return item.image !== "";
//     });

//     assetName === "" && setIsAssetError("Please fill the field");
//     // brand === "" && setIsBrandError("Please fill the field");
//     // soldBy === "" && setIsSoldByError("Please fill the field");
//     // warranty === "" && setIsWarrantyError("Please fill the field");
//     department === "" && setIsDepartmentError("Please fill the field");
//     assetType === "" && setIsAssetTypeError("Please fill the field");
//     assetStatus === "" && setIsAssetStatusError("Please fill the field");
//     calibrationStatus === "" &&
//       setIsCalibrationStatusError("Please fill the field");
//     cmcAmc === "" && setIsCmcAmcError("Please fill the field");
//     assetCode === "" && setIsAssetCodeError("Please fill the field");
//     // dateOfPurchase === "" && setIsDateOfPurchaseError("Please fill the field");
//     // usefullLife === "" && setIsUsefullLifeError("Please fill the field")
//     // price === "" && setIsPriceError("Please fill the field")
//     fileUrl.length <= 0 && setIsPhotoError("Please add an image");
//     if (calibrationStatus === "Yes") {
//       ((calibrationStartDate === "NA" || calibrationStartDate === "") &&
//         setIsCalibrationDateError("Please fill the field")) ||
//         ((calibrationEndDate === "NA" || calibrationEndDate === "") &&
//           setIsCalibrationDateError("Please fill the field"));
//     }
//     if (calibrationStatus === "No") {
//       (required === "NA" || required === "") &&
//         setIsRequiredError("Please fill the field");
//     }

//     if (cmcAmc === "AMC" || cmcAmc === "CMC") {
//       (cmcAmcEndDate === "" ||
//         cmcAmcEndDate === "NA" ||
//         cmcAmcStartDate === "" ||
//         cmcAmcStartDate === "NA") &&
//         setIsCmcEndDateError("Please fill this field");
//     }
//     if (
//       assetName === "" ||
//       department === "" ||
//       assetType === "" ||
//       assetStatus === "" ||
//       calibrationStatus === "" ||
//       cmcAmc === "" ||
//       fileUrl.length === 0 ||
//       assetCode === ""
//     ) {
//       setSnackbarMessage("Please fill all the mandatory fields.");
//       setSnackbarOpen(true);
//       return;
//     }
//     if (
//       assetName !== "" &&
//       // department !== "" &&
//       // soldBy !== "" &&
//       // usefullLife !== "" &&
//       // brand !== "" &&
//       assetType !== "" &&
//       assetStatus !== "" &&
//       calibrationStatus !== "" &&
//       cmcAmc !== "" &&
//       fileUrl.length !== 0 &&
//       assetCode !== ""
//     ) {
//       if (
//         isAssetError === null &&
//         isSerialNumberError === null &&
//         isModelNumberError === null &&
//         // isBrandError === null &&
//         // isSoldByError === null &&
//         isWarrantyError === null &&
//         isDateOfPurchaseError === null &&
//         isPriceError === null &&
//         // isDueDateError === null &&
//         isAmcEndDateError === null &&
//         isCmcEndDateError === null &&
//         isUsefullLifeError === null &&
//         isAssetTypeError === null &&
//         isAssetStatusError === null &&
//         isCalibrationStatusError === null &&
//         isCmcAmcError === null &&
//         isCalibrationDateError === null &&
//         isRequiredError === null &&
//         isPhotoError === null &&
//         isCertificateError === null &&
//         isAssetCodeError === null
//       ) {
//         let data = {
//           assetid: params.id,
//           hospid: userIds.hospid,
//           hospname: userIds.hospname,
//           userid: userIds.userid,
//           username: userIds.username,
//           assetname: assetName.trim(),
//           serialno: serialNumber === "" ? "NA" : serialNumber.trim(),
//           assetcode: assetCode === "" ? "NA" : assetCode.trim(),
//           modelno: modelNumber === "" ? "NA" : modelNumber.trim(),
//           department: department.trim(),
//           department_id: departmentId,
//           // prev_department: departmentPreId===""?"NA":departmentPreId,
//           // prev_department_id: preDepartment===""?"NA":preDepartment,
//           assettype: assetType,
//           lifeyrs: usefullLife === "" ? "1" : usefullLife.trim(),
//           assetstatus: assetStatus,
//           brand: brand === "" ? "NA" : brand.trim(),
//           price: price === "" ? "NA" : price.trim(),
//           purchdate:
//             dateOfPurchase === "" ||
//             dateOfPurchase === "NA" ||
//             dateOfPurchase === "Invalid date"
//               ? "NA"
//               : moment(dateOfPurchase).format("DD-MM-YYYY"),
//           warntdate:
//             warrantyStartDate === "" || warrantyStartDate === "NA"
//               ? "NA"
//               : moment(warrantyStartDate).format("DD-MM-YYYY"),
//           wrntymonth: "1",
//           expdate:
//             warrantyEndDate === "" || warrantyEndDate === "NA"
//               ? "NA"
//               : moment(warrantyEndDate).format("DD-MM-YYYY"),
//           sellername: soldBy === "" ? "NA" : soldBy.trim(),
//           prodimgs:
//             fileUrl.length > 0
//               ? fileUrl?.map((item) => {
//                   return item.imageid;
//                 })
//               : "NA",
//           wrntyimage:
//             certificateUrl.length > 0
//               ? certificateUrl?.map((item) => {
//                   return item.imageid;
//                 })
//               : "NA",
//           calibrate_status: calibrationStatus,
//           calibrate_date:
//             calibrationStartDate === "" ||
//             calibrationStartDate === "Invalid date"
//               ? "NA"
//               : moment(calibrationStartDate).format("DD-MM-YYYY"),
//           calibrate_req: required === "" ? "NA" : required,
//           calibrate_duemonth: "1",
//           calibrate_duedate:
//             calibrationEndDate === "" || calibrationEndDate === "Invalid date"
//               ? "NA"
//               : moment(calibrationEndDate).format("DD-MM-YYYY"),
//           cmcamc: cmcAmc,
//           cmcamc_start:
//             cmcAmcStartDate === "" || cmcAmcStartDate === "Invalid date"
//               ? "NA"
//               : moment(cmcAmcStartDate).format("DD-MM-YYYY"),
//           cmcamc_due:
//             cmcAmcEndDate === "" || cmcAmcEndDate === "Invalid date"
//               ? "NA"
//               : moment(cmcAmcEndDate).format("DD-MM-YYYY"),
//           calibformat:
//             calibrationStartDate === "" ||
//             calibrationStartDate === "Invalid date"
//               ? "NA"
//               : moment(calibrationStartDate).format("YYYY-MM-DD"),
//           purdateformat: moment(dateOfPurchase).format("YYYY-MM-DD"),
//           notes: notes === "" ? "NA" : notes.trim(),
//           report_names:
//             rData?.length === 0
//               ? []
//               : rData?.map((item) => {
//                   return item.report_name;
//                 }),
//           report_ids:
//             rData?.length === 0
//               ? []
//               : rData?.map((item) => {
//                   return item.imageid;
//                 }),
//         };

//         dispatch(editAssetFun(data));



//         setToggle(true);
//         // navigate(-1);
//       }
//     }
//   };

//   useEffect(() => {
//     let data = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       assetid: params.id,
//     };
//     dispatch(getAsset(data));
//   }, [dispatch]);

//   useEffect(() => {
//     if (Object.keys(editData).length !== 0) {
//       setPrice(editData?.price);
//       setAssetName(editData?.name);
//       setDepartment(editData?.dept);
//       setSerialNumber(editData?.serialno);
//       setModelNumber(editData?.modelno);
//       setWarranty(editData?.wrntmonth);
//       setUsefullLife(editData?.lifeyrs);
//       setBrand(editData?.brand);
//       setSoldBy(editData?.soldby);
//       setCalibrationStatus(editData?.calibstatus);
//       setDateOfPurchase(
//         moment(editData?.dop, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );
//       setAssetStatus(editData?.status);
//       setAssetType(editData?.type);
//       setDepartmentId(editData?.departid);
//       setNotes(editData?.notes);
//       setAssetCode(editData?.code);
//       setCmcAmc(editData?.cmcamc);
//       setCmcAmcStartDate(
//         moment(editData?.cmcamcstart, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );
//       setCmcAmcEndDate(
//         moment(editData?.cmcamcdue, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );
//       setCalibrationDate(
//         moment(editData?.calibdate, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );

//       setWarrantyStartDate(
//         moment(editData?.wrntdate, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );
//       setWarrantyEndDate(
//         moment(editData?.wrntexpdate, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );

//       setRequired(editData?.calibreq);
//       setDueDate(editData?.calibmnth);
//       setCalibrationStartDate(
//         moment(editData?.calibdate, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );
//       setCalibrationEndDate(
//         moment(editData?.calibduedate, "DD-MM-YYYY").format("YYYY-MM-DD")
//       );
//       setFileUrl(
//         editData?.imgs === "NA" || editData?.imgs === ""
//           ? []
//           : editData?.imgs?.map((item) => {
//               return {
//                 imageid: item.imgid,
//                 image: item.imgurl,
//               };
//             })
//       );
//       setCertificateUrl(
//         editData?.wimg === "NA" || editData?.wimg === ""
//           ? []
//           : editData?.wimg?.map((item) => {
//               return {
//                 imageid: item.imgid,
//                 image: item.imgurl,
//               };
//             })
//       );

//       setReportData(
//         editData?.reportimgs === "NA" || editData?.reportimgs === ""
//           ? []
//           : editData?.reportimgs?.map((item, index) => {
//               return {
//                 imageid: item.imgid,
//                 image: item.imgurl,
//                 report_name: editData?.reportnames[index],
//               };
//             })
//       );
//     }
//   }, [editData]);

//   const handleCloseModal = () => {
//     setToggle(false);
//     navigate("/all-assets");
//   };

//   const handleOpenReport = () => {
//     setOpenReport(true);
//   };

//   const handleCloseReport = () => {
//     setOpenReport(false);
//     const filteredData = dataToSend.filter((item) => item.image !== "");
//     handleAssetReport(filteredData);
//   };

//   const handleAddRow = () => {
//     setReportData([...reportData, { ...newRow }]);
//     setDataToSend([...reportData, { ...newRow }]);
//     setNewRow({ ...initialRow });
//   };

//   const handleRemoveRow = (index) => {
//     const updatedData = [...reportData];
//     let dataDelete = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       imageid: updatedData[index].imageid,
//     };
//     dispatch(assetDeleteReport(dataDelete));
//     updatedData.splice(index, 1);
//     setReportData(updatedData);
//     setDataToSend(updatedData);
//   };

//   const handleImageUpload = (event, index) => {
//     const file = event.target.files[0];
//     const updatedData = [...reportData];

//     if (file) {
//       const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
//       const fileType = file.type.split("/")[1];

//       if (allowedTypes.includes(fileType)) {
//         const splitId = uuid().substring(0, 6);
//         const imageid = params.id.substring(0, 6);
//         const reader = new FileReader();
//         reader.onload = () => {
//           const dataUrl = reader.result;
//           updatedData[index].imageid = `${imageid}_${splitId}.${fileType}`;
//           updatedData[index].image = dataUrl;
//           setReportData(updatedData);
//           setDataToSend(updatedData);
//           let assetRep = {
//             hospid: userIds.hospid,
//             userid: userIds.userid,
//             imageid: updatedData[index].imageid,
//             image: updatedData[index].image,
//           };

//           dispatch(AddAssetReport(assetRep));
//           setFileError("");
//         };
//         reader.readAsDataURL(file);
//       } else {
//         setFileError("Please upload pdf, jpg, jpeg or png file");
//       }
//     } else {
//       updatedData[index].imageid = "";
//       updatedData[index].image = "";
//       setReportData(updatedData);
//       setDataToSend(updatedData);
//     }
//   };
//   const handleClearImage = (index) => {
//     const updatedData = [...reportData];
//     updatedData[index].image = "";
//     setReportData(updatedData);
//     setDataToSend(updatedData);
//     let dataDelete = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       imageid: updatedData[index].imageid,
//     };

//     dispatch(assetDeleteReport(dataDelete));
//   };

//   const warrantyValidDateRange = (startDate, endDate) => {
//     if (
//       !startDate ||
//       startDate === "NA" ||
//       startDate === "Invalid date" ||
//       !endDate ||
//       endDate === "NA" ||
//       endDate === "Invalid date"
//     ) {
//       setIsWarrantyError("Enter start `and end date");
//       return;
//     }

//     if (new Date(startDate) > new Date(endDate)) {
//       setIsWarrantyError("Invalid date Range");
//     } else {
//       setIsWarrantyError(null);
//     }
//   };

//   const handleWarrantyStartDate = (event) => {
//     setWarrantyStartDate(event.target.value);
//     warrantyValidDateRange(event.target.value, warrantyEndDate);
//   };

//   const handleWarrantyEndDate = (event) => {
//     setWarrantyEndDate(event.target.value);
//     warrantyValidDateRange(warrantyStartDate, event.target.value);
//   };

//   const handleReportName = (event, index) => {
//     const updatedData = [...reportData];
//     const selectedReportName = event.target.value;
//     updatedData[index].report_name = selectedReportName;
//     setReportData(updatedData);
//     setDataToSend(updatedData);

//     let assetRep = {
//       hospid: userIds.hospid,
//       userid: userIds.userid,
//       imageid: updatedData[index].imageid,
//       image: updatedData[index].image,
//     };

//     dispatch(AddAssetReport(assetRep));
//   };

//   const handleNameChange = (event, index) => {
//     // const updatedData = [...data];
//     // updatedData[index].name = event.target.value;
//     // setData(updatedData);
//   };

//   const handleUpload = () => {
//     const filteredData = dataToSend.filter((item) => item.image !== "");
//     handleAssetReport(filteredData);
//     handleCloseReport();
//   };

//   return (
//     <OuterBox>
//       <TopContainers>
//         <TitleHead>Edit Asset</TitleHead>
//       </TopContainers>
//       <Main>
//         <FormWrapper>
//           <FormElement>
//             <FormTextLabel>Assets Name</FormTextLabel>
//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               placeholder="Asset Name"
//               value={assetName}
//               onChange={handleAssetName}
//             />
//             {<HelperText>{isAssetError}</HelperText>}
//           </FormElement>
//           <FormElement>
//             <FormTextLabel>Serial Number</FormTextLabel>
//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               placeholder="Serial Number"
//               value={serialNumber}
//               onChange={handleSerialNumber}
//             />
//             {<HelperText>{isSerialNumberError}</HelperText>}
//           </FormElement>
//           <FormElement>
//             <FormTextLabel>Department</FormTextLabel>

//             <FormControl>
//               {/* <FormSelect
//                 sx={{ bgcolor: "#EFF5FE" }}
//                 value={department}
//                 onChange={handleDepartment}
//                 displayEmpty
//                 inputProps={{ "aria-label": "Error text" }}>
//                 {departmentData?.map((item) => (
//                   <MenuItem
//                     onClick={() => handleDepartmentId(item)}
//                     value={item.departname}>
//                     {item.departname}
//                   </MenuItem>
//                 ))}
//               </FormSelect> */}

//               <SpecialDropDowne>

// <Autocomplete
//   sx={{ bgcolor: "#EFF5FE" }}
//   options={departmentData?.map((option) => ({
//     name: option.departname,
//     id: option?.departid,
//   }))}
//   value={{ name: department, id:departmentId }}
//   getOptionLabel={(option) => option.name} // Specify how to get the label for each option
//   onChange={handleDepartment}
//   renderInput={(params) => (
//     <TextField
//       placeholder="e.g. Emergency Department"
//       {...params}
//     />
//   )}
// />

//                   </SpecialDropDowne>

//             </FormControl>
//             {<HelperText>{isDepartmentError}</HelperText>}
//           </FormElement>
//           <FormElement>
//             <FormTextLabel>Model Number</FormTextLabel>
//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               placeholder="e.g. 7356230040"
//               value={modelNumber}
//               onChange={handleModelNumber}
//             />
//             {<HelperText>{isModelNumberError}</HelperText>}
//           </FormElement>

//           <FormElement>
//             <FormTextLabel>Assets code</FormTextLabel>
//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               value={assetCode}
//               placeholder="Asset code"
//               onChange={handleAssetCode}
//             />
//             <HelperText>{isAssetCodeError}</HelperText>
//           </FormElement>

//           <FormElement>
//             <FormTextLabel>Brand</FormTextLabel>
//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               placeholder="Brand"
//               value={brand}
//               onChange={handleBrand}
//             />
//             {<HelperText>{isBrandError}</HelperText>}
//           </FormElement>
//           <FormElement>
//             <FormTextLabel>Seller Name</FormTextLabel>

//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               placeholder="Seller Name"
//               value={soldBy}
//               onChange={handleSoldBy}
//             />
//             {<HelperText>{isSoldByError}</HelperText>}
//           </FormElement>

//           <FormElement>
//             <FormTextLabel>Date of Purchase</FormTextLabel>
//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               type="date"
//               value={dateOfPurchase}
//               onChange={handlePurchaseDate}
//             />
//             {<HelperText>{isDateOfPurchaseError}</HelperText>}
//           </FormElement>

//           <FormElement>
//             <FormTextLabel>Useful Life</FormTextLabel>
//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               type="text"
//               placeholder="Useful Life"
//               value={usefullLife}
//               onChange={handleUsefulLife}
//             />
//             {<HelperText>{isUsefullLifeError}</HelperText>}
//           </FormElement>

//           <FormElement>
//             <FormTextLabel>Price</FormTextLabel>

//             <FormInput
//               sx={{ bgcolor: "#EFF5FE" }}
//               placeholder="Price"
//               value={price}
//               onChange={handlePrice}
//             />
//             {<HelperText>{isPriceError}</HelperText>}
//           </FormElement>

//           <FormElement>
//             <CheckBoxTextLabel>Warranty</CheckBoxTextLabel>
//             <YesDateBox>
//               <DateBox padding="5px" bgcolor="#EFF5FE">
//                 <CustomText fontWeight="600">Warranty start date</CustomText>
//                 <HalfInput
//                   sx={{ bgcolor: "white" }}
//                   type="date"
//                   value={warrantyStartDate}
//                   onChange={handleWarrantyStartDate}
//                 />
//               </DateBox>

//               <DateBox padding="5px" bgcolor="#EFF5FE">
//                 <CustomText fontWeight="600">Warranty end date</CustomText>
//                 <HalfInput
//                   sx={{ bgcolor: "white" }}
//                   value={warrantyEndDate}
//                   type="date"
//                   onChange={handleWarrantyEndDate}
//                 />
//               </DateBox>
//             </YesDateBox>
//             {<HelperText>{isWarrantyError}</HelperText>}
//           </FormElement>

//           <FormElement>
//             <CheckBoxTextLabel>Asset Type</CheckBoxTextLabel>
//             <CheckBoxContainer>
//               <FormRadio>
//                 <RadioGroup
//                   row
//                   aria-labelledby="demo-row-radio-buttons-group-label"
//                   name="row-radio-buttons-group"
//                   value={assetType}
//                   onChange={handleAssetType}>
//                   <FormControlLabel
//                     value="New"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>New</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="Old"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Old</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="Refurbished"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Refurbished</CustomLabel>}
//                   />
//                 </RadioGroup>
//                 {<HelperText>{isAssetTypeError}</HelperText>}
//               </FormRadio>
//             </CheckBoxContainer>
//           </FormElement>

//           <FormElement>
//             <CheckBoxTextLabel>Assets Status</CheckBoxTextLabel>
//             <CheckBoxContainer>
//               <FormRadio>
//                 <RadioGroup
//                   row
//                   aria-labelledby="demo-row-radio-buttons-group-label"
//                   name="row-radio-buttons-group"
//                   value={assetStatus}
//                   onChange={handleAssetStatus}>
//                   <FormControlLabel
//                     value="Working"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Working</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="Not working"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Not working</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="Need Service"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Need Service</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="Discard"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Discard</CustomLabel>}
//                   />
//                 </RadioGroup>
//                 {<HelperText>{isAssetStatusError}</HelperText>}
//               </FormRadio>
//             </CheckBoxContainer>
//           </FormElement>

//           <FormElement>
//             <CheckBoxTextLabel>Calibration Status</CheckBoxTextLabel>
//             <CheckBoxContainer>
//               <FormRadio>
//                 <RadioGroup
//                   value={calibrationStatus}
//                   onChange={handleCalibrationStatus}
//                   row
//                   aria-labelledby="demo-row-radio-buttons-group-label"
//                   name="row-radio-buttons-group">
//                   <FormControlLabel
//                     value="Yes"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Calibrated</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="No"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>Not calibrated</CustomLabel>}
//                   />
//                 </RadioGroup>
//                 {<HelperText>{isCalibrationStatusError}</HelperText>}
//               </FormRadio>
//               {calibrationStatus === "Yes" && (
//                 <YesDateBox>
//                   <DateBox padding="5px" bgcolor="#EFF5FE">
//                     <CustomText fontWeight="600">
//                       Calibration Start Date
//                     </CustomText>
//                     <HalfInput
//                       value={calibrationStartDate}
//                       onChange={handleCalibrationStartDate}
//                       sx={{ bgcolor: "white" }}
//                       type="date"
//                     />
//                     {<HelperText>{isCalibrationDateError}</HelperText>}
//                   </DateBox>
//                   <DueDateBox>
//                     <DateBox padding="5px" bgcolor="#EFF5FE">
//                       <CustomText fontWeight="600">
//                         Calibration End Date
//                       </CustomText>
//                       <HalfInput
//                         placeholder="No: of months"
//                         value={calibrationEndDate}
//                         type="date"
//                         sx={{ bgcolor: "white" }}
//                         onChange={handleCalibrationEndDate}
//                       />
//                       {/* {<HelperText>{isCalibrationDateError}</HelperText>} */}
//                     </DateBox>
//                   </DueDateBox>
//                 </YesDateBox>
//               )}
//               {calibrationStatus === "No" && (
//                 <DateBox
//                   bgcolor="#EFF5FE"
//                   padding="5px"
//                   maxHeight="88px"
//                   maxWidth="258px">
//                   <CustomText fontWeight="600" textAlign={"left"}>
//                     Required ?
//                   </CustomText>
//                   <FormRadio>
//                     <Box bgcolor="white" padding="3px" borderRadius="6px">
//                       <RadioGroup
//                         onChange={handleRequired}
//                         value={required}
//                         row
//                         aria-labelledby="demo-row-radio-buttons-group-label"
//                         name="row-radio-buttons-group">
//                         <FormControlLabel
//                           value="Required"
//                           control={<Radio size="small" />}
//                           label={<CustomText>Required</CustomText>}
//                         />
//                         <FormControlLabel
//                           value="Not Required"
//                           control={<Radio size="small" />}
//                           label={<CustomText>Not Required</CustomText>}
//                         />
//                       </RadioGroup>
//                       {<HelperText>{isRequiredError}</HelperText>}
//                     </Box>
//                   </FormRadio>
//                 </DateBox>
//               )}
//             </CheckBoxContainer>
//           </FormElement>

//           <FormElement>
//             <CheckBoxTextLabel>CMC/AMC</CheckBoxTextLabel>
//             <CheckBoxContainer>
//               <FormRadio onChange={handleAmcCmc}>
//                 <RadioGroup
//                   row
//                   aria-labelledby="demo-row-radio-buttons-group-label"
//                   value={cmcAmc}
//                   name="row-radio-buttons-group">
//                   <FormControlLabel
//                     value="CMC"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>CMC</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="AMC"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>AMC</CustomLabel>}
//                   />
//                   <FormControlLabel
//                     value="None"
//                     control={<Radio size="small" />}
//                     label={<CustomLabel>None</CustomLabel>}
//                   />
//                 </RadioGroup>
//                 {<HelperText>{isCmcAmcError}</HelperText>}
//               </FormRadio>
//               {cmcAmc === "AMC" && (
//                 <YesDateBox>
//                   <DateBox padding="5px" bgcolor="#EFF5FE">
//                     <CustomText fontWeight="600">Start Date</CustomText>
//                     <HalfInput
//                       sx={{ bgcolor: "white" }}
//                       type="date"
//                       value={cmcAmcStartDate}
//                       onChange={handleAmcCmcStartDateChange}
//                     />
//                   </DateBox>
//                   <DateBox padding="5px" bgcolor="#EFF5FE">
//                     <CustomText fontWeight="600">End Date</CustomText>
//                     <HalfInput
//                       sx={{ bgcolor: "white" }}
//                       type="date"
//                       value={cmcAmcEndDate}
//                       onChange={handleAmcCmcEndDateChange}
//                     />
//                   </DateBox>
//                 </YesDateBox>
//               )}

//               {cmcAmc === "CMC" && (
//                 <YesDateBox>
//                   <DateBox padding="5px" bgcolor="#EFF5FE">
//                     <CustomText fontWeight="600">Start Date</CustomText>

//                     <HalfInput
//                       sx={{ bgcolor: "white" }}
//                       type="date"
//                       value={cmcAmcStartDate}
//                       onChange={handleAmcCmcStartDateChange}
//                     />
//                   </DateBox>
//                   <DateBox padding="5px" bgcolor="#EFF5FE">
//                     <CustomText fontWeight="600">End Date</CustomText>
//                     <HalfInput
//                       sx={{ bgcolor: "white" }}
//                       type="date"
//                       value={cmcAmcEndDate}
//                       onChange={handleAmcCmcEndDateChange}
//                     />
//                   </DateBox>
//                 </YesDateBox>
//               )}
//               {<HelperText>{isAmcEndDateError}</HelperText>}
//               {<HelperText>{isCmcEndDateError}</HelperText>}
//             </CheckBoxContainer>
//           </FormElement>
//         </FormWrapper>
//         <BottomElement>
//           <FormElement>
//             <NotesTextLabel>Notes</NotesTextLabel>
//             <NotesInput
//               placeholder="Notes"
//               value={notes}
//               onChange={handleNotes}
//             />
//             {/* { <HelperText>Error text</HelperText>} */}
//           </FormElement>
//         </BottomElement>
//       </Main>
//       <UploadContainer>
//         <>
//           <ButtonWrapper>
//             <>
//               <UploadButton
//                 onClick={() => setPhotoFlag(!photoFlag)}
//                 sx={{
//                   "&:hover": {
//                     transform: "scale3d(1.05, 1.05, 2)",
//                     cursor: "pointer",
//                     color: "#FFF7E9",
//                     bgcolor: "#F78117",
//                   },
//                   bgcolor: "#ADC2EC",
//                 }}>
//                 <AddPhotoAlternateIcon sx={{ color: "#1746A2" }} />
//                 Edit Photos
//               </UploadButton>
//             </>

//             <UploadButton
//               onClick={() => setCertificateFlag(!certificateFlag)}
//               sx={{
//                 "&:hover": {
//                   transform: "scale3d(1.05, 1.05, 2)",
//                   cursor: "pointer",
//                   color: "#FFF7E9",
//                   bgcolor: "#F78117",
//                 },
//                 bgcolor: "#ADC2EC",
//               }}>
//               <VideoLibraryIcon sx={{ color: "#1746A2" }} />
//               Warranty Certificate
//             </UploadButton>
//           </ButtonWrapper>

//           <FlexBox>
//             <ImageOuterBox
//               sx={{
//                 textAlign: "start",
//                 color: "#1746A2",
//               }}>
//               {/* {(photoFlag || fileUrl?.length > 0) && (
//                 <> */}
//               <PhotoText>{/* Edit photos */}</PhotoText>
//               <UploadImageBox>
//                 {fileUrl?.length < 3 && (
//                   <>
//                     <DotedBox>
//                       <label for="img">
//                         {" "}
//                         <AddIcon sx={{ marginTop: "2%", cursor: "pointer" }} />
//                       </label>
//                     </DotedBox>
//                     <input
//                       type="file"
//                       name="uploadfile"
//                       id="img"
//                       style={{
//                         display: "none",

//                         width: "42px",
//                         height: "42px",
//                       }}
//                       onChange={handlePhotoUpload}
//                     />
//                   </>
//                 )}
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}>
//                   {fileUrl !== "NA" &&
//                     fileUrl?.map((image, index) => (
//                       <ImageBox key={index} className="image-item">
//                         <Badge
//                           sx={{ color: "rgba(255, 115, 29, 0.5)" }}
//                           badgeContent={
//                             <RemoveCircleIcon
//                               sx={{
//                                 width: "10px",
//                                 height: "10px",
//                                 color: "red",
//                               }}
//                               onClick={() => removePhoto(image.imageid)}
//                             />
//                           }>
//                           {image?.image !== undefined ? (
//                             <Photo
//                               as="img"
//                               src={image?.image}
//                               alt={image?.image}
//                             />
//                           ) : (
//                             <Photo
//                               as="img"
//                               src={image?.imgurl}
//                               alt={image?.imgurl}
//                             />
//                           )}
//                           {/* {image?.imageid!==undefined &&  <Photo as="img"
//                               src={image?.imageid}
//                               alt={image?.imageid}

//                             />} */}
//                         </Badge>
//                       </ImageBox>
//                     ))}
//                 </Box>
//               </UploadImageBox>
//               {/* </>
//               )} */}

//               <Box style={{ color: "red", fontSize: "12px" }}>
//                 <>
//                   {(photoFlag || fileUrl?.length > 0) && (
//                     <ImageWarningText>
//                       Format should be either in jpg, jpeg or png.
//                     </ImageWarningText>
//                   )}
//                   {<HelperText>{isPhotoError}</HelperText>}
//                 </>
//               </Box>
//             </ImageOuterBox>

//             <ImageRightOuterBox
//               sx={{
//                 textAlign: "start",
//                 color: "#1746A2",
//               }}>
//               {(certificateFlag || certificateUrl?.length > 0) && (
//                 <>
//                   {/* Edit Certificate */}
//                   <UploadImageBox>
//                     {certificateUrl?.length <= 3 && (
//                       <>
//                         <DotedBox
//                         // type="file"
//                         >
//                           <label for="img-2">
//                             {" "}
//                             <AddIcon
//                               sx={{ marginTop: "2%", cursor: "pointer" }}
//                             />
//                           </label>
//                           {/* <AddIcon
//                           style={"isDragging" ? { color: "red" } : null}

//                         //   {..."dragProps"}
//                         /> */}
//                         </DotedBox>

//                         <input
//                           type="file"
//                           name="uploadfile"
//                           id="img-2"
//                           style={{
//                             display: "none",

//                             width: "42px",
//                             height: "42px",
//                           }}
//                           onChange={handleCertificateUpload}
//                         />
//                       </>
//                     )}
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                       }}>
//                       {certificateUrl !== "NA" &&
//                         certificateUrl?.map((image, index) => (
//                           <ImageBox key={index} className="image-item">
//                             <Badge
//                               sx={{ color: "rgba(255, 115, 29, 0.5)" }}
//                               badgeContent={
//                                 <RemoveCircleIcon
//                                   sx={{
//                                     width: "10px",
//                                     height: "10px",
//                                     color: "red",
//                                   }}
//                                   onClick={() =>
//                                     removeCertificate(image.imageid)
//                                   }
//                                 />
//                               }>
//                               {image?.image !== undefined ? (
//                                 <Photo
//                                   as="img"
//                                   src={
//                                     image?.fileType === "pdf" ||
//                                     image?.imageid?.split(".")[1] === "pdf"
//                                       ? pdfImage
//                                       : image?.image
//                                   }
//                                   alt={image?.image}
//                                 />
//                               ) : (
//                                 <Photo
//                                   as="img"
//                                   src={
//                                     image?.fileType === "pdf" ||
//                                     image?.imageid?.split(".")[1] === "pdf"
//                                       ? pdfImage
//                                       : image?.image
//                                   }
//                                   alt={image?.imgurl}
//                                 />
//                               )}
//                             </Badge>
//                           </ImageBox>
//                         ))}
//                     </Box>
//                   </UploadImageBox>
//                 </>
//               )}
//               <Box style={{ color: "red", fontSize: "12px" }}>
//                 <>
//                   {(certificateFlag || certificateUrl?.length > 0) && (
//                     <ImageWarningText color="#1746A2">
//                       Format should be either in jpg, jpeg, png or pdf.
//                     </ImageWarningText>
//                   )}
//                   {<HelperText>{isCertificateError}</HelperText>}
//                 </>
//               </Box>
//             </ImageRightOuterBox>
//           </FlexBox>

//           <Box>
//             <AssetReportBox>
//               {reportData?.map((row, index) => (
//                 <div
//                   style={{ display: "flex", padding: "10px", gap: "10px" }}
//                   key={index}>
//                   {row.image ? (
//                     <ImageSet
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                         bgcolor: "#EFF5FE",
//                       }}>
//                       <Box
//                         width={"50px"}
//                         height={"50px"}
//                         as="img"
//                         src={`${
//                           row.imageid?.split(".")?.pop() === "pdf"
//                             ? pdfImage
//                             : row.image
//                         }`}
//                         alt="Selected"
//                       />

//                       <Button
//                         onClick={() => handleClearImage(index)}
//                         sx={{
//                           borderRadius: "0 10px 10px 0",
//                           height: "45px",
//                           bgcolor: "#839ED0",
//                           "&:hover": {
//                             bgcolor: "#1746A2",
//                           },
//                         }}>
//                         <IconButton>
//                           <CancelIcon sx={{ color: "white" }} />
//                         </IconButton>
//                       </Button>
//                     </ImageSet>
//                   ) : (
//                     <>
//                       <input
//                         type="file"
//                         accept=".pdf,.jpg,.jpeg,.png"
//                         onChange={(event) => handleImageUpload(event, index)}
//                         style={{ display: "none" }}
//                         id="file-upload"
//                       />

//                       <label htmlFor="file-upload">
//                         <ButtonGroup
//                           component="span"
//                           sx={{
//                             textTransform: "none",
//                             height: "45px",
//                             borderRadius: "10px",
//                           }}
//                           aria-label="split button">
//                           <Button
//                             component="span"
//                             sx={{
//                               bglocor: "#EFF5FE",
//                               color: "black",
//                               textTransform: "none",
//                               borderRadius: "10px 0 0 10px",
//                               height: "45px",
//                             }}>
//                             {" "}
//                             Upload IMG/PDF{" "}
//                           </Button>
//                           <Button
//                             component="span"
//                             sx={{
//                               bgcolor: "#839ED0",
//                               height: "45px",
//                               "&:hover": {
//                                 bgcolor: "#1746A2",
//                               },
//                             }}
//                             aria-label="select merge strategy"
//                             aria-haspopup="menu">
//                             <FileUploadIcon sx={{ color: "white" }} />
//                           </Button>
//                         </ButtonGroup>
//                       </label>
//                     </>
//                   )}
//                   <Select
//                     sx={{ bgcolor: "#EFF5FE", height: "45px" }}
//                     value={row.report_name}
//                     onChange={(event) => handleReportName(event, index)}>
//                     <MenuItem value="Service Report">Service Report</MenuItem>
//                     <MenuItem value="Installation Report">
//                       Installation Report
//                     </MenuItem>
//                     <MenuItem value="Warranty Document">
//                       Warranty Document
//                     </MenuItem>
//                     <MenuItem value="Calibration Document">
//                       Calibration Document
//                     </MenuItem>
//                     <MenuItem value="Service Invoice">Service Invoice</MenuItem>
//                     <MenuItem value="Others">Others</MenuItem>
//                   </Select>

//                   <IconButton onClick={() => handleRemoveRow(index)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </div>
//               ))}

//               <Typography>{fileError}</Typography>
//               <Box
//                 sx={{
//                   display: "flex",
//                   minWidth: "240px",
//                   paddingTop: "10px",
//                   justifyContent: "space-between",
//                 }}>
//                 <UploadButton
//                   onClick={handleAddRow}
//                   sx={{
//                     bgcolor: "#ADC2EC",
//                   }}>
//                   <DataThresholdingIcon sx={{ color: "#1746A2" }} />
//                   Upload Report
//                 </UploadButton>
//               </Box>
//             </AssetReportBox>
//           </Box>
//           <ButtonBox>
//             <SubmitButton
//               variant="outlined"
//               size="large"
//               sx={{
//                 border: "2px solid #1746A2",
//                 color: "#1746A2",
//                 bgcolor: "white",
//               }}
//               onClick={() => navigate(-1)}>
//               Cancel
//             </SubmitButton>

//             <SubmitButton
//               variant="contained"
//               disabled={userAccess?.editprod !== "Yes"}
//               size="large"
//               sx={{
//                 bgcolor: "#1746A2",

//                 cursor: "pointer",
//                 color: "white",
//               }}
//               onClick={handleSubmit}>
//               Update
//             </SubmitButton>
//           </ButtonBox>
//         </>
//       </UploadContainer>
//       <EditAssetModal
//         status={status}
//         handleCloseModal={handleCloseModal}
//         toggle={toggle}
//       />
//       <ImageDeleteModal handleClose={handleClose} open={open} />
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={4000}
//         onClose={() => setSnackbarOpen(false)}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "center",
//         }}>
//         <Alert
//           onClose={() => setSnackbarOpen(false)}
//           severity="error"
//           sx={{ width: "100%" }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </OuterBox>
//   );
// }

// export default EditAssetPage;
import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  IconButton,
  Select,
  Typography,
  ButtonGroup,
  Snackbar,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";

import moment from "moment";

import { v4 as uuid } from "uuid";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import pdfImage from "../images/pdfImage.png";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AddIcon from "@mui/icons-material/Add";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import { useDispatch } from "react-redux";
import {
  AddAssetReport,
  assetDeleteReport,
  deleteCertificate,
  deleteImage,
  editAssetFun,
  getAsset,
  getDepartmentData,
  uploadImage,
  uploadedCertificate,
} from "../redux/data/action";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import EditAssetModal from "../components/EditAssetModal";

import ImageDeleteModal from "../components/ImageDeleteModal";
import MuiAlert from "@mui/material/Alert";

import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
const FormInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",
  width: "370px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    height: "56px",
  },
}));
const FormInput2 = styled(DatePicker)(({ theme }) => ({
  border: "none",
  width: "370px",

  [theme.breakpoints.down("xl")]: {
    width: "360px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));

const FormSelect = styled(Select)(({ theme }) => ({
  textAlign: "left",
  border: "none",
  width: "370px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    height: "56px",
  },
}));
const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",
  height: "174px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "174px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "174px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  justifyItems: "center",
  justifyContent: "space-evenly",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("xs")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
}));

const Main = styled(Box)(({ theme }) => ({
  // border: "2px solid blue",
  display: "flex",
  flexDirection: "column",
  width: "900px",
  paddingBottom: "40px",
  borderRadius: "30px",
  background: "#FFF",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",
  padding: "20px",
  [theme.breakpoints.down("xl")]: {
    padding: "20px",
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "20px",
    width: "750px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CheckBoxTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  fontSize: "22px",
  fontWeight: "400",
  marginTop: "30px",
  color: "#FF731D",

  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
    marginTop: "10px",
    fontWeight: "400",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const NotesTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  fontSize: "22px",
  marginTop: "30px",
  color: "#FF731D",

  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
    marginTop: "20px",
    fontWeight: "400px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: "400px",
  },
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
// const BottomElement = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "start",
//   alignItems: "start",
//   paddingLeft: "20px",
//   [theme.breakpoints.down("xl")]: {},
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     paddingLeft: "0px",
//   },
//   [theme.breakpoints.down("sm")]: {},
// }));
const BottomElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  padding: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    padding: "0px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const FormHalfElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gap: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    gap: "10px",
  },
}));

const HalfInputDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const HalfInput = styled(OutlinedInput)(({ theme }) => ({
  bgcolor: "white",
  width: "165px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {
    width: "165px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "160px",
  },
  [theme.breakpoints.down("md")]: {
    width: "160px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "160px",
  },
}));
const HalfInput2 = styled(DatePicker)(({ theme }) => ({
  bgcolor: "white",
  width: "165px",
  // height: "40px",
  // border: "none",

  [theme.breakpoints.down("xl")]: {
    width: "165px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "160px",
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
}));
const CheckBoxContainer = styled(Box)(({ theme }) => ({
  // border: '4px solid blue',
  width: "370px",
  minHeight: "50px",

  [theme.breakpoints.down("xl")]: {
    width: "360px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));

const OuterBox = styled(Box)(({ theme }) => ({
  // border: '1px solid green',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "15px",
  textTransform: "none",
  bgcolor: "#ADC2EC",
  color: "#1746A2",
  "&:hover": {
    backgroundColor: "#ADC2EC",
    color: "#1746A2",
  },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormRadio = styled(FormControl)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  bgcolor: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const DateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const DueDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const YesDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CustomText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  textAlign: "left",
  fontWeight: "400px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const InnerTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  display: "flex",
  flexDirection: "column",
  width: "900px",
  paddingBottom: "40px",

  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "750px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
}));

const UploadImageBox = styled(Box)(({ theme }) => ({
  // border: "4px solid blue",
  border: "1px solid rgba(23, 70, 162, 0.2)",
  boxShadow: "0px 0px 2px #EAF1FF",
  margin: "2.5px",
  width: "232px",
  padding: "10px",
  display: "flex",
  alignItems: "center",

  borderRadius: "8px",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "160px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "160px",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: "8px",
  width: "40px",
  height: "40px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    marginLeft: "4px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const DotedBox = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  cursor: "pointer",

  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ImageOuterBox = styled(Box)(({ theme }) => ({
  width: "282px",
  minHeight: "1px",

  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "186px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "186px",
  },
}));

const ImageRightOuterBox = styled(Box)(({ theme }) => ({
  width: "282px",

  minHeight: "1px",
  display: "flex",
  justifyContent: "right",
  alignItems: "end",
  flexDirection: "column",
  bgcolor: "#efecea",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "186px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "186px",
  },
}));

const ImageWarningText = styled(FormHelperText)(({ theme }) => ({
  width: "232px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "166px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const FlexBox = styled(Box)(({ theme }) => ({
  // border: "3px solid black",

  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const AssetReportBox = styled(Box)(({ theme }) => ({
  // border: "3px solid black",
  // width: "300px",
  padding: "10px",
  // display: "flex",
  // justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const ImageSet = styled(Box)(({ theme }) => ({
  width: "140px",
  // border:'2px solid red',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Photo = styled(Box)(({ theme }) => ({
  border: "1px dotted black",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const PhotoText = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CustomLabel = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  bgcolor: "#1746A2",
  textTransform: "none",
  fontSize: "16px",

  borderRadius: "40px",

  width: "130px",
  height: "46px",
  // "&:hover": {
  //   transform: "scale3d(1.05, 1.05, 2)",
  //   cursor: "pointer",
  //   color: "#FFF7E9",
  //   bgcolor: "#F78117",
  // },

  [theme.breakpoints.down("xl")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("md")]: {
    width: "130px",
    height: "46px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "130px",
    height: "46px",
  },
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainers = styled(Box)(({ theme }) => ({
  // border:"1px solid green",
  width: "900px",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "750px",
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "390px",
    border: "none",
  },
  [theme.breakpoints.down("sm")]: {
    width: "390px",
    border: "none",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",

  // flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  gap: "40px",
  marginTop: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
    gap: "20px",
    flexDirection: "column-reverse",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SpecialDropDowne = styled(Stack)(({ theme }) => ({
  border: "none",
  width: "370px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    height: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    height: "56px",
  },
}));

function EditAssetPage() {
  const params = useParams();
  const navigate = useNavigate();
  const editData = useSelector((store) => store.data.getAsset);

  const status = useSelector((store) => store.data.editAsset);
  const [open, setOpen] = useState(false);
  const [assetReport, setAssetReport] = useState([]);
  const [fileError, setFileError] = useState("");
  const handleAssetReport = (data) => {
    setAssetReport(data);
  };
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const departmentData = useSelector((store) => store.data.department) || [];
  const [department, setDepartment] = useState("");
  const [preDepartment, setPreDepartment] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  const [certificateFlag, setCertificateFlag] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState([]);
  const [name, setName] = useState([]);
  const [warranty, setWarranty] = useState("");

  const [phone, setPhone] = useState("");
  const [isPhoneError, setIsPhoneError] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(null);
  const [assetName, setAssetName] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [brand, setBrand] = useState("");
  const [usefullLife, setUsefullLife] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [soldBy, setSoldBy] = useState("");
  const [price, setPrice] = useState("");
  const [assetType, setAssetType] = useState("");
  const [assetStatus, setAssetStatus] = useState("");
  const [calibrationStatus, setCalibrationStatus] = useState("");
  const [cmcAmc, setCmcAmc] = useState("");
  const [cmcAmcStartDate, setCmcAmcStartDate] = useState("");
  const [cmcAmcEndDate, setCmcAmcEndDate] = useState("");
  const [calibrationDate, setCalibrationDate] = useState("");

  const [calibrationStartDate, setCalibrationStartDate] = useState("");
  const [calibrationEndDate, setCalibrationEndDate] = useState("");

  const [required, setRequired] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [departmentPreId, setDepartmentPreId] = useState("");
  const [notes, setNotes] = useState("");
  const [isAssetError, setIsAssetError] = useState(null);
  const [isSerialNumberError, setIsSerialNumberError] = useState(null);
  const [isModelNumberError, setIsModelNumberError] = useState(null);
  const [isBrandError, setIsBrandError] = useState(null);
  const [isSoldByError, setIsSoldByError] = useState(null);
  const [isWarrantyError, setIsWarrantyError] = useState(null);
  const [isPriceError, setIsPriceError] = useState(null);
  const [isDueDateError, setIsDueDateError] = useState(null);
  const [isAmcEndDateError, setIsAmcEndDateError] = useState(null);
  const [isCmcEndDateError, setIsCmcEndDateError] = useState(null);
  const [isAssetTypeError, setIsAssetTypeError] = useState(null);
  const [isAssetStatusError, setIsAssetStatusError] = useState(null);
  const [isCalibrationStatusError, setIsCalibrationStatusError] =
    useState(null);

  const [warrantyStartDate, setWarrantyStartDate] = useState("");
  const [warrantyEndDate, setWarrantyEndDate] = useState("");

  const [isAssetCodeError, setIsAssetCodeError] = useState(null);
  const [isUsefullLifeError, setIsUsefullLifeError] = useState(null);
  const [isCmcAmcError, setIsCmcAmcError] = useState(null);
  const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);
  const [isRequiredError, setIsRequiredError] = useState(null);
  const [isDepartmentError, setIsDepartmentError] = useState(null);
  const [isDateOfPurchaseError, setIsDateOfPurchaseError] = useState(null);
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [isCertificateError, setIsCertificateError] = useState(null);
  const [locale, setLocale] = React.useState("en-gb");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submittedCalibrationStartDate, setSubmittedCalibrationStartDate] =
    useState("");
  const [submittedCalibrationEndDate, setSubmittedCalibrationEndDate] =
    useState("");

  const [submittedWarrantyStartDate, setSubmittedWarrantyStartDate] =
    useState("");
  const [submittedWarrantyEndDate, setSubmittedWarrantyEndDate] = useState("");

  const [submittedCmcAmcStartDate, setSubmittedCmcAmcStartDate] = useState("");
  const [submittedCmcAmcEndDate, setSubmittedCmcAmcEndDate] = useState("");
  const [submittedDateOfPurchase, setSubmittedDateOfPurchase] = useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [assetCode, setAssetCode] = useState();
  let idSplit = params.id.substring(0, 4);
  const initialRow = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    imageid: "",
    image: "",
    report_name: "Service Report",
  };

 

  const [openReport, setOpenReport] = useState(false);

  const [reportData, setReportData] = useState([initialRow]);
  const [dataToSend, setDataToSend] = useState([initialRow]);
  const [newRow, setNewRow] = useState({ ...initialRow });

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);

        // return false;
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${idSplit}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(uploadImage(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(uploadImage(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };
  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    dispatch(deleteImage(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCertificateUpload = (e) => {
    const singlefile = e.target.files[0];

    const splitId = params.id.substring(0, 4);
    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 2 * 1024 * 1024; // 2 MB
      if (allowedTypes.indexOf(file.type) === -1) {
        setIsCertificateError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        setIsCertificateError("Greater than 2 mb can't be uploaded");
        return false;
      } else {
        setIsCertificateError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          //   setCertificateUrl([...fileUrl,reader.result]);

          let sendData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: `${splitId}_` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          let checkData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: sendData.imageid,
            image: reader.result,
            fileType: fileType,
            branchid: userBranch?.id,
          };

          setCertificateUrl([...certificateUrl, checkData]);
          dispatch(uploadedCertificate(sendData));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const removeCertificate = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };
    dispatch(deleteCertificate(data));
    setCertificateUrl(certificateUrl.filter((item) => item.imageid !== id));
    setOpen(true);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z ]+$/;
    if (value === "" || regex.test(value)) {
      setName(value);
    }
  };

  const handleAssetName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    setAssetName(value);
    if (value === "" || regex.test(value)) {
      setAssetName(value);
      setIsAssetError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetError("wrong asset Name");
    }
    if (value === "") {
      setIsAssetError("Please fill the field");
    }
  };

  const handleSerialNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setSerialNumber(value);
    if (regex.test(value) || value === "") {
      setSerialNumber(value);
      setIsSerialNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsSerialNumberError("wrong serial number");
    }
  };

  const handleModelNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setModelNumber(value);
    if (regex.test(value) || value === "") {
      setModelNumber(value);
      setIsModelNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsModelNumberError("wrong model number");
    }
  };

  const handleBrand = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setBrand(value);
    if (value === "" || regex.test(value)) {
      setBrand(value);
      setIsBrandError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsBrandError("wrong brand Name");
    }
  };



  const handleSoldBy = (e) => {
    const value = e.target.value
    const regex = /^[A-Za-z\s]+$/;
    setSoldBy(value);
    if (value === "" || regex.test(value)) {
      setSoldBy(value);
      setIsSoldByError(null);

      if(email==="NA"||email===""){
        setIsEmailError("Enter valid email");
      }

      if(phone==="NA"||phone===""){
        setIsPhoneError("Enter valid Phone Number");
      }

      if (phone === "") {
        setIsPhoneError("Please fill the field");
      }
      if (email === "") {
        setIsEmailError("Please fill the field");
      }
    }
    if (value !== "" && !regex.test(value)) {
      setIsSoldByError("Enter valid details");
    }
    if (value === "") {
      setIsPhoneError(null);
      setIsEmailError(null);
      setEmail("");
      setPhone("");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setIsEmailError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsEmailError("Enter valid email");
    }
    if (value === "") {
      setIsEmailError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    console.log("e.target.value",e.target.value)
    const value = e.target.value.trim();
    const regex = /^\d{10}$/;
    setPhone(value);
    if (value === "" || regex.test(value)) {
      setPhone(value);
      setIsPhoneError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPhoneError("Enter valid Phone Number");
    }
    if (value === "") {
      setIsPhoneError("Please fill the field");
    }
  };




  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  const handleUsefulLife = (e) => {
    const value = e.target.value.trim();
    const regex = /^[1-9]\d*$/;
    setUsefullLife(value.trim());
    if (value.trim() === "" || regex.test(value.trim())) {
      setUsefullLife(value.trim());
      setIsUsefullLifeError(null);
    }
    if (value.trim() !== "" && !regex.test(value.trim())) {
      setIsUsefullLifeError("Enter valid number");
    }
    if (value.trim() === "") {
      setIsUsefullLifeError(null);
      setIsPriceError(null);
      setIsDateOfPurchaseError(null);
    }
    if (value.trim() !== "" && price === "") {
      setIsPriceError("Please fill the field");
    }
    if (value.trim() !== "" && dateOfPurchase === "") {
      setIsDateOfPurchaseError("Please fill the field");
    }
  };

  const handleDueDate = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (value === "" || regex.test(value)) {
      setDueDate(value);
      setIsDueDateError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsDueDateError("Enter valid month count");
    }
    if (value > 99) {
      setIsDueDateError("Enter valid month count");
    }
    if (calibrationDate == "") {
      setIsCalibrationDateError("Please fill the field");
    }
  };

  const handleNotes = (e) => {
    const value = e.target.value;
    setNotes(value);
  };

  const handleDepartment = (event, value) => {
    setDepartmentPreId(editData?.departid);
    if (value !== null && value !== undefined) {
      setDepartmentId(value.id);
      setDepartment(value.name);

      if (value.name !== "" && value.name !== null) {
        setIsDepartmentError(null);
      }

      if (editData?.departid === value?.id) {
        setDepartmentPreId("NA");
      }
      if (editData?.departid !== value?.id) {
        setDepartmentPreId(editData?.departid);
      }

      if (editData?.dept === value.name) {
        setPreDepartment("NA");
      }
      if (editData?.dept !== value.name) {
        setPreDepartment(editData?.dept);
      }
    }
  };

  const handleAmcCmcStartDateChange = (date) => {
    setCmcAmcStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(date, cmcAmcEndDate);
  };

  const handleAmcCmcEndDateChange = (date) => {
    setCmcAmcEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(cmcAmcStartDate, date);
  };

  const amcValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsAmcEndDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsAmcEndDateError(null);
      setIsCmcEndDateError(null);
    }
  };

  const calibrationValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsCalibrationDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsCalibrationDateError(null);
    }
  };

  const handleCalibrationStartDate = (date) => {
    setCalibrationStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    calibrationValidateDateRange(date, calibrationEndDate);
  };

  const handleCalibrationEndDate = (date) => {

    setCalibrationEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    calibrationValidateDateRange(calibrationStartDate, date);

  };

  const handleAmcCmc = (e) => {
    const value = e.target.value;
    setCmcAmc(value);
    if (value === "None") {
      setIsCmcEndDateError(null);
      setIsAmcEndDateError(null);
      setCmcAmcEndDate("");
      setCmcAmcStartDate("");
    }
    if (value === "AMC") {
      setIsCmcEndDateError("Please enter the date");
      setIsAmcEndDateError(null);
      if (cmcAmcStartDate !== "" && cmcAmcEndDate !== "") {
        setIsCmcEndDateError(null);
      }
      if (
        // cmcAmcStartDate === "Invalid date" &&
        // cmcAmcEndDate === "Invalid date"
        !dayjs(cmcAmcStartDate).isValid() ||
        !dayjs(cmcAmcEndDate).isValid()
      ) {
        setIsCmcEndDateError("Please enter the date");
      }
    }
    if (value === "CMC") {
      setIsAmcEndDateError("Please enter the date");
      setIsCmcEndDateError(null);
      if (cmcAmcStartDate !== "" && cmcAmcEndDate !== "") {
        setIsAmcEndDateError(null);
      }
      if (
        // cmcAmcStartDate === "Invalid date" &&
        // cmcAmcEndDate === "Invalid date"
        !dayjs(cmcAmcStartDate).isValid() ||
        !dayjs(cmcAmcEndDate).isValid()
      ) {
        setIsAmcEndDateError("Please enter the date");
      }
    }
    if (value !== "") {
      setIsCmcAmcError(null);
    }
  };

  const handleRequired = (e) => {
    let value = e.target.value;
    if (value !== "") {
      setRequired(value);
      setIsRequiredError(null);
    }
    setCalibrationStartDate("");
    setCalibrationEndDate("");
  };

  const handleCalibrationStatus = (e) => {
    let value = e.target.value;
    setCalibrationStatus(value);
    if (value === "No") {
      setCalibrationStartDate("");
      setCalibrationEndDate("");
      setIsRequiredError("");
      setIsDueDateError(null);
      setIsCalibrationDateError(null);
    }
    if (value === "Yes") {
      setRequired("NA");
      setIsRequiredError(null);
      setCalibrationEndDate("");
      setCalibrationStartDate("");
      setIsCalibrationDateError("Please fill the field");
    }
    if (value !== "") {
      setIsCalibrationStatusError(null);
    }
  };

  const handleAssetStatus = (e) => {
    const value = e.target.value;
    setAssetStatus(value);
    if (value !== "") {
      setIsAssetStatusError(null);
    }
  };

  const handleDepartmentId = (item) => {
    setDepartmentPreId(editData?.departid);
    setDepartmentId(item.departid);
    if (item !== "") {
      setIsDepartmentError(null);
    }

    if (editData?.departid === item?.departid) {
      setDepartmentPreId("NA");
    }
    if (editData?.departid !== item?.departid) {
      setDepartmentPreId(editData?.departid);
    }
  };

  const handleAssetType = (e) => {
    const value = e.target.value;
    setAssetType(value);
    if (value !== "") {
      setIsAssetTypeError(null);
    }
  };

  const handlePurchaseDate = (date) => {
    // const value = e.target.value;
    setDateOfPurchase(date && dayjs(date).format("YYYY-MM-DD"));
    if (date !== "") {
      setIsDateOfPurchaseError(null);
    }
  };

  const handleAssetCode = (e) => {
    const value = e.target.value;
    setAssetCode(value);
    if (value !== "") {
      setAssetCode(value);
      setIsAssetCodeError(null);
    }
    if (value === "") {
      setIsAssetCodeError("Please fill the field");
    }
  };

  const handleSubmit = () => {
    let rData = reportData?.filter((item) => {
      return item.image !== "";
    });

    assetName === "" && setIsAssetError("Please fill the field");
    // brand === "" && setIsBrandError("Please fill the field");
    // soldBy === "" && setIsSoldByError("Please fill the field");
    // warranty === "" && setIsWarrantyError("Please fill the field");
    (department === ""|| department === null) && setIsDepartmentError("Please fill the field");
    assetType === "" && setIsAssetTypeError("Please fill the field");
    assetStatus === "" && setIsAssetStatusError("Please fill the field");
    calibrationStatus === "" &&
      setIsCalibrationStatusError("Please fill the field");
    cmcAmc === "" && setIsCmcAmcError("Please fill the field");
    assetCode === "" && setIsAssetCodeError("Please fill the field");
    // dateOfPurchase === "" && setIsDateOfPurchaseError("Please fill the field");
    // usefullLife === "" && setIsUsefullLifeError("Please fill the field")
    // price === "" && setIsPriceError("Please fill the field")
    fileUrl.length <= 0 && setIsPhotoError("Please add an image");









    if (calibrationStatus === "Yes") {
      ((calibrationStartDate === "NA" || calibrationStartDate === "") &&
        setIsCalibrationDateError("Please fill the field")) ||
        ((calibrationEndDate === "NA" || calibrationEndDate === "") &&
          setIsCalibrationDateError("Please fill the field"));
    }
    if (calibrationStatus === "No") {
      (required === "NA" || required === "") &&
        setIsRequiredError("Please fill the field");
    }

    if (cmcAmc === "AMC" || cmcAmc === "CMC") {
      (cmcAmcEndDate === "" ||
        cmcAmcEndDate === "NA" ||
        cmcAmcStartDate === "" ||
        cmcAmcStartDate === "NA") &&
        setIsCmcEndDateError("Please fill this field");
    }
    if (
      assetName === "" ||
      department === "" ||
      department === null ||
      assetType === "" ||
      assetStatus === "" ||
      calibrationStatus === "" ||
      cmcAmc === "" ||
      fileUrl.length === 0 ||
      assetCode === ""
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }
    if (
      assetName !== "" &&
      department !== null &&
      assetType !== "" &&
      assetStatus !== "" &&
      calibrationStatus !== "" &&
      cmcAmc !== "" &&
      fileUrl.length !== 0 &&
      assetCode !== ""
    ) {
      if (
        isAssetError === null &&
        isPhoneError === null &&
        isEmailError === null &&
        isSerialNumberError === null &&
        isModelNumberError === null &&
        isWarrantyError === null &&
        isDateOfPurchaseError === null &&
        isPriceError === null &&
        isAmcEndDateError === null &&
        isCmcEndDateError === null &&
        isUsefullLifeError === null &&
        isAssetTypeError === null &&
        isAssetStatusError === null &&
        isCalibrationStatusError === null &&
        isCmcAmcError === null &&
        isCalibrationDateError === null &&
        isRequiredError === null &&
        isPhotoError === null &&
        isCertificateError === null &&
        isAssetCodeError === null
      ) {
        let data = {
          assetid: params.id,
          hospid: userIds.hospid,
          hospname: userIds.hospname,
          userid: userIds.userid,
          username: userIds.username,
          assetname: assetName.trim(),
          serialno: serialNumber === "" ? "NA" : serialNumber.trim(),
          assetcode: assetCode === "" ? "NA" : assetCode.trim(),
          modelno: modelNumber === "" ? "NA" : modelNumber.trim(),

          sellermobile: phone===""?'NA':phone,
          selleremail:email===""?'NA':email,
          
          prev_department: editData?.dept,
          prev_department_id: editData?.departid,
          curr_department: department.trim(),
          curr_department_id: departmentId,

          department: department.trim(),
          department_id: departmentId,
          branchid: userBranch?.id,
          assettype: assetType,
          lifeyrs: usefullLife === "" ? "1" : usefullLife.trim(),
          assetstatus: assetStatus,
          brand: brand === "" ? "NA" : brand.trim(),
          price: price === "" ? "NA" : price.trim(),
          purchdate: dayjs(dateOfPurchase).isValid()
            ? dateOfPurchase !== submittedDateOfPurchase
              ? dayjs(dateOfPurchase).format("DD-MM-YYYY")
              : submittedDateOfPurchase
            : "NA",
          warntdate: dayjs(warrantyStartDate).isValid()
            ? warrantyStartDate !== submittedWarrantyStartDate
              ? dayjs(warrantyStartDate).format("DD-MM-YYYY")
              : submittedWarrantyStartDate
            : "NA",

          wrntymonth: "1",
          expdate: dayjs(warrantyEndDate).isValid()
            ? warrantyEndDate !== submittedWarrantyEndDate
              ? dayjs(warrantyEndDate).format("DD-MM-YYYY")
              : submittedWarrantyEndDate
            : "NA",
          sellername: soldBy === "" ? "NA" : soldBy.trim(),
          prodimgs:
            fileUrl.length > 0
              ? fileUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          wrntyimage:
            certificateUrl.length > 0
              ? certificateUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          calibrate_status: calibrationStatus,

          calibrate_date: dayjs(calibrationStartDate).isValid()
            ? calibrationStartDate !== submittedCalibrationStartDate
              ? dayjs(calibrationStartDate).format("DD-MM-YYYY")
              : submittedCalibrationStartDate
            : "NA",
          calibrate_req: required === "" ? "NA" : required,
          calibrate_duemonth: "1",
          calibrate_duedate: dayjs(calibrationEndDate).isValid()
            ? calibrationEndDate !== submittedCalibrationEndDate
              ? dayjs(calibrationEndDate).format("DD-MM-YYYY")
              : submittedCalibrationEndDate
            : "NA",
          cmcamc: cmcAmc,

          cmcamc_start: dayjs(cmcAmcStartDate).isValid()
            ? cmcAmcStartDate !== submittedCmcAmcStartDate
              ? dayjs(cmcAmcStartDate).format("DD-MM-YYYY")
              : submittedCmcAmcStartDate
            : "NA",

          cmcamc_due: dayjs(cmcAmcEndDate).isValid()
            ? cmcAmcEndDate !== submittedCmcAmcEndDate
              ? dayjs(cmcAmcEndDate).format("DD-MM-YYYY")
              : submittedCmcAmcEndDate
            : "NA",
          calibformat: dayjs(calibrationStartDate).isValid()
            ? calibrationStartDate !== submittedCalibrationStartDate
              ? dayjs(calibrationStartDate).format("YYYY-MM-DD")
              : submittedCalibrationStartDate
            : "NA",
          purdateformat: dayjs(dateOfPurchase).isValid()
            ? dateOfPurchase !== submittedDateOfPurchase
              ? dayjs(dateOfPurchase).format("YYYY-MM-DD")
              : submittedDateOfPurchase
            : "NA",
          notes: notes === "" ? "NA" : notes.trim(),
          report_names:
            rData?.length === 0
              ? []
              : rData?.map((item) => {
                  return item.report_name;
                }),
          report_ids:
            rData?.length === 0
              ? []
              : rData?.map((item) => {
                  return item.imageid;
                }),
        };

        dispatch(editAssetFun(data));

   

        setToggle(true);
        // navigate(-1);
      }
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(getAsset(data));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setPrice(editData?.price);
      setAssetName(editData?.name);
      setDepartment(editData?.dept);
      setSerialNumber(editData?.serialno);
      setModelNumber(editData?.modelno);
      setWarranty(editData?.wrntmonth);
      setUsefullLife(editData?.lifeyrs);
      setBrand(editData?.brand);
      setSoldBy(editData?.soldby);
      setCalibrationStatus(editData?.calibstatus);
      setDateOfPurchase(dayjs(editData?.dop, "DD-MM-YYYY"));
      setAssetStatus(editData?.status);
      setAssetType(editData?.type);
      setDepartmentId(editData?.departid);
      setNotes(editData?.notes);
      setAssetCode(editData?.code);
      setCmcAmc(editData?.cmcamc);
      setCmcAmcStartDate(dayjs(editData?.cmcamcstart, "DD-MM-YYYY"));
      setCmcAmcEndDate(dayjs(editData?.cmcamcdue, "DD-MM-YYYY"));
      setCalibrationDate(dayjs(editData?.calibdate, "DD-MM-YYYY"));

      setWarrantyStartDate(dayjs(editData?.wrntdate, "DD-MM-YYYY"));
      setWarrantyEndDate(dayjs(editData?.wrntexpdate, "DD-MM-YYYY"));
      setEmail(editData?.selleremail)
      setPhone(editData?.sellermobile)
      setRequired(editData?.calibreq);
      setDueDate(editData?.calibmnth);
      setCalibrationStartDate(dayjs(editData?.calibdate, "DD-MM-YYYY"));
      setCalibrationEndDate(dayjs(editData?.calibduedate, "DD-MM-YYYY"));
      setFileUrl(
        editData?.imgs === "NA" || editData?.imgs === ""
          ? []
          : editData?.imgs?.map((item) => {
              return {
                imageid: item.imgid,
                image: item.imgurl,
              };
            })
      );
      setCertificateUrl(
        editData?.wimg === "NA" || editData?.wimg === ""
          ? []
          : editData?.wimg?.map((item) => {
              return {
                imageid: item.imgid,
                image: item.imgurl,
              };
            })
      );

      setReportData(
        editData?.reportimgs === "NA" || editData?.reportimgs === ""
          ? []
          : editData?.reportimgs?.map((item, index) => {
              return {
                imageid: item.imgid,
                image: item.imgurl,
                report_name: editData?.reportnames[index],
              };
            })
      );
    }
  }, [editData]);

  const handleCloseModal = () => {
    setToggle(false);
    navigate("/all-assets");
  };

  const handleOpenReport = () => {
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
    const filteredData = dataToSend.filter((item) => item.image !== "");
    handleAssetReport(filteredData);
  };

  const handleAddRow = () => {
    setReportData([...reportData, { ...newRow }]);
    setDataToSend([...reportData, { ...newRow }]);
    setNewRow({ ...initialRow });
  };

  const handleRemoveRow = (index) => {
    const updatedData = [...reportData];
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };
    dispatch(assetDeleteReport(dataDelete));
    updatedData.splice(index, 1);
    setReportData(updatedData);
    setDataToSend(updatedData);
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    const updatedData = [...reportData];

    if (file) {
      const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
      const fileType = file.type.split("/")[1];

      if (allowedTypes.includes(fileType)) {
        const splitId = uuid().substring(0, 6);
        const imageid = params.id.substring(0, 6);
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          updatedData[index].imageid = `${imageid}_${splitId}.${fileType}`;
          updatedData[index].image = dataUrl;
          setReportData(updatedData);
          setDataToSend(updatedData);
          let assetRep = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: updatedData[index].imageid,
            image: updatedData[index].image,
            branchid: userBranch?.id,
          };

          dispatch(AddAssetReport(assetRep));
          setFileError("");
        };
        reader.readAsDataURL(file);
      } else {
        setFileError("Please upload pdf, jpg, jpeg or png file");
      }
    } else {
      updatedData[index].imageid = "";
      updatedData[index].image = "";
      setReportData(updatedData);
      setDataToSend(updatedData);
    }
  };
  const handleClearImage = (index) => {
    const updatedData = [...reportData];
    updatedData[index].image = "";
    setReportData(updatedData);
    setDataToSend(updatedData);
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };

    dispatch(assetDeleteReport(dataDelete));
  };

  // const warrantyValidDateRange = (startDate, endDate) => {
  //   if (
  //     !startDate ||
  //     startDate === "NA" ||
  //     startDate === "Invalid date" ||
  //     !endDate ||
  //     endDate === "NA" ||
  //     endDate === "Invalid date"
  //   ) {
  //     setIsWarrantyError("Enter start `and end date");
  //     return;
  //   } else {
  //     setIsWarrantyError(null);
  //   }

  //   if (new Date(startDate) > new Date(endDate)) {
  //     setIsWarrantyError("Invalid date Range");
  //   } else {
  //     setIsWarrantyError(null);
  //   }

  // };
  const warrantyValidDateRange = (startDate, endDate) => {
    if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
      setIsWarrantyError("Enter valid start and end date");
      return;
    } else {
      setIsWarrantyError(null);
    }

    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setIsWarrantyError("Invalid date range");
    } else {
      setIsWarrantyError(null);
    }


  };
  const handleWarrantyStartDate = (date) => {
    setWarrantyStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    warrantyValidDateRange(date, warrantyEndDate);
  };

  const handleWarrantyEndDate = (date) => {
    setWarrantyEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    warrantyValidDateRange(warrantyStartDate, date);
  };

  const handleReportName = (event, index) => {
    const updatedData = [...reportData];
    const selectedReportName = event.target.value;
    updatedData[index].report_name = selectedReportName;
    setReportData(updatedData);
    setDataToSend(updatedData);

    let assetRep = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      image: updatedData[index].image,
      branchid: userBranch?.id,
    };

    dispatch(AddAssetReport(assetRep));
  };

  const handleNameChange = (event, index) => {
    // const updatedData = [...data];
    // updatedData[index].name = event.target.value;
    // setData(updatedData);
  };

  const handleUpload = () => {
    const filteredData = dataToSend.filter((item) => item.image !== "");
    handleAssetReport(filteredData);
    handleCloseReport();
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setSubmittedCalibrationStartDate(editData.calibdate);
      setSubmittedCalibrationEndDate(editData.calibduedate);
      setSubmittedWarrantyStartDate(editData.wrntdate);
      setSubmittedWarrantyEndDate(editData.wrntexpdate);
      setSubmittedCmcAmcStartDate(editData.cmcamcstart);
      setSubmittedCmcAmcEndDate(editData.cmcamcdue);
      setSubmittedDateOfPurchase(editData.dop);
    }
  }, [editData]);

console.log("email phone", email, phone)

useEffect(()=>{
  if(soldBy!=="NA"&&soldBy!==""){
    if(phone===""||phone==="NA"){
      if(phone===""){
        setIsPhoneError("Please fill the field");
      }else{
        setIsPhoneError("Enter valid Phone Number");
      }
     
    }
  
    if(email===""||email==="NA"){
      if(email===""){
        setIsEmailError("Please fill the field");
      }else{
        setIsEmailError("Enter valid email");
      }
      
    }
  }
  },[phone,soldBy,email])
  




  return (
    <OuterBox>
      <TopContainers>
        <TitleHead>Edit Asset</TitleHead>
      </TopContainers>
      <Main>
        <FormWrapper>
          <FormElement>
            <FormTextLabel>Assets Name</FormTextLabel>
            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              placeholder="Asset Name"
              value={assetName}
              onChange={handleAssetName}
            />
            {<HelperText>{isAssetError}</HelperText>}
          </FormElement>
          <FormElement>
            <FormTextLabel>Serial Number</FormTextLabel>
            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              placeholder="Serial Number"
              value={serialNumber}
              onChange={handleSerialNumber}
            />
            {<HelperText>{isSerialNumberError}</HelperText>}
          </FormElement>
          <FormElement>
            <FormTextLabel>Department</FormTextLabel>

            <FormControl>
              {/* <FormSelect
                sx={{ bgcolor: "#EFF5FE" }}
                value={department}
                onChange={handleDepartment}
                displayEmpty
                inputProps={{ "aria-label": "Error text" }}>
                {departmentData?.map((item) => (
                  <MenuItem
                    onClick={() => handleDepartmentId(item)}
                    value={item.departname}>
                    {item.departname}
                  </MenuItem>
                ))}
              </FormSelect> */}

              <SpecialDropDowne>
                <Autocomplete
                  sx={{ bgcolor: "#EFF5FE" }}
                  options={departmentData?.map((option) => ({
                    name: option.departname,
                    id: option?.departid,
                  }))}
                  value={{ name: department, id: departmentId }}
                  getOptionLabel={(option) => option.name} // Specify how to get the label for each option
                  onChange={handleDepartment}
                  renderInput={(params) => (
                    <TextField
                      placeholder="e.g. Emergency Department"
                      {...params}
                    />
                  )}
                />
              </SpecialDropDowne>
            </FormControl>
            {<HelperText>{isDepartmentError}</HelperText>}
          </FormElement>
          <FormElement>
            <FormTextLabel>Model Number</FormTextLabel>
            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              placeholder="e.g. 7356230040"
              value={modelNumber}
              onChange={handleModelNumber}
            />
            {<HelperText>{isModelNumberError}</HelperText>}
          </FormElement>

          <FormElement>
            <FormTextLabel>Assets code</FormTextLabel>
            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              value={assetCode}
              placeholder="Asset code"
              onChange={handleAssetCode}
            />
            <HelperText>{isAssetCodeError}</HelperText>
          </FormElement>

          <FormElement>
            <FormTextLabel>Brand</FormTextLabel>
            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              placeholder="Brand"
              value={brand}
              onChange={handleBrand}
            />
            {<HelperText>{isBrandError}</HelperText>}
          </FormElement>
          <FormElement>
            <FormTextLabel>Seller Name</FormTextLabel>

            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              placeholder="Seller Name"
              value={soldBy}
              onChange={handleSoldBy}
            />
            {<HelperText>{isSoldByError}</HelperText>}
          </FormElement>

          {(soldBy && soldBy!=="NA") && (
                <FormElement>
                  <FormTextLabel>Phone Number</FormTextLabel>

                  <FormInput
                    placeholder="e.g. 8971580265"
                    onChange={handlePhone}
                    value={phone}
                    sx={{ bgcolor: "#EFF5FE" }}
                  />
                  {<HelperText>{isPhoneError}</HelperText>}
                </FormElement>
              )}


              {(soldBy && soldBy!=="NA") && (
                <FormElement>
                  <FormTextLabel>Email</FormTextLabel>

                  <FormInput
                    placeholder="e.g. example@gmail.com"
                    onChange={handleEmail}
                    value={email}
                    sx={{ bgcolor: "#EFF5FE" }}
                  />
                  {<HelperText>{isEmailError}</HelperText>}
                </FormElement>
              )}

          <FormElement>
            <FormTextLabel>Date of Purchase</FormTextLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}>
              <FormInput2
                sx={{
                  bgcolor: "#EFF5FE",
                }}
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
                format="DD-MM-YYYY"
                value={dateOfPurchase}
                onChange={handlePurchaseDate}
              />
            </LocalizationProvider>
            {<HelperText>{isDateOfPurchaseError}</HelperText>}
          </FormElement>

          <FormElement>
            <FormTextLabel>Useful Life</FormTextLabel>
            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              type="text"
              placeholder="Useful Life"
              value={usefullLife}
              onChange={handleUsefulLife}
            />
            {<HelperText>{isUsefullLifeError}</HelperText>}
          </FormElement>

          <FormElement>
            <FormTextLabel>Price</FormTextLabel>

            <FormInput
              sx={{ bgcolor: "#EFF5FE" }}
              placeholder="Price"
              value={price}
              onChange={handlePrice}
            />
            {<HelperText>{isPriceError}</HelperText>}
          </FormElement>

          <FormElement>
            <CheckBoxTextLabel>Warranty</CheckBoxTextLabel>
            <YesDateBox>
              <DateBox padding="5px" bgcolor="#EFF5FE">
                <CustomText fontWeight="600">Warranty start date</CustomText>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locale}>
                  <HalfInput2
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    format="DD-MM-YYYY"
                    value={warrantyStartDate}
                    sx={{ bgcolor: "white" }}
                    onChange={handleWarrantyStartDate}
                  />
                </LocalizationProvider>
              </DateBox>

              <DateBox padding="5px" bgcolor="#EFF5FE">
                <CustomText fontWeight="600">Warranty end date</CustomText>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locale}>
                  <HalfInput2
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    sx={{ bgcolor: "white" }}
                    format="DD-MM-YYYY"
                    value={warrantyEndDate}
                    type="date"
                    onChange={handleWarrantyEndDate}
                  />
                </LocalizationProvider>
              </DateBox>
            </YesDateBox>
            {<HelperText>{isWarrantyError}</HelperText>}
          </FormElement>

          <FormElement>
            <CheckBoxTextLabel>Asset Type</CheckBoxTextLabel>
            <CheckBoxContainer>
              <FormRadio>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={assetType}
                  onChange={handleAssetType}>
                  <FormControlLabel
                    value="New"
                    control={<Radio size="small" />}
                    label={<CustomLabel>New</CustomLabel>}
                  />
                  <FormControlLabel
                    value="Old"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Old</CustomLabel>}
                  />
                  <FormControlLabel
                    value="Refurbished"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Refurbished</CustomLabel>}
                  />
                </RadioGroup>
                {<HelperText>{isAssetTypeError}</HelperText>}
              </FormRadio>
            </CheckBoxContainer>
          </FormElement>

          <FormElement>
            <CheckBoxTextLabel>Assets Status</CheckBoxTextLabel>
            <CheckBoxContainer>
              <FormRadio>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={assetStatus}
                  onChange={handleAssetStatus}>
                  <FormControlLabel
                    value="Working"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Working</CustomLabel>}
                  />
                  <FormControlLabel
                    value="Not working"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Not working</CustomLabel>}
                  />
                  <FormControlLabel
                    value="Need Service"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Need Service</CustomLabel>}
                  />
                  <FormControlLabel
                    value="Discard"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Discard</CustomLabel>}
                  />
                </RadioGroup>
                {<HelperText>{isAssetStatusError}</HelperText>}
              </FormRadio>
            </CheckBoxContainer>
          </FormElement>

          <FormElement>
            <CheckBoxTextLabel>Calibration Status</CheckBoxTextLabel>
            <CheckBoxContainer>
              <FormRadio>
                <RadioGroup
                  value={calibrationStatus}
                  onChange={handleCalibrationStatus}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group">
                  <FormControlLabel
                    value="Yes"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Calibrated</CustomLabel>}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio size="small" />}
                    label={<CustomLabel>Not calibrated</CustomLabel>}
                  />
                </RadioGroup>
                {<HelperText>{isCalibrationStatusError}</HelperText>}
              </FormRadio>
              {calibrationStatus === "Yes" && (
                <YesDateBox>
                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600">
                      Calibration Start Date
                    </CustomText>

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <HalfInput2
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                        value={calibrationStartDate}
                        sx={{ bgcolor: "white" }}
                        onChange={handleCalibrationStartDate}
                      />
                    </LocalizationProvider>
                    <Box>
                      {<HelperText>{isCalibrationDateError}</HelperText>}
                    </Box>
                  </DateBox>
                  <DueDateBox>
                    <DateBox padding="5px" bgcolor="#EFF5FE">
                      <CustomText fontWeight="600">
                        Calibration End Date
                      </CustomText>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}>
                        <HalfInput2
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                          format="DD-MM-YYYY"
                          value={calibrationEndDate}
                          sx={{ bgcolor: "white" }}
                          onChange={handleCalibrationEndDate}
                        />
                      </LocalizationProvider>
                      <Box>
                        {
                          <Typography
                            variant="caption"
                            sx={{ color: "#EFF5FE" }}>
                            {isCalibrationDateError}
                          </Typography>
                        }
                      </Box>
                      {/* {<HelperText>{isCalibrationDateError}</HelperText>} */}
                    </DateBox>
                  </DueDateBox>
                </YesDateBox>
              )}
              {calibrationStatus === "No" && (
                <DateBox
                  bgcolor="#EFF5FE"
                  padding="5px"
                  maxHeight="88px"
                  maxWidth="258px">
                  <CustomText fontWeight="600" textAlign={"left"}>
                    Required ?
                  </CustomText>
                  <FormRadio>
                    <Box bgcolor="white" padding="3px" borderRadius="6px">
                      <RadioGroup
                        onChange={handleRequired}
                        value={required}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group">
                        <FormControlLabel
                          value="Required"
                          control={<Radio size="small" />}
                          label={<CustomText>Required</CustomText>}
                        />
                        <FormControlLabel
                          value="Not Required"
                          control={<Radio size="small" />}
                          label={<CustomText>Not Required</CustomText>}
                        />
                      </RadioGroup>
                      {<HelperText>{isRequiredError}</HelperText>}
                    </Box>
                  </FormRadio>
                </DateBox>
              )}
            </CheckBoxContainer>
          </FormElement>

          <FormElement>
            <CheckBoxTextLabel>CMC/AMC</CheckBoxTextLabel>
            <CheckBoxContainer>
              <FormRadio onChange={handleAmcCmc}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  value={cmcAmc}
                  name="row-radio-buttons-group">
                  <FormControlLabel
                    value="CMC"
                    control={<Radio size="small" />}
                    label={<CustomLabel>CMC</CustomLabel>}
                  />
                  <FormControlLabel
                    value="AMC"
                    control={<Radio size="small" />}
                    label={<CustomLabel>AMC</CustomLabel>}
                  />
                  <FormControlLabel
                    value="None"
                    control={<Radio size="small" />}
                    label={<CustomLabel>None</CustomLabel>}
                  />
                </RadioGroup>
                {<HelperText>{isCmcAmcError}</HelperText>}
              </FormRadio>
              {cmcAmc === "AMC" && (
                <YesDateBox>
                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600">Start Date</CustomText>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <HalfInput2
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                        format="DD-MM-YYYY"
                        value={cmcAmcStartDate}
                        sx={{ bgcolor: "white" }}
                        onChange={handleAmcCmcStartDateChange}
                      />
                    </LocalizationProvider>
                  </DateBox>
                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600">End Date</CustomText>

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <HalfInput2
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                        format="DD-MM-YYYY"
                        value={cmcAmcEndDate}
                        sx={{ bgcolor: "white" }}
                        onChange={handleAmcCmcEndDateChange}
                      />
                    </LocalizationProvider>
                  </DateBox>
                </YesDateBox>
              )}

              {cmcAmc === "CMC" && (
                <YesDateBox>
                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600">Start Date</CustomText>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <HalfInput2
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                        format="DD-MM-YYYY"
                        value={cmcAmcStartDate}
                        sx={{ bgcolor: "white" }}
                        onChange={handleAmcCmcStartDateChange}
                      />
                    </LocalizationProvider>
                  </DateBox>
                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600">End Date</CustomText>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <HalfInput2
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                        format="DD-MM-YYYY"
                        value={cmcAmcEndDate}
                        sx={{ bgcolor: "white" }}
                        onChange={handleAmcCmcEndDateChange}
                      />
                    </LocalizationProvider>
                  </DateBox>
                </YesDateBox>
              )}
              {<HelperText>{isAmcEndDateError}</HelperText>}
              {<HelperText>{isCmcEndDateError}</HelperText>}
            </CheckBoxContainer>
          </FormElement>
        </FormWrapper>
        <BottomElement>
          <NotesTextLabel>Notes</NotesTextLabel>
          <NotesInput
            placeholder="Notes"
            value={notes}
            onChange={handleNotes}
            sx={{ bgcolor: "#EFF5FE", width: "100%" }}
          />
          {/* { <HelperText>Error text</HelperText>} */}
        </BottomElement>
      </Main>
      <UploadContainer>
        <>
          <ButtonWrapper>
            <>
              <UploadButton
                onClick={() => setPhotoFlag(!photoFlag)}
                sx={{
                  "&:hover": {
                    transform: "scale3d(1.05, 1.05, 2)",
                    cursor: "pointer",
                    color: "#FFF7E9",
                    bgcolor: "#F78117",
                  },
                  bgcolor: "#ADC2EC",
                }}>
                <AddPhotoAlternateIcon sx={{ color: "#1746A2" }} />
                Edit Photos
              </UploadButton>
            </>

            <UploadButton
              onClick={() => setCertificateFlag(!certificateFlag)}
              sx={{
                "&:hover": {
                  transform: "scale3d(1.05, 1.05, 2)",
                  cursor: "pointer",
                  color: "#FFF7E9",
                  bgcolor: "#F78117",
                },
                bgcolor: "#ADC2EC",
              }}>
              <VideoLibraryIcon sx={{ color: "#1746A2" }} />
              Warranty Certificate
            </UploadButton>
          </ButtonWrapper>

          <FlexBox>
            <ImageOuterBox
              sx={{
                textAlign: "start",
                color: "#1746A2",
              }}>
              {/* {(photoFlag || fileUrl?.length > 0) && (
                <> */}
              <PhotoText>{/* Edit photos */}</PhotoText>
              <UploadImageBox>
                {fileUrl?.length < 3 && (
                  <>
                    <DotedBox>
                      <label for="img">
                        {" "}
                        <AddIcon sx={{ marginTop: "2%", cursor: "pointer" }} />
                      </label>
                    </DotedBox>
                    <input
                      type="file"
                      name="uploadfile"
                      id="img"
                      style={{
                        display: "none",

                        width: "42px",
                        height: "42px",
                      }}
                      onChange={handlePhotoUpload}
                    />
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {fileUrl !== "NA" &&
                    fileUrl?.map((image, index) => (
                      <ImageBox key={index} className="image-item">
                        <Badge
                          sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                          badgeContent={
                            <RemoveCircleIcon
                              sx={{
                                width: "10px",
                                height: "10px",
                                color: "red",
                              }}
                              onClick={() => removePhoto(image.imageid)}
                            />
                          }>
                          {image?.image !== undefined ? (
                            <Photo
                              as="img"
                              src={image?.image}
                              alt={image?.image}
                            />
                          ) : (
                            <Photo
                              as="img"
                              src={image?.imgurl}
                              alt={image?.imgurl}
                            />
                          )}
                          {/* {image?.imageid!==undefined &&  <Photo as="img"
                              src={image?.imageid}
                              alt={image?.imageid}

                            />} */}
                        </Badge>
                      </ImageBox>
                    ))}
                </Box>
              </UploadImageBox>
              {/* </>
              )} */}

              <Box style={{ color: "red", fontSize: "12px" }}>
                <>
                  {(photoFlag || fileUrl?.length > 0) && (
                    <ImageWarningText>
                      Format should be either in jpg, jpeg or png.
                    </ImageWarningText>
                  )}
                  {<HelperText>{isPhotoError}</HelperText>}
                </>
              </Box>
            </ImageOuterBox>

            <ImageRightOuterBox
              sx={{
                textAlign: "start",
                color: "#1746A2",
              }}>
              {(certificateFlag || certificateUrl?.length > 0) && (
                <>
                  {/* Edit Certificate */}
                  <UploadImageBox>
                    {certificateUrl?.length <= 3 && (
                      <>
                        <DotedBox
                        // type="file"
                        >
                          <label for="img-2">
                            {" "}
                            <AddIcon
                              sx={{ marginTop: "2%", cursor: "pointer" }}
                            />
                          </label>
                          {/* <AddIcon
                          style={"isDragging" ? { color: "red" } : null}
                       
                        //   {..."dragProps"}
                        /> */}
                        </DotedBox>

                        <input
                          type="file"
                          name="uploadfile"
                          id="img-2"
                          style={{
                            display: "none",

                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handleCertificateUpload}
                        />
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {certificateUrl !== "NA" &&
                        certificateUrl?.map((image, index) => (
                          <ImageBox key={index} className="image-item">
                            <Badge
                              sx={{ color: "rgba(255, 115, 29, 0.5)" }}
                              badgeContent={
                                <RemoveCircleIcon
                                  sx={{
                                    width: "10px",
                                    height: "10px",
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    removeCertificate(image.imageid)
                                  }
                                />
                              }>
                              {image?.image !== undefined ? (
                                <Photo
                                  as="img"
                                  src={
                                    image?.fileType === "pdf" ||
                                    image?.imageid?.split(".")[1] === "pdf"
                                      ? pdfImage
                                      : image?.image
                                  }
                                  alt={image?.image}
                                />
                              ) : (
                                <Photo
                                  as="img"
                                  src={
                                    image?.fileType === "pdf" ||
                                    image?.imageid?.split(".")[1] === "pdf"
                                      ? pdfImage
                                      : image?.image
                                  }
                                  alt={image?.imgurl}
                                />
                              )}
                            </Badge>
                          </ImageBox>
                        ))}
                    </Box>
                  </UploadImageBox>
                </>
              )}
              <Box style={{ color: "red", fontSize: "12px" }}>
                <>
                  {(certificateFlag || certificateUrl?.length > 0) && (
                    <ImageWarningText color="#1746A2">
                      Format should be either in jpg, jpeg, png or pdf.
                    </ImageWarningText>
                  )}
                  {<HelperText>{isCertificateError}</HelperText>}
                </>
              </Box>
            </ImageRightOuterBox>
          </FlexBox>

          <Box>
            <AssetReportBox>
              {reportData?.map((row, index) => (
                <div
                  style={{ display: "flex", padding: "10px", gap: "10px" }}
                  key={index}>
                  {row.image ? (
                    <ImageSet
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: "#EFF5FE",
                      }}>
                      <Box
                        width={"50px"}
                        height={"50px"}
                        as="img"
                        src={`${
                          row.imageid?.split(".")?.pop() === "pdf"
                            ? pdfImage
                            : row.image
                        }`}
                        alt="Selected"
                      />

                      <Button
                        onClick={() => handleClearImage(index)}
                        sx={{
                          borderRadius: "0 10px 10px 0",
                          height: "45px",
                          bgcolor: "#839ED0",
                          "&:hover": {
                            bgcolor: "#1746A2",
                          },
                        }}>
                        <IconButton>
                          <CancelIcon sx={{ color: "white" }} />
                        </IconButton>
                      </Button>
                    </ImageSet>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        onChange={(event) => handleImageUpload(event, index)}
                        style={{ display: "none" }}
                        id="file-upload"
                      />

                      <label htmlFor="file-upload">
                        <ButtonGroup
                          component="span"
                          sx={{
                            textTransform: "none",
                            height: "45px",
                            borderRadius: "10px",
                          }}
                          aria-label="split button">
                          <Button
                            component="span"
                            sx={{
                              bglocor: "#EFF5FE",
                              color: "black",
                              textTransform: "none",
                              borderRadius: "10px 0 0 10px",
                              height: "45px",
                            }}>
                            {" "}
                            Upload IMG/PDF{" "}
                          </Button>
                          <Button
                            component="span"
                            sx={{
                              bgcolor: "#839ED0",
                              height: "45px",
                              "&:hover": {
                                bgcolor: "#1746A2",
                              },
                            }}
                            aria-label="select merge strategy"
                            aria-haspopup="menu">
                            <FileUploadIcon sx={{ color: "white" }} />
                          </Button>
                        </ButtonGroup>
                      </label>
                    </>
                  )}
                  <Select
                    sx={{ bgcolor: "#EFF5FE", height: "45px" }}
                    value={row.report_name}
                    onChange={(event) => handleReportName(event, index)}>
                    <MenuItem value="Service Report">Service Report</MenuItem>
                    <MenuItem value="Installation Report">
                      Installation Report
                    </MenuItem>
                    <MenuItem value="Warranty Document">
                      Warranty Document
                    </MenuItem>
                    <MenuItem value="Calibration Document">
                      Calibration Document
                    </MenuItem>
                    <MenuItem value="Service Invoice">Service Invoice</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>

                  <IconButton onClick={() => handleRemoveRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}

              <Typography>{fileError}</Typography>
              <Box
                sx={{
                  display: "flex",
                  minWidth: "240px",
                  paddingTop: "10px",
                  justifyContent: "space-between",
                }}>
                <UploadButton
                  onClick={handleAddRow}
                  sx={{
                    bgcolor: "#ADC2EC",
                  }}>
                  <DataThresholdingIcon sx={{ color: "#1746A2" }} />
                  Upload Report
                </UploadButton>
              </Box>
            </AssetReportBox>
          </Box>
          <ButtonBox>
            <SubmitButton
              variant="outlined"
              size="large"
              sx={{
                border: "2px solid #1746A2",
                color: "#1746A2",
                bgcolor: "white",
              }}
              onClick={() => navigate(-1)}>
              Cancel
            </SubmitButton>

            <SubmitButton
              variant="contained"
              disabled={userAccess?.editprod !== "Yes"}
              size="large"
              sx={{
                bgcolor: "#1746A2",

                cursor: "pointer",
                color: "white",
              }}
              onClick={handleSubmit}>
              Update
            </SubmitButton>
          </ButtonBox>
        </>
      </UploadContainer>
      <EditAssetModal
        status={status}
        handleCloseModal={handleCloseModal}
        toggle={toggle}
      />
      <ImageDeleteModal handleClose={handleClose} open={open} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </OuterBox>
  );
}

export default EditAssetPage;
