import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import serviceSubmit from "../images/serviceSubmit.png";
import taskFailed from "../images/taskFailed.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "350px", lg: "350px", md: "300px", sm: "300px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageBox = styled(Box)(({ theme }) => ({

  width:"300px",
  height:"300px",
  
 [theme.breakpoints.down("xl")]: {
  width:"250px",
  height:"250px",
 },
 [theme.breakpoints.down("lg")]: {
  width:"200px",
  height:"200px",
 },
 [theme.breakpoints.down("md")]: {
  width:"180px",
  height:"180px",
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));




export default function ServiceSuccess({
  open,
  close,
  flag,
}) {





  return (
    <div>
      <Modal
        keepMounted
        open={(open||!open) && flag}
        onClose={close}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Box
            sx={{
              color: "red",
              display: "flex",
              justifyContent:'center',
              alignItems:'center',
              gap: "20px",
              flexDirection: "column",
              marginTop: "20px",
            }}>

            
{open &&
<>
<ImageBox as="img" src={serviceSubmit} alt="submit" />


            <Typography sx={{ mt: 2, color: "green", textAlign: "center" }}>
              {`Service Company Added Sucessfully`}
            </Typography></>}
            
            {open===false &&
            <>
<ImageBox as="img" src={taskFailed} alt="failed" />
<Typography sx={{ mt: 2, color: "red", textAlign: "center" }}>
  {`Service Company Submission Failed`}
  </Typography>
  </>
  
  }


            <Button
              variant="contained"
              onClick={close}
              sx={{
                borderRadius: "35px",

                margin: "auto",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              size="large">
              Done
            </Button>
          </Box>
        </ModalBox>
      </Modal>
    </div>
  );
}
